import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import compose from 'recompose/compose';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import {
  Typography,
  Button,
  Container,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import TagManager from 'react-gtm-module';
import styles from './styles';

const UPDATE_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-search-settings.graphql' );
const UPDATE_ADVANCED_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-advanced-search-settings.graphql' );
const SAVED_SEARCHED_KEYWORD = loader( '../../../graphql/schema/search/mutations/saved-searched-keyword.graphql' );

const SearchBox = ( props ) => {
  const {
    classes,
    history,
    altTheme,
    initialValues,
    link,
    location,
    disable,
  } = props;

  const apolloClient = useApolloClient();

  const [updateSearchSettings] = useMutation( UPDATE_SEARCH_SETTINGS, {
    onCompleted() {
      const searchObject = JSON.parse( localStorage.getItem( 'searchObj' ) );
      const searchText = searchObject.search_text.replace( '’', "'" );
      const encodedSearchString = btoa( unescape( encodeURIComponent( searchText ) ) );
      if ( searchObject ) {
        const resultsURL = `search-results?search_text=${encodedSearchString}&page=${searchObject.page || 1}&per_page=${50}&sort_by=${searchObject.sort_by || 'relevance'}&filters=`;
        history.push( resultsURL );
      }
    },
  } );

  const [updateAdvancedSearchSettings] = useMutation( UPDATE_ADVANCED_SEARCH_SETTINGS );
  const [updateSavedKeyword] = useMutation( SAVED_SEARCHED_KEYWORD );

  useEffect( () => {
    if ( location.pathname === '/' ) {
      apolloClient.writeData( { data: { isSearchBoxDisable: false } } );
    }
  }, [apolloClient, location.pathname] );

  const onSubmit = ( values ) => {
    const keywordText = ( values && values.keyword ) ? values.keyword : '';
    const allValues = {
      search_text: keywordText,
      page: 1,
      sort_by: 'relevance',
      userQuery: '',
      initialFilter: {},
      optionalFilters: {},
    };
    localStorage.setItem( 'searchObj', JSON.stringify( allValues ) );
    updateSearchSettings( {
      variables: {
        data: {
          keyword: keywordText,
          from: 0,
          page: '',
        },
      },
    } );

    if ( keywordText ) {
      // const tagManagerArgs = {
      //   gtmId: 'UA-156427794-1',
      //   events: {
      //     searchKeyword: keywordText,
      //   },
      // };
      // TagManager.initialize( tagManagerArgs );
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push( {
        event: 'search',
        searchTerm: keywordText, // this should be dynamically replaced with an actual search query
      } );

      updateSavedKeyword( {
        variables: {
          keyword: values.keyword,
        },
      } );
    }

    updateAdvancedSearchSettings( {
      variables: {
        data: {
          input_query: '',
        },
      },
    } );
  };

  const redirectToOldSite = ( url ) => {
    history.push( url );
  };

  const redirectToSearchResults = () => {
    const resultsURL = `search-results?search_text=&page=${1}&per_page=${50}&sort_by=${'relevance'}&filters=`;
    history.push( resultsURL );
  };

  return (
    <div className={disable ? classes.disableSearchBlockSection : classes.searchBlockSection}>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.searchBlock}>
          <div className={classes.newSearch}>
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              render={( { handleSubmit, submitting } ) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Field
                    id="outlined-keyword-input"
                    placeholder={'Search a phrase such as "Cash transfers"'}
                    type="text"
                    name="keyword"
                    fullWidth
                    autoComplete="off"
                    margin="normal"
                    variant="outlined"
                    component={TextField}
                  />
                  <Button type="submit" disabled={submitting}><i className="fa fa-search" /></Button>
                  {link && <div className={classNames( classes.aboutHeadSearch, classes.searchPhaseBtn )} role="presentation" onClick={() => redirectToOldSite( '/about-us' )}>About</div>}
                </form>
              ) }
            />
          </div>
          { location.pathname === '/'
            && (
              <Typography component="i" className={altTheme ? classes.infoText : classes.infoTextColor}>
                Or
                {' '}
                <span className={location.pathname === '/' ? classes.searchLink : classes.searchLinkWhite} role="presentation" onClick={() => redirectToSearchResults()}>explore all records using filters</span>
              </Typography>
            )
          }
        </div>
      </Container>
    </div>
  );
};

SearchBox.defaultProps = {
  altTheme: false,
  link: false,
};

SearchBox.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  altTheme: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  link: PropTypes.bool,
  location: PropTypes.object.isRequired,
  disable: PropTypes.bool.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( SearchBox );
