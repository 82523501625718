/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import ReactHtmlParser from 'react-html-parser';

import {
  Container, Grid,
} from '@material-ui/core';
import Loading from '../../components/ui/loading/loading';
import styles from './styles';

const DECISIONTREE = loader( '../../graphql/schema/home/decision-tree.graphql' );


const DecisionTree = ( props ) => {
  const { classes, history } = props;

  const handleBackToHome = () => {
    history.push( '/' );
  };

  const { data, loading } = useQuery( DECISIONTREE );


  if ( loading ) {
    return <Loading theme="fullHeight" />;
  }

  return (
    <Typography variant="body1" component="div">
      <Helmet>
        <title>DEP Decision Tree</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.decisionContainer}>
        <div>
          <Typography component="p" onClick={handleBackToHome} className={classes.decisionBackBtn}>
            <i className="fas fa-chevron-left" />
            Back
          </Typography>
        </div>
        <Typography variant="h1">
          {data.decisiontree.decision_tree_title}
        </Typography>
        <Grid item xs={12} className={classes.decisionContentContainer}>
          {ReactHtmlParser( data.decisiontree.decision_tree_content )}
        </Grid>
      </Container>
    </Typography>
  );
};

DecisionTree.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( DecisionTree );
