import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { Helmet } from 'react-helmet';
import SearchBanner from '../../components/search/search-banner/search-banner';
import WelcomeBanner from './welcome-banner/welcome-banner';
import SearchBox from '../../components/search/search-box/search-box';
import AdvancedSearch from '../../components/search/advanced-search/advanced-search';
import Sectors from './sectors/sectors';
import PreviousSearch from './previous-searches/previous-searches';
import PopularSearches from './popular-searches/popular-searches';
import RecentSearches from './recent-searches/recent-searches';
import SavedSearches from './saved-searches/saved-searches';
import ImportantKeyPoints from './important-key-points/important_key_points';
import NewestContent from './newest-content/newest-content';
import Sponsors from './sponsors/sponsors';
import DecisionDialog from '../../components/decision-dialog/decision-dialog';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
  },
  searchFilter: {
    paddingBottom: '27px',
    '& h3': {
      color: theme.palette.common.darkBlue,
      marginBottom: '0',
      fontWeight: '300',
      borderBottom: '1px solid #153180',
      paddingBottom: '10px',
      fontSize: 20,
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  advancedSearchBlocks: {
    paddingTop: '26px',
  },
  searchBanner: {
    paddingTop: '3px',
  },
} );

const GET_AUTH_INFO = loader( '../../graphql/schema/auth/auth-info.graphql' );

const Home = ( props ) => {
  const { classes } = props;

  const [keywordSearchBoxDisable, setKeywordSearchBoxDisable] = useState( false );
  const [advancedSearchBoxDisable, setAdvancedSearchBoxDisable] = useState( true );
  const [advancedFirstTimePopup, setAdvancedFirstTimePopup] = useState( false );

  const { data } = useQuery( GET_AUTH_INFO );

  const previousSearch = Cookies.getJSON( 'previousSearch' );

  const handleAdvancedSearch = ( value ) => {
    if ( value === 'open' ) {
      setKeywordSearchBoxDisable( true );
      setAdvancedSearchBoxDisable( false );
      setAdvancedFirstTimePopup( true );
    } else {
      setKeywordSearchBoxDisable( false );
      setAdvancedSearchBoxDisable( true );
      setAdvancedFirstTimePopup( false );
    }
  };

  return (
    <Typography variant="body1" component="div">
      <Helmet>
        <title>3ie Development Evidence Portal | 3ie</title>
      </Helmet>
      <div className={classes.landingPage}>
        <WelcomeBanner />
        <div className={classes.searchBanner}>
          <SearchBanner />
        </div>
        <div className={classes.advancedSearchBlocks}>
          <SearchBox disable={keywordSearchBoxDisable} initialValues={ { keyword: '' } } redirect />
          <AdvancedSearch showAdvFirstTimePopup={advancedFirstTimePopup} setAdvancedFirstTimePopup={setAdvancedFirstTimePopup} handleAdvancedSearch={handleAdvancedSearch} disable={advancedSearchBoxDisable} initialValues={ { userQuery: '', initialFilter: { type: null, value: null } } } redirect />
        </div>
        <Sectors />
        <div className={classes.searchFilter}>
          <Container maxWidth="lg" className={classes.container}>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} sm={6}>
                { data.isLoggedIn ? <RecentSearches /> : ( previousSearch && <PreviousSearch /> ) }
              </Grid>
              <Grid item xs={12} sm={previousSearch ? 6 : 12}>
                { data.isLoggedIn ? <SavedSearches /> : <PopularSearches /> }
              </Grid>
            </Grid>
          </Container>
        </div>
        <NewestContent />
        <ImportantKeyPoints />
        <Sponsors />
      </div>
      <DecisionDialog
        buttonText="Ok"
        decisionFirstTimePopupOpen
        setDecisionFirstTimePopup
      />
    </Typography>
  );
};

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Home );
