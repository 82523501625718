import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Scrollbars } from 'react-custom-scrollbars';
import ReactTooltip from 'react-tooltip';
import FilterIcon from '../../../../assets/img/outcomes.svg';
import InfoBlue from '../../../../assets/img/info-blue.svg';

const EvaluationMethod = ( props ) => {
  const {
    classes, filters, isLoading, checkedListAll, handleEvmFiltersTest,
  } = props;

  const [open, setOpen] = useState( !!( checkedListAll && checkedListAll.length > 0 ) );

  const handleClick = () => {
    setOpen( !open );
  };

  const renderEvm = ( methods ) => methods.map( ( info ) => {
    let checked = false;
    let list = '';
    if ( checkedListAll && checkedListAll.indexOf( info.key ) > -1 ) {
      checked = true;
    }
    if ( info.key !== '' ) {
      list = (
        <ListItem button className={classes.nested} key={info.key}>
          <ListItemText className={classes.filterItem}>
            <FormControlLabel
              className={classes.checkBox}
              control={(
                <Checkbox
                  value={info.key}
                  classes={{
                    root: classes.checkBox,
                    checked: classes.checked,
                  }}
                  checked={checked}
                  onClick={!isLoading && handleEvmFiltersTest}
                  disabled={isLoading}
                  disableRipple
                />
                    )}
              label={`${info.key} (${info.doc_count})`}
            />
          </ListItemText>
        </ListItem>
      );
    }
    return list;
  } );

  return (
    <List className={classes.filterMenu}>
      <ListItem button onClick={handleClick} className={classes.filterHead}>
        <ListItemText>
          <div className={classes.SVG__Filter}>
            <img src={FilterIcon} alt="" />
            &nbsp;
            Evaluation Method
            <img data-for="evmInfo" data-event="click focus mouseover" data-tip className={classes.infoIcon} src={InfoBlue} alt="" />
            <ReactTooltip id="evmInfo" className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="right" type="light" effect="solid">
              <span>
              Impact evaluation method used in the study. Please note that applying any value will filter out any systematic reviews.
              </span>
            </ReactTooltip>
          </div>
        </ListItemText>
        {
          open ? <i className="fa fa-caret-up" aria-hidden="true" /> : <i className="fa fa-caret-down" aria-hidden="true" />
        }
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit className={classes.filterMenuBlock}>
        <div className={classes.maxHeightScroll}>
          <Scrollbars autoHeight>
            <List component="div" disablePadding>
              {
            filters
              ? (
                renderEvm( filters )
              )
              : ( <span className={classes.noFiltersFound}>No filters for Outcomes</span> )
          }
            </List>
          </Scrollbars>
        </div>
      </Collapse>
    </List>
  );
};

EvaluationMethod.defaultProps = {
  filters: null,
  isLoading: false,
  checkedListAll: null,
};

EvaluationMethod.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  filters: PropTypes.arrayOf( PropTypes.object ),
  handleEvmFiltersTest: PropTypes.func.isRequired,
  checkedListAll: PropTypes.arrayOf( PropTypes.string ),
};

export default EvaluationMethod;
