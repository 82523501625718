/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import compose from 'recompose/compose';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import styles from './styles';
import { scrollToTop } from '../../../utilities/commonFunctions';

const RECORD_COUNTS = loader( '../../../graphql/schema/home/record-count.graphql' );
const UPDATE_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-search-settings.graphql' );
const UPDATE_ADVANCED_SEARCH_SETTINGS = loader( '../../../graphql/schema/search/mutations/update-advanced-search-settings.graphql' );

const WelcomeBanner = ( props ) => {
  const { classes, history } = props;

  const { data } = useQuery( RECORD_COUNTS );

  const [updateSearchSettings] = useMutation( UPDATE_SEARCH_SETTINGS );

  const [updateAdvancedSearchSettings] = useMutation( UPDATE_ADVANCED_SEARCH_SETTINGS );

  const redirectSearchResult = ( value ) => {
    const allValues = {
      search_text: '',
      page: 1,
      sort_by: 'relevance',
      userQuery: '',
      initialFilter: {},
      optionalFilters: {},
    };
    localStorage.setItem( 'searchObj', JSON.stringify( allValues ) );
    const updatedFilters = {
      product_type: [value],
      sector_name: [],
      continents: [],
      threeie_funded: [],
      fcv_status: [],
      countries: [],
      equity_dimension: [],
      primary_theme: [],
      equity_focus: [],
      year_of_publication: [],
      dataset_available: [],
    };
    const encodedString = btoa( JSON.stringify( updatedFilters ) );
    const encodedSearchString = btoa( allValues.search_text );
    const resultsURL = `search-results?search_text=${encodedSearchString}&page=${allValues.page || 1}&per_page=${50}&sort_by=${allValues.sort_by || 'relevance'}&filters=${encodedString}`;
    scrollToTop();
    history.push( resultsURL );
    updateSearchSettings( {
      variables: {
        data: {
          keyword: '',
        },
      },
    } );

    updateAdvancedSearchSettings( {
      variables: {
        data: {
          input_query: '',
        },
      },
    } );
  };

  return (
    <div className={classes.welcomeSection}>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.welcomeContent}>
          <div className={classes.welcomeText}>
            <Typography variant="h2">3ie Development Evidence Portal</Typography>
            <Typography component="p">
              The Development Evidence Portal (DEP) is an expansive and growing repository of rigorous evidence on what works in international development. It contains high-quality impact evaluations, systematic reviews, and evidence gap maps and is the most comprehensive resource for this kind of evidence from low- and middle-income countries. We maintain this portal as a global public good and ensure it is updated frequently. We are committed to keeping it this way.
            </Typography>
            <div className={classes.bannerButtonContainer}>
              <Link to="/about-us">
                <Button>
                  About the DEP
                </Button>
              </Link>
              <a href="https://www.3ieimpact.org/support-us" rel="noopener noreferrer" target="_blank">
                <Button>
                Donate
                </Button>
              </a>
              <Link to="/whats-new">
                <Button className={classes.whatsNewButton}>
                  See what's new
                </Button>
              </Link>
            </div>
          </div>
          <div role="button" tabIndex="0" className={classes.welcomeTextBlock} onKeyPress={() => {}} onClick={() => redirectSearchResult( 'ier' )}>
            <Typography variant="h2">{( data && data.recordCount && data.recordCount.ier > 0 ) ? data.recordCount.ier : 0}</Typography>
            <Typography component="p">impact evaluation records</Typography>
          </div>
          <div role="button" tabIndex="0" className={classes.welcomeTextBlock} onKeyPress={() => {}} onClick={() => redirectSearchResult( 'srr' )}>
            <Typography variant="h2">{( data && data.recordCount && data.recordCount.srr > 0 ) ? data.recordCount.srr : 0}</Typography>
            <Typography component="p">systematic review records</Typography>
          </div>
          <div role="button" tabIndex="0" className={classes.welcomeTextBlock} onKeyPress={() => {}} onClick={() => redirectSearchResult( 'egm' )}>
            <Typography variant="h2">{( data && data.recordCount && data.recordCount.egm > 0 ) ? data.recordCount.egm : 0}</Typography>
            <Typography component="p">
              evidence
              &nbsp;
              <span>gap maps</span>
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  );
};

WelcomeBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( WelcomeBanner );
