/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import ReactHtmlParser from 'react-html-parser';

import {
  Container, Grid,
} from '@material-ui/core';
import Loading from '../../components/ui/loading/loading';
import styles from './styles';

const SUPPORT = loader( '../../graphql/schema/home/support-us.graphql' );


const SupportUs = ( props ) => {
  const { classes, history } = props;

  const handleBackToHome = () => {
    history.push( '/' );
  };

  const { data, loading } = useQuery( SUPPORT );


  if ( loading ) {
    return <Loading theme="fullHeight" />;
  }

  return (
    <Typography variant="body1" component="div">
      <Helmet>
        <title>Support us</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.supportUsContainer}>
        <div>
          <Typography component="p" onClick={handleBackToHome} className={classes.supportUsBackBtn}>
            <i className="fas fa-chevron-left" />
            Back
          </Typography>
        </div>
        <Typography variant="h1">
          {data.support.support_title}
        </Typography>
        <Grid item xs={12} className={classes.supportUsContentContainer}>
          {ReactHtmlParser( data.support.support_content )}
        </Grid>
        <Grid item xs={12}>
          <form action="https://www.paypal.com/donate" method="post" target="_top">
            <input type="hidden" name="hosted_button_id" value="TND2ETMXGER7S" />
            <input formTarget="_blank" className={classes.donateImage} type="image" src="https://pics.paypal.com/00/s/N2I5ZDU1NWYtMzI1NC00NzZlLTk1ZWQtZTczMDFjY2VmMWFi/file.PNG" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
          </form>
        </Grid>
        <hr />
        <Grid item xs={12} className={classes.supportUsContentContainer}>
          <p>
            The International Initiative for Impact Evaluation (3ie) is registered as a 501(c)(3) non-profit organization in the US. Contributions to 3ie are tax-deductible in the US to the extent premitted by law. 3ie&apos;s tax identification number is 26-2681792
          </p>
        </Grid>
      </Container>
    </Typography>
  );
};

SupportUs.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( SupportUs );
