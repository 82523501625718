/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  IconButton,
  Collapse,
} from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import _filter from 'lodash/filter';
import { Rating } from '@material-ui/lab';
import _truncate from 'lodash/truncate';
import ReactHtmlParser from 'react-html-parser';
import globalStyles from '../../layout/globalStyles';
import combineStyles from '../../../utilities/combineStyles';
import { renderConfidenceMessage, renderLowConfidenceMessage } from '../../../utilities/commonFunctions';
import styles from './styles';
import Info from '../../../assets/img/info.svg';

const StyledRating = withStyles( {
  iconFilled: {
    color: '#153180',
  },
} )( Rating );

const SearchResult = ( props ) => {
  const {
    classes, data, bookmarkRecords, handleCheckboxClick, checkedListAll, handleCreateBookmark, handleDeleteBookmark, handleSearchResultDetails,
  } = props;

  const isBookmark = _filter( bookmarkRecords, ( id ) => id === data.id );

  const getSettings = ( resultData ) => {
    const settings = {
      srr: () => ( {
        borderColor: classes.greenBorder, searchType: 'Systematic Review', recordType: 'systematic-review-repository', confidenceIndicator: true, additionalLink: true,
      } ),
      ier: () => ( {
        borderColor: classes.redBorder, searchType: 'Impact Evaluation', recordType: 'impact-evaluation-repository', confidenceIndicator: false, additionalLink: false,
      } ),
      egm: () => ( {
        borderColor: classes.blueBorder, searchType: 'Evidence Gap Map', recordType: 'evidence-maps', confidenceIndicator: false, additionalLink: true,
      } ),
    };

    return settings[resultData.product_type]();
  };

  const getRatingStar = ( resultData ) => {
    const ratingStar = {
      Low: 1,
      Medium: 2,
      High: 3,
    };

    return ratingStar[resultData];
  };

  const [expanded, setExpanded] = useState( false );
  const [settings] = useState( () => getSettings( data ) );
  const [ratingStar] = useState( () => getRatingStar( data.based_on_the_above_assessments_of_the_methods_how_would_you_rate_the_reliability_of_the_review ) );

  const showDetails = () => {
    setExpanded( !expanded );
  };

  const renderAdditionalLink = ( result ) => result && result.map( ( link ) => (
    <li key={link.additional_url}><a href={link.additional_url_address} target="_blank" rel="noopener noreferrer">{link.additional_url}</a></li>
  ) );

  const renderAuthor = ( authors ) => {
    const author = authors.map( ( auth ) => ( auth.author ? auth.author.trim() : auth.author ) );

    return (
      <React.Fragment>
        {author && author.join( '; ' )}
      </React.Fragment>
    );
  };

  const renderConfidenceText = ( confidenceLevel ) => {
    if ( confidenceLevel === 'Low' ) {
      return ( <span> LOW CONFIDENCE: </span> );
    } if ( confidenceLevel === 'Medium' ) {
      return ( <span> MEDIUM CONFIDENCE: </span> );
    } if ( confidenceLevel === 'High' ) {
      return ( <span> HIGH CONFIDENCE: </span> );
    }
    return null;
  };

  let recordTitle = data.title;
  recordTitle = recordTitle.replace( /["~/!@#$%^&*_+=`{}\\:;'<>,.?"\- \t\r\n]+/g, '-' ).toLowerCase();

  const equityDimension = data && data.equity_dimension && data.equity_dimension.filter( ( x ) => x !== 'Not applicable' && x !== 'Not specified' ).join( ', ' );


  let abstractContent = data && ( data.abstract !== 'null' && data.abstract !== null ) ? data.abstract : '';
  let headlineFindingsContent = data && ( data.headline_findings !== 'null' && data.headline_findings !== null ) ? data.headline_findings : '';
  let synopsisContent = '';
  if ( data.threeie_funded === 'true' ) {
    synopsisContent = data && ( data.synopsis !== 'null' && data.synopsis !== null ) ? data.synopsis : '';
  }


  abstractContent = abstractContent.replace( /^\s+|\s+$/gm, '' );
  synopsisContent = synopsisContent.replace( /^\s+|\s+$/gm, '' );
  headlineFindingsContent = headlineFindingsContent.replace( /^\s+|\s+$/gm, '' );


  /* REMOVE SPECIAL CHARACTORS : Start */
  let abstractContentCleanText = '';
  abstractContentCleanText = abstractContent.replace( /<\/?[^>]+(>|$)/g, '' );
  abstractContentCleanText = abstractContentCleanText.replace( /^\s+|\s+$/gm, '' );
  abstractContentCleanText = abstractContentCleanText.toLowerCase();

  let synopsisContentCleanText = '';
  synopsisContentCleanText = synopsisContent.replace( /<\/?[^>]+(>|$)/g, '' );
  synopsisContentCleanText = synopsisContentCleanText.replace( /^\s+|\s+$/gm, '' );
  synopsisContentCleanText = synopsisContentCleanText.toLowerCase();

  let headlineFindingsContentCleanText = '';
  headlineFindingsContentCleanText = headlineFindingsContent.replace( /<\/?[^>]+(>|$)/g, '' );
  headlineFindingsContentCleanText = headlineFindingsContentCleanText.replace( /^\s+|\s+$/gm, '' );
  headlineFindingsContentCleanText = headlineFindingsContentCleanText.toLowerCase();
  /* REMOVE SPECIAL CHARACTORS : End */

  /* REMOVE STATIC TEXT : Start */
  abstractContent = ( abstractContentCleanText != 'not applicable' && abstractContentCleanText != 'not available' && abstractContentCleanText != 'not specified' && abstractContentCleanText != 'not reported' && abstractContentCleanText != 'no abstract' && abstractContentCleanText != 'null' && abstractContentCleanText != null ) ? abstractContent : '';
  synopsisContent = ( synopsisContentCleanText != 'not applicable' && synopsisContentCleanText != 'not available' && synopsisContentCleanText != 'not specified' && synopsisContentCleanText != 'not reported' && synopsisContentCleanText != 'no synopsis' && synopsisContentCleanText != 'null' && synopsisContentCleanText != null ) ? synopsisContent : '';
  headlineFindingsContent = ( headlineFindingsContentCleanText != 'not applicable' && headlineFindingsContentCleanText != 'not available' && headlineFindingsContentCleanText != 'not specified' && headlineFindingsContentCleanText != 'not reported' && headlineFindingsContentCleanText != 'No headline findings' && headlineFindingsContentCleanText != 'null' && headlineFindingsContentCleanText != null ) ? headlineFindingsContent : '';
  /* REMOVE STATIC TEXT : End */

  let listingBoxedContent = '';
  if ( data.product_type === 'ier' ) {
    listingBoxedContent = ( synopsisContent != '' && synopsisContentCleanText != '' ) ? synopsisContent : abstractContent;
  } else if ( data.product_type === 'srr' ) {
    listingBoxedContent = ( headlineFindingsContent != '' && headlineFindingsContentCleanText != '' ) ? headlineFindingsContent : abstractContent;
  } else if ( data.product_type === 'egm' ) {
    listingBoxedContent = ( abstractContent != '' ) ? abstractContent : '';
  }

  let listingBoxedContentCleanText = '';
  listingBoxedContentCleanText = listingBoxedContent.replace( /<\/?[^>]+(>|$)/g, '' );
  listingBoxedContentCleanText = listingBoxedContentCleanText.replace( /^\s+|\s+$/gm, '' );
  if ( listingBoxedContentCleanText === '' ) {
    listingBoxedContent = '';
  }


  return (
    <div className={classes.resultsDetailsContainer}>
      <div className={classes.detailsResults}>
        <div className={classNames( classes.detailsBox, settings.borderColor )} key={data.id}>
          <div className={classNames( classes.checkBoxDetailFlex, classes.checkBoxDetailFlexSearchResults )}>
            <div className={classes.checkBoxDetail}>
              <FormControlLabel
                className={classes.checkBox}
                control={(
                  <Checkbox
                    value={data.id}
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checked,
                    }}
                    disableRipple
                    checked={checkedListAll.includes( data.id )}
                    onChange={handleCheckboxClick}
                  />
              )}
              />
              <Typography className={classes.listingLabelHead} component="label">
                {settings.searchType}
                <span>{data.year_of_publication}</span>
                {data.publication_type && <span>|</span>}
                <span>{data.publication_type}</span>
              </Typography>
            </div>
            <div className={classNames( classes.actions, classes.searchResultActions )}>
              <Typography component="div">
                {data.threeie_funded === 'true' ? <span className={classes.fundedBy3ie}>Funded by 3ie</span> : ''}
                {isBookmark.length > 0
                  ? (
                    <IconButton data-for="bookmark-icon" data-tip disableTouchRipple disableRipple disableFocusRipple className={classNames( classes.bookmarkIcon, classes.defaultIconButton )} aria-label="close" onClick={() => handleDeleteBookmark( data.id )}>
                      <i className="fas fa-bookmark" />
                    </IconButton>
                  )
                  : (
                    <IconButton data-for="bookmark-icon" data-tip disableTouchRipple disableRipple disableFocusRipple className={classNames( classes.bookmarkIcon, classes.defaultIconButton )} aria-label="close" onClick={() => handleCreateBookmark( data.id )}>
                      <i className="far fa-bookmark" />
                    </IconButton>
                  ) }
                <ReactTooltip id="bookmark-icon" className={classes.reactTooltip} globalEventOff="click" place="bottom" type="dark" effect="solid">
                  <span>Bookmark</span>
                </ReactTooltip>
              </Typography>
            </div>
          </div>
          <div className={classes.boxBlock}>
            <div className={classes.detailsHead}>
              <a href={`search-result-details/${settings.recordType}/${recordTitle}/${data.id}`} onClick={() => handleSearchResultDetails( data )}>
                <IconButton disableTouchRipple disableRipple disableFocusRipple className={classes.defaultIconButton} onClick={() => handleSearchResultDetails( data )}>{data.title}</IconButton>
              </a>
              {data.authors && (
              <Typography component="p">
                {renderAuthor( data.authors )}
              </Typography>
              )}

            </div>
            <div className={classNames( classes.sectorHead, classes.sectorHeadSearch )}>
              {data.continent && (
                <React.Fragment>
                  <Typography variant="h3">Sector</Typography>
                  <Typography component="p">
                    <span>{data.sector_name}</span>
                  </Typography>
                </React.Fragment>
              )}
              {data.product_type === 'egm' && data.systematic_reviews && (
                <div className={classes.statusTopGap}>
                  <Typography variant="h3">Systematic reviews</Typography>
                  <Typography component="p">
                    <span>{data.systematic_reviews}</span>
                  </Typography>
                </div>
              )}
              {data.product_type === 'egm' && data.impact_evaluations && (
                <div className={classes.statusTopGap}>
                  <Typography variant="h3">Impact evaluations</Typography>
                  <Typography component="p">
                    <span>{data.impact_evaluations}</span>
                  </Typography>
                </div>
              )}
              {data.product_type === 'srr' && data.study_status && (
                <div className={classes.statusTopGap}>
                  <Typography variant="h3">Status</Typography>
                  <Typography component="p">
                    <span>{data.study_status}</span>
                  </Typography>
                </div>
              )}
              {data.product_type === 'ier' && data.continent[0].countries[0].country && (
                <div className={classes.statusTopGap}>
                  <Typography variant="h3">COUNTRY</Typography>
                  <Typography component="p">
                    <span>{data.continent[0].countries[0].country}</span>
                  </Typography>
                </div>
              )}
              {settings.confidenceIndicator
                && (
                <div className={classes.ratingBlock}>
                  <Typography component="div" display="inline">
                    Confidence Indicator
                    <img data-for={`confidenceIndicator${data.id}`} data-event="click focus mouseover" data-tip className={classes.infoIcon} src={Info} alt="" />
                    <ReactTooltip id={`confidenceIndicator${data.id}`} className={classNames( classes.reactTooltip, classes.reactTooltipClickableLink )} globalEventOff="mouseover" place="bottom" type="light" effect="solid">
                      <span>
                        {renderConfidenceText( data.based_on_the_above_assessments_of_the_methods_how_would_you_rate_the_reliability_of_the_review )}
                        {' '}
                        {ratingStar > 0 && renderConfidenceMessage( )}
                        {ratingStar === undefined && renderLowConfidenceMessage( data.study_status, data.review_type )}
                        {' '}
                        {ratingStar > 0
                          && (
                            <a href="https://www.3ieimpact.org/sites/default/files/2019-04/quality-appraisal-checklist-srdatabase.pdf" rel="noopener noreferrer" target="_blank">here.</a>
                          )
                        }
                      </span>
                    </ReactTooltip>
                  </Typography>
                  <StyledRating
                    name="simple-controlled"
                    value={ratingStar}
                    max={3}
                    size="large"
                    readOnly
                  />
                </div>
                )
              }
              {settings.additionalLink
                && (
                  <ul className={classes.additionalLink}>
                    {renderAdditionalLink( data.additional_url )}
                  </ul>
                )
              }
            </div>
          </div>
          <Collapse in={expanded}>
            <div className={( listingBoxedContent ) ? classes.moreDetailsBox : classNames( classes.sectorHead, classes.hideSynopsis )}>
              <div className={classes.moreDetailContent}>
                {listingBoxedContent && (
                <Typography component="div">
                  {listingBoxedContent && ReactHtmlParser( listingBoxedContent.substring( 0, 480 ) )}
                  {listingBoxedContent && listingBoxedContent.length >= 480 && <Button disableRipple className={classes.readMoreButtonList} onClick={() => handleSearchResultDetails( data )}>Read More</Button>}
                </Typography>
                )}
              </div>
              <div className={classes.personalDetails}>
                {data.product_type !== 'srr' && data.study_status && (
                <p>
                  <span>Status: </span>
                  {data.study_status && data.study_status}
                </p>
                )}
                {data.open_access && (
                <p>
                  <span>Open access: </span>
                  {data.open_access.charAt( 0 ).toUpperCase() + data.open_access.slice( 1 )}
                </p>
                )}
                {equityDimension && (
                <p>
                  <span>Equity dimension: </span>
                  {equityDimension}
                </p>
                )}
                {data.keywords && (
                <p>
                  <span>Keywords: </span>
                  {data.keywords && data.keywords.join( ', ' )}
                </p>
                )}
              </div>
            </div>
          </Collapse>
          <Typography component="p" className={classes.moreDetails} aria-expanded={expanded} onClick={showDetails}>
            Details
            <i className={expanded ? 'fas fa-caret-up' : 'fas fa-caret-down'} />
          </Typography>
        </div>
      </div>
    </div>
  );
};

SearchResult.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  checkedListAll: PropTypes.arrayOf( PropTypes.string ).isRequired,
  handleCreateBookmark: PropTypes.func.isRequired,
  handleSearchResultDetails: PropTypes.func.isRequired,
  handleDeleteBookmark: PropTypes.func.isRequired,
  bookmarkRecords: PropTypes.arrayOf( PropTypes.string ).isRequired,
};

const combinedStyles = combineStyles( globalStyles, styles );

export default withStyles( combinedStyles )( SearchResult );
