/* eslint-disable max-len */
const interventionData = [
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Agricultural chemical policy',
    Definition1: 'Government policies that regulate the use of chemicals in agricultural production',
    DisaggregatedIntervention: 'Regulations on fertiliser use',
    Definition: 'Government policies regulating the types and amount of fertilisers that can be used in agricultural production',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Agricultural chemical policy',
    Definition1: 'Government policies that regulate the use of chemicals in agricultural production',
    DisaggregatedIntervention: 'Regulations on herbicide use',
    Definition: 'Government policies regulating the types and amount of herbicides that can be used in agricultural production',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Agricultural chemical policy',
    Definition1: 'Government policies that regulate the use of chemicals in agricultural production',
    DisaggregatedIntervention: 'Regulations on pesticide use',
    Definition: 'Government policies regulating the types and amount of pesticides that can be used in agricultural production',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Agricultural market policy',
    Definition1: 'Government regulations or incentives that influence agricultural supply, demand, or prices',
    DisaggregatedIntervention: 'Export levies',
    Definition: 'Tax that a country imposes on its exports, which makes them more expensive. Export levies may encourage domestic consumption of domestically produced goods.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Agricultural market policy',
    Definition1: 'Government regulations or incentives that influence agricultural supply, demand, or prices',
    DisaggregatedIntervention: 'Export quotas',
    Definition: 'A restriction imposed by a government on the amount or number of goods or services that may be exported within a given period, usually with the intent of keeping prices of those goods or services low for domestic users.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Agricultural market policy',
    Definition1: 'Government regulations or incentives that influence agricultural supply, demand, or prices',
    DisaggregatedIntervention: 'Import quotas',
    Definition: 'A restriction imposed by a government on the amount or number of goods or services that may be exported within a given period, usually with the intent of keeping prices of those goods or services high for domestic users/firms.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Agricultural market policy',
    Definition1: 'Government regulations or incentives that influence agricultural supply, demand, or prices',
    DisaggregatedIntervention: 'Import tariffs',
    Definition: 'Tax that a country imposes on its imports. A duty exists to make an import more expensive and to thereby encourage people to buy goods produced in their own country. Proponents of their use argue that duties discourage outsourcing of jobs to other countries and make the country more self-sufficient',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Agricultural market policy',
    Definition1: 'Government regulations or incentives that influence agricultural supply, demand, or prices',
    DisaggregatedIntervention: 'Minimum agricultural product tariff',
    Definition: 'Government schemes that allow farmers to determine in advance a fixed price at which they can sell their product',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Agricultural market policy',
    Definition1: 'Government regulations or incentives that influence agricultural supply, demand, or prices',
    DisaggregatedIntervention: 'Production quotas',
    Definition: 'A goal for the production of a good. It is typically set by a government or an organization, and can be applied to an individual worker, firm, industry or country. Quotas can be set high to encourage production, or can be used to restrict production to support a certain price level.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Agricultural research & development',
    Definition1: 'Programmes to promote research and development on agricultural technologies and practices',
    DisaggregatedIntervention: 'Agricultural research and development',
    Definition: 'Research and development for agricultural processes.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Agricultural research & development',
    Definition1: 'Programmes to promote research and development on agricultural technologies and practices',
    DisaggregatedIntervention: 'Financial/tax incentives for agricultural research',
    Definition: 'Interventions that provide tax or financial incentives for research and development activities such as hybridization, new irrigation systems, experimentation with different fertiliser varieties etc.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Agricultural research & development',
    Definition1: 'Programmes to promote research and development on agricultural technologies and practices',
    DisaggregatedIntervention: 'Funding for agricultural research and development',
    Definition: 'Interventions that dispense funding for agricultural research and development',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Biofuel policy',
    Definition1: 'Policies that regulate the production and use of biofuels (e.g., ethanol)',
    DisaggregatedIntervention: 'Biofuel price regulation',
    Definition: 'Policies that regulate the price of biofuels',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Biofuel policy',
    Definition1: 'Policies that regulate the production and use of biofuels (e.g., ethanol)',
    DisaggregatedIntervention: 'Biofuel production subsidies',
    Definition: 'Government subsidies to biofuel producers (includes subsidies for biofuel crop production)',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Biofuel policy',
    Definition1: 'Policies that regulate the production and use of biofuels (e.g., ethanol)',
    DisaggregatedIntervention: 'Regulations on biofuel content of petrol blends',
    Definition: 'Policies that mandate a minimum percentage of biofuel content in petrol blends',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Farming certifications',
    Definition1: 'Confirmations to quality, or certain characteristics of agricultural produce.',
    DisaggregatedIntervention: 'Animal welfare certification',
    Definition: 'Interventions that provide certification or assistance in obtaining certification, or promote certification in animal welfare.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Farming certifications',
    Definition1: 'Confirmations to quality, or certain characteristics of agricultural produce.',
    DisaggregatedIntervention: 'Fair trade certification',
    Definition: 'Interventions that provide certification or assistance in obtaining certification, or promote certification in fair trade',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Farming certifications',
    Definition1: 'Confirmations to quality, or certain characteristics of agricultural produce.',
    DisaggregatedIntervention: 'Organic certification',
    Definition: 'Interventions that provide certification or assistance in obtaining certification, or promote certification in organic standards',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Farming certifications',
    Definition1: 'Confirmations to quality, or certain characteristics of agricultural produce.',
    DisaggregatedIntervention: 'Other certification',
    Definition: 'Interventions that provide certification or assistance in obtaining certification, or promote certification other than those otherwise listed here.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Farming certifications',
    Definition1: 'Confirmations to quality, or certain characteristics of agricultural produce.',
    DisaggregatedIntervention: 'Quality certifications',
    Definition: 'Interventions that provide certification or assistance in obtaining certification, or promote certification in quality standards (other than fair trade and organic)',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Food policy',
    Definition1: 'Government regulations on food production and quality assurance that affect agriculture',
    DisaggregatedIntervention: 'Food safety regulations',
    Definition: 'Government policies that establish quality assurance regulations for agricultural food products (excludes food safety regulations at point of sale)',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Forestry policy',
    Definition1: 'Policies affecting access to and management of forest products and services',
    DisaggregatedIntervention: 'Forest conservation policy',
    Definition: 'Government policies that regulate conservation of forest land (e.g., logging quotas or protected areas)',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Forestry policy',
    Definition1: 'Policies affecting access to and management of forest products and services',
    DisaggregatedIntervention: 'Participatory forest management',
    Definition: 'Policies and programmes that allow direct stakeholders (e.g., people who reside in forests and rely on forest products/services) to participate in all aspects of forest management decision-making. Also called community-based forest management',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Irrigation and water supply',
    Definition1: 'Initiatives to build or improve agricultural irrigation systems and water supply',
    DisaggregatedIntervention: 'Erosion management',
    Definition: 'Interventions to prevent soil erosion from irrigation',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Irrigation and water supply',
    Definition1: 'Initiatives to build or improve agricultural irrigation systems and water supply',
    DisaggregatedIntervention: 'Irrigation infrastructure',
    Definition: 'Interventions that build or improve agricultural irrigation systems. For example, provisions of public goods such as canals, dams, cheap private irrigation equipment such as pump sets etc.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Irrigation and water supply',
    Definition1: 'Initiatives to build or improve agricultural irrigation systems and water supply',
    DisaggregatedIntervention: 'Water mineralisation',
    Definition: 'Management of the mineral content in water supply for agricultural purposes',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Land titling & reform',
    Definition1: 'Programmes that provide farmers formal property rights on lands they occupy.',
    DisaggregatedIntervention: 'Land redistribution',
    Definition: 'Policies that seek to redistribute land to smallholders for agricultural production.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Land titling & reform',
    Definition1: 'Programmes that provide farmers formal property rights on lands they occupy.',
    DisaggregatedIntervention: 'Land titling and certification',
    Definition: 'Conferring freehold or leasehold rights over land.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Natural resource conservation',
    Definition1: 'Conservancies designed to manage natural resources.',
    DisaggregatedIntervention: 'Ecosystem conservation',
    Definition: 'Preservation of ecosystems, including through awareness campaigns.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Natural resource conservation',
    Definition1: 'Conservancies designed to manage natural resources.',
    DisaggregatedIntervention: 'Invasive species management',
    Definition: 'Identification, tracking, and management of invasive species',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Natural resource conservation',
    Definition1: 'Conservancies designed to manage natural resources.',
    DisaggregatedIntervention: 'Wildlife conservation',
    Definition: 'Preservation of wild animal resources such as anti-poaching measures for endangered species and public/private reserves.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Roads',
    Definition1: 'Initiatives to build or improve roads in largely agricultural areas.',
    DisaggregatedIntervention: 'Feeder road construction in agricultural areas',
    Definition: 'Interventions that facilitate the construction of feeder roads, which are those roads that connect local communities/areas to national roads/highways/corridors (often managed by local districts/municipalities).',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Roads',
    Definition1: 'Initiatives to build or improve roads in largely agricultural areas.',
    DisaggregatedIntervention: 'Feeder road maintenance in agricultural areas',
    Definition: 'Interventions for the maintenance and repair of existing feeder roads, which are those roads that connect local communities/areas to national roads/highways/corridors (often managed by local districts/municipalities).',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Roads',
    Definition1: 'Initiatives to build or improve roads in largely agricultural areas.',
    DisaggregatedIntervention: 'National road construction in agricultural areas',
    Definition: 'Interventions that facilitate the construction of national roads, which are roads often referred to as highways/corridors/motorways (often managed through a national government agency/ministry).',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural policy & infrastructure',
    InterventionGroup: 'Roads',
    Definition1: 'Initiatives to build or improve roads in largely agricultural areas.',
    DisaggregatedIntervention: 'National road maintenance in agricultural areas',
    Definition: 'Interventions for the maintenance and repair of existing national roads, which are roads often referred to as highways/corridors/motorways (often managed through a national government agency/ministry).',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural risk management',
    InterventionGroup: 'Agricultural insurance',
    Definition1: 'Insurance to mitigate risks of pre- or post-harvest loss',
    DisaggregatedIntervention: 'Crop insurance',
    Definition: 'Insurance to cover yield losses due to non-preventable risks at various stages of crop production',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural risk management',
    InterventionGroup: 'Agricultural insurance',
    Definition1: 'Insurance to mitigate risks of pre- or post-harvest loss',
    DisaggregatedIntervention: 'Equipment insurance',
    Definition: 'Insurance to cover damage and malfunctioning of agricultural equipment; warranties',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural risk management',
    InterventionGroup: 'Agricultural insurance',
    Definition1: 'Insurance to mitigate risks of pre- or post-harvest loss',
    DisaggregatedIntervention: 'Index insurance',
    Definition: 'Insurance linked to an index (e.g., rainfall, temperature, yield) rather than actual loss',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural risk management',
    InterventionGroup: 'Agricultural insurance',
    Definition1: 'Insurance to mitigate risks of pre- or post-harvest loss',
    DisaggregatedIntervention: 'Livestock insurance',
    Definition: 'Insurance for farmers and livestock-reorders against livestock death due to diseases, accidents, natural calamities etc.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural risk management',
    InterventionGroup: 'Climate adaption & mitigation strategies',
    Definition1: 'Interventions looking to mitigate the effects of climate change or to adapt to climate conditions.',
    DisaggregatedIntervention: 'Adaptive crop management',
    Definition: 'Crop management techniques in response to climate change, such as crop planting dates, harvest periods, etc.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural risk management',
    InterventionGroup: 'Climate adaption & mitigation strategies',
    Definition1: 'Interventions looking to mitigate the effects of climate change or to adapt to climate conditions.',
    DisaggregatedIntervention: 'Agrobiodiversity',
    Definition: 'Promotion of biodiversity to increase resilience to changing environmental conditions and stresses. This includes selection of crops and cultivars with tolerance to abiotic stresses (e.g. high temperature, drought, flooding, high salt content in soil, pest and disease resistance).',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural risk management',
    InterventionGroup: 'Climate adaption & mitigation strategies',
    Definition1: 'Interventions looking to mitigate the effects of climate change or to adapt to climate conditions.',
    DisaggregatedIntervention: 'Forest resource management',
    Definition: 'Using forest resources as a mitigation strategy for climate change.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural risk management',
    InterventionGroup: 'Climate adaption & mitigation strategies',
    Definition1: 'Interventions looking to mitigate the effects of climate change or to adapt to climate conditions.',
    DisaggregatedIntervention: 'Land reclamation',
    Definition: 'Restoration or improvement of microclimate areas through land reclamation and rejuvenation.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural risk management',
    InterventionGroup: 'Climate adaption & mitigation strategies',
    Definition1: 'Interventions looking to mitigate the effects of climate change or to adapt to climate conditions.',
    DisaggregatedIntervention: 'Sustainable land management',
    Definition: 'Implementation of sustainable land management practices in agricultural activity. Including cover cropping, crop rotations, agroforestry, crop-livestock associations, crop-fish systems and the use of hedges, vegetative buffer strips and other farm landscaping practices.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural risk management',
    InterventionGroup: 'Climate adaption & mitigation strategies',
    Definition1: 'Interventions looking to mitigate the effects of climate change or to adapt to climate conditions.',
    DisaggregatedIntervention: 'Water resource management',
    Definition: 'Adapting water management and farming practices for resilience, e.g. zero tillage, mulching, etc.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural risk management',
    InterventionGroup: 'Commitment devices',
    Definition1: 'Schemes to encourage farmers to make decisions that have favourable short-term/long-term trade-offs, such as commitment savings accounts or pre-ordering fertiliser for future seasons.',
    DisaggregatedIntervention: 'Advance agricultural purchase commitments',
    Definition: 'Schemes to allow farmers to make advance commitments to purchase agricultural inputs (e.g., seed, fertiliser) or equipment. For example, allowing farmers to commit to future fertiliser purchases after harvest, when they do not need fertiliser immediately but have cash at hand',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural risk management',
    InterventionGroup: 'Commitment devices',
    Definition1: 'Schemes to encourage farmers to make decisions that have favourable short-term/long-term trade-offs, such as commitment savings accounts or pre-ordering fertiliser for future seasons.',
    DisaggregatedIntervention: 'Advance agricultural sales commitments',
    Definition: 'Schemes to allow farmers to sell products in advance on the agricultural market for a fixed price, to mitigate risk of future price drops.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural risk management',
    InterventionGroup: 'Commitment devices',
    Definition1: 'Schemes to encourage farmers to make decisions that have favourable short-term/long-term trade-offs, such as commitment savings accounts or pre-ordering fertiliser for future seasons.',
    DisaggregatedIntervention: 'Agricultural commitment savings accounts',
    Definition: 'Savings accounts for farmers that discourage withdrawals (e.g., through withdrawal penalties) and thereby promote saving and capital accumulation for future investment',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural risk management',
    InterventionGroup: 'Credit infrastructure',
    Definition1: 'Schemes to improve farmers’ access to credit',
    DisaggregatedIntervention: 'Access to microcredit',
    Definition: 'Provision of microcredit schemes in the form of individual or group lending, either through a microcredit group in the community, or through a microfinance institution (MFI). These schemes can be provided on their own, or include access to other financial services such as savings and insurance.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural risk management',
    InterventionGroup: 'Credit infrastructure',
    Definition1: 'Schemes to improve farmers’ access to credit',
    DisaggregatedIntervention: 'Formal credit to farmers',
    Definition: 'Interventions to augment a farmer\'s ability to obtain credit from formal banking institutions.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural risk management',
    InterventionGroup: 'Credit infrastructure',
    Definition1: 'Schemes to improve farmers’ access to credit',
    DisaggregatedIntervention: 'Micro-leasing',
    Definition: 'Interventions that provide lease of assets to the poor to access productive assets to generate income',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Agricultural risk management',
    InterventionGroup: 'Credit infrastructure',
    Definition1: 'Schemes to improve farmers’ access to credit',
    DisaggregatedIntervention: 'Rotating/Accumulated savings and credit associations',
    Definition: 'A group of individuals acting as an informal financial institution in the form of an alternative financial vehicle. A ROSCA happens via set contributions and withdrawals to and from a common fund. An ASCRA happens via set contributions with the common fund used for extending credit within or outside of the group. These include Savings and Internal Lending Communities (SILCs) and Self-Help Groups (SHG).',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Food hygiene',
    Definition1: 'Interventions to promote hygienic food handling practices.',
    DisaggregatedIntervention: 'Behavioural food hygiene promotion',
    Definition: 'These are interventions that encourage the practice of proper food hygiene at the household or community level through behavioural promotion, such as using social pressure, environmental cues, nudges, or triggers of disgust and shame.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Food hygiene',
    Definition1: 'Interventions to promote hygienic food handling practices.',
    DisaggregatedIntervention: 'Educational food hygiene promotion',
    Definition: 'These are interventions that encourage the practice of proper food hygiene practices at the household or community level through educational promotion, such as food hygiene education, teaching best practices, and information on food hygiene.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Food hygiene',
    Definition1: 'Interventions to promote hygienic food handling practices.',
    DisaggregatedIntervention: 'Food labelling and advertising regulations',
    Definition: 'Regulations on ways in which food must be packaged, labelled, and advertised. This includes the correct display of nutritional and allergen information and list of ingredients.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Food hygiene',
    Definition1: 'Interventions to promote hygienic food handling practices.',
    DisaggregatedIntervention: 'Food safety regulations',
    Definition: 'Government policies that establish quality assurance regulations for agricultural food products (excludes food safety regulations at point of sale)',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedIntervention: 'Agricultural price stabilisation',
    Definition: 'Government policies that aim to stabilise the prices of agricultural commodities and ensure food security, including food reserves, minimum support prices.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedIntervention: 'Household food production',
    Definition: 'Interventions to encourage home gardening and household animal production to enhance nutrition and food security.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Food security',
    Definition1: 'The extent to which households have adequate and reliable access to enough nutritious food to meet basic dietary needs.',
    DisaggregatedIntervention: 'Promotion and education of composting/food donation',
    Definition: 'Educational interventions that provide information about and promote the practices of composting and donation of excess food to prevent food waste.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Nutrition',
    Definition1: 'Interventions targeting nutrition and diet in patients of all ages.',
    DisaggregatedIntervention: 'Maternal and infant nutrition care',
    Definition: 'Nutritional counselling on healthy diet to reduce low birth weight, appropriate macro and micro nutrient supplementation for women during pregnancy and postpartum, counsel women to improve breastfeeding practices and manage breastfeeding difficulties, optimal feeding of low-birth weight infants, Optimize new-born feeding practices and address additional care needs of infants',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Nutrition',
    Definition1: 'Interventions targeting nutrition and diet in patients of all ages.',
    DisaggregatedIntervention: 'Nutrient fortification in foods',
    Definition: 'Interventions to deliver nutrient fortified foods, such as iron, vitamin A or iodine fortification.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Nutrition',
    Definition1: 'Interventions targeting nutrition and diet in patients of all ages.',
    DisaggregatedIntervention: 'Nutrient supplementation',
    Definition: 'Use of nutrition supplements and specialized food products to prevent and treat nutrition deficiencies (including micro and macro nutrients) .',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Nutrition',
    Definition1: 'Interventions targeting nutrition and diet in patients of all ages.',
    DisaggregatedIntervention: 'Nutrition care throughout the life-course',
    Definition: 'Nutrition counselling and support for appropriate complementary feeding and achieving healthy diets throughout the life-course as well as specialised nutritional care for specific medical conditions in line with the WHO Essential Nutrition Actions.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Nutrition',
    Definition1: 'Interventions targeting nutrition and diet in patients of all ages.',
    DisaggregatedIntervention: 'Nutrition education and behaviour change',
    Definition: 'Interventions targeting unhealthy nutrition and dieting behaviour and encouraging change. This includes overnutrion, as well as malnutrition.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Nutrition',
    Definition1: 'Interventions targeting nutrition and diet in patients of all ages.',
    DisaggregatedIntervention: 'Nutrition in emergencies',
    Definition: 'Infant and young child feeding programmes in emergencies, prevention and control of micronutrient deficiencies in emergencies.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Nutrition',
    Definition1: 'Interventions targeting nutrition and diet in patients of all ages.',
    DisaggregatedIntervention: 'Promotion of healthy eating',
    Definition: 'Interventions specifically focused on the promotion of healthy eating practices, including innovative store designs.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Public works programmes',
    Definition1: 'Public works programmes (PWPs) are activities which entail the payment of a wage (in cash or food), often but not always by the state, in return for the provision of labour. The aim is to enhance employment and produce a physical or social asset, with the overall objective of promoting social protection. They are sometimes classified as labour market interventions depending on whether their function is primarily poverty alleviation or job creation. Sometimes referred to as public employment programmes (PEP) defined as ‘programmes creating state sponsored employment which is not market based (known as Public Works Programmes, Workfare, Welfare to Work, Cash for Work, Employment of Last Resort, Employment Guarantee programmes, etc.)’ (McCord, 2018: 10).',
    DisaggregatedIntervention: 'Food for work',
    Definition: 'Food (in-kind) transfers to poor families and individuals in return from the provision of labour.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Social Transfers',
    Definition1: 'Social transfers are direct transfers, increasingly paid through secure electronic systems, such as directly into bank accounts, mobile phone accounts, or on smart cards. They can be regular or one-off payments and recipients must meet the eligibility criteria. They have the objective of providing immediate relief and reducing poverty, as well as contributing to increased resilience of poor households by enabling them to save, invest and cope better with risks and shocks.',
    DisaggregatedIntervention: 'Asset transfers',
    Definition: 'Provision of productive assets as in-kind support (non-cash goods or services)',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Social Transfers',
    Definition1: 'Social transfers are direct transfers, increasingly paid through secure electronic systems, such as directly into bank accounts, mobile phone accounts, or on smart cards. They can be regular or one-off payments and recipients must meet the eligibility criteria. They have the objective of providing immediate relief and reducing poverty, as well as contributing to increased resilience of poor households by enabling them to save, invest and cope better with risks and shocks.',
    DisaggregatedIntervention: 'Conditional Cash Transfers (CCTs)',
    Definition: 'Cash transfer to families or individuals provided on a specific condition, such as school enrolment or child vaccination. The transfer is made when and only if the condition is met, which is verify by a more or less intense monitoring.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Social Transfers',
    Definition1: 'Social transfers are direct transfers, increasingly paid through secure electronic systems, such as directly into bank accounts, mobile phone accounts, or on smart cards. They can be regular or one-off payments and recipients must meet the eligibility criteria. They have the objective of providing immediate relief and reducing poverty, as well as contributing to increased resilience of poor households by enabling them to save, invest and cope better with risks and shocks.',
    DisaggregatedIntervention: 'Food transfers',
    Definition: 'Provision of free food as in-kind support (non cash / goods or services)',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Social Transfers',
    Definition1: 'Social transfers are direct transfers, increasingly paid through secure electronic systems, such as directly into bank accounts, mobile phone accounts, or on smart cards. They can be regular or one-off payments and recipients must meet the eligibility criteria. They have the objective of providing immediate relief and reducing poverty, as well as contributing to increased resilience of poor households by enabling them to save, invest and cope better with risks and shocks.',
    DisaggregatedIntervention: 'Labelled cash transfers',
    Definition: 'Monetary transfer to families or individuals without any formal conditionality attached to it, but labelled with the intended purpose of the transfer( soft conditionality). The aim of the transfer is emphasized when given to the beneficiaries but no monitoring of the beneficiaries\' actions following the transfer is conducted.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Social Transfers',
    Definition1: 'Social transfers are direct transfers, increasingly paid through secure electronic systems, such as directly into bank accounts, mobile phone accounts, or on smart cards. They can be regular or one-off payments and recipients must meet the eligibility criteria. They have the objective of providing immediate relief and reducing poverty, as well as contributing to increased resilience of poor households by enabling them to save, invest and cope better with risks and shocks.',
    DisaggregatedIntervention: 'Non-agricultural input grants',
    Definition: 'Provision of non-agricultural inputs as in-kind support (non-cash goods or services)',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Social Transfers',
    Definition1: 'Social transfers are direct transfers, increasingly paid through secure electronic systems, such as directly into bank accounts, mobile phone accounts, or on smart cards. They can be regular or one-off payments and recipients must meet the eligibility criteria. They have the objective of providing immediate relief and reducing poverty, as well as contributing to increased resilience of poor households by enabling them to save, invest and cope better with risks and shocks.',
    DisaggregatedIntervention: 'Other in-kind transfers',
    Definition: 'Provision of other in-kind transfer (non cash / goods or services).',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Social Transfers',
    Definition1: 'Social transfers are direct transfers, increasingly paid through secure electronic systems, such as directly into bank accounts, mobile phone accounts, or on smart cards. They can be regular or one-off payments and recipients must meet the eligibility criteria. They have the objective of providing immediate relief and reducing poverty, as well as contributing to increased resilience of poor households by enabling them to save, invest and cope better with risks and shocks.',
    DisaggregatedIntervention: 'School feeding programmes',
    Definition: 'Provision of food at school as in-kind support. Normally provision of free nutritious meal at school – usually lunch – and sometimes take-home rations for children most in need. School feeding programmes aim both to reduce hunger and improve food security, as well as increase school attendance and learner performance.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Social Transfers',
    Definition1: 'Social transfers are direct transfers, increasingly paid through secure electronic systems, such as directly into bank accounts, mobile phone accounts, or on smart cards. They can be regular or one-off payments and recipients must meet the eligibility criteria. They have the objective of providing immediate relief and reducing poverty, as well as contributing to increased resilience of poor households by enabling them to save, invest and cope better with risks and shocks.',
    DisaggregatedIntervention: 'Unconditional Cash Transfers (UCTs)',
    Definition: 'Cash transfer to families or individuals without any condition from the beneficiaries. This can be a regular cash transfer or a one-time cash transfer.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Food security & nutrition',
    InterventionGroup: 'Subsidies',
    Definition1: 'Subsidies for goods or services in the form of entitled reduced price for the population. Subsidies aim to keep prices low for certain goods and services.',
    DisaggregatedIntervention: 'Food subsidies',
    Definition: 'Food subsidies in the form of entitled reduced price.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Agricultural extension',
    Definition1: 'Programmes to expand access to agricultural extension services',
    DisaggregatedIntervention: 'Additional agricultural extension workers',
    Definition: 'Interventions that increase the number of extension workers for greater coverage',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Agricultural extension',
    Definition1: 'Programmes to expand access to agricultural extension services',
    DisaggregatedIntervention: 'Agricultural extension worker incentives',
    Definition: 'Incentives provided to extension workers such as result-based payment, larger operational budgets, increased recognition of individual achievements etc.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Agricultural extension',
    Definition1: 'Programmes to expand access to agricultural extension services',
    DisaggregatedIntervention: 'Agricultural extension worker training',
    Definition: 'Interventions that provide training to agricultural extension workers in extension methods, communication, marketing, new technologies etc.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Agricultural extension',
    Definition1: 'Programmes to expand access to agricultural extension services',
    DisaggregatedIntervention: 'Aquaculture pond management training',
    Definition: 'Training interventions for best practices in managing fish ponds, including species produced, feed, breeding, harvesting techniques, etc.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Agricultural extension',
    Definition1: 'Programmes to expand access to agricultural extension services',
    DisaggregatedIntervention: 'ICT in agricultural extension',
    Definition: 'The use of information and communications technology (ICT) in the delivery of agricultural extension services.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Agricultural extension',
    Definition1: 'Programmes to expand access to agricultural extension services',
    DisaggregatedIntervention: 'Improved inputs for agriculture',
    Definition: 'Interventions that promote the use of improved inputs for agriculture such as improved seeds or crop varieties.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Agricultural extension',
    Definition1: 'Programmes to expand access to agricultural extension services',
    DisaggregatedIntervention: 'Increasing agricultural extension worker capacity',
    Definition: 'Interventions other than training that increase extension worker capacity, such as providing a bicycle or transit subsidy to expand their range',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Agricultural extension',
    Definition1: 'Programmes to expand access to agricultural extension services',
    DisaggregatedIntervention: 'Introduction of agricultural extension services',
    Definition: 'Interventions that introduce agricultural extension (i.e., information and training to farmers about latest agricultural techniques, storage, marketing strategies etc.) where there was none before.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Agricultural extension',
    Definition1: 'Programmes to expand access to agricultural extension services',
    DisaggregatedIntervention: 'Livestock management training',
    Definition: 'Training interventions for best practices in managing livestock, including feeding, parasite and disease control, breeding, etc.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Business training',
    Definition1: 'Training farmers in the business aspects of agriculture, including bookkeeping, marketing, and credit management.',
    DisaggregatedIntervention: 'Agricultural marketing training',
    Definition: 'Training in how to bring products to market, including price-setting and negotiation',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Business training',
    Definition1: 'Training farmers in the business aspects of agriculture, including bookkeeping, marketing, and credit management.',
    DisaggregatedIntervention: 'Entrepreneurship training',
    Definition: 'Provision of entrepreneurship training focused on how to start up a business from scratch, which can include for example developing a business plan, day-to-day management of small enterprise, including bookkeeping, financial planning, etc.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Crop management training',
    Definition1: 'Training farmers in best practices in managing crops, including planting techniques, soil fertility, pest management, etc.',
    DisaggregatedIntervention: 'Crop management training',
    Definition: 'Training interventions for best practices in managing crops, including planting techniques, soil fertility, pest management, etc.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Demonstration plots',
    Definition1: 'Designated plots in publically accessible areas that demonstrate recommended crop management techniques, including demonstrating different yield levels between traditional and improved practices.',
    DisaggregatedIntervention: 'Demonstration plots',
    Definition: 'Designated plots in publically accessible areas that demonstrate recommended crop management techniques, including demonstrating different yield levels between traditional and improved practices.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Farmer field schools',
    Definition1: 'Educational events held on farms to demonstrate best practices in crop or animal management and promote information sharing.',
    DisaggregatedIntervention: 'Farmer field schools',
    Definition: 'Includes farmer field days',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Market information',
    Definition1: 'Programmes to increase farmers’ access to market information (e.g., supply, demand, prices)',
    DisaggregatedIntervention: 'Agricultural commercialisation',
    Definition: 'Interventions that promote agricultural commercialisation (replacing food crops with cash crops).',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Market information',
    Definition1: 'Programmes to increase farmers’ access to market information (e.g., supply, demand, prices)',
    DisaggregatedIntervention: 'Agricultural market information',
    Definition: 'Interventions that provide farmers with market information (e.g., supply, demand, prices). Can also be known as market information systems MIS), market intelligence systems, or market information services.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Mass media messages',
    Definition1: 'Messages distributed through television, radio, or other mass media to disseminate information relevant to agricultural production.',
    DisaggregatedIntervention: 'Flyer distribution for agricultural production',
    Definition: 'Dissemination of information relevant to agricultural production through flyers',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Mass media messages',
    Definition1: 'Messages distributed through television, radio, or other mass media to disseminate information relevant to agricultural production.',
    DisaggregatedIntervention: 'Radio messages for agricultural production',
    Definition: 'Dissemination of information relevant to agricultural production over the radio',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Mass media messages',
    Definition1: 'Messages distributed through television, radio, or other mass media to disseminate information relevant to agricultural production.',
    DisaggregatedIntervention: 'Television messages for agricultural production',
    Definition: 'Dissemination of information relevant to agricultural production through television messages',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Mass media messages',
    Definition1: 'Messages distributed through television, radio, or other mass media to disseminate information relevant to agricultural production.',
    DisaggregatedIntervention: 'Vehicles with loudspeakers and/or billboards for agricultural production',
    Definition: 'Dissemination of information relevant to agricultural production from vehicles with loudspeakers and/or billboards',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Mobile phone messages',
    Definition1: 'Text or voice messages to disseminate information relevant to agricultural production.',
    DisaggregatedIntervention: 'Mobile video messages for agricultural production',
    Definition: 'Dissemination of information relevant to agricultural production through video messages',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Mobile phone messages',
    Definition1: 'Text or voice messages to disseminate information relevant to agricultural production.',
    DisaggregatedIntervention: 'Text/SMS messages for agricultural production',
    Definition: 'Dissemination of information relevant to agricultural production through text/SMS messages',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Mobile phone messages',
    Definition1: 'Text or voice messages to disseminate information relevant to agricultural production.',
    DisaggregatedIntervention: 'Voice messages for agricultural production',
    Definition: 'Dissemination of information relevant to agricultural production through voice messages',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Private sector capacity building',
    Definition1: 'Interventions to improve capacity of private sector actors to deliver agriculture and aquaculture services (e.g., inputs and extension services)',
    DisaggregatedIntervention: 'Entrepreneurship training',
    Definition: 'Provision of entrepreneurship training focused on how to start up a business from scratch, which can include for example developing a business plan, day-to-day management of small enterprise, including bookkeeping, financial planning, etc.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Private sector capacity building',
    Definition1: 'Interventions to improve capacity of private sector actors to deliver agriculture and aquaculture services (e.g., inputs and extension services)',
    DisaggregatedIntervention: 'Technical training for private service providers in agriculture',
    Definition: 'Interventions that provide technical agricultural training for private service providers',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Social networking',
    Definition1: 'Dissemination of information and practices through peers',
    DisaggregatedIntervention: 'Local champions',
    Definition: 'Interventions that rely on influential members of the farming community to disseminate information',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Social networking',
    Definition1: 'Dissemination of information and practices through peers',
    DisaggregatedIntervention: 'Peer-to-peer learning for farmers',
    Definition: 'Interventions that facilitate information and practice sharing among peer farmers',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Information',
    InterventionGroup: 'Soil testing',
    Definition1: 'Programmes to conduct soil fertility tests and provide farmers with customised information on how to improve soil fertility.',
    DisaggregatedIntervention: 'Soil testing',
    Definition: 'Interventions that test soil fertility and provide farmers with information regarding soil quality and techniques for improvement',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of fish farmers that allow them to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
    DisaggregatedIntervention: 'Access to input markets for aquaculture organisations',
    Definition: 'Interventions to improve aquaculture farmer-based organisations’ access to markets for agricultural inputs like seed, fertiliser, etc.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of farmers or other actors in agricultural value chains that allow for collective bargaining in the marketplace, information and resource sharing, and more efficient delivery of extension services.',
    DisaggregatedIntervention: 'Access to input markets for farmer-based organisation',
    Definition: 'Interventions to improve farmer-based organisations’ access to markets for agricultural inputs like seed, fertiliser, etc.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of fish farmers that allow them to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
    DisaggregatedIntervention: 'Access to output markets for aquaculture organisations',
    Definition: 'Interventions to improve aquaculture farmer-based organisations’ access to output markets for sale of their products, including provisions for transport to markets.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of fish farmers that allow them to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
    DisaggregatedIntervention: 'Establishing agricultural buyers’ organisations',
    Definition: 'Interventions to establish organisation among agricultural buyers',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of fish farmers that allow them to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
    DisaggregatedIntervention: 'Establishing agricultural input supplier organisations',
    Definition: 'Interventions to establish organisation among agricultural input suppliers',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of fish farmers that allow them to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
    DisaggregatedIntervention: 'Establishing aquaculture buyers’ organisations',
    Definition: 'Interventions to establish organisation among aquicultural buyers',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of fish farmers that allow them to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
    DisaggregatedIntervention: 'Establishing aquaculture input supplier organisations',
    Definition: 'Interventions to establish organisation among agricultural input suppliers',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of fish farmers that allow them to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
    DisaggregatedIntervention: 'Establishing aquaculture producer organisations',
    Definition: 'Interventions that facilitate the establishment of organisations of aquaculture producers',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of farmers or other actors in agricultural value chains that allow for collective bargaining in the marketplace, information and resource sharing, and more efficient delivery of extension services.',
    DisaggregatedIntervention: 'Establishing farmer-based organisations',
    Definition: 'Interventions that facilitate the establishment of agricultural farmer-based organisations',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of farmers or other actors in agricultural value chains that allow for collective bargaining in the marketplace, information and resource sharing, and more efficient delivery of extension services.',
    DisaggregatedIntervention: 'Farmer organisation-based credit',
    Definition: 'Interventions that expand agricultural/aquaculture farmer-based organisations’ access to credit',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of farmers or other actors in agricultural value chains that allow for collective bargaining in the marketplace, information and resource sharing, and more efficient delivery of extension services.',
    DisaggregatedIntervention: 'Interventions to improve smallholder crop/livestock farmers’ access to output markets for sale of their products (other than through farmer-based organisations), , including provisions for transport to markets.',
    Definition: 'Interventions to improve farmer-based organisations’ access to output markets for sale of their products, including provisions for transport to markets.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of farmers or other actors in agricultural value chains that allow for collective bargaining in the marketplace, information and resource sharing, and more efficient delivery of extension services.',
    DisaggregatedIntervention: 'Organisational contract farming (agriculture)',
    Definition: 'Contracting between buyers and farmer-based organisations, such as producers\' groups, which often establishes conditions for production and marketing.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of fish farmers that allow them to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
    DisaggregatedIntervention: 'Organisational contract farming (aquaculture)',
    Definition: 'Contracting between buyers and aquaculture producer organisations, such as producers\' groups, which often establishes conditions for production and marketing.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of fish farmers that allow them to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
    DisaggregatedIntervention: 'Strengthening agricultural buyers’ organisations',
    Definition: 'Interventions to strengthen organisation among agricultural buyers',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of fish farmers that allow them to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
    DisaggregatedIntervention: 'Strengthening agricultural input supplier organisations',
    Definition: 'Interventions to strengthen organisation among agricultural input suppliers',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of fish farmers that allow them to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
    DisaggregatedIntervention: 'Strengthening aquaculture buyers’ organisations',
    Definition: 'Interventions to strengthen organisation among aquicultural buyers',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of fish farmers that allow them to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
    DisaggregatedIntervention: 'Strengthening aquaculture input supplier organisations',
    Definition: 'Interventions to strengthen organisation among agricultural input suppliers',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of fish farmers that allow them to bargain collectively in the marketplace, share information and resources, and receive extension services more efficiently.',
    DisaggregatedIntervention: 'Strengthening aquaculture producer organisations',
    Definition: 'Interventions to strengthen aquaculture producer organisations through formalisation, expanding membership, organisational management training, etc.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture & aquaculture organisations',
    Definition1: 'Establishing or strengthening organisations of farmers or other actors in agricultural value chains that allow for collective bargaining in the marketplace, information and resource sharing, and more efficient delivery of extension services.',
    DisaggregatedIntervention: 'Strengthening farmer-based organisations',
    Definition: 'Interventions to strengthen farmer-based organisations through formalisation, expanding membership, organisational management training, etc.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture market linkages',
    Definition1: 'Strengthening market linkages for individual smallholder farmers',
    DisaggregatedIntervention: 'Access to input markets for smallholder farmers',
    Definition: 'Interventions to improve smallholder crop/livestock farmers’ access to markets for agricultural inputs like seed, fertiliser, etc. (other than through farmer-based organisations)',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture market linkages',
    Definition1: 'Strengthening market linkages for individual smallholder farmers',
    DisaggregatedIntervention: 'Access to output markets for smallholder farmers',
    Definition: 'Interventions to improve smallholder crop/livestock farmers’ access to output markets for sale of their products (other than through farmer-based organisations), including provisions for transport to markets.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Agriculture market linkages',
    Definition1: 'Strengthening market linkages for individual smallholder farmers',
    DisaggregatedIntervention: 'Support to local producers or service providers',
    Definition: 'Interventions that support local producers or service providers through purchasing or encouraging the purchase of their goods and/or services, or by providing incentives to fulfil quotas.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Aquaculture market linkages',
    Definition1: 'Strengthening market linkages for individual smallholder fish farmers',
    DisaggregatedIntervention: 'Access to input markets for smallholder aquaculture producers',
    Definition: 'Interventions to improve smallholder fish farmers’ access to markets for agricultural inputs like seed, fertiliser, etc. (other than through producer organisations)',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Aquaculture market linkages',
    Definition1: 'Strengthening market linkages for individual smallholder fish farmers',
    DisaggregatedIntervention: 'Access to output markets for smallholder aquaculture producers',
    Definition: 'Interventions to improve smallholder fish farmers’ access to output markets for sale of their products (other than through producer organisations), including provisions for transport to markets.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Commodity aggregation',
    Definition1: 'Interventions to facilitate aggregating agricultural products to obtain better prices.',
    DisaggregatedIntervention: 'Storage credit for agricultural products',
    Definition: 'System for providing credit to farmers for agricultural products held in storage',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Commodity aggregation',
    Definition1: 'Interventions to facilitate aggregating agricultural products to obtain better prices.',
    DisaggregatedIntervention: 'Warehousing and other storage services for agricultural products',
    Definition: 'Storage facilities for agricultural products',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Value chain improvement',
    Definition1: 'Interventions to improve agricultural or forestry value chains',
    DisaggregatedIntervention: 'Processed goods in agriculture',
    Definition: 'Interventions that provide training/resources to farmers/foresters on expanding production of processed goods derived from primary agricultural products (e.g., cheese/yoghurt from milk, textiles from wool, etc.)',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Market linkages & value chains',
    InterventionGroup: 'Value chain improvement',
    Definition1: 'Interventions to augment farmer adoption of improved agricultural practices.',
    DisaggregatedIntervention: 'Veterinary services',
    Definition: 'Provision or expansion of veterinary services, including offering a broader range of treatments and care.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Cash grants',
    Definition1: 'Monetary grant to promote agricultural activities',
    DisaggregatedIntervention: 'Cash compensation to promote agricultural activities',
    Definition: 'Interventions where beneficiaries receive cash compensation for participating in the programme.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Cash grants',
    Definition1: 'Monetary grant to promote agricultural activities',
    DisaggregatedIntervention: 'Conditional Cash Transfers (CCTs)',
    Definition: 'Cash transfer to families or individuals provided on a specific condition, such as school enrolment or child vaccination. The transfer is made when and only if the condition is met, which is verify by a more or less intense monitoring.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Cash grants',
    Definition1: 'Monetary grant to promote agricultural activities',
    DisaggregatedIntervention: 'Microenterprise grant',
    Definition: 'Provision of a small unconditional grant intended specifically at supporting a microenterprise or starting up a microenterprise from scratch. This program can be implemented on its own, but is usually combined with other components of microenterprise support such as a business training, access to credit etc.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Cash grants',
    Definition1: 'Monetary grant to promote agricultural activities',
    DisaggregatedIntervention: 'Unconditional Cash Transfers (UCTs)',
    Definition: 'Cash transfer to families or individuals without any condition from the beneficiaries. This can be a regular cash transfer or a one-time cash transfer.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Crop subsidies',
    Definition1: 'Subsidies to farmers that supplement/influence the cost and supply of agricultural products',
    DisaggregatedIntervention: 'Cassava subsidies',
    Definition: 'Programmes that provide price subsidies for cassava',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Crop subsidies',
    Definition1: 'Subsidies to farmers that supplement/influence the cost and supply of agricultural products',
    DisaggregatedIntervention: 'Cocao subsidies',
    Definition: 'Programmes that provide price subsidies for cocao',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Crop subsidies',
    Definition1: 'Subsidies to farmers that supplement/influence the cost and supply of agricultural products',
    DisaggregatedIntervention: 'Coffee subsidies',
    Definition: 'Programmes that provide price subsidies for coffee',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Crop subsidies',
    Definition1: 'Subsidies to farmers that supplement/influence the cost and supply of agricultural products',
    DisaggregatedIntervention: 'Cotton subsidies',
    Definition: 'Programmes that provide price subsidies for cotton',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Crop subsidies',
    Definition1: 'Subsidies to farmers that supplement/influence the cost and supply of agricultural products',
    DisaggregatedIntervention: 'Fruit subsidies',
    Definition: 'Programmes that provide price subsidies for fruit',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Crop subsidies',
    Definition1: 'Subsidies to farmers that supplement/influence the cost and supply of agricultural products',
    DisaggregatedIntervention: 'Maize subsidies',
    Definition: 'Programmes that provide price subsidies for maize',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Crop subsidies',
    Definition1: 'Subsidies to farmers that supplement/influence the cost and supply of agricultural products',
    DisaggregatedIntervention: 'Other grain subsidies',
    Definition: 'Programmes that provide price subsidies for other grains',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Crop subsidies',
    Definition1: 'Subsidies to farmers that supplement/influence the cost and supply of agricultural products',
    DisaggregatedIntervention: 'Other vegetable subsidies',
    Definition: 'Programmes that provide price subsidies for other vegetables',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Crop subsidies',
    Definition1: 'Subsidies to farmers that supplement/influence the cost and supply of agricultural products',
    DisaggregatedIntervention: 'Rice subsidies',
    Definition: 'Programmes that provide price subsidies for rice',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Crop subsidies',
    Definition1: 'Subsidies to farmers that supplement/influence the cost and supply of agricultural products',
    DisaggregatedIntervention: 'Soya subsidies',
    Definition: 'Programmes that provide price subsidies for soya',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Crop subsidies',
    Definition1: 'Subsidies to farmers that supplement/influence the cost and supply of agricultural products',
    DisaggregatedIntervention: 'Sugar cane subsidies',
    Definition: 'Programmes that provide price subsidies for sugar cane',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Crop subsidies',
    Definition1: 'Subsidies to farmers that supplement/influence the cost and supply of agricultural products',
    DisaggregatedIntervention: 'Sweet potato/yam subsidies',
    Definition: 'Programmes that provide price subsidies for sweet potato/yam',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Crop subsidies',
    Definition1: 'Subsidies to farmers that supplement/influence the cost and supply of agricultural products',
    DisaggregatedIntervention: 'Tea subsidies',
    Definition: 'Programmes that provide price subsidies for tea',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Crop subsidies',
    Definition1: 'Subsidies to farmers that supplement/influence the cost and supply of agricultural products',
    DisaggregatedIntervention: 'Tobacco subsidies',
    Definition: 'Programmes that provide price subsidies for tobacco',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Crop subsidies',
    Definition1: 'Subsidies to farmers that supplement/influence the cost and supply of agricultural products',
    DisaggregatedIntervention: 'Wheat subsidies',
    Definition: 'Programmes that provide price subsidies for wheat',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Equipment subsidies',
    Definition1: 'Subsidies to offset the costs of agriculture or aquaculture equipment such as tools and machinery',
    DisaggregatedIntervention: 'Equipment subsidies',
    Definition: 'Distribution of agricultural equipment at prices that are less than the standard market price',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Export subsidies',
    Definition1: 'Subsidies provided to encourage export of agricultural products',
    DisaggregatedIntervention: 'Export subsidies',
    Definition: 'Subsidies provided to encourage export of agricultural products',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'In-kind grants',
    Definition1: 'Non-monetary grants of agricultural inputs (seed, fertiliser, etc.), equipment, assets, etc. Including vouchers.',
    DisaggregatedIntervention: 'Animal feed grants',
    Definition: 'Programmes that provide in-kind animal feed grants',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'In-kind grants',
    Definition1: 'Non-monetary grants of agricultural inputs (seed, fertiliser, etc.), equipment, assets, etc. Including vouchers.',
    DisaggregatedIntervention: 'Equipment grants',
    Definition: 'Programmes that provide in-kind equipment grants',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'In-kind grants',
    Definition1: 'Non-monetary grants of agricultural inputs (seed, fertiliser, etc.), equipment, assets, etc. Including vouchers.',
    DisaggregatedIntervention: 'Fertiliser grants',
    Definition: 'Programmes that provide in-kind fertiliser grants',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'In-kind grants',
    Definition1: 'Non-monetary grants of agricultural inputs (seed, fertiliser, etc.), equipment, assets, etc. Including vouchers.',
    DisaggregatedIntervention: 'Grain grants',
    Definition: 'Programmes that provide in-kind grain grants',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'In-kind grants',
    Definition1: 'Non-monetary grants of agricultural inputs (seed, fertiliser, etc.), equipment, assets, etc. Including vouchers.',
    DisaggregatedIntervention: 'Livestock grants',
    Definition: 'Grant of one or more livestock assets (cow, goat, etc.)',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'In-kind grants',
    Definition1: 'Non-monetary grants of agricultural inputs (seed, fertiliser, etc.), equipment, assets, etc. Including vouchers.',
    DisaggregatedIntervention: 'Seed grants',
    Definition: 'Programmes that provide in-kind seed grants',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'In-kind grants',
    Definition1: 'Non-monetary grants of agricultural inputs (seed, fertiliser, etc.), equipment, assets, etc. Including vouchers.',
    DisaggregatedIntervention: 'Seedling grants',
    Definition: 'Programmes that provide in-kind seedling grants',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Input subsidies',
    Definition1: 'Subsidies to offset the costs of agriculture or aquaculture inputs such as seed or fertiliser',
    DisaggregatedIntervention: 'Animal enclosure subsidies',
    Definition: 'Distribution of materials for construction of animal enclosures at prices that are less than the standard market price.',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Input subsidies',
    Definition1: 'Subsidies to offset the costs of agriculture or aquaculture inputs such as seed or fertiliser',
    DisaggregatedIntervention: 'Fertiliser subsidies',
    Definition: 'Distribution of fertiliser at prices that are less than the standard market price',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Input subsidies',
    Definition1: 'Subsidies to offset the costs of agriculture or aquaculture inputs such as seed or fertiliser',
    DisaggregatedIntervention: 'Fish feed subsidies',
    Definition: 'Distribution of fish feed at prices that are less than the standard market price',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Input subsidies',
    Definition1: 'Subsidies to offset the costs of agriculture or aquaculture inputs such as seed or fertiliser',
    DisaggregatedIntervention: 'Fish seed subsidies',
    Definition: 'Distribution of fish ',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Input subsidies',
    Definition1: 'Subsidies to offset the costs of agriculture or aquaculture inputs such as seed or fertiliser',
    DisaggregatedIntervention: 'Livestock feed subsidies',
    Definition: 'Distribution of livestock feed at prices that are less than the standard market price',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Input subsidies',
    Definition1: 'Subsidies to offset the costs of agriculture or aquaculture inputs such as seed or fertiliser',
    DisaggregatedIntervention: 'Seed subsidies',
    Definition: 'Distribution of seeds at prices that are less than the standard market price',
  },
  {
    InterventionSector: 'Agriculture & Forestry',
    BroadGroup: 'Subsidies & grants',
    InterventionGroup: 'Input subsidies',
    Definition1: 'Subsidies to offset the costs of agriculture or aquaculture inputs such as seed or fertiliser',
    DisaggregatedIntervention: 'Seedling subsidies',
    Definition: 'Distribution of seedlings at prices that are less than the standard market price',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Migration & displaced population',
    Definition1: 'Programmes targeted at displaced population, including migrants and refugees',
    DisaggregatedIntervention: 'Aid to displaced populations',
    Definition: 'Provision of aid to displaced populations, including food, water, shelter, education, healthcare etc. This includes establishment, management and support of camps.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Migration & displaced population',
    Definition1: 'Programmes targeted at displaced population, including migrants and refugees',
    DisaggregatedIntervention: 'Compensation schemes targeted at migrants and refugees',
    Definition: 'Provision of compensation schemes such as new living habitats or financial compensations in cases of resettlements.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Migration & displaced population',
    Definition1: 'Programmes targeted at displaced population, including migrants and refugees',
    DisaggregatedIntervention: 'Migration rights and legal assistance',
    Definition: 'Interventions to advocate for or raise awareness of migration rights and/or provide legal services and advice.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Migration & displaced population',
    Definition1: 'Programmes targeted at displaced population, including migrants and refugees',
    DisaggregatedIntervention: 'Pre-departure orientation',
    Definition: 'Interventions to empower migrants by providing them with information and assistance prior to departure.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Migration & displaced population',
    Definition1: 'Programmes targeted at displaced population, including migrants and refugees',
    DisaggregatedIntervention: 'Refugee status determination',
    Definition: 'Recognition of refugee status for individuals migrating in a new country, which usually entitles them to the same rights and similar benefits to national citizens.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Migration & displaced population',
    Definition1: 'Programmes targeted at displaced population, including migrants and refugees',
    DisaggregatedIntervention: 'Repatriation',
    Definition: 'Programmes to sponsor the repatriation of migrants and/or refugees.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Migration & displaced population',
    Definition1: 'Programmes targeted at displaced population, including migrants and refugees',
    DisaggregatedIntervention: 'Resettlement',
    Definition: 'Programmes that facilitate the resettlement population affected by conflict or crisis. This includes migrants and refugees.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Migration & displaced population',
    Definition1: 'Programmes targeted at displaced population, including migrants and refugees',
    DisaggregatedIntervention: 'Seasonal migration promotion',
    Definition: 'Interventions that promote seasonal migration, typically through stipends or accommodations.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Peace processes, oversight & post-conflict justice',
    Definition1: 'This set of interventions includes activities targeted at potential or existing formal peace processes, including efforts to establish, facilitate and monitor the implementation of negotiations or peace agreements.',
    DisaggregatedIntervention: 'Peace agreement implementation and oversight',
    Definition: 'This includes efforts to facilitate the implementation of a finalised peace agreement and neutral third-party oversight to ensure compliance amongst all parties to the agreement.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Peace processes, oversight & post-conflict justice',
    Definition1: 'This set of interventions includes activities targeted at potential or existing formal peace processes, including efforts to establish, facilitate and monitor the implementation of negotiations or peace agreements.',
    DisaggregatedIntervention: 'Peace policy influencing',
    Definition: 'This includes interventions to support civil-society-led efforts to influence policies to end violence and promote sustainable peace, including through advocacy campaigns, conflict assessments and mass movements / collective civic action.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Peace processes, oversight & post-conflict justice',
    Definition1: 'This set of interventions includes activities targeted at potential or existing formal peace processes, including efforts to establish, facilitate and monitor the implementation of negotiations or peace agreements.',
    DisaggregatedIntervention: 'Peace processes and negotiation',
    Definition: 'A peace process is often thought of as ',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Peace processes, oversight & post-conflict justice',
    Definition1: 'This set of interventions includes activities targeted at potential or existing formal peace processes, including efforts to establish, facilitate and monitor the implementation of negotiations or peace agreements.',
    DisaggregatedIntervention: 'Release of children from armed forces',
    Definition: 'Implementation of programme to release children from armed groups, which can be done through a formal or informal agreement between a UN group and an armed force, or a formal and controlled disarmament, demobilization and reintegration (DDR) programme for combatants.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Peace processes, oversight & post-conflict justice',
    Definition1: 'This set of interventions includes activities targeted at potential or existing formal peace processes, including efforts to establish, facilitate and monitor the implementation of negotiations or peace agreements.',
    DisaggregatedIntervention: 'Skills for conflict management',
    Definition: 'Interventions that aim at preventing disputes of scaling up at the community or village-level. This can include interventions promoting dialogue and negotiation skills or training on dispute resolution mechanisms to avoid the use of violence.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Peace processes, oversight & post-conflict justice',
    Definition1: 'This set of interventions includes activities targeted at potential or existing formal peace processes, including efforts to establish, facilitate and monitor the implementation of negotiations or peace agreements.',
    DisaggregatedIntervention: 'Transitional justice processes',
    Definition: 'These interventions offer stand-alone (outside the standard justice system), formal and informal (or judicial and non-judicial) measures to address the legacy of human rights abuses in a country and address past injustices. These often include truth commissions (or simple truth telling by both victims and perpetrators), programmes for reparations, and actual prosecutions.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Social well-being & conflict resolution',
    Definition1: 'This category of interventions aims to restore, or strengthen, friendly relations in an area that has been affected by conflict and/or deep social rifts. These include justice interventions that aim to resolve current disputes, psychosocial support to victims and perpetrators, peace education and efforts to end social isolation',
    DisaggregatedIntervention: 'Capacity building for conflict transformation',
    Definition: 'Interventions that build community and key actors\' capacity to participate in or establish opportunities or platforms for community and subnational conflict transformation processes. These may also be referred to as building skills for mediation, negotiation, conflict resolution, dispute resolution or conflict prevention. This may include interventions involving political and military leaders unofficial dialogue and problem-solving activities with different civil society actors; grassroots level and activities including joint meetings to advocacy for marginalized groups.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Social well-being & conflict resolution',
    Definition1: 'This category of interventions aims to restore, or strengthen, friendly relations in an area that has been affected by conflict and/or deep social rifts. These include justice interventions that aim to resolve current disputes, psychosocial support to victims and perpetrators, peace education and efforts to end social isolation',
    DisaggregatedIntervention: 'Family tracing and reunification (FTR)',
    Definition: 'Creation of a system for family tracing and reunification (FTR), which allows humanitarian response team to locate and reunite family members who have been separated. This can take the form of a mobile platform.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Social well-being & conflict resolution',
    Definition1: 'This category of interventions aims to restore, or strengthen, friendly relations in an area that has been affected by conflict and/or deep social rifts. These include justice interventions that aim to resolve current disputes, psychosocial support to victims and perpetrators, peace education and efforts to end social isolation',
    DisaggregatedIntervention: 'Gender equality behavioural-change communications',
    Definition: 'These interventions aim to change behaviours, attitudes and beliefs around gender equality and the role of women society.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Social well-being & conflict resolution',
    Definition1: 'This category of interventions aims to restore, or strengthen, friendly relations in an area that has been affected by conflict and/or deep social rifts. These include justice interventions that aim to resolve current disputes, psychosocial support to victims and perpetrators, peace education and efforts to end social isolation',
    DisaggregatedIntervention: 'Informal judicial system strengthening',
    Definition: 'Interventions that establish or strengthen informal justice processes, such as alternative dispute resolution mechanisms, or build community capacity to manage and participate in them. This also includes interventions that strengthen linkages between informal and formal justice systems.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Social well-being & conflict resolution',
    Definition1: 'This category of interventions aims to restore, or strengthen, friendly relations in an area that has been affected by conflict and/or deep social rifts. These include justice interventions that aim to resolve current disputes, psychosocial support to victims and perpetrators, peace education and efforts to end social isolation',
    DisaggregatedIntervention: 'Intergroup dialogues',
    Definition: 'These interventions aim to increase dialogue and social interaction between different groups, including different ethnic groups, displaced and host community groups, and people of different faiths. They are not part of formal peace processes, but rather processes that use engagement with key community leaders to bring different groups together. They may include purely dialogue-focused interventions or bring groups together through activities, such as arts or sports.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Social well-being & conflict resolution',
    Definition1: 'This category of interventions aims to restore, or strengthen, friendly relations in an area that has been affected by conflict and/or deep social rifts. These include justice interventions that aim to resolve current disputes, psychosocial support to victims and perpetrators, peace education and efforts to end social isolation',
    DisaggregatedIntervention: 'Mental health and psychosocial support (MHPSS)',
    Definition: 'Provision of mental health and psychosocial support (MHPS) in emergencies or situation of chronic hardship. MHPS can be specialized if implemented by specialist such as psychiatrist or psychologist, or non-specialized if implemented by primary health care doctors and community health workers. It can also be a community support group or a network strengthening intervention to allow people to seek psychosocial support directly within their community.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Social well-being & conflict resolution',
    Definition1: 'This category of interventions aims to restore, or strengthen, friendly relations in an area that has been affected by conflict and/or deep social rifts. These include justice interventions that aim to resolve current disputes, psychosocial support to victims and perpetrators, peace education and efforts to end social isolation',
    DisaggregatedIntervention: 'Peace education',
    Definition: 'Peace education interventions promote the knowledge, skills and attitudes that shape the social environment to both prevent conflict from occurring and help people to resolve it peacefully. These interventions can be run at many scales (i.e. local versus national) and often involve promoting a community dialogue. They usually cover a range of topics including non-violent conflict resolution techniques, human rights, democracy, disarmament, gender equality, tolerance and communication skills.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Social well-being & conflict resolution',
    Definition1: 'This category of interventions aims to restore, or strengthen, friendly relations in an area that has been affected by conflict and/or deep social rifts. These include justice interventions that aim to resolve current disputes, psychosocial support to victims and perpetrators, peace education and efforts to end social isolation',
    DisaggregatedIntervention: 'Peace messaging and media',
    Definition: 'These interventions focus on the capacity building of media organisations and supporting them to provide peace messaging to their local community, among other content.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Social well-being & conflict resolution',
    Definition1: 'This category of interventions aims to restore, or strengthen, friendly relations in an area that has been affected by conflict and/or deep social rifts. These include justice interventions that aim to resolve current disputes, psychosocial support to victims and perpetrators, peace education and efforts to end social isolation',
    DisaggregatedIntervention: 'Sexual and gender-based violence (SGBV) - prevention',
    Definition: 'This group of interventions aim to prevent sexual and gender-based violence (SGBV) through a combination of methods such as media campaigns, behaviour-change communication, and education. This also includes the establishment of legal protections, such as new laws to criminalise SGBV, training for the police and courts on how to investigate and handle SGBV.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Social well-being & conflict resolution',
    Definition1: 'This category of interventions aims to restore, or strengthen, friendly relations in an area that has been affected by conflict and/or deep social rifts. These include justice interventions that aim to resolve current disputes, psychosocial support to victims and perpetrators, peace education and efforts to end social isolation',
    DisaggregatedIntervention: 'Social inclusion and reintegration initiatives',
    Definition: 'These interventions include efforts to engage marginalised groups into the social fabric of communities, including the reintegration of non-combatants affected by violence, particularly in a conflict setting. This can include building relationships between vulnerable individuals and potential mentor figures, their families, and the wider community. This can also be formal or informal efforts to minimise social isolation. This does not cover reintegration of ex-combatants, captured elsewhere.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Social well-being & conflict resolution',
    Definition1: 'This category of interventions aims to restore, or strengthen, friendly relations in an area that has been affected by conflict and/or deep social rifts. These include justice interventions that aim to resolve current disputes, psychosocial support to victims and perpetrators, peace education and efforts to end social isolation',
    DisaggregatedIntervention: 'Truth telling processes for post conflict transition',
    Definition: 'These interventions seek to confront the truth in post-conflict communities, particularly through open trials or by bringing together both victims and perpetrators of violence.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Violence & security',
    Definition1: 'These interventions focus on stabilising a conflict or violent situation, preventing the outbreak of violence and helping the population to feel secure.',
    DisaggregatedIntervention: 'Anti-trafficking',
    Definition: 'Any intervention to combat trafficking in persons or illicit goods. These interventions could include awareness campaigns, law enforcement, public recording, etc.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Violence & security',
    Definition1: 'These interventions focus on stabilising a conflict or violent situation, preventing the outbreak of violence and helping the population to feel secure.',
    DisaggregatedIntervention: 'Civilian police reform',
    Definition: 'These interventions work within the system of traditional civilian police forces to restructure, reform, and improve access to police services. These interventions include higher-level and governmental reform of police forces and local level efforts, such as Community policing interventions, which aim to build ties between the formal police force and the community, such as through neighbourhood watches, as well as encouraging civil organisations to be involved in promoting security.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Violence & security',
    Definition1: 'These interventions focus on stabilising a conflict or violent situation, preventing the outbreak of violence and helping the population to feel secure.',
    DisaggregatedIntervention: 'Conflict-focused early warning systems',
    Definition: 'This includes interventions that aim to create early warning systems that track fragile situations and aim to identify conflict, or potential conflict, as soon as, or just before, it breaks out, such as through tracking the use of hate speech in traditional and public social media.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Violence & security',
    Definition1: 'These interventions focus on stabilising a conflict or violent situation, preventing the outbreak of violence and helping the population to feel secure.',
    DisaggregatedIntervention: 'Countering Violent Extremism (CVE)',
    Definition: 'CVE is a security-focused approach that intends to address the conditions and reduce the factors that most likely contribute to violent extremism or terrorism. Interventions within this group may try to counteract radicalization by providing information and resources to the general public, as well as training to local leaders to engage with different stakeholders to reduce the recruitment and radicalization by violent extremists.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Violence & security',
    Definition1: 'These interventions focus on stabilising a conflict or violent situation, preventing the outbreak of violence and helping the population to feel secure.',
    DisaggregatedIntervention: 'Demining',
    Definition: 'This is the process of removing mines and other unexploded ordinance from an area.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Violence & security',
    Definition1: 'These interventions focus on stabilising a conflict or violent situation, preventing the outbreak of violence and helping the population to feel secure.',
    DisaggregatedIntervention: 'Disarmament, Demobilisation, and Reintegration (DDR) and gang drop-out programmes',
    Definition: 'DDR is a complex program for ex-combatants that brings together reconciliation, security, and socioeconomic dimensions. Typically involves removing weapons from combatants\' hands, taking individuals out of militarized structures, providing them with training for a new livelihood, and psychosocial support. This also captures programs to reintegrate former gang members into their communities, drafting provision of agreements, and negotiation advice.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Violence & security',
    Definition1: 'These interventions focus on stabilising a conflict or violent situation, preventing the outbreak of violence and helping the population to feel secure.',
    DisaggregatedIntervention: 'Informal judicial system strengthening',
    Definition: 'Interventions that establish or strengthen informal justice processes, such as alternative dispute resolution mechanisms, or build community capacity to manage and participate in them. This also includes interventions that strengthen linkages between informal and formal justice systems.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Violence & security',
    Definition1: 'These interventions focus on stabilising a conflict or violent situation, preventing the outbreak of violence and helping the population to feel secure.',
    DisaggregatedIntervention: 'Peacekeeping missions',
    Definition: 'This specifically covers the deployment of UN Peacekeepers into countries in a post-conflict setting. This involves the deployment of international troops and police, who work with local civilian peacekeepers to provide security to an area.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Violence & security',
    Definition1: 'These interventions focus on stabilising a conflict or violent situation, preventing the outbreak of violence and helping the population to feel secure.',
    DisaggregatedIntervention: 'Preventative protection measures',
    Definition: 'Non-police or security force-based efforts to reduce incidences of violence or criminal activity, including through making the physical environment less conducive to such acts and through minimising the exposure of vulnerable groups to risky situations.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Security & conflict resolution',
    InterventionGroup: 'Violence & security',
    Definition1: 'These interventions focus on stabilising a conflict or violent situation, preventing the outbreak of violence and helping the population to feel secure.',
    DisaggregatedIntervention: 'Sexual and gender-based violence (SGBV) - prevention',
    Definition: 'This group of interventions aim to prevent sexual and gender-based violence (SGBV) through a combination of methods such as media campaigns, behaviour-change communication, and education. This also includes the establishment of legal protections, such as new laws to criminalise SGBV, training for the police and courts on how to investigate and handle SGBV.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Community-based natural resource management for conflict resolution',
    Definition: 'These interventions aim to strengthen community-level management of natural resources, including water, rangelands and forests. This may include participatory management of irrigation systems, water user associations or rangeland/forestry user associations. These interventions aim to decentralise control over resource management and benefits to a local level. Interventions to support community-based natural resource management groups often comprise elements of conflict resolution training.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Community-driven Development & Reconstruction (CDD & CDR)',
    Definition: 'Development initiative that provides control of the development process, resources and decision making authority directly to groups in the community. This includes both community-driven development (CDD) and community driven reconstruction (CDR).',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Housing reconstruction',
    Definition: 'This should be separated out from the infrastructure intervention group, which is more about the establishment and management of public infrastructure. This relates specifically to the reconstruction of (private) housing following conflict or natural disasters.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Infrastructure development and reconstruction',
    Definition: 'This covers all interventions that build, or re-build, infrastructure outside of CDD&R processes, with the aim of stimulating the economy through both short-term employment and ensuring the presence of infrastructure necessary for economic development.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Labour-intensive public works',
    Definition: 'These interventions are often used as a way of both ensuring critical infrastructure is rebuilt or maintained, and as a way of providing access to income for vulnerable households. This is separate from the infrastructure interventions as a specific type of intervention.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Life skills and employment training for conflict affected population',
    Definition: 'This category provides training programmes primarily for jobs and livelihoods for conflict affected populations, but can also include programmes such as music instruction for youth, which may have less obvious monetary reward but aims to provide skills and an alternative to violence. This category does not include the development of peace skills (such as conflict management).',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Market development to support economic development',
    Definition: 'This includes interventions that aim to develop the wider economic market and provide increased opportunities at the macro-level of the economy. This includes work on trade, foreign and national investment, and business, cooperative or association formation and oversight.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'National natural resource benefits sharing to reduce conflict',
    Definition: 'This includes interventions to shape the sharing of natural resource benefits, particularly in the extractives sector, such as efforts to increase the provision of benefits to local communities or reduce risks of violent conflicts over control of resources',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Social funds',
    Definition: 'These interventions encourage participatory planning and the inclusion of local constituents in identifying and addressing local needs. This can include social funds based on community decision-making or collaborative projects with the local government. It does not include the establishment of community committees to help oversee the development or reconstruction efforts, which are captured under CDD. This also does not cover social safety nets or the provision of funds for community projects where expenditure decisions are not controlled by communities.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Transboundary water sharing',
    Definition: 'This includes support for (re)negotiation of transboundary water agreements (e.g. treaties) and management to improve the equity and sustainability of water use.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Urban design for prevention of violence',
    Definition: 'Interventions that improve the safety of public services such as transport.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Emergency response management',
    Definition1: 'Augmentation of local, regional, national, or international emergency response management capacities in contexts of crisis. These crises include disease outbreaks, natural disasters and conflict that might leave already vulnerable populations even more at risk.',
    DisaggregatedIntervention: 'Community disaster management planning',
    Definition: 'Creation or improvement of community-based disaster response and resource management plan.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Emergency response management',
    Definition1: 'Augmentation of local, regional, national, or international emergency response management capacities in contexts of crisis. These crises include disease outbreaks, natural disasters and conflict that might leave already vulnerable populations even more at risk.',
    DisaggregatedIntervention: 'Conditional Cash Transfers (CCTs)',
    Definition: 'Cash transfer to families or individuals provided on a specific condition, such as school enrolment or child vaccination. The transfer is made when and only if the condition is met, which is verify by a more or less intense monitoring.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Emergency response management',
    Definition1: 'Augmentation of local, regional, national, or international emergency response management capacities in contexts of crisis. These crises include disease outbreaks, natural disasters and conflict that might leave already vulnerable populations even more at risk.',
    DisaggregatedIntervention: 'Education in emergencies and academic catch-up',
    Definition: 'These programmes aim to address the school years lost by children due to conflict and displacement (Amal for Education, 2019). They are particularly important when attempting to reintegrate refugee and child soldier populations. Typically they will cover all academic areas to ensure the children have broad capacity. This also includes \'education in emergency\' interventions that aim to create interim opportunities to ensure children have access to education, such as through setting up temporary education in camps for displaced populations.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Emergency response management',
    Definition1: 'Augmentation of local, regional, national, or international emergency response management capacities in contexts of crisis. These crises include disease outbreaks, natural disasters and conflict that might leave already vulnerable populations even more at risk.',
    DisaggregatedIntervention: 'Emergency and humanitarian response plans',
    Definition: 'Interventions that try to alleviate emerging trends and risks of humanitarian crises. These crises include disease outbreaks, natural disasters and conflict that might leave already vulnerable populations even more at risk. An emergency humanitarian response plan aimed to support efficient, effective, and coordinated humanitarian response through the sharing of operational information.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Emergency response management',
    Definition1: 'Augmentation of local, regional, national, or international emergency response management capacities in contexts of crisis. These crises include disease outbreaks, natural disasters and conflict that might leave already vulnerable populations even more at risk.',
    DisaggregatedIntervention: 'Emergency provision of food',
    Definition: 'Interventions intended for providing food (including vouchers/transfers) to vulnerable persons socially or economically hit by the an emergent humanitarian crisis, including natural disasters.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Emergency response management',
    Definition1: 'Augmentation of local, regional, national, or international emergency response management capacities in contexts of crisis. These crises include disease outbreaks, natural disasters and conflict that might leave already vulnerable populations even more at risk.',
    DisaggregatedIntervention: 'Emergency provision of other in-kind items (non food)',
    Definition: 'Emergency provision of other in-kind items (non food)',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Emergency response management',
    Definition1: 'Augmentation of local, regional, national, or international emergency response management capacities in contexts of crisis. These crises include disease outbreaks, natural disasters and conflict that might leave already vulnerable populations even more at risk.',
    DisaggregatedIntervention: 'Emergency provision of shelter',
    Definition: 'Interventions intended for providing shelter or emergency housing (including shelter for cattle) to vulnerable persons socially or economically hit by an emergent humanitarian crisis, including natural disasters.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Emergency response management',
    Definition1: 'Augmentation of local, regional, national, or international emergency response management capacities in contexts of crisis. These crises include disease outbreaks, natural disasters and conflict that might leave already vulnerable populations even more at risk.',
    DisaggregatedIntervention: 'Emergency provision of WaSH resources',
    Definition: 'Emergency provision of any water, sanitation and hygiene supplies or services offered to help cope with the basic hygiene needs in a disaster-affected population. Interventions can include water treatment, excreta disposal, temporary provision of toilets, etc.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Emergency response management',
    Definition1: 'Augmentation of local, regional, national, or international emergency response management capacities in contexts of crisis. These crises include disease outbreaks, natural disasters and conflict that might leave already vulnerable populations even more at risk.',
    DisaggregatedIntervention: 'Hazard and vulnerability mapping',
    Definition: 'Creation or improvement of hazard and vulnerability maps for strategic response or abatement.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Emergency response management',
    Definition1: 'Augmentation of local, regional, national, or international emergency response management capacities in contexts of crisis. These crises include disease outbreaks, natural disasters and conflict that might leave already vulnerable populations even more at risk.',
    DisaggregatedIntervention: 'Strategic stockpiling of resources',
    Definition: 'Stockpiles of critical goods for the purpose of disaster response. This included logistics and distributions frameworks for stockpiled resources.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Emergency response management',
    Definition1: 'Augmentation of local, regional, national, or international emergency response management capacities in contexts of crisis. These crises include disease outbreaks, natural disasters and conflict that might leave already vulnerable populations even more at risk.',
    DisaggregatedIntervention: 'Unconditional Cash Transfers (UCTs)',
    Definition: 'Cash transfer to families or individuals without any condition from the beneficiaries. This can be a regular cash transfer or a one-time cash transfer.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Inclusive & accountable institutions',
    Definition1: 'These interventions look at creating and strengthening the government and public service institutions necessary to support long-term economic, social and political development (Centre for Global Development 2016)',
    DisaggregatedIntervention: 'Civic engagement initiatives',
    Definition: 'These are interventions that aim to empower individuals, often marginalised groups or youth, by providing them with formal and/or informal opportunities to make their voices heard through engaging in politics, civilian participation, and oversight of public institutions and other civil society activities.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Inclusive & accountable institutions',
    Definition1: 'These interventions look at creating and strengthening the government and public service institutions necessary to support long-term economic, social and political development (Centre for Global Development 2016)',
    DisaggregatedIntervention: 'Civil society capacity building',
    Definition: 'This encompasses a fairly broad range of interventions that work with civil society organisations (CSOs) to develop their capacity as a force of change (i.e. capacity building of CSOs to advocate for and engage with citizens and the government). This can include the establishment of community interest groups, such as women and youth committees.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Inclusive & accountable institutions',
    Definition1: 'These interventions look at creating and strengthening the government and public service institutions necessary to support long-term economic, social and political development (Centre for Global Development 2016)',
    DisaggregatedIntervention: 'Community-driven Development & Reconstruction (CDD & CDR)',
    Definition: 'Development initiative that provides control of the development process, resources and decision making authority directly to groups in the community. This includes both community-driven development (CDD) and community driven reconstruction (CDR).',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Inclusive & accountable institutions',
    Definition1: 'These interventions look at creating and strengthening the government and public service institutions necessary to support long-term economic, social and political development (Centre for Global Development 2016)',
    DisaggregatedIntervention: 'Elections support to ensure fair elections',
    Definition: 'Trusted elections help to establish the legitimacy of the government, and election support interventions typically work with both international actors (who often monitor elections) and local civil society organisations (and NGOs) to ensure that electoral law is followed, eligible citizens are able to vote freely, and election results are more trustworthy. Elections support also comprises interventions to increase access to information about election processes and candidates.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Inclusive & accountable institutions',
    Definition1: 'These interventions look at creating and strengthening the government and public service institutions necessary to support long-term economic, social and political development (Centre for Global Development 2016)',
    DisaggregatedIntervention: 'Foundational state design processes',
    Definition: 'This includes efforts to support the design and drafting of legal foundations (e.g. constitutions), as well as political system design and state institutional design (e.g. drawing boundaries, decentralisation, federalism or division of territory into districts).',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Inclusive & accountable institutions',
    Definition1: 'These interventions look at creating and strengthening the government and public service institutions necessary to support long-term economic, social and political development (Centre for Global Development 2016)',
    DisaggregatedIntervention: 'Informal judicial system strengthening',
    Definition: 'Interventions that establish or strengthen informal justice processes, such as alternative dispute resolution mechanisms, or build community capacity to manage and participate in them. This also includes interventions that strengthen linkages between informal and formal justice systems.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Inclusive & accountable institutions',
    Definition1: 'These interventions look at creating and strengthening the government and public service institutions necessary to support long-term economic, social and political development (Centre for Global Development 2016)',
    DisaggregatedIntervention: 'Justice and human rights support',
    Definition: 'This includes interventions that aim to enable access to justice and human rights for individuals and groups through strengthening support from the demand-side. This can include programmes to provide pro-bono legal aid to vulnerable individuals or groups, information campaigns to improve people\'s knowledge of their and others\' rights, and advocacy programmes to increase awareness of human rights abuses or injustices.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Inclusive & accountable institutions',
    Definition1: 'These interventions look at creating and strengthening the government and public service institutions necessary to support long-term economic, social and political development (Centre for Global Development 2016)',
    DisaggregatedIntervention: 'Justice system support and reform',
    Definition: 'These interventions work within the formal justice sector – the ‘supply side’ of justice – to improve court systems and access. This can include integrating human rights into the legal framework, capacity building for courts and lawyers, and reforming the criminal penal code to strengthen equal protection for human rights under national laws.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Inclusive & accountable institutions',
    Definition1: 'These interventions look at creating and strengthening the government and public service institutions necessary to support long-term economic, social and political development (Centre for Global Development 2016)',
    DisaggregatedIntervention: 'Land management and reform',
    Definition: 'These interventions work with the government to develop or reform and implement laws to improve the equity of land distribution for vulnerable community members, such as indigenous groups, the ultra-poor, or displaced persons. This also includes interventions that formalise land titles or rights, such as to informal settlement dwellers, or teach people about their land rights. Finally, it further includes interventions that create or strengthen systems through which land entitlements are coded, adjudicated, and land conflicts are resolved.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Inclusive & accountable institutions',
    Definition1: 'These interventions look at creating and strengthening the government and public service institutions necessary to support long-term economic, social and political development (Centre for Global Development 2016)',
    DisaggregatedIntervention: 'Public sector governance and institutionalisation strengthening',
    Definition: 'These interventions work with public institutions at all levels (national, subnational and local), including core government bodies and public service institutions. They aim to build capacities and processes to strengthen governance, including through improving the accountability, transparency, responsiveness, efficiency and equity of access to government and public services. Whilst public services (e.g. health and education) are usually thought of as provided by the government, they may also be provided by NGOs, although this is usually envisioned as an intermediary step, whereas capacity is built in the government. These interventions also may include efforts to build links between civilians and state officials to strengthen state legitimacy.',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Inclusive & accountable institutions',
    Definition1: 'These interventions look at creating and strengthening the government and public service institutions necessary to support long-term economic, social and political development (Centre for Global Development 2016)',
    DisaggregatedIntervention: 'Security sector reform',
    Definition: 'These interventions work to help governments improve their provision of safety, security and justice through actors in the security sector, which includes all levels of military and civilian organisations, governmental bodies providing oversight to such organisations, and actual state security providers (including police, customs, military forces and correction officers). This can include interventions to reform armed forces, improve national security planning, and provide oversight and transparency to justice, police and corrections actors',
  },
  {
    InterventionSector: 'Conflict, Security, & Fragility',
    BroadGroup: 'Vulnerability & resilience',
    InterventionGroup: 'Inclusive & accountable institutions',
    Definition1: 'These interventions look at creating and strengthening the government and public service institutions necessary to support long-term economic, social and political development (Centre for Global Development 2016)',
    DisaggregatedIntervention: 'Sexual and gender-based violence (SGBV) - prevention',
    Definition: 'This group of interventions aim to prevent sexual and gender-based violence (SGBV) through a combination of methods such as media campaigns, behaviour-change communication, and education. This also includes the establishment of legal protections, such as new laws to criminalise SGBV, training for the police and courts on how to investigate and handle SGBV.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity.',
    DisaggregatedIntervention: 'Access and participation',
    Definition: 'Provision of support and services to increase access to services and participation (for example in education, employment, public life). This can be done for example through quotas in universities, public services and political institutions (affirmative positive actions) or through educational campaign and targeted encouragement (positive action) or other social inclusion interventions. For communication or behavioural change interventions relating to gender issues, please use the gender equality code.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity.',
    DisaggregatedIntervention: 'Community-based education',
    Definition: 'Employment of community-based education strategy and instruction, often to provide access in areas where formal education institutions are inaccessible, or otherwise hard-to-reach; this is especially relevant in the rural, conflict, and natural disaster contexts.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity.',
    DisaggregatedIntervention: 'Disability inclusion in education',
    Definition: 'Provision of educational services aimed at students with learning, linguistic, or physical disabilities.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity.',
    DisaggregatedIntervention: 'Distance learning',
    Definition: 'Education provided remotely, without in-person contact between students and teachers. This can often be complemented through the employment of digital and other technologies.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity.',
    DisaggregatedIntervention: 'Education reintegration programmes',
    Definition: 'Reintegration programmes for those who have dropped out, but also those who may have been affected by conflicts or natural disasters that have closed schools. These include accelerated education (which are second change programs), remedial programs, catch up programs, and bridging programs.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity.',
    DisaggregatedIntervention: 'Educational opportunities targeting minorities/vulnerable groups',
    Definition: 'Programmes that provide opportunities exclusively or primarily to those belonging to a minority/vulnerable group',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity.',
    DisaggregatedIntervention: 'Safety interventions in education',
    Definition: 'Interventions that promote the safety of students, including policies and actions to prevent internal (e.g. bullying or corporal punishment), external (e.g. armed attack), or environmental (e.g. natural disasters or environmental contamination) hazards.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity.',
    DisaggregatedIntervention: 'Scholarships for minorities/vulnerable groups',
    Definition: 'Scholarship programmes directed toward those belonging to a minority/vulnerable group to promote enrolment, performance, and continuation.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity.',
    DisaggregatedIntervention: 'School choice programmes',
    Definition: 'Programmes that allow households or students to choose the school, at which the student will attend. These programmes often offer students with the option to attend higher performing schools.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity.',
    DisaggregatedIntervention: 'School transportation services',
    Definition: 'Programmes for providing transportation for students to and from school; for example providing school busing or bicycles for students.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity.',
    DisaggregatedIntervention: 'Social promotion for minorities/vulnerable groups education',
    Definition: 'Information or social mechanism campaigns to promote the participation of those belonging to a minority/vulnerable group, at all levels.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education (Finance)',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity related to finance',
    DisaggregatedIntervention: 'Conditional Cash Transfers (CCTs)',
    Definition: 'Cash transfer to families or individuals provided on a specific condition, such as school enrolment or child vaccination. The transfer is made when and only if the condition is met, which is verified by a more or less intense monitoring.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education (Finance)',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity related to finance',
    DisaggregatedIntervention: 'Education fee waivers',
    Definition: 'Provision of waiver from payment for education fee.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education (Finance)',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity related to finance',
    DisaggregatedIntervention: 'Financial aid information for education',
    Definition: 'Provision of information pertaining to financial aid for education, including funding options and programmes available.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education (Finance)',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity related to finance',
    DisaggregatedIntervention: 'Labelled cash transfers',
    Definition: 'Monetary transfer to families or individuals without any formal conditionality attached to it, but labelled with the intended purpose of the transfer( soft conditionality). The aim of the transfer is emphasized when given to the beneficiaries but no monitoring of the beneficiaries\' actions following the transfer is conducted.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education (Finance)',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity related to finance',
    DisaggregatedIntervention: 'Merit-based scholarships',
    Definition: 'Scholarships to attend school contingent on high academic performance: achievement or ability.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education (Finance)',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity related to finance',
    DisaggregatedIntervention: 'Reduce school fees',
    Definition: 'Interventions designed to reduce school fees or other economic costs such as uniforms or materials as a barrier to attendance.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education (Finance)',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity related to finance',
    DisaggregatedIntervention: 'School feeding programmes',
    Definition: 'Provision of food at school as in-kind support. Normally provision of free nutritious meal at school – usually lunch – and sometimes take-home rations for children most in need. School feeding programmes aim both to reduce hunger and improve food security, as well as increase school attendance and learner performance.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education (Finance)',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity related to finance',
    DisaggregatedIntervention: 'School supplies transfers (textbooks, uniforms, etc.)',
    Definition: 'Provision of school supplies as in-kind support (such as textbooks, uniforms, etc.)',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education (Finance)',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity related to finance',
    DisaggregatedIntervention: 'School vouchers',
    Definition: 'Giving vouchers to households, students, or schools vouchers to fund part of - in some cases the whole - cost of education. Vouchers are often much more flexible than other school fee reduction schemes, even though some vouchers may be valid for only specific schools.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education (Finance)',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity related to finance',
    DisaggregatedIntervention: 'Transportation allowance',
    Definition: 'Provision of waiver from payment for transport (public or private).',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Access to Education (Finance)',
    Definition1: 'Interventions that address supply and/or demand issues in educational access and equity related to finance',
    DisaggregatedIntervention: 'Unconditional Cash Transfers (UCTs)',
    Definition: 'Cash transfer to families or individuals without any condition from the beneficiaries. This can be a regular cash transfer or a one-time cash transfer.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Gender in education',
    Definition1: 'Programmes to address gender disparities and adverse environments in education.',
    DisaggregatedIntervention: 'Conditional Cash Transfers (CCTs)',
    Definition: 'Cash transfer to families or individuals provided on a specific condition, such as school enrolment or child vaccination. The transfer is made when and only if the condition is met, which is verified by a more or less intense monitoring.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Gender in education',
    Definition1: 'Programmes to address gender disparities and adverse environments in education.',
    DisaggregatedIntervention: 'Educational opportunities targeting girls',
    Definition: 'Programmes that provide opportunities exclusively or primarily to girls or women, such as STEM promotion programmes.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Gender in education',
    Definition1: 'Programmes to address gender disparities and adverse environments in education.',
    DisaggregatedIntervention: 'Gender sensitive toilets',
    Definition: 'Interventions that facilitate the construction or designation of gender specific toilets, especially in schools.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Gender in education',
    Definition1: 'Programmes to address gender disparities and adverse environments in education.',
    DisaggregatedIntervention: 'Health education for girls',
    Definition: 'Health curriculum or instruction targeted at girls, to promote healthy behaviours, such as reducing teenage pregnancy, menstrual hygiene, etc.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Gender in education',
    Definition1: 'Programmes to address gender disparities and adverse environments in education.',
    DisaggregatedIntervention: 'Labelled cash transfers',
    Definition: 'Monetary transfer to families or individuals without any formal conditionality attached to it, but labelled with the intended purpose of the transfer( soft conditionality). The aim of the transfer is emphasized when given to the beneficiaries but no monitoring of the beneficiaries\' actions following the transfer is conducted.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Gender in education',
    Definition1: 'Programmes to address gender disparities and adverse environments in education.',
    DisaggregatedIntervention: 'Safety interventions for girls',
    Definition: 'Interventions that promote safety for girls\' participation in the education system, including policies and actions to reduce gender-based violence, on or off of school grounds.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Gender in education',
    Definition1: 'Programmes to address gender disparities and adverse environments in education.',
    DisaggregatedIntervention: 'Scholarships for girls',
    Definition: 'Scholarship programmes directed toward girls to promote enrolment, performance, and continuation.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Gender in education',
    Definition1: 'Programmes to address gender disparities and adverse environments in education.',
    DisaggregatedIntervention: 'Sex-segregated education',
    Definition: 'Programmes to separate girls and boys either through single-sex classrooms or schools.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Gender in education',
    Definition1: 'Programmes to address gender disparities and adverse environments in education.',
    DisaggregatedIntervention: 'Social promotion for girls education',
    Definition: 'Information or social mechanism campaigns to promote the participation of girls in education, at all levels.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Gender in education',
    Definition1: 'Programmes to address gender disparities and adverse environments in education.',
    DisaggregatedIntervention: 'Teenage mothers and pregnancy inclusion',
    Definition: 'Educational inclusion initiatives and policies to protect and support the learning needs of teenage mothers or pregnant students.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'Gender in education',
    Definition1: 'Programmes to address gender disparities and adverse environments in education.',
    DisaggregatedIntervention: 'Unconditional Cash Transfers (UCTs)',
    Definition: 'Cash transfer to families or individuals without any condition from the beneficiaries. This can be a regular cash transfer or a one-time cash transfer.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'School-based health',
    Definition1: 'Health policies or programs delivered in schools.',
    DisaggregatedIntervention: 'Basic first aid',
    Definition: 'Instruction or provision of materials for use in first aid scenarios.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'School-based health',
    Definition1: 'Health policies or programs delivered in schools.',
    DisaggregatedIntervention: 'General health education and behaviour change',
    Definition: 'Interventions targeting unhealthy behaviours and encouraging change, not including nutrition and dieting. This would include ',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'School-based health',
    Definition1: 'Health policies or programs delivered in schools.',
    DisaggregatedIntervention: 'Individual-based screening',
    Definition: 'Screening performed on an individual basis for specific conditions, such as eyesight or hearing.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'School-based health',
    Definition1: 'Health policies or programs delivered in schools.',
    DisaggregatedIntervention: 'Mental health and psychosocial support (MHPSS)',
    Definition: 'Provision of mental health and psychosocial support (MHPS) in emergencies or situation of chronic hardship. MHPS can be specialized if implemented by specialist such as psychiatrist or psychologist, or non-specialized if implemented by primary health care doctors and community health workers. It can also be a community support group or a network strengthening intervention to allow people to seek psychosocial support directly within their community.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'School-based health',
    Definition1: 'Health policies or programs delivered in schools.',
    DisaggregatedIntervention: 'School feeding programmes',
    Definition: 'Provision of food at school as in-kind support. Normally provision of free nutritious meal at school – usually lunch – and sometimes take-home rations for children most in need. School feeding programmes aim both to reduce hunger and improve food security, as well as increase school attendance and learner performance.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Access & participation',
    InterventionGroup: 'School-based health',
    Definition1: 'Health policies or programs delivered in schools.',
    DisaggregatedIntervention: 'Sexual and reproductive health education in schools',
    Definition: 'Sexual and reproductive health education initiatives in schools, providing information on STIs, puberty, etc.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Decentralisation of education',
    Definition1: 'Interventions that promote subsidiarity and the decentralisation of school management to local levels.',
    DisaggregatedIntervention: 'Decentralised resource allocation in education systems',
    Definition: 'The decentralisation of resource allocation to local government, community-based organisations, or individual schools.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Decentralisation of education',
    Definition1: 'Interventions that promote subsidiarity and the decentralisation of school management to local levels.',
    DisaggregatedIntervention: 'Inter-school collaboration',
    Definition: 'Inter-school communication and management of school resources and operating procedures.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Decentralisation of education',
    Definition1: 'Interventions that promote subsidiarity and the decentralisation of school management to local levels.',
    DisaggregatedIntervention: 'School-based management',
    Definition: 'Decentralising authority away from regional or local governance arrangements to the school level for everyday school management. This is often to improve the quality of school administration and leadership.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education accountability',
    Definition1: 'Accountability mechanisms in education governance.',
    DisaggregatedIntervention: 'Education accreditation',
    Definition: 'Accreditation programmes for institutions of specific educational programmes.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education accountability',
    Definition1: 'Accountability mechanisms in education governance.',
    DisaggregatedIntervention: 'Public oversight of private schools',
    Definition: 'Interventions that effect the level of public oversight in the management and operation of private schools.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education accountability',
    Definition1: 'Accountability mechanisms in education governance.',
    DisaggregatedIntervention: 'Reform implementation strategies',
    Definition: 'Use of educational reform implementation strategies in rolling out any policy or standards changes, such as incremental, gradual, piloted, or sudden implementation.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education accountability',
    Definition1: 'Accountability mechanisms in education governance.',
    DisaggregatedIntervention: 'Reporting standards and procedures in education',
    Definition: 'Reform or enforcement of reporting standards from school administrators and teachers to the relevant ministries or governance structures on educations quality and student performance.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education financing',
    Definition1: 'Initiatives to improve and expand the funding mechanisms of education systems and educational institutions on all levels.',
    DisaggregatedIntervention: 'Alternative education funding',
    Definition: 'Interventions to promote any alternative education funding schemes, especially innovative funding mechanisms.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education financing',
    Definition1: 'Initiatives to improve and expand the funding mechanisms of education systems and educational institutions on all levels.',
    DisaggregatedIntervention: 'Education institution-level grants',
    Definition: 'Grants to fund development plans in schools, vocational institutions or universities.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education financing',
    Definition1: 'Initiatives to improve and expand the funding mechanisms of education systems and educational institutions on all levels.',
    DisaggregatedIntervention: 'Education lending',
    Definition: 'Provision or promotion of educational loans, including programmes to provide subsidized or reduced interest loans to students.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education financing',
    Definition1: 'Initiatives to improve and expand the funding mechanisms of education systems and educational institutions on all levels.',
    DisaggregatedIntervention: 'Education savings accounts',
    Definition: 'Prepayment schemes for individuals or families to cover education-related costs.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education financing',
    Definition1: 'Initiatives to improve and expand the funding mechanisms of education systems and educational institutions on all levels.',
    DisaggregatedIntervention: 'Endowment management',
    Definition: 'Changes to, or establishing, an endowment for an educational institution with the hope of promoting sustainable financing. This could be for the overall institution, or to support specific staff positions or programmes.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education financing',
    Definition1: 'Initiatives to improve and expand the funding mechanisms of education systems and educational institutions on all levels.',
    DisaggregatedIntervention: 'Fee for service in education',
    Definition: 'Cost-recovery through tuition and/or other fees.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education financing',
    Definition1: 'Initiatives to improve and expand the funding mechanisms of education systems and educational institutions on all levels.',
    DisaggregatedIntervention: 'Financial management training',
    Definition: 'Financial management education and training programmes.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education financing',
    Definition1: 'Initiatives to improve and expand the funding mechanisms of education systems and educational institutions on all levels.',
    DisaggregatedIntervention: 'Performance-based financing for private education institutions',
    Definition: 'Grants to private institutions for meeting performance and/or reporting targets.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education financing',
    Definition1: 'Initiatives to improve and expand the funding mechanisms of education systems and educational institutions on all levels.',
    DisaggregatedIntervention: 'Public expenditure for education',
    Definition: 'Provision of funds by central or local governments through tax revenues to promote education through establishment of educational and vocational institutions, provision of educational infrastructure, subsidising education fee etc.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education financing',
    Definition1: 'Initiatives to improve and expand the funding mechanisms of education systems and educational institutions on all levels.',
    DisaggregatedIntervention: 'Results-based financing in education',
    Definition: 'Results-based/performance-based financing and budgeting.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education organisation',
    Definition1: 'Organisational structure and classification for the delivery of education services.',
    DisaggregatedIntervention: 'Charter schools',
    Definition: 'Interventions aimed at promoting enrolment at privately run schools which are publically funded. Re-structuring (turning private to public)',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education organisation',
    Definition1: 'Organisational structure and classification for the delivery of education services.',
    DisaggregatedIntervention: 'Higher education promotion',
    Definition: 'Interventions aimed at promoting enrolment in higher education institutions, such as by informing high school students about the short-term and long-term economic and social returns of tertiary education through role-playing etc.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education organisation',
    Definition1: 'Organisational structure and classification for the delivery of education services.',
    DisaggregatedIntervention: 'Private school attendance',
    Definition: 'Interventions aimed at promoting attendance and reducing truancy at private schools and other private educational institutions',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education organisation',
    Definition1: 'Organisational structure and classification for the delivery of education services.',
    DisaggregatedIntervention: 'Public school attendance',
    Definition: 'Interventions aimed at promoting attendance and reducing truancy at public schools and other public educational institutions',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education organisation',
    Definition1: 'Organisational structure and classification for the delivery of education services.',
    DisaggregatedIntervention: 'Religious education',
    Definition: 'Provision of educational services which are associated with or aimed primarily at groups belonging to a particular religion such as Catholic schools, Madrassahs',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education organisation',
    Definition1: 'Organisational structure and classification for the delivery of education services.',
    DisaggregatedIntervention: 'Secular education',
    Definition: 'Provision of educational services which are not associated with any religious group',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education policy and regulation',
    Definition1: 'Policies and regulation mechanisms in education.',
    DisaggregatedIntervention: 'Completion standards',
    Definition: 'Reform of education completion standards, such as additional years of instruction and minimum passing grade.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education policy and regulation',
    Definition1: 'Policies and regulation mechanisms in education.',
    DisaggregatedIntervention: 'Curriculum standards',
    Definition: 'Reform of curriculum and education standards, which includes quality standards for teachers and materials.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education policy and regulation',
    Definition1: 'Policies and regulation mechanisms in education.',
    DisaggregatedIntervention: 'Home schooling policy',
    Definition: 'Interventions that are designed to promote and support home schooling through the introduction/change of home schooling curriculums or standards, including certification.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education policy and regulation',
    Definition1: 'Policies and regulation mechanisms in education.',
    DisaggregatedIntervention: 'Mandatory school policy',
    Definition: 'Policies enacted by the government to make education mandatory for all',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education policy and regulation',
    Definition1: 'Policies and regulation mechanisms in education.',
    DisaggregatedIntervention: 'Operating standards and procedures in education',
    Definition: 'Reform or enforcement of operational standards for educational institutions from early childhood education through vocational training institutes and universities. In this case, operational standards are referring to any policies or procedures that govern the administration of educational institutions, from calendar scheduling through absentee policies.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education policy and regulation',
    Definition1: 'Policies and regulation mechanisms in education.',
    DisaggregatedIntervention: 'PPP in education',
    Definition: 'Public-private partnerships in education. These can also be applied in regulations and standards in education.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education policy and regulation',
    Definition1: 'Policies and regulation mechanisms in education.',
    DisaggregatedIntervention: 'School choice policies',
    Definition: 'Policies that govern the level of school choice available to a student. These policies often act as accountability mechanisms for underperforming school.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Education policy and regulation',
    Definition1: 'Policies and regulation mechanisms in education.',
    DisaggregatedIntervention: 'School safety regulation',
    Definition: 'Reform or enforcement of school safety regulations, including student discipline, bullying, sexual harassment, etc.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Household & community engagement',
    Definition1: 'Interventions designed to harness community or household involvement in a child\'s education and monitoring of school performance.',
    DisaggregatedIntervention: 'Community-based literacy promotion',
    Definition: 'Instructing learners to achieve literacy through promoting learning and positive learning environments in the community, this can include community libraries.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Household & community engagement',
    Definition1: 'Interventions designed to harness community or household involvement in a child\'s education and monitoring of school performance.',
    DisaggregatedIntervention: 'Community-based monitoring of education performance',
    Definition: 'Interventions that provide student/ school performance information to parents/ communities to increase transparency.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Household & community engagement',
    Definition1: 'Interventions designed to harness community or household involvement in a child\'s education and monitoring of school performance.',
    DisaggregatedIntervention: 'Community-based tutoring',
    Definition: 'Programs that use a tutoring approach, with peers or adult community members teaching children literacy skills.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Household & community engagement',
    Definition1: 'Interventions designed to harness community or household involvement in a child\'s education and monitoring of school performance.',
    DisaggregatedIntervention: 'Home-based schooling and education',
    Definition: 'Interventions that are designed to promote and support home-based schooling and education.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Household & community engagement',
    Definition1: 'Interventions designed to harness community or household involvement in a child\'s education and monitoring of school performance.',
    DisaggregatedIntervention: 'Parental and community participation',
    Definition: 'Involvement of parents and the community in school events and projects, even including sporting events.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Household & community engagement',
    Definition1: 'Interventions designed to harness community or household involvement in a child\'s education and monitoring of school performance.',
    DisaggregatedIntervention: 'Parental participation in education',
    Definition: 'Encouraging parental commitment in education (service, quality and management). This can include parent associations for quality of education',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Household & community engagement',
    Definition1: 'Interventions designed to harness community or household involvement in a child\'s education and monitoring of school performance.',
    DisaggregatedIntervention: 'Parental training for educational support',
    Definition: 'Programmes that show parents how to support their children’s school readiness (including in the area of literacy).',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Household & community engagement',
    Definition1: 'Interventions designed to harness community or household involvement in a child\'s education and monitoring of school performance.',
    DisaggregatedIntervention: 'Parent-teacher organisations',
    Definition: 'Establishing or promoting participation in parent-teacher organisations.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Household & community engagement',
    Definition1: 'Interventions designed to harness community or household involvement in a child\'s education and monitoring of school performance.',
    DisaggregatedIntervention: 'Service learning',
    Definition: 'Programmes to apply learning in community service oriented ways. This involves students demonstrating learned knowledge in practical applications.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Teacher policies',
    Definition1: 'Policies that govern, and programmes that support, the hiring, contracting, and firing of educators.',
    DisaggregatedIntervention: 'Probationary periods',
    Definition: 'Use of probationary periods when hiring new teachers, before awarding a long-term contract.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Teacher policies',
    Definition1: 'Policies that govern the hiring, contracting, and firing of educators.',
    DisaggregatedIntervention: 'Teacher education and certification',
    Definition: 'Teacher certification to improve quality of education, including skills and knowledge tests. This also includes minimum education requirements for teaching.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Teacher policies',
    Definition1: 'Policies that govern, and programmes that support, the hiring, contracting, and firing of educators.',
    DisaggregatedIntervention: 'Teacher firing policies',
    Definition: 'Policies and mechanisms that govern the firing of underperforming teachers.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Teacher policies',
    Definition1: 'Policies that govern, and programmes that support, the hiring, contracting, and firing of educators.',
    DisaggregatedIntervention: 'Teacher hiring practices',
    Definition: 'Teacher hiring practices including incentivised contracting, adding an additional contract teacher, performance related pay, different terms and conditions to existing workforce.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Teacher policies',
    Definition1: 'Policies that govern, and programmes that support, the hiring, contracting, and firing of educators.',
    DisaggregatedIntervention: 'Teacher housing',
    Definition: 'Providing housing for teachers, typically observed in rural/remote settings.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Teacher policies',
    Definition1: 'Policies that govern, and programmes that support, the hiring, contracting, and firing of educators.',
    DisaggregatedIntervention: 'Teacher transfer policies',
    Definition: 'Policies that transfer teachers between schools to improve the quality and equity of education, especially between urban and rural schools.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education governance',
    InterventionGroup: 'Teacher policies',
    Definition1: 'Policies that govern, and programmes that support, the hiring, contracting, and firing of educators.',
    DisaggregatedIntervention: 'Teacher unions',
    Definition: 'The unionisation/union busting of teaches. These include limiting union presence in stakeholder engagements.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'School & classroom organisation',
    Definition1: 'Interventions designed to improve standards through changes in school and classroom organisation.',
    DisaggregatedIntervention: 'Anti-cheating',
    Definition: 'Interventions that aim to reduce cheating at all levels of education.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'School & classroom organisation',
    Definition1: 'Interventions designed to improve standards through changes in school and classroom organisation.',
    DisaggregatedIntervention: 'Grade retention',
    Definition: 'Grouping students by ability (grade retention interventions).',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'School & classroom organisation',
    Definition1: 'Interventions designed to improve standards through changes in school and classroom organisation.',
    DisaggregatedIntervention: 'Language of instruction',
    Definition: 'Changes in language of instruction policies locally/nationally.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'School & classroom organisation',
    Definition1: 'Interventions designed to improve standards through changes in school and classroom organisation.',
    DisaggregatedIntervention: 'Students to teacher ratio',
    Definition: 'Regrouping or scheduling of classes to maintain or improve students to teacher ratios.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'School & classroom organisation',
    Definition1: 'Interventions designed to improve standards through changes in school and classroom organisation.',
    DisaggregatedIntervention: 'Time in school',
    Definition: 'Increase/decrease in the number of instructional hours in the classroom.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'School & classroom organisation',
    Definition1: 'Interventions designed to improve standards through changes in school and classroom organisation.',
    DisaggregatedIntervention: 'Tracking students',
    Definition: 'Grouping students by ability (tracking interventions).',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'Student assessment',
    Definition1: 'Activities that strengthen countries, teachers, and schools’ abilities to collect and use information on student learning through assessments. This theme also includes examinations on the tertiary level and skills certification.',
    DisaggregatedIntervention: 'Classroom assessments and examinations',
    Definition: 'The use of classroom assessments and examinations.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'Student assessment',
    Definition1: 'Activities that strengthen countries, teachers, and schools’ abilities to collect and use information on student learning through assessments. This theme also includes examinations on the tertiary level and skills certification.',
    DisaggregatedIntervention: 'Examination boards',
    Definition: 'Creation or training for examination boards.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'Student assessment',
    Definition1: 'Activities that strengthen countries, teachers, and schools’ abilities to collect and use information on student learning through assessments. This theme also includes examinations on the tertiary level and skills certification.',
    DisaggregatedIntervention: 'Incentives for student performance',
    Definition: 'Provision of incentives to students based on performance.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'Student assessment',
    Definition1: 'Activities that strengthen countries, teachers, and schools’ abilities to collect and use information on student learning through assessments. This theme also includes examinations on the tertiary level and skills certification.',
    DisaggregatedIntervention: 'International student assessments',
    Definition: 'Creation of or administration of International student assessments (PISA, TIMSS, PIRLS).',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'Student assessment',
    Definition1: 'Activities that strengthen countries, teachers, and schools’ abilities to collect and use information on student learning through assessments. This theme also includes examinations on the tertiary level and skills certification.',
    DisaggregatedIntervention: 'National student assessments',
    Definition: 'Creation of or administration of national student assessments.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'Student assessment',
    Definition1: 'Activities that strengthen countries, teachers, and schools’ abilities to collect and use information on student learning through assessments. This theme also includes examinations on the tertiary level and skills certification.',
    DisaggregatedIntervention: 'Regional student assessments',
    Definition: 'Creation of or administration of regional student assessments (PASEC, LLECE, SACMEQ).',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'Student assessment',
    Definition1: 'Activities that strengthen countries, teachers, and schools’ abilities to collect and use information on student learning through assessments. This theme also includes examinations on the tertiary level and skills certification.',
    DisaggregatedIntervention: 'School readiness assessments in ECD',
    Definition: 'School readiness assessments in Early Childhood Education.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'Student assessment',
    Definition1: 'Activities that strengthen countries, teachers, and schools’ abilities to collect and use information on student learning through assessments. This theme also includes examinations on the tertiary level and skills certification.',
    DisaggregatedIntervention: 'Skills certification systems',
    Definition: 'Skills certification systems.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'Student assessment',
    Definition1: 'Activities that strengthen countries, teachers, and schools’ abilities to collect and use information on student learning through assessments. This theme also includes examinations on the tertiary level and skills certification.',
    DisaggregatedIntervention: 'Subnational learning assessment systems',
    Definition: 'Creation of or administration of sub-national learning assessment systems.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'Student assessment',
    Definition1: 'Activities that strengthen countries, teachers, and schools’ abilities to collect and use information on student learning through assessments. This theme also includes examinations on the tertiary level and skills certification.',
    DisaggregatedIntervention: 'Tertiary-level examination systems',
    Definition: 'Tertiary-level examination systems.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'Teacher development',
    Definition1: 'Programmes that seek to improve the performance and ability of educators.',
    DisaggregatedIntervention: 'Continuing education for educators',
    Definition: 'Continuing education programmes for teachers.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'Teacher development',
    Definition1: 'Programmes that seek to improve the performance and ability of educators.',
    DisaggregatedIntervention: 'Pay for performance',
    Definition: 'Contract schemes for private and public sector employees, especially teachers and healthcare professionals, that provide incentives based on performance metrics.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'Teacher development',
    Definition1: 'Programmes that seek to improve the performance and ability of educators.',
    DisaggregatedIntervention: 'Teacher collaboration',
    Definition: 'Interventions that bring teachers together to share and discuss pedagogy techniques and materials, including symposiums.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'Teacher development',
    Definition1: 'Programmes that seek to improve the performance and ability of educators.',
    DisaggregatedIntervention: 'Teacher evaluation',
    Definition: 'Diagnostic assessments of the teacher\'s ability; can be provided by students, peers, or supervisors.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'Teacher development',
    Definition1: 'Programmes that seek to improve the performance and ability of educators.',
    DisaggregatedIntervention: 'Teacher training for child health',
    Definition: 'Interventions that train teachers to look after students\' health and identify health conditions, such as conducting basic medical inspection (physical and mental) and providing first aid. These may also include school-based health interventions, such as de-worming programmes, where teachers are trained to organise and assist in drug/immunisation administration and in general health promotion, such as delivering educational messages.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Education quality & assessment',
    InterventionGroup: 'Teacher development',
    Definition1: 'Programmes that seek to improve the performance and ability of educators.',
    DisaggregatedIntervention: 'Teacher training for children with disabilities',
    Definition: 'Interventions that train teachers to look after and assist students with learning, linguistic, or physical disabilities.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Education facilities & equipment',
    Definition1: 'The construction, rehabilitation, and furnishing of educational facilities across all levels of education: from early childhood education through tertiary.',
    DisaggregatedIntervention: 'Classroom equipment',
    Definition: 'Provision or improvement of furnishings (desks/chairs) and equipment within the classroom.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Education facilities & equipment',
    Definition1: 'The construction, rehabilitation, and furnishing of educational facilities across all levels of education: from early childhood education through tertiary.',
    DisaggregatedIntervention: 'Disability inclusive infrastructure',
    Definition: 'Provision of disability inclusive infrastructure on campus such as ramps for wheelchairs',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Education facilities & equipment',
    Definition1: 'The construction, rehabilitation, and furnishing of educational facilities across all levels of education: from early childhood education through tertiary.',
    DisaggregatedIntervention: 'Electricity in schools',
    Definition: 'Electrification of classrooms to improve learning environments and use of technologies in the classroom.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Education facilities & equipment',
    Definition1: 'The construction, rehabilitation, and furnishing of educational facilities across all levels of education: from early childhood education through tertiary.',
    DisaggregatedIntervention: 'Housing and boarding for students',
    Definition: 'On-site provision and maintenance of housing and boarding for students while school is in session.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Education facilities & equipment',
    Definition1: 'The construction, rehabilitation, and furnishing of educational facilities across all levels of education: from early childhood education through tertiary.',
    DisaggregatedIntervention: 'Housing and boarding for teachers',
    Definition: 'On-site provision and maintenance of housing and boarding for teachers while school is in session. This is especially relevant in rural/remote locations.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Education facilities & equipment',
    Definition1: 'The construction, rehabilitation, and furnishing of educational facilities across all levels of education: from early childhood education through tertiary.',
    DisaggregatedIntervention: 'Improved road access to school',
    Definition: 'Improving access to education through road construction or improvement.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Education facilities & equipment',
    Definition1: 'The construction, rehabilitation, and furnishing of educational facilities across all levels of education: from early childhood education through tertiary.',
    DisaggregatedIntervention: 'Libraries and resource centres',
    Definition: 'Establishing, improving, or expanding school libraries and resource centres for students.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Education facilities & equipment',
    Definition1: 'The construction, rehabilitation, and furnishing of educational facilities across all levels of education: from early childhood education through tertiary.',
    DisaggregatedIntervention: 'New schools and facilities',
    Definition: 'Establishment of new schools, or expansion of old schools, through the construction of new education facilities.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Education facilities & equipment',
    Definition1: 'The construction, rehabilitation, and furnishing of educational facilities across all levels of education: from early childhood education through tertiary.',
    DisaggregatedIntervention: 'Renovated schools and facilities',
    Definition: 'Renovation of existing school facilities to improve the learning environment for students and/or to provide access to technology in the classroom.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Education facilities & equipment',
    Definition1: 'The construction, rehabilitation, and furnishing of educational facilities across all levels of education: from early childhood education through tertiary.',
    DisaggregatedIntervention: 'School latrines',
    Definition: 'Construction, renovation, improvement, or expansion of school latrines.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Education facilities & equipment',
    Definition1: 'The construction, rehabilitation, and furnishing of educational facilities across all levels of education: from early childhood education through tertiary.',
    DisaggregatedIntervention: 'School water systems',
    Definition: 'On-site water sourcing or treatment to provide educational facilities with potable water.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Learning materials',
    Definition1: 'Provision of learning materials for use in the classroom.',
    DisaggregatedIntervention: 'Classroom decoration',
    Definition: 'Provision of classroom decorations and accessories to enhance the learning environment and convey information, such as posters or announcement boards.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Learning materials',
    Definition1: 'Provision of learning materials for use in the classroom.',
    DisaggregatedIntervention: 'Digital curricular materials',
    Definition: 'Provision of access to digital materials, such as texts/books, to support or supplement learning in the classroom.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Learning materials',
    Definition1: 'Provision of learning materials for use in the classroom.',
    DisaggregatedIntervention: 'Non-digital curricular materials',
    Definition: 'Provision of non-digital materials, such as texts/books, to support or supplement learning in the classroom.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Learning materials',
    Definition1: 'Provision of learning materials for use in the classroom.',
    DisaggregatedIntervention: 'Presentation materials',
    Definition: 'Provision of flip charts and other materials for presentation and learning in the classroom.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Learning materials',
    Definition1: 'Provision of learning materials for use in the classroom.',
    DisaggregatedIntervention: 'Textbook provision',
    Definition: 'Provision of textbooks to aid in the education of students.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Technology in the classroom',
    Definition1: 'Technology made available in the classroom setting to aid in learning or as an end in itself.',
    DisaggregatedIntervention: 'Audio-visual equipment in the classroom',
    Definition: 'Programmes to provide, or improve the availability and reliability of, audio-visual equipment, such as microphones or cameras, for recording lectures and interactions in the classroom.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Technology in the classroom',
    Definition1: 'Technology made available in the classroom setting to aid in learning or as an end in itself.',
    DisaggregatedIntervention: 'Computers in the classroom',
    Definition: 'Programmes to provide, or improve the availability and reliability of, computers and/or tablets in the classroom.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Educational resources',
    InterventionGroup: 'Technology in the classroom',
    Definition1: 'Technology made available in the classroom setting to aid in learning or as an end in itself.',
    DisaggregatedIntervention: 'Presentation equipment in the classroom',
    Definition: 'Programmes to provide, or improve the availability and reliability of, presentation equipment in the classroom, such as projectors, smart boards, etc.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Pedagogy',
    InterventionGroup: 'Teaching methodology',
    Definition1: 'Interventions that address the methods and practice of instruction.',
    DisaggregatedIntervention: 'Disability studies pedagogy',
    Definition: 'Programs and curricula that typically are meant to address the needs of students who have learning, linguistic, or physical disabilities.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Pedagogy',
    InterventionGroup: 'Teaching methodology',
    Definition1: 'Interventions that address the methods and practice of instruction.',
    DisaggregatedIntervention: 'Language of instruction',
    Definition: 'Changes in language of instruction policies locally/nationally.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Pedagogy',
    InterventionGroup: 'Teaching methodology',
    Definition1: 'Interventions that address the methods and practice of instruction.',
    DisaggregatedIntervention: 'Other pedagogical intervention',
    Definition: 'Programmes that affect the method(s) of instruction & learning (includes blended or technology-assisted learning).',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Pedagogy',
    InterventionGroup: 'Teaching methodology',
    Definition1: 'Interventions that address the methods and practice of instruction.',
    DisaggregatedIntervention: 'Peer-to-peer learning',
    Definition: 'Schemes to allow students to learn together, and from each other, such as study groups.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Pedagogy',
    InterventionGroup: 'Teaching methodology',
    Definition1: 'Interventions that address the methods and practice of instruction.',
    DisaggregatedIntervention: 'Remedial education',
    Definition: 'Additional instruction, targeting underperforming students.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Pedagogy',
    InterventionGroup: 'Teaching methodology',
    Definition1: 'Interventions that address the methods and practice of instruction.',
    DisaggregatedIntervention: 'School dropout prevention',
    Definition: 'Programmes targeted at at-risk students by monitoring their attendance, coursework, and behaviour to prevent dropouts.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Pedagogy',
    InterventionGroup: 'Teaching methodology',
    Definition1: 'Interventions that address the methods and practice of instruction.',
    DisaggregatedIntervention: 'School-based tutoring',
    Definition: 'Programmes to provide tutoring at school to address the specific needs of learners.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Pedagogy',
    InterventionGroup: 'Teaching methodology',
    Definition1: 'Interventions that address the methods and practice of instruction.',
    DisaggregatedIntervention: 'Social and emotional learning',
    Definition: 'Interventions that focus on the development of social and emotional learning.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Pedagogy',
    InterventionGroup: 'Teaching methodology',
    Definition1: 'Interventions that address the methods and practice of instruction.',
    DisaggregatedIntervention: 'Visual aids',
    Definition: 'Presentations utilising visual aids in instruction of pupils.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Pedagogy',
    InterventionGroup: 'Technology in learning',
    Definition1: 'Use of technology in learning, at any level, including in the home.',
    DisaggregatedIntervention: 'Computer Assisted Learning (CAL)',
    Definition: 'Any use of computers to aid or support the education or training of people.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Pedagogy',
    InterventionGroup: 'Technology in learning',
    Definition1: 'Use of technology in learning, at any level, including in the home.',
    DisaggregatedIntervention: 'Digital learning materials',
    Definition: 'Use of digital learning materials in the classroom, such as visualisation programmes, e-books, etc.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Pedagogy',
    InterventionGroup: 'Technology in learning',
    Definition1: 'Use of technology in learning, at any level, including in the home.',
    DisaggregatedIntervention: 'Educational programming',
    Definition: 'Programming targeting students through alternative channels than school, including television, radio, games, etc.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Pedagogy',
    InterventionGroup: 'Technology in learning',
    Definition1: 'Use of technology in learning, at any level, including in the home.',
    DisaggregatedIntervention: 'Integrated technology for learning',
    Definition: 'Use of integrate technology in the classroom for teaching purposes, such as projectors, smartboards, etc.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Pedagogy',
    InterventionGroup: 'Technology in learning',
    Definition1: 'Use of technology in learning, at any level, including in the home.',
    DisaggregatedIntervention: 'Mobile technology education',
    Definition: 'Programmes or curriculums to provide education on the use of mobile technologies, such as mobile phones or tablets.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Pedagogy',
    InterventionGroup: 'Technology in learning',
    Definition1: 'Use of technology in learning, at any level, including in the home.',
    DisaggregatedIntervention: 'Provision of computers for learning',
    Definition: 'Programmes that seek to provide individuals or households with computers.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Adult education',
    Definition1: 'Educational programmes targeted at adults, especially those behind in educational attainment.',
    DisaggregatedIntervention: 'Adult education catch-up',
    Definition: 'Education directed to adults who had previously dropped out of the education system, with an end toward certification (e.g. GED).',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Adult education',
    Definition1: 'Educational programmes targeted at adults, especially those behind in educational attainment.',
    DisaggregatedIntervention: 'Adult literacy',
    Definition: 'Literacy programmes tailored and targeted to adults.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Adult education',
    Definition1: 'Educational programmes targeted at adults, especially those behind in educational attainment.',
    DisaggregatedIntervention: 'ICT capacity building',
    Definition: 'ICT capacity building such as Computer literacy training and ICT skill training, including in mobile technology.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Early childhood development',
    Definition1: 'Programmes that focus on early childhood development.',
    DisaggregatedIntervention: 'Early childhood care',
    Definition: 'Programmes to provide effective care and nurture children at early childhood education centres.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Early childhood development',
    Definition1: 'Programmes that focus on early childhood development.',
    DisaggregatedIntervention: 'Home-based early childhood development',
    Definition: 'Early childhood development programmes implemented at home, where there is an environment conducive to learning.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Early childhood development',
    Definition1: 'Programmes that focus on early childhood development.',
    DisaggregatedIntervention: 'Parental information and training on ECD',
    Definition: 'Providing information or training to parents about pre-primary school intervention programs targeting the first 1,000 days.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Early childhood development',
    Definition1: 'Programmes that focus on early childhood development.',
    DisaggregatedIntervention: 'Pre-schooling and kindergarten',
    Definition: 'Institution or enrolment in pre-primary education programmes.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Employability & skills training',
    Definition1: 'Skills development programmes with an eye to employment.',
    DisaggregatedIntervention: 'Apprenticeship programmes',
    Definition: 'Work experience programmes, often formal, to train a worker to become skilled in a particular trade.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Employability & skills training',
    Definition1: 'Skills development programmes with an eye to employment.',
    DisaggregatedIntervention: 'Entrepreneurship training',
    Definition: 'Provision of entrepreneurship training focused on how to start up a business from scratch, which can include for example developing a business plan, day-to-day management of small enterprise, including bookkeeping, financial planning, etc.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Employability & skills training',
    Definition1: 'Skills development programmes with an eye to employment.',
    DisaggregatedIntervention: 'Internship programmes',
    Definition: 'Work experience programmes, often informal, to learn about a company, industry, or trade.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Employability & skills training',
    Definition1: 'Skills development programmes with an eye to employment.',
    DisaggregatedIntervention: 'Job placement schemes',
    Definition: 'Work placement programmes that seek to put job-seekers into positions within business or trade. These include internship or apprenticeship programmes.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Employability & skills training',
    Definition1: 'Skills development programmes with an eye to employment.',
    DisaggregatedIntervention: 'Leadership programmes',
    Definition: 'Programmes directed at students to develop leadership skills and ability.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Employability & skills training',
    Definition1: 'Skills development programmes with an eye to employment.',
    DisaggregatedIntervention: 'Other life skills',
    Definition: 'Programmes that train and enhance other life skills.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Employability & skills training',
    Definition1: 'Skills development programmes with an eye to employment.',
    DisaggregatedIntervention: 'Technical and Vocational Education and Training (TVET)',
    Definition: 'Education and training which provides knowledge and skills for employment. TVET uses formal, non-formal, and informal learning.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Employability & skills training',
    Definition1: 'Skills development programmes with an eye to employment.',
    DisaggregatedIntervention: 'Transferable skills training',
    Definition: 'Programmes that train in portable skills that are universal in trade work/employment.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Other topics in education',
    Definition1: 'Programmes to promote other topics in education.',
    DisaggregatedIntervention: 'Literature and the Arts',
    Definition: 'Programmes to promote education and interest in the arts and literature.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Other topics in education',
    Definition1: 'Programmes to promote other topics in education.',
    DisaggregatedIntervention: 'Non-native language skills',
    Definition: 'Programmes to teach students non-native languages.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Other topics in education',
    Definition1: 'Programmes to promote other topics in education.',
    DisaggregatedIntervention: 'Physical education',
    Definition: 'Programmes and curriculums around physical education, especially exercise.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Other topics in education',
    Definition1: 'Programmes to promote other topics in education.',
    DisaggregatedIntervention: 'Youth extracurricular programmes',
    Definition: 'Programmes that offer youth with extracurricular activities, such as special interest clubs.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Science & technology',
    Definition1: 'Activities to promote scientific and technological knowledge and innovation inside and outside of educational institutions. They can range from initiatives to improve science curriculum and instruction in secondary schools to research and development (R&D) grants at the tertiary level.',
    DisaggregatedIntervention: 'Ethics in research',
    Definition: 'Regulation and promotion by public institutions to modify and approve research projects, with an aim to improve the technical or ethical components of the research.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Science & technology',
    Definition1: 'Activities to promote scientific and technological knowledge and innovation inside and outside of educational institutions. They can range from initiatives to improve science curriculum and instruction in secondary schools to research and development (R&D) grants at the tertiary level.',
    DisaggregatedIntervention: 'Extracurricular activities in STEM',
    Definition: 'Participation in groups or individual work in science, technology, engineering, and mathematics, such as a science fair or club.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Science & technology',
    Definition1: 'Activities to promote scientific and technological knowledge and innovation inside and outside of educational institutions. They can range from initiatives to improve science curriculum and instruction in secondary schools to research and development (R&D) grants at the tertiary level.',
    DisaggregatedIntervention: 'Informed consent for research',
    Definition: 'Facilitation of informed consent procedures to approve research projects',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Science & technology',
    Definition1: 'Activities to promote scientific and technological knowledge and innovation inside and outside of educational institutions. They can range from initiatives to improve science curriculum and instruction in secondary schools to research and development (R&D) grants at the tertiary level.',
    DisaggregatedIntervention: 'Institutional Review Boards (IRBs)',
    Definition: 'Institution of boards with power to modify and approve research projects, with an aim to improve the technical or ethical components of the research.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Science & technology',
    Definition1: 'Activities to promote scientific and technological knowledge and innovation inside and outside of educational institutions. They can range from initiatives to improve science curriculum and instruction in secondary schools to research and development (R&D) grants at the tertiary level.',
    DisaggregatedIntervention: 'Research fellowships',
    Definition: 'Fellowship opportunities provided to students to be able to investigate a field or topic.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Science & technology',
    Definition1: 'Activities to promote scientific and technological knowledge and innovation inside and outside of educational institutions. They can range from initiatives to improve science curriculum and instruction in secondary schools to research and development (R&D) grants at the tertiary level.',
    DisaggregatedIntervention: 'Research funding',
    Definition: 'Funding provided to allow for a greater volume of research or higher quality of research.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Science & technology',
    Definition1: 'Activities to promote scientific and technological knowledge and innovation inside and outside of educational institutions. They can range from initiatives to improve science curriculum and instruction in secondary schools to research and development (R&D) grants at the tertiary level.',
    DisaggregatedIntervention: 'Research labs and facilities',
    Definition: 'Building or refurbishment of science labs and facilities to facilitate research.',
  },
  {
    InterventionSector: 'Education',
    BroadGroup: 'Topics in education',
    InterventionGroup: 'Science & technology',
    Definition1: 'Activities to promote scientific and technological knowledge and innovation inside and outside of educational institutions. They can range from initiatives to improve science curriculum and instruction in secondary schools to research and development (R&D) grants at the tertiary level.',
    DisaggregatedIntervention: 'Science and technology policy for education purposes',
    Definition: 'Science, technology and innovation policy.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Information and citizen participation',
    Definition1: 'Civil information for oversight and participation in energy sector.',
    DisaggregatedIntervention: 'Citizen monitoring and feedback mechanism in the energy and extractives sector',
    Definition: 'Provision of a platform for meetings between citizens and relevant stakeholders (government, company), for discussing citizens\' rights and feedback concerns for priorities to the stakeholders, to develop citizen action plan and/ or setting up grievance redress mechanisms to follow up with stakeholders. This intervention can take the form of a scorecard, a social audit or face-to-face meetings.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Information and citizen participation',
    Definition1: 'Civil information for oversight and participation in energy sector.',
    DisaggregatedIntervention: 'Citizen participation in decision making in the energy and extractives sector',
    Definition: 'Facilitation of public participation in public institutions\' decision-making processes, priority setting or budget allocation decisions, including participatory budgeting.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Information and citizen participation',
    Definition1: 'Civil information for oversight and participation in energy sector.',
    DisaggregatedIntervention: 'Citizen rights and responsibilities in the energy and extractives sector',
    Definition: 'Provision of information to citizens about their rights to participate in decision-making processes, financial and environmental information related to the industry, their rights to compensation, their rights and responsibilities as citizens and users of services, and government and companies\' responsibilities. Information dissemination can be done through leafleting, broadcasting, SMS, theatre, face-to-face information campaign etc.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Information and citizen participation',
    Definition1: 'Civil information for oversight and participation in energy sector.',
    DisaggregatedIntervention: 'Community based management of extractive resources',
    Definition: 'Delegation of the extractives sector\'s management and governance to the local community.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Information and citizen participation',
    Definition1: 'Civil information for oversight and participation in energy sector.',
    DisaggregatedIntervention: 'Government or private actor performance in the energy and extractives sector',
    Definition: 'Provision of information to citizens about social and environmental performance such as bidding and revenue sharing arrangements (taxes, royalties, and production and equity sharing etc.), transparency of extractives companies payments to the government, compliance with environmental and social standards. Information dissemination can be done through leafleting, broadcasting, SMS, theatre, face-to-face information campaign etc.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Information and citizen participation',
    Definition1: 'Civil information for oversight and participation in energy sector.',
    DisaggregatedIntervention: 'Social License to Operate (SLO)',
    Definition: 'Introduction of new requirements for firms to operate in the energy and extractives sectors, in the form of a Social License to Operate. This requires companies to assess the predicted impact of future activities on the local community through a social impact assessment, and sometimes requires to get the project approved by community members themselves through an informed consent.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Laws and regulations',
    Definition1: 'Laws and regulations to promote equity and address externalities.',
    DisaggregatedIntervention: 'Anti-theft of energy and extractives',
    Definition: 'Laws and regulations on the enforcement and prosecution of cable/electricity theft.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Laws and regulations',
    Definition1: 'Laws and regulations to promote equity and address externalities.',
    DisaggregatedIntervention: 'Decentralisation',
    Definition: 'Decentralisation interventions shift existing power over spending and decision-making to sub-national levels.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Laws and regulations',
    Definition1: 'Laws and regulations to promote equity and address externalities.',
    DisaggregatedIntervention: 'Environmental audit',
    Definition: 'Implementation of independent audits in the private and/or public sector of the energy and extractives companies in order to verify their compliance with environmental standards such as waste management and pollution.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Laws and regulations',
    Definition1: 'Laws and regulations to promote equity and address externalities.',
    DisaggregatedIntervention: 'Environmental contamination response and prevention',
    Definition: 'Regulations to prevent, or procedures to respond, to incidences of environmental contamination, such as oil spills.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Laws and regulations',
    Definition1: 'Laws and regulations to promote equity and address externalities.',
    DisaggregatedIntervention: 'Environmental regulation',
    Definition: 'Any regulation of pollution or environmental footprint. These include environmental auditing institutions.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Laws and regulations',
    Definition1: 'Laws and regulations to promote equity and address externalities.',
    DisaggregatedIntervention: 'Financial audit',
    Definition: 'Implementation of independent audits in the private and/or public sector of the energy and extractives companies in order to verify their compliance with financial laws and regulations, and identify issues of corruption. This also includes interventions to augment auditing capacity.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Laws and regulations',
    Definition1: 'Laws and regulations to promote equity and address externalities.',
    DisaggregatedIntervention: 'Implementation of EITI standards',
    Definition: 'Implementation of the Extractive Industry Transparency Initiative (EITI) standards into national laws and regulations, as required by EITI member countries, with the purpose to make the public and private sector of this industry more transparent, and more accountable to the local population for its economic, social, and environmental impact. These standards include oversight of the sector by a multi-stakeholder group, strengthening of the legal and regulatory framework with allocation of contract and license to operate in particular, financial disclosure requirements, revenue distribution etc.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Laws and regulations',
    Definition1: 'Laws and regulations to promote equity and address externalities.',
    DisaggregatedIntervention: 'Implementation of PWYP standards',
    Definition: 'Implementation of Publish What You Pay (PWYP) standards. These standards include advocating for financial disclosure of payments made by extractive companies to the government, organize campaigns to raise awareness about the issue of good governance.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Laws and regulations',
    Definition1: 'Laws and regulations to promote equity and address externalities.',
    DisaggregatedIntervention: 'Implementation of the Kimberley Process Certification Scheme',
    Definition: 'Implementation of transparency and accountability standards stemming from the Kimberley Process Certification Scheme (KPCS), which identifies diamonds mined to finance conflict and provide a free from conflict certification to other diamonds shipped to other countries.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Laws and regulations',
    Definition1: 'Laws and regulations to promote equity and address externalities.',
    DisaggregatedIntervention: 'Land and mineral rights',
    Definition: 'Interventions that address land holder rights with regards to rights over minerals and/or natural resources on owned land.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Laws and regulations',
    Definition1: 'Laws and regulations to promote equity and address externalities.',
    DisaggregatedIntervention: 'Law enforcement in mining and extractives',
    Definition: 'Enforcement of laws and regulations in the mining and extractives sector.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Laws and regulations',
    Definition1: 'Laws and regulations to promote equity and address externalities.',
    DisaggregatedIntervention: 'Mining and drilling bans',
    Definition: 'Introduction of bans on mining or drilling in certain areas to protect the environment.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Laws and regulations',
    Definition1: 'Laws and regulations to promote equity and address externalities.',
    DisaggregatedIntervention: 'Natural resource lease',
    Definition: 'Changes in the lease rules and regulation for natural resource extraction.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Laws and regulations',
    Definition1: 'Laws and regulations to promote equity and address externalities.',
    DisaggregatedIntervention: 'Resource and fuel use regulation',
    Definition: 'Regulations placed on the use of natural resource commodities and fuel, for example a ban on coal burning.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Laws and regulations',
    Definition1: 'Laws and regulations to promote equity and address externalities.',
    DisaggregatedIntervention: 'Universal service obligations for electricity',
    Definition: 'Provision of a baseline level of electricity supply to all citizen at a reduced price or for free, to ensure that basic needs for electricity are met. Tariffs above that baseline level can vary.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Laws and regulations',
    Definition1: 'Laws and regulations to promote equity and address externalities.',
    DisaggregatedIntervention: 'Workplace health and safety regulations',
    Definition: 'Introduction of new requirements to respect health and safety rules to minimize the number of accidents and to provide safe working spaces.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Laws and regulations',
    Definition1: 'Laws and regulations to promote equity and address externalities.',
    DisaggregatedIntervention: 'Workweek regulation',
    Definition: 'Regulation that governs the amount of time workers may work, including the number and frequency of holidays and enforced breaks.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Market-based reform and incentives',
    Definition1: 'Laws and regulations to promote use of market forces.',
    DisaggregatedIntervention: 'Electricity tariff design',
    Definition: 'Creation, or change in the design, of electricity tariff/pricing, with the aim to ensure economic sustainability and efficiency, and equal access to basic services. Concretely this intervention is the creation of a variety of electricity provision offers tailored to individual or companies\' needs.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Market-based reform and incentives',
    Definition1: 'Laws and regulations to promote use of market forces.',
    DisaggregatedIntervention: 'Energy subsidies',
    Definition: 'Provision of subsidies for individual households, communities, or firms\' energy supply which may or may not depend on the level of energy consumption.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Market-based reform and incentives',
    Definition1: 'Laws and regulations to promote use of market forces.',
    DisaggregatedIntervention: 'Investment based incentives for renewable energy',
    Definition: 'Provision of an awards for the initial investment into a renewable energy. These incentive schemes can be implemented for individual household, companies, or investment banks. The reward can be a personal income tax or VAT exemption on the installation of an equipment or generator, an interest-free loan, or loan guarantees which aims to reduce the risk of an investment through exchange rates guarantees for example.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Market-based reform and incentives',
    Definition1: 'Laws and regulations to promote use of market forces.',
    DisaggregatedIntervention: 'Liberalisation of electricity markets',
    Definition: 'Reduction of entry costs for new companies to enter the electricity market (e.g. reducing the price of a license to operate).',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Market-based reform and incentives',
    Definition1: 'Laws and regulations to promote use of market forces.',
    DisaggregatedIntervention: 'Private sector involvement in the energy and extractives sector',
    Definition: 'Opening of the energy and/or extractives sectors to private actors.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Market-based reform and incentives',
    Definition1: 'Laws and regulations to promote use of market forces.',
    DisaggregatedIntervention: 'Privatisation of government owned assets',
    Definition: 'Transferring/selling government owned assets to private enterprise, including government owned shares',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Market-based reform and incentives',
    Definition1: 'Laws and regulations to promote use of market forces.',
    DisaggregatedIntervention: 'Production based incentives for renewable energy',
    Definition: 'Provision of awards proportional to the energy generated. This can take the form of a minimum feed-in tariff, which guarantee a fixed price per kWh generated for a defined time period, or a quota system which guarantee a certain number of units can be sold on the market, but no price guarantee.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Market-based reform and incentives',
    Definition1: 'Laws and regulations to promote use of market forces.',
    DisaggregatedIntervention: 'Public Private Partnerships (PPPs) in energy',
    Definition: 'Cooperative arrangements between the government and private sector actors in the energy sector. The allocation of risks, funding, and responsibilities between the government and the private actor at different stages in the supply chain can vary with the contract.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Market-based reform and incentives',
    Definition1: 'Laws and regulations to promote use of market forces.',
    DisaggregatedIntervention: 'Public Private Partnerships (PPPs) in mining',
    Definition: 'Cooperative arrangements between the government and private sector actors in the mining sector. The allocation of risks, funding, and responsibilities between the government and the private actor at different stages in the supply chain can vary with the contract.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy & extractives regulation',
    InterventionGroup: 'Market-based reform and incentives',
    Definition1: 'Laws and regulations to promote use of market forces.',
    DisaggregatedIntervention: 'Public Private Partnerships (PPPs) in oil and gas',
    Definition: 'Cooperative arrangements between the government and private sector actors in the oil and gas sector. The allocation of risks, funding, and responsibilities between the government and the private actor at different stages in the supply chain can vary with the contract.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy access & use',
    InterventionGroup: 'Appliance usage',
    Definition1: 'Use of appliances within the household, in a community, or by a firm for everyday activities.',
    DisaggregatedIntervention: 'Cooking appliances',
    Definition: 'Interventions to promote the use/ownership of cooking appliances for households/firms.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy access & use',
    InterventionGroup: 'Appliance usage',
    Definition1: 'Use of appliances within the household, in a community, or by a firm for everyday activities.',
    DisaggregatedIntervention: 'Entertainment, information, and communications appliances',
    Definition: 'Interventions to promote the use/ownership of entertainment, information, or communications appliances for households/firms.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy access & use',
    InterventionGroup: 'Appliance usage',
    Definition1: 'Use of appliances within the household, in a community, or by a firm for everyday activities.',
    DisaggregatedIntervention: 'Heating appliances',
    Definition: 'Interventions to promote the use/ownership of heating appliances for households/firms.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy access & use',
    InterventionGroup: 'Appliance usage',
    Definition1: 'Use of appliances within the household, in a community, or by a firm for everyday activities.',
    DisaggregatedIntervention: 'Machinery and other productive appliances',
    Definition: 'Interventions to promote the use/ownership of machinery or other productive appliances for households/firms.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy access & use',
    InterventionGroup: 'Appliance usage',
    Definition1: 'Use of appliances within the household, in a community, or by a firm for everyday activities.',
    DisaggregatedIntervention: 'Other appliances',
    Definition: 'Interventions to promote the use/ownership of appliances than those caught here for households/firms.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy access & use',
    InterventionGroup: 'Appliance usage',
    Definition1: 'Use of appliances within the household, in a community, or by a firm for everyday activities.',
    DisaggregatedIntervention: 'Technology replacement programmes',
    Definition: 'Programmes to upgrade technology used in household/firms. This can be an exchange or subsidy/rebate for upgrading.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy access & use',
    InterventionGroup: 'Construction & design',
    Definition1: 'Interventions to improve construction and design of buildings, related to energy.',
    DisaggregatedIntervention: 'Energy efficient construction',
    Definition: 'Promotion of energy efficient building construction or renovations, through financial or social incentives.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy access & use',
    InterventionGroup: 'Construction & design',
    Definition1: 'Interventions to improve construction and design of buildings, related to energy.',
    DisaggregatedIntervention: 'Preparation for electrification',
    Definition: 'Installation of equipment to integrate electrification into a structure.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy access & use',
    InterventionGroup: 'Construction & design',
    Definition1: 'Interventions to improve construction and design of buildings, related to energy.',
    DisaggregatedIntervention: 'Ventilation and chimneys',
    Definition: 'Construction or improvement of ventilation and/or chimney systems.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy access & use',
    InterventionGroup: 'Construction & design',
    Definition1: 'Interventions to improve construction and design of buildings, related to energy.',
    DisaggregatedIntervention: 'Weatherproofing and insulation',
    Definition: 'Construction or improvement of weatherproofing and insulation in buildings.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy access & use',
    InterventionGroup: 'Electrification',
    Definition1: 'Interventions that seek to augment household, community, or firm level of electrification and reliability',
    DisaggregatedIntervention: 'Grid connections',
    Definition: 'Connecting households or communities directly to the power grid.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy access & use',
    InterventionGroup: 'Electrification',
    Definition1: 'Interventions that seek to augment household, community, or firm level of electrification and reliability',
    DisaggregatedIntervention: 'Microgrids',
    Definition: 'Connecting or establishing microgrids.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy access & use',
    InterventionGroup: 'Electrification',
    Definition1: 'Interventions that seek to augment household, community, or firm level of electrification and reliability',
    DisaggregatedIntervention: 'Off-grid connections',
    Definition: 'Connecting households, communities, or firms to electricity using off-grid energy generation, such as solar, wind, gas generator, etc.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy conservation',
    Definition1: 'Plans to reduce energy use for the purpose of widespread delivery, e.g. rolling blackouts, regulations, fines, etc.',
    DisaggregatedIntervention: 'Conservation behaviour change',
    Definition: 'Campaigns and social pressures applied to promote energy conservation practices and behaviour.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy conservation',
    Definition1: 'Plans to reduce energy use for the purpose of widespread delivery, e.g. rolling blackouts, regulations, fines, etc.',
    DisaggregatedIntervention: 'Electricity tariff design',
    Definition: 'Creation, or change in the design, of electricity tariff/pricing, with the aim to ensure economic sustainability and efficiency, and equal access to basic services. Concretely this intervention is the creation of a variety of electricity provision offers tailored to individual or companies\' needs.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy conservation',
    Definition1: 'Plans to reduce energy use for the purpose of widespread delivery, e.g. rolling blackouts, regulations, fines, etc.',
    DisaggregatedIntervention: 'Energy efficiency certifications',
    Definition: 'Certification schemes for energy efficient devices or buildings. This can be public (e.g. Energy Star) or private (e.g. better business bureau).',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy conservation',
    Definition1: 'Plans to reduce energy use for the purpose of widespread delivery, e.g. rolling blackouts, regulations, fines, etc.',
    DisaggregatedIntervention: 'Energy efficient construction',
    Definition: 'Promotion of energy efficient building construction or renovations, through financial or social incentives.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy conservation',
    Definition1: 'Plans to reduce energy use for the purpose of widespread delivery, e.g. rolling blackouts, regulations, fines, etc.',
    DisaggregatedIntervention: 'Energy efficient devices',
    Definition: 'Provision or subsidisation (including tax incentives) for the use of energy efficient devices, such as smart metres, in the home or in businesses.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy generation',
    Definition1: 'Creation, quality, and rehabilitation/maintenance of energy generation infrastructure.',
    DisaggregatedIntervention: 'Biomass energy generation infrastructure',
    Definition: 'Construction, maintenance, or rehabilitation of, or provision of technical support for permanent infrastructures for the generation of energy through biomass power. These include combined heat and power (CHP) plant based on solid biomass, liquid biofuels or organic wastes, biogas-based power plant or bio digesters.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy generation',
    Definition1: 'Creation, quality, and rehabilitation/maintenance of energy generation infrastructure.',
    DisaggregatedIntervention: 'Energy smart grids',
    Definition: 'Grids that manage the energy generation of intermittent sources of electricity using modern technologies.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy generation',
    Definition1: 'Creation, quality, and rehabilitation/maintenance of energy generation infrastructure.',
    DisaggregatedIntervention: 'Geothermal systems',
    Definition: 'Construction, maintenance, or rehabilitation of, or provision of technical support for permanent infrastructures for the generation of energy through geothermal energy power. These include geothermal energy-based power plants and CHP plants, geothermal heat pumps, and heating system.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy generation',
    Definition1: 'Creation, quality, and rehabilitation/maintenance of energy generation infrastructure.',
    DisaggregatedIntervention: 'Hydroelectric systems',
    Definition: 'Construction, maintenance, or rehabilitation of, or provision of technical support for permanent infrastructures for the generation of energy through hydro-electric power. These include different kind of hydro-electric power plants such as storage, run-of river, or pumped storage facilities.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy generation',
    Definition1: 'Creation, quality, and rehabilitation/maintenance of energy generation infrastructure.',
    DisaggregatedIntervention: 'Non-renewable energy generation',
    Definition: 'Construction, maintenance, or rehabilitation of, or provision of technical support for permanent infrastructures for the generation of energy from non-renewable sources (oil, gas, coal, other fossil fuel or nuclear sources).',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy generation',
    Definition1: 'Creation, quality, and rehabilitation/maintenance of energy generation infrastructure.',
    DisaggregatedIntervention: 'Solar energy generation',
    Definition: 'Construction, maintenance, or rehabilitation of, or provision of technical support for permanent infrastructures for the generation of energy through solar power. These include different kind of solar photovoltaic installations such as ground mounted, rooftop and floating PV installations.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy generation',
    Definition1: 'Creation, quality, and rehabilitation/maintenance of energy generation infrastructure.',
    DisaggregatedIntervention: 'Wind energy generation',
    Definition: 'Construction, maintenance, or rehabilitation of, or provision of technical support for permanent infrastructures for the generation of energy through wind power. These include onshore and offshore wind farms.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy storage',
    Definition1: 'Improving energy storage capacity through building, maintaining, and using energy storage solutions, pre- and post-production.',
    DisaggregatedIntervention: 'Battery energy storage',
    Definition: 'Construction, maintenance, rehabilitation or use of batteries for the storage of electricity after energy generation. This is especially relevant for renewable-energies generated in the moment.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy storage',
    Definition1: 'Improving energy storage capacity through building, maintaining, and using energy storage solutions, pre- and post-production.',
    DisaggregatedIntervention: 'Other grid energy storage',
    Definition: 'Use of methods other than those listed here in the storage of energy, after production.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy storage',
    Definition1: 'Improving energy storage capacity through building, maintaining, and using energy storage solutions, pre- and post-production.',
    DisaggregatedIntervention: 'Potential energy storage',
    Definition: 'Methods of storing energy in the form of potential energy, such as using gravitational generators with solid mass or air compression',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy storage',
    Definition1: 'Improving energy storage capacity through building, maintaining, and using energy storage solutions, pre- and post-production.',
    DisaggregatedIntervention: 'Reserves and stockpiles for energy',
    Definition: 'Stockpiling of resources or reserves to store commodities before use in energy production, often in raw form. These are used to smooth consumption, meet demand, and prevent supply-chain interruptions to continuous service.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy systems',
    Definition1: 'Improving capacity of the energy system.',
    DisaggregatedIntervention: 'Advance notification systems',
    Definition: 'Systems for providing energy consumers with advance notices for interruption of services, including for regular maintenance and upgrading.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy systems',
    Definition1: 'Improving capacity of the energy system.',
    DisaggregatedIntervention: 'Apprenticeship programmes',
    Definition: 'Work experience programmes, often formal, to train a worker to become skilled in a particular trade.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy systems',
    Definition1: 'Improving capacity of the energy system.',
    DisaggregatedIntervention: 'Capacity building in energy',
    Definition: 'Implementation of activities to build and strengthen capacity of workers in the energy supply chain, with the aim to improve efficiency and minimize technical and non-technical energy losses. This can take the form of a training on the use of a new technology or system for instance.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy systems',
    Definition1: 'Improving capacity of the energy system.',
    DisaggregatedIntervention: 'Energy information system',
    Definition: 'Creation of a system which uses information to improve energy supply efficiency and access. An example of an information system intervention is the integration of a data collection and analysis system for energy policy, to tailor energy supply and tariffs to specific environments and needs.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy systems',
    Definition1: 'Improving capacity of the energy system.',
    DisaggregatedIntervention: 'Energy system quality',
    Definition: 'Interventions that improve system quality and reliability through reform/changes to regulatory frameworks.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy systems',
    Definition1: 'Improving capacity of the energy system.',
    DisaggregatedIntervention: 'Feed-in tariffs',
    Definition: 'A policy mechanism designed to accelerate investment in renewable energy technologies by offering long-term contracts to renewable energy producers.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy systems',
    Definition1: 'Improving capacity of the energy system.',
    DisaggregatedIntervention: 'Internship programmes',
    Definition: 'Work experience programmes, often informal, to learn about a company, industry, or trade.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy systems',
    Definition1: 'Improving capacity of the energy system.',
    DisaggregatedIntervention: 'Monitoring of electricity usage',
    Definition: 'Installation of individual or group electricity meters or feeders, to measure the amount of electricity consumed by an individual or group of households in a neighbourhood, with the goal to improve compliance with payments and reduce companies\' non-technical losses.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy systems',
    Definition1: 'Improving capacity of the energy system.',
    DisaggregatedIntervention: 'Net metering',
    Definition: 'A billing mechanism that credits solar energy system owners for the electricity they add to the grid.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Energy systems',
    Definition1: 'Improving capacity of the energy system.',
    DisaggregatedIntervention: 'Technical and Vocational Education and Training (TVET)',
    Definition: 'Education and training which provides knowledge and skills for employment. TVET uses formal, non-formal, and informal learning.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Transmission and distribution',
    Definition1: 'Access to energy commodities and services',
    DisaggregatedIntervention: 'Electrification infrastructure',
    Definition: 'Connection and internal wiring of poor households to the main electricity grid, either for free, at an interest free credit, or a subsidized price.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Transmission and distribution',
    Definition1: 'Access to energy commodities and services',
    DisaggregatedIntervention: 'Energy transmission and distribution infrastructure',
    Definition: 'Construction of, or provision of technical support to construct permanent infrastructures composed of lines, mains and pipes, for the transmission and distribution of energy. Energy can be provided through this kind of infrastructure in different forms such as electric power, natural gas, hot water etc.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Transmission and distribution',
    Definition1: 'Access to energy commodities and services',
    DisaggregatedIntervention: 'Provision of electric generators',
    Definition: 'Construction of, or provision of technical support to construct community generators directly linked to households.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Transmission and distribution',
    Definition1: 'Access to energy commodities and services',
    DisaggregatedIntervention: 'Provision of improved sources of energy',
    Definition: 'Provision of, or technical support to install less polluting and more efficient source of energy for households through the use of traditional fuels (wood, charcoal, dung, agricultural waste) in cooking, heating, or agricultural activities.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Transmission and distribution',
    Definition1: 'Access to energy commodities and services',
    DisaggregatedIntervention: 'Provision of oil and gas tanks',
    Definition: 'Construction of, or provision of technical support to construct gas or oil tanks accessible to communities, households, or firms.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Transmission and distribution',
    Definition1: 'Access to energy commodities and services',
    DisaggregatedIntervention: 'Reduction of non-technical losses',
    Definition: 'Distribution network system improvements to abate non-technical losses: undesired dissipation of energy, typically through theft. These interventions include adding/improving monitoring systems, other anti-theft devices, etc. This code does NOT apply for legal regulation or enforcement.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Energy infrastructure',
    InterventionGroup: 'Transmission and distribution',
    Definition1: 'Access to energy commodities and services',
    DisaggregatedIntervention: 'Reduction of technical losses',
    Definition: 'Distribution network system improvements to abate technical losses: undesired dissipation of energy through parasitic, resistive or other effects.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Innovation finance',
    Definition1: 'Interventions to make financing available for research and development.',
    DisaggregatedIntervention: 'Credit schemes for R&D',
    Definition: 'Provision of credit opportunities to finance R&D projects.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Innovation finance',
    Definition1: 'Interventions to make financing available for research and development.',
    DisaggregatedIntervention: 'Grants and subsidisation of R&D',
    Definition: 'Provision of grants and subsidies for industrial research and development, this does not include seed capital for SMEs.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Innovation finance',
    Definition1: 'Interventions to make financing available for research and development.',
    DisaggregatedIntervention: 'R&D portfolio management',
    Definition: 'Interventions to affect the management of R&D portfolios, including implementing strategies for diversification and hedging.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Innovation finance',
    Definition1: 'Interventions to make financing available for research and development.',
    DisaggregatedIntervention: 'Seed capital grants',
    Definition: 'Grants to provide the initial investment for the creation of a business, including grants for small and medium-sized enterprises to compete with established businesses and increase competition.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Knowledge sharing & collaboration',
    Definition1: 'Interventions to provide opportunities or instil a custom of knowledge sharing and collaboration between organisations or individuals.',
    DisaggregatedIntervention: 'Networks for innovation',
    Definition: 'Facilitating the development of networks, partnerships and relationships between individuals or organisations for the purposes of information sharing, technology diffusion, network development or creating credible and recognisable associations.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity and/or the standards of research.',
    DisaggregatedIntervention: 'Informed consent for research',
    Definition: 'Facilitation of informed consent procedures to approve research projects',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity and/or the standards of research.',
    DisaggregatedIntervention: 'Institutional Review Boards (IRBs)',
    Definition: 'Institution of boards with power to modify and approve research projects, with an aim to improve the technical or ethical components of the research.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity or the standards of research.',
    DisaggregatedIntervention: 'Material support for research',
    Definition: 'Provision of material support for research programmes, this can come in the form of samples, tools and equipment, or the facilities themselves.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity or the standards of research.',
    DisaggregatedIntervention: 'Research exchanges and collaborations',
    Definition: 'Collaboration between researchers, educational institutions or other research-based entities for the purposes of scientific research or capacity building.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity or the standards of research.',
    DisaggregatedIntervention: 'Research fellowships',
    Definition: 'Fellowship opportunities provided to students to be able to investigate a field or topic.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity or the standards of research.',
    DisaggregatedIntervention: 'Research funding',
    Definition: 'Funding provided to allow for a greater volume of research or higher quality of research.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity or the standards of research.',
    DisaggregatedIntervention: 'Research labs and facilities',
    Definition: 'Building or refurbishment of science labs and facilities to facilitate research.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity or the standards of research.',
    DisaggregatedIntervention: 'Research policy for industry',
    Definition: 'Changes in government policy and regulation around industry, trade, or service research.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity or the standards of research.',
    DisaggregatedIntervention: 'STEM education programmes',
    Definition: 'Educational programmes, scholarships, training and in-kind donations at all non-tertiary educational levels intended to promote the science, technology, engineering and mathematics (STEM) fields.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity or the standards of research.',
    DisaggregatedIntervention: 'Technical assistance for research',
    Definition: 'Assistance or training for researchers, often provided by an international nongovernmental organisation or university from a high-income country.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Construction & rehabilitation',
    Definition1: 'Interventions to construct, improve or rehabilitate extractives infrastructure',
    DisaggregatedIntervention: 'Beneficiation',
    Definition: 'Construction or improvement of infrastructure to allow beneficiation and value addition of extractive products to occur (such as refining ore to create a higher grade product)',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Construction & rehabilitation',
    Definition1: 'Interventions to construct, improve or rehabilitate extractives infrastructure',
    DisaggregatedIntervention: 'Coal mines',
    Definition: 'Establishment or improvement of coal mines',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Construction & rehabilitation',
    Definition1: 'Interventions to construct, improve or rehabilitate extractives infrastructure',
    DisaggregatedIntervention: 'Construction of pipelines',
    Definition: 'Construction or extension of gas pipelines',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Construction & rehabilitation',
    Definition1: 'Interventions to construct, improve or rehabilitate extractives infrastructure',
    DisaggregatedIntervention: 'Drilling',
    Definition: 'Establishment or improvement of a drilling site',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Construction & rehabilitation',
    Definition1: 'Interventions to construct, improve or rehabilitate extractives infrastructure',
    DisaggregatedIntervention: 'Environmental clean-up and reclamation',
    Definition: 'Interventions to clean-up or reclaim an area that has been used for mining, quarries, or drilling, to return it to a natural or economically usable state.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Construction & rehabilitation',
    Definition1: 'Interventions to construct, improve or rehabilitate extractives infrastructure',
    DisaggregatedIntervention: 'Living facilities',
    Definition: 'Construction or renovation of infrastructure in mining towns, including the establishment of new mining towns.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Construction & rehabilitation',
    Definition1: 'Interventions to construct, improve or rehabilitate extractives infrastructure',
    DisaggregatedIntervention: 'Metalliferous mines',
    Definition: 'Establishment or improvement of metalliferous mines',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Construction & rehabilitation',
    Definition1: 'Interventions to construct, improve or rehabilitate extractives infrastructure',
    DisaggregatedIntervention: 'Other infrastructure for extractives',
    Definition: 'Construction or renovation of infrastructure for extractives, including supporting infrastructure, other than those listed here.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Construction & rehabilitation',
    Definition1: 'Interventions to construct, improve or rehabilitate extractives infrastructure',
    DisaggregatedIntervention: 'Quarries',
    Definition: 'Establishment or improvement of quarries',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Construction & rehabilitation',
    Definition1: 'Interventions to construct, improve or rehabilitate extractives infrastructure',
    DisaggregatedIntervention: 'Rehabilitation of mining infrastructure',
    Definition: 'Interventions to rehabilitate mining infrastructure for alternative future use following the closure of a mine.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Equipment & technology',
    Definition1: 'Interventions to improve the equipment and technology used in extractive activities',
    DisaggregatedIntervention: 'Transition to mechanised extraction',
    Definition: 'Interventions that promote and/or provide assistance for a transition to mechanised extraction.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Extractives skills & training',
    Definition1: 'Interventions that provide training for workers in the extractives industry',
    DisaggregatedIntervention: 'Apprenticeship programmes',
    Definition: 'Work experience programmes, often formal, to train a worker to become skilled in a particular trade.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Extractives skills & training',
    Definition1: 'Interventions that provide training for workers in the extractives industry',
    DisaggregatedIntervention: 'Capacity building in extractives',
    Definition: 'Implementation of activities to build and strengthen capacity of workers in the extractives industry, with the aim to improve efficiency and performance. This can take the form of a training on the use of a new technology or system for instance.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Extractives skills & training',
    Definition1: 'Interventions that provide training for workers in the extractives industry',
    DisaggregatedIntervention: 'Internship programmes',
    Definition: 'Work experience programmes, often informal, to learn about a company, industry, or trade.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Extractives skills & training',
    Definition1: 'Interventions that provide training for workers in the extractives industry',
    DisaggregatedIntervention: 'Technical and Vocational Education and Training (TVET)',
    Definition: 'Education and training which provides knowledge and skills for employment. TVET uses formal, non-formal, and informal learning.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Bridge construction',
    Definition: 'These interventions construct, or provide knowledge on how to, bridges.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Bridge maintenance',
    Definition: 'These interventions conduct work to, or provide knowledge on how to, regularly maintain existing bridges.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Bridge rehabilitation',
    Definition: 'These interventions conduct work to, or provide knowledge on how to, repair existing bridges, or parts of them, that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Cargo transport services',
    Definition: 'Provision or improvement of cargo transportation services.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Public-private joint ventures',
    Definition: 'A commercial enterprise undertaken jointly by two or more parties which otherwise retain their distinct identities, where one of these entities is public.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Renewable energy infrastructure construction',
    Definition: 'These interventions construct, or provide knowledge on how to, renewable energy infrastructure (e.g. charging stations for electric vehicles).',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Renewable energy infrastructure maintenance',
    Definition: 'These interventions conduct work to, or knowledge on how to, regularly maintain existing renewable energy infrastructure (e.g. charging stations for electric vehicles).',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Renewable energy infrastructure rehabilitation',
    Definition: 'These interventions conduct work to, or knowledge on how to, repair existing renewable energy infrastructure (e.g. charging stations for electric vehicles) that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Road quality monitoring',
    Definition: 'These interventions provide monitoring services for road quality, including training of monitoring professionals.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Roadside services infrastructure construction',
    Definition: 'These interventions construct, or provide knowledge on how to, roadside service infrastructure (e.g. lorry parks/rest stops).',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Roadside services infrastructure maintenance',
    Definition: 'These interventions conduct work to, or provide knowledge on how to, regularly maintain existing roadside service infrastructure (e.g. lorry parks/rest stops).',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Roadside services infrastructure rehabilitation',
    Definition: 'These interventions conduct work to, or provide knowledge on how to, repair existing roadside service infrastructure (e.g. lorry parks/rest stops) that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Rural road construction',
    Definition: 'These interventions construct, or provide knowledge on how to, roads in rural areas and/or between cities.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Rural road maintenance',
    Definition: 'These interventions conduct work to, or provide knowledge on how to, regularly maintain existing rural roads.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Rural road rehabilitation',
    Definition: 'These interventions conduct work to, or provide knowledge on how to, repair existing rural roads that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Safe operations regulation in transportation',
    Definition: 'Regulatory reform and enforcement of safe operations standards and practices in transportation.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Security monitoring systems in transportation',
    Definition: 'Security provisions for transportation, to combat crime, including piracy or robbery, through monitoring systems, for example CCTV.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Security personnel in transportation',
    Definition: 'Security provisions for transportation, to combat crime, including piracy or robbery, through personnel: security guards or officers.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives infrastructure',
    InterventionGroup: 'Transport services and infrastructure',
    Definition1: 'Transportation services and infrastructure as related to the extractives industry including to access extraction sites, onsite infrastructure, and to transport goods.',
    DisaggregatedIntervention: 'Vehicle safety standards',
    Definition: 'Changes in, or roll out of, a system for vehicle inspection and regulation, ensuring cars on the road meet certain safety standards.',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives policies',
    InterventionGroup: 'Safety & support',
    Definition1: 'Interventions that seek to ensure the safety of extractives sites, and support those who work in extractives industry and their families',
    DisaggregatedIntervention: 'Extractives site security',
    Definition: 'Interventions that provide security for extractive sites to protect against threat of theft, destruction of property, and/or violence',
  },
  {
    InterventionSector: 'Energy & Extractives',
    BroadGroup: 'Extractives policies',
    InterventionGroup: 'Safety & support',
    Definition1: 'Interventions that seek to ensure the safety of extractives sites, and support those who work in extractives industry and their families',
    DisaggregatedIntervention: 'Support for families of extractives workers',
    Definition: 'Interventions that provide support for the families of extractives workers who are no longer able to work due to illness, death, or injury resulting from their work.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Entrepreneurial financing',
    Definition1: 'Provision of financial assistance and tools to entrepreneurs and promote SME business growth.',
    DisaggregatedIntervention: 'Business incubators and accelerators',
    Definition: 'Help for new and start-up companies to develop by providing services such as management training/mentorship or office space.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Entrepreneurial financing',
    Definition1: 'Provision of financial assistance and tools to entrepreneurs and promote SME business growth.',
    DisaggregatedIntervention: 'Business plan competitions',
    Definition: 'Competitions in which business plans are pitched and have the potential of winning a prize or investment.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Entrepreneurial financing',
    Definition1: 'Provision of financial assistance and tools to entrepreneurs and promote SME business growth.',
    DisaggregatedIntervention: 'Microenterprise grant',
    Definition: 'Provision of a small unconditional grant intended specifically at supporting a microenterprise or starting up a microenterprise from scratch. This program can be implemented on its own, but is usually combined with other components of microenterprise support such as a business training, access to credit etc.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Entrepreneurial financing',
    Definition1: 'Provision of financial assistance and tools to entrepreneurs and promote SME business growth.',
    DisaggregatedIntervention: 'Seed capital grants',
    Definition: 'Grants to provide the initial investment for the creation of a business, including grants for small and medium-sized enterprises to compete with established businesses and increase competition.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Entrepreneurial financing',
    Definition1: 'Provision of financial assistance and tools to entrepreneurs and promote SME business growth.',
    DisaggregatedIntervention: 'SME grants',
    Definition: 'Grants provided to small and medium sized enterprises to fund operations, payoff debts, or expand. This is separate from seed capital grants, which are initial investments.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Entrepreneurial financing',
    Definition1: 'Provision of financial assistance and tools to entrepreneurs and promote SME business growth.',
    DisaggregatedIntervention: 'SME investment funds',
    Definition: 'Investments made in small and medium sized enterprises to finance operations and expansion, this could be in exchange for equity or debt in the firm.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Formal banking',
    Definition1: 'Inclusion in the formal banking sector.',
    DisaggregatedIntervention: 'Brokerage accounts',
    Definition: 'Accounts used to invest in financial products.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Formal banking',
    Definition1: 'Inclusion in the formal banking sector.',
    DisaggregatedIntervention: 'Credit lines',
    Definition: 'Formal lines of credit extended to businesses from banks or traditional financial institutions. Typically an expansion in size or availability of credit lines.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Formal banking',
    Definition1: 'Inclusion in the formal banking sector.',
    DisaggregatedIntervention: 'Credit tracking',
    Definition: 'Providing tracking mechanisms, such as credit scores, to assess creditworthiness of prospective borrowers. This also includes the use of biometric identification for credit tracking purposes.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Formal banking',
    Definition1: 'Inclusion in the formal banking sector.',
    DisaggregatedIntervention: 'Deposit insurance',
    Definition: 'Government or private underwriting of bank deposits.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Formal banking',
    Definition1: 'Inclusion in the formal banking sector.',
    DisaggregatedIntervention: 'Education lending',
    Definition: 'Provision or promotion of educational loans, including programmes to provide subsidized or reduced interest loans to students.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Formal banking',
    Definition1: 'Inclusion in the formal banking sector.',
    DisaggregatedIntervention: 'Expanded banking services',
    Definition: 'Expansion of general banking services including opening of more branches and increasing the range of offered services.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Formal banking',
    Definition1: 'Inclusion in the formal banking sector.',
    DisaggregatedIntervention: 'Indemnified loans',
    Definition: 'Loans protecting against damage or loss, especially agricultural loans that are forgiven in instances of crop loss.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Formal banking',
    Definition1: 'Inclusion in the formal banking sector.',
    DisaggregatedIntervention: 'Lending outreach',
    Definition: 'Employment of business models to extend banking and financial services to underrepresented areas and promote financial inclusion. An example of which would be the ',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Formal banking',
    Definition1: 'Inclusion in the formal banking sector.',
    DisaggregatedIntervention: 'Mortgage and loans',
    Definition: 'Extension of loans for business or personal use, of larger amounts than micro-credit and/or requiring collateral.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Formal banking',
    Definition1: 'Inclusion in the formal banking sector.',
    DisaggregatedIntervention: 'Remittance',
    Definition: 'Interventions that promote or support the practice of remittances. These include the reduction of transfer costs.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Formal banking',
    Definition1: 'Inclusion in the formal banking sector.',
    DisaggregatedIntervention: 'Savings accounts',
    Definition: 'Savings accounts at formal banking institutions.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Formal banking',
    Definition1: 'Inclusion in the formal banking sector.',
    DisaggregatedIntervention: 'Subsidised loans',
    Definition: 'Programmes that subsidised loans, often used to reduce the interest rate incurred by the borrower.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Formal banking',
    Definition1: 'Inclusion in the formal banking sector.',
    DisaggregatedIntervention: 'Underwriting',
    Definition: 'Sign and accept liability under an insurance policy, thus guaranteeing payment in case loss or damage occurs. Applicable for banks, including loan guarantees.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'ICT in banking & credit',
    Definition1: 'Loan re-payment schemes and programmes.',
    DisaggregatedIntervention: 'Biometric tracking in banking',
    Definition: 'Tracking borrowers using biometrics to assess credit worthiness.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'ICT in banking & credit',
    Definition1: 'Loan re-payment schemes and programmes.',
    DisaggregatedIntervention: 'Mobile banking',
    Definition: 'Introduction of mobile banking, either through financial institutions or phone service provider (M-Pesa).',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'ICT in banking & credit',
    Definition1: 'Loan re-payment schemes and programmes.',
    DisaggregatedIntervention: 'Online banking',
    Definition: 'Introduction of online account and banking client management.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Microfinance',
    Definition1: 'Microfinance interventions that provide financial services to households or individuals not otherwise served by the formal banking system.',
    DisaggregatedIntervention: 'Access to microcredit',
    Definition: 'Provision of microcredit schemes in the form of individual or group lending, either through a microcredit group in the community, or through a microfinance institution (MFI). These schemes can be provided on their own, or include access to other financial services such as savings and insurance.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Microfinance',
    Definition1: 'Microfinance interventions that provide financial services to households or individuals not otherwise served by the formal banking system.',
    DisaggregatedIntervention: 'Access to savings through MFI',
    Definition: 'Provision of savings opportunity in the form of individual or group saving through a microfinance institution (MFI). These schemes can be provided on their own, or be combined with access to other financial services such as microcredit and insurance. Payments banks would fit here.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Microfinance',
    Definition1: 'Microfinance interventions that provide financial services to households or individuals not otherwise served by the formal banking system.',
    DisaggregatedIntervention: 'Gender-based lending',
    Definition: 'Loan decisions based on gender of prospective borrower.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Microfinance',
    Definition1: 'Microfinance interventions that provide financial services to households or individuals not otherwise served by the formal banking system.',
    DisaggregatedIntervention: 'In-kind loans',
    Definition: 'Providing advance of in-kind inputs with delayed repayment.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Microfinance',
    Definition1: 'Microfinance interventions that provide financial services to households or individuals not otherwise served by the formal banking system.',
    DisaggregatedIntervention: 'Insurance products',
    Definition: 'Providing insurance products to poor households, individuals, and businesses, in addition to insurance against default, used as collateral in lending schemes.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Microfinance',
    Definition1: 'Microfinance interventions that provide financial services to households or individuals not otherwise served by the formal banking system.',
    DisaggregatedIntervention: 'Lending outreach',
    Definition: 'Employment of business models to extend banking and financial services to underrepresented areas and promote financial inclusion. An example of which would be the ',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Microfinance',
    Definition1: 'Microfinance interventions that provide financial services to households or individuals not otherwise served by the formal banking system.',
    DisaggregatedIntervention: 'Remittance',
    Definition: 'Interventions that promote or support the practice of remittances. These include the reduction of transfer costs.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Microfinance',
    Definition1: 'Microfinance interventions that provide financial services to households or individuals not otherwise served by the formal banking system.',
    DisaggregatedIntervention: 'Rotating/Accumulated savings and credit associations',
    Definition: 'A group of individuals acting as an informal financial institution in the form of an alternative financial vehicle. A ROSCA happens via set contributions and withdrawals to and from a common fund. An ASCRA happens via set contributions with the common fund used for extending credit within or outside of the group. These include Savings and Internal Lending Communities (SILCs) and Self-Help Groups (SHG).',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Repayment',
    Definition1: 'Loan repayment schemes and programmes.',
    DisaggregatedIntervention: 'Debt collection',
    Definition: 'Different methods of debt collection such as collection agents and automated payment deductions.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Repayment',
    Definition1: 'Loan repayment schemes and programmes.',
    DisaggregatedIntervention: 'Debt relief',
    Definition: 'Programmes to relieve borrowers of re-payment liabilities.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Repayment',
    Definition1: 'Loan repayment schemes and programmes.',
    DisaggregatedIntervention: 'Group lending',
    Definition: 'Collective lending schemes in which social connections underwrite loans.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Banking & Credit',
    InterventionGroup: 'Repayment',
    Definition1: 'Loan repayment schemes and programmes.',
    DisaggregatedIntervention: 'Re-payment reminders',
    Definition: 'SMS or other reminders when payments are due.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial environment',
    InterventionGroup: 'Financial regulation',
    Definition1: 'Interventions pertaining to the legal framework of a country pertaining to the financial sector.',
    DisaggregatedIntervention: 'Banking regulation',
    Definition: 'Regulation or de-regulation of the banking sector to encourage behaviour.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial environment',
    InterventionGroup: 'Financial regulation',
    Definition1: 'Interventions pertaining to the legal framework of a country pertaining to the financial sector.',
    DisaggregatedIntervention: 'Bankruptcy',
    Definition: 'Bankruptcy protections.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial environment',
    InterventionGroup: 'Financial regulation',
    Definition1: 'Interventions pertaining to the legal framework of a country pertaining to the financial sector.',
    DisaggregatedIntervention: 'Financial fraud prevention',
    Definition: 'Interventions to counteract financial fraud, including system strengthening.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial environment',
    InterventionGroup: 'Financial regulation',
    Definition1: 'Interventions pertaining to the legal framework of a country pertaining to the financial sector.',
    DisaggregatedIntervention: 'Financial regulation',
    Definition: 'Regulation or de-regulation of financial sector to encourage behaviour.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial environment',
    InterventionGroup: 'Financial regulation',
    Definition1: 'Interventions pertaining to the legal framework of a country pertaining to the financial sector.',
    DisaggregatedIntervention: 'Financial sector legal reforms',
    Definition: 'Reforms to the legal system to strengthen legal institutions, such as contract enforceability.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial environment',
    InterventionGroup: 'Financial regulation',
    Definition1: 'Interventions pertaining to the legal framework of a country pertaining to the financial sector.',
    DisaggregatedIntervention: 'Lending requirements',
    Definition: 'Loan regulations that pose restrictions on lending practices, or conversely requires lending to otherwise un-creditworthy individuals.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial environment',
    InterventionGroup: 'Government policy',
    Definition1: 'Interventions pertaining to the legal framework of a country pertaining to the financial sector.',
    DisaggregatedIntervention: 'Government tax policy for financial relief',
    Definition: 'Government tax policy, including tax holidays and tax incentives.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial environment',
    InterventionGroup: 'Government policy',
    Definition1: 'Interventions pertaining to the legal framework of a country pertaining to the financial sector.',
    DisaggregatedIntervention: 'Monetary policy',
    Definition: 'Interventions and policies that influence money supply which can be broadly classified as either expansionary or contractionary.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial environment',
    InterventionGroup: 'Knowledge & skills',
    Definition1: 'Interventions to increase a person or business\' financial knowledge or skills.',
    DisaggregatedIntervention: 'Entrepreneurship training',
    Definition: 'Provision of entrepreneurship training focused on how to start up a business from scratch, which can include for example developing a business plan, day-to-day management of small enterprise, including bookkeeping, financial planning, etc.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial environment',
    InterventionGroup: 'Knowledge & skills',
    Definition1: 'Interventions to increase a person or business\' financial knowledge or skills.',
    DisaggregatedIntervention: 'Financial awareness',
    Definition: 'Increasing awareness of the financial environment and options.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial environment',
    InterventionGroup: 'Knowledge & skills',
    Definition1: 'Interventions to increase a person or business\' financial knowledge or skills.',
    DisaggregatedIntervention: 'Financial literacy',
    Definition: 'Skills development in financial topics and products.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial environment',
    InterventionGroup: 'Knowledge & skills',
    Definition1: 'Interventions to increase a person or business\' financial knowledge or skills.',
    DisaggregatedIntervention: 'Savings promotion',
    Definition: 'Promotion of saving activities and formal accounts. These include financial management strategies such as budgeting and consumption smoothing.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial services',
    InterventionGroup: 'Investment services',
    Definition1: 'Professional investment services offered to customers.',
    DisaggregatedIntervention: 'Brokerage and agent services',
    Definition: 'Provision of brokerage or other agent services, including listing services.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial services',
    InterventionGroup: 'Investment services',
    Definition1: 'Professional investment services offered to customers.',
    DisaggregatedIntervention: 'Mutual funds',
    Definition: 'Interventions related to the management of mutual funds.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial services',
    InterventionGroup: 'Investment services',
    Definition1: 'Professional investment services offered to customers.',
    DisaggregatedIntervention: 'Other retirement funds',
    Definition: 'Interventions related to the management of other retirement funds.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial services',
    InterventionGroup: 'Investment services',
    Definition1: 'Professional investment services offered to customers.',
    DisaggregatedIntervention: 'Pension funds',
    Definition: 'Interventions related to the management of pension funds, including privatisation.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial services',
    InterventionGroup: 'Professional services',
    Definition1: 'Professional financial services offered to customers.',
    DisaggregatedIntervention: 'Accounting and bookkeeping',
    Definition: 'Providing accounting and/or bookkeeping services or training.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial services',
    InterventionGroup: 'Professional services',
    Definition1: 'Professional financial services offered to customers.',
    DisaggregatedIntervention: 'Asset valuation',
    Definition: 'Providing asset valuation services or training.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial services',
    InterventionGroup: 'Professional services',
    Definition1: 'Professional financial services offered to customers.',
    DisaggregatedIntervention: 'Financial advisory',
    Definition: 'Providing financial advice and financial planning.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial services',
    InterventionGroup: 'Professional services',
    Definition1: 'Professional financial services offered to customers.',
    DisaggregatedIntervention: 'Financial audit',
    Definition: 'Implementation of independent audits in the private and/or public sector in order to verify their compliance with financial laws and regulations, and identify issues of corruption. This also includes interventions to augment auditing capacity.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial services',
    InterventionGroup: 'Professional services',
    Definition1: 'Professional financial services offered to customers.',
    DisaggregatedIntervention: 'Legal advice',
    Definition: 'Provision of legal advice in finance and business management.',
  },
  {
    InterventionSector: 'Financial Sector',
    BroadGroup: 'Financial services',
    InterventionGroup: 'Professional services',
    Definition1: 'Professional financial services offered to customers.',
    DisaggregatedIntervention: 'Mergers and acquisitions',
    Definition: 'Providing advisory services for transactions in which the ownership of companies, other business organizations, or their operating units are transferred or consolidated with other entities.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Community engagement & health promotion',
    Definition1: 'Activities to engage communities in healthcare systems and healthcare promotion.',
    DisaggregatedIntervention: 'Community accountability mechanisms',
    Definition: 'Institution of community-based accountability mechanisms for localised healthcare and/or for community member health behaviour. These can sometimes come in the form of health committees.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Community engagement & health promotion',
    Definition1: 'Activities to engage communities in healthcare systems and healthcare promotion.',
    DisaggregatedIntervention: 'Community involvement in health information',
    Definition: 'Community engagement in the dissemination of health information.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Community engagement & health promotion',
    Definition1: 'Activities to engage communities in healthcare systems and healthcare promotion.',
    DisaggregatedIntervention: 'Community involvement in institution support',
    Definition: 'Community engagement in the support of local health institutions, facilities, and staff.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Community engagement & health promotion',
    Definition1: 'Activities to engage communities in healthcare systems and healthcare promotion.',
    DisaggregatedIntervention: 'Individual health communications',
    Definition: 'Health communications or advertising used to convey information to a narrow (individual) audience for health promotion and safety.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Community engagement & health promotion',
    Definition1: 'Activities to engage communities in healthcare systems and healthcare promotion.',
    DisaggregatedIntervention: 'Mass health communications',
    Definition: 'Health communications or advertising used to convey information to a wide audience for health promotion and safety.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Coordination of Care',
    Definition1: 'Mechanisms through which health workers interact with each other or patients to ensure efficient delivery of healthcare.',
    DisaggregatedIntervention: 'Care teams',
    Definition: 'Creating and delivering care through a multidisciplinary team of healthcare workers.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Coordination of Care',
    Definition1: 'Mechanisms through which health workers interact with each other or patients to ensure efficient delivery of healthcare.',
    DisaggregatedIntervention: 'Communication between health care providers',
    Definition: 'Systems or strategies for improving the communication between health care providers, especially between regular healthcare providers/family doctors and other providers and specialised care.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Coordination of Care',
    Definition1: 'Mechanisms through which health workers interact with each other or patients to ensure efficient delivery of healthcare.',
    DisaggregatedIntervention: 'Comprehensive assessment',
    Definition: 'A multidimensional interdisciplinary diagnostic process focused on determining a person\'s medical, psychological and functional capability to ensure that problems are identified, quantified and managed appropriately',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Coordination of Care',
    Definition1: 'Mechanisms through which health workers interact with each other or patients to ensure efficient delivery of healthcare.',
    DisaggregatedIntervention: 'Continuity of care',
    Definition: 'Interventions to reduce fragmented care and undesirable consequences of fragmented care, for example by ensuring the responsibility of care is passed from one facility to another so the patient perceives their needs and circumstances are known to the provider',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Coordination of Care',
    Definition1: 'Mechanisms through which health workers interact with each other or patients to ensure efficient delivery of healthcare.',
    DisaggregatedIntervention: 'Health programme coordination',
    Definition: 'Coordination so as to promote healthcare service integration, such as consolidating the provision of healthcare services, so as to offer more and complete services at healthcare facilities. This could be in the form of reorganisation/retraining, or in increasing the medical capacity of a facility.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Coordination of Care',
    Definition1: 'Mechanisms through which health workers interact with each other or patients to ensure efficient delivery of healthcare.',
    DisaggregatedIntervention: 'Healthcare referral systems',
    Definition: 'Systems for managing referrals of patients between health care providers for the same health condition.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Coordination of Care',
    Definition1: 'Mechanisms through which health workers interact with each other or patients to ensure efficient delivery of healthcare.',
    DisaggregatedIntervention: 'Multi-institutional arrangements in health',
    Definition: 'Policies for how multiple organizations work together including policies that regulate interactions between donors and governments, social franchising, governance arrangements for coordinating care across multiple providers, mergers, and collaborations between local health and local government agencies for health improvement.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Coordination of Care',
    Definition1: 'Mechanisms through which health workers interact with each other or patients to ensure efficient delivery of healthcare.',
    DisaggregatedIntervention: 'Patient discharge planning',
    Definition: 'Individualised plans of discharge to facilitate the transfer of a patient from hospital to a post-discharge setting.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Coordination of Care',
    Definition1: 'Mechanisms through which health workers interact with each other or patients to ensure efficient delivery of healthcare.',
    DisaggregatedIntervention: 'Shared care',
    Definition: 'Continuing collaborative clinical care between primary and specialist care physicians',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Coordination of Care',
    Definition1: 'Mechanisms through which health workers interact with each other or patients to ensure efficient delivery of healthcare.',
    DisaggregatedIntervention: 'Shared decision-making of care',
    Definition: 'Sharing healthcare decision making responsibilities among different individuals, potentially including the patient.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Coordination of Care',
    Definition1: 'Mechanisms through which health workers interact with each other or patients to ensure efficient delivery of healthcare.',
    DisaggregatedIntervention: 'Transition of care',
    Definition: 'Interventions to improve transition from one care provider to another, for example adolescents moving from child to adult health services in general (in contrast to referral systems above).',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Delivery of care',
    Definition1: 'Interventions that target the delivery mechanisms for healthcare services: when and where those services are provided.',
    DisaggregatedIntervention: 'Care environment',
    Definition: 'Changes to the physical or sensory healthcare environment, by adding or altering equipment or layout, providing music, art.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Delivery of care',
    Definition1: 'Interventions that target the delivery mechanisms for healthcare services: when and where those services are provided.',
    DisaggregatedIntervention: 'Care transportation services',
    Definition: 'Arrangements for, or provision of, transportation services to or between medical care providers.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Delivery of care',
    Definition1: 'Interventions that target the delivery mechanisms for healthcare services: when and where those services are provided.',
    DisaggregatedIntervention: 'Chronic disease management',
    Definition: 'Programs designed to manage or prevent a chronic condition using a systematic approach to care and potentially employing multiple ways of influencing patients, providers or the process of care',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Delivery of care',
    Definition1: 'Interventions that target the delivery mechanisms for healthcare services: when and where those services are provided.',
    DisaggregatedIntervention: 'Healthcare infrastructure improvement',
    Definition: 'Improvement of healthcare infrastructure including through the construction of new facilities, and the expansion or renovation of existing facilities',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Delivery of care',
    Definition1: 'Interventions that target the delivery mechanisms for healthcare services: when and where those services are provided.',
    DisaggregatedIntervention: 'Length of health consultation',
    Definition: 'Changes in the length of consultations',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Delivery of care',
    Definition1: 'Interventions that target the delivery mechanisms for healthcare services: when and where those services are provided.',
    DisaggregatedIntervention: 'Outreach services of care',
    Definition: 'Visits by health workers to different locations, for example involving specialists, generalists, mobile units',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Delivery of care',
    Definition1: 'Interventions that target the delivery mechanisms for healthcare services: when and where those services are provided.',
    DisaggregatedIntervention: 'Packages of care',
    Definition: 'Introduction, modification, or removal of packages of services designed to be implemented together for general healthcare, including treatment, therapy, or monitoring packages',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Delivery of care',
    Definition1: 'Interventions that target the delivery mechanisms for healthcare services: when and where those services are provided.',
    DisaggregatedIntervention: 'Patient-initiated appointment systems',
    Definition: 'Systems that enable patients to make urgent appointments when they feel they cannot manage their condition or where something has changed unexpectedly',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Delivery of care',
    Definition1: 'Interventions that target the delivery mechanisms for healthcare services: when and where those services are provided.',
    DisaggregatedIntervention: 'Prioritisation of care',
    Definition: 'Strategies to prioritise the delivery of care and manage the queue of patients. This could be in the form of managed waiting lists, managing emergency and non-emergency cases, appointment scheduling, etc.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Delivery of care',
    Definition1: 'Interventions that target the delivery mechanisms for healthcare services: when and where those services are provided.',
    DisaggregatedIntervention: 'Providing care to groups versus individual patients',
    Definition: 'Comparisons of providing care to groups versus individual patients, for example intensive group therapy, group vs individual antenatal care.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Delivery of care',
    Definition1: 'Interventions that target the delivery mechanisms for healthcare services: when and where those services are provided.',
    DisaggregatedIntervention: 'Quality and safety systems of care',
    Definition: 'Standards for quality of healthcare, and reduction of poor outcomes related to unsafe healthcare.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Delivery of care',
    Definition1: 'Interventions that target the delivery mechanisms for healthcare services: when and where those services are provided.',
    DisaggregatedIntervention: 'Self-management of care',
    Definition: 'Shifting or promoting the responsibility for healthcare or disease management to the patient and/or their family.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Delivery of care',
    Definition1: 'Interventions that target the delivery mechanisms for healthcare services: when and where those services are provided.',
    DisaggregatedIntervention: 'Site of care service delivery',
    Definition: 'Changes in where care is provided, for example home vs. healthcare facility, inpatient vs outpatient, specialized vs. non-specialized facility, walk in clinics, medical day hospital, mobile units',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Delivery of care',
    Definition1: 'Interventions that target the delivery mechanisms for healthcare services: when and where those services are provided.',
    DisaggregatedIntervention: 'Size of care organisations',
    Definition: 'Increasing or decreasing the size of health service provider units',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Delivery of care',
    Definition1: 'Interventions that target the delivery mechanisms for healthcare services: when and where those services are provided.',
    DisaggregatedIntervention: 'Tailored care interventions',
    Definition: 'Interventions to change practice to take individual patient\'s needs and preferences into account in developing a treatment plan, for example through interviews or surveys.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Delivery of care',
    Definition1: 'Interventions that target the delivery mechanisms for healthcare services: when and where those services are provided.',
    DisaggregatedIntervention: 'Triage',
    Definition: 'The assignment of degrees of urgency to wounds or illnesses to decide the order of treatment of a large number of patients or casualties.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare systems management',
    Definition1: 'Improvements in the management procedures and systems for healthcare',
    DisaggregatedIntervention: 'Clinical incident reporting',
    Definition: 'System for reporting critical incidents.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare systems management',
    Definition1: 'Improvements in the management procedures and systems for healthcare',
    DisaggregatedIntervention: 'Clinical Practice Guidelines',
    Definition: 'Clinical guidelines are systematically developed statements to assist healthcare providers and patients to decide on appropriate health care for specific clinical circumstances\'(US IOM).',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare systems management',
    Definition1: 'Improvements in the management procedures and systems for healthcare',
    DisaggregatedIntervention: 'Continuous quality improvement of care',
    Definition: 'An iterative process to review and improve care that includes involvement of healthcare teams, analysis of a process or system, a structured process improvement method or problem solving approach, and use of data analysis to assess changes',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare systems management',
    Definition1: 'Improvements in the management procedures and systems for healthcare',
    DisaggregatedIntervention: 'Contracting out health services',
    Definition: 'Contracting is a strategy to use public sector funds to finance the provision of healthcare services.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare systems management',
    Definition1: 'Improvements in the management procedures and systems for healthcare',
    DisaggregatedIntervention: 'Health fund holding',
    Definition: 'Budgets allocated to a group or individual providers to purchase services with financial rewards for underspending or penalties for overspending (includes indicative budgets)',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare systems management',
    Definition1: 'Improvements in the management procedures and systems for healthcare',
    DisaggregatedIntervention: 'Local health consensus processes',
    Definition: 'Formal or informal local consensus processes, for example agreeing a clinical protocol to manage a patient group, adapting a guideline for a local health system or promoting the implementation of guidelines.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare systems management',
    Definition1: 'Improvements in the management procedures and systems for healthcare',
    DisaggregatedIntervention: 'Local health opinion leaders',
    Definition: 'The identification and use of identifiable local opinion leaders to promote good clinical practice.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare systems management',
    Definition1: 'Improvements in the management procedures and systems for healthcare',
    DisaggregatedIntervention: 'Procurement and distribution of drugs',
    Definition: 'Systems for procuring and distributing drugs for patients, pharmacies, or healthcare facilities.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare systems management',
    Definition1: 'Improvements in the management procedures and systems for healthcare',
    DisaggregatedIntervention: 'Procurement and distribution of medical devices',
    Definition: 'Systems for procuring and distributing medical devices/equipment used for the specific purposes of diagnosis and treatment of disease or rehabilitation following disease or injury; it can be used either alone or in combination with any accessory, consumable or other piece of medical equipment. Medical equipment excludes implantable, disposable or single-use medical devices.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare systems management',
    Definition1: 'Improvements in the management procedures and systems for healthcare',
    DisaggregatedIntervention: 'Procurement and distribution of medical supplies',
    Definition: 'Systems for procuring and distributing medical supplies - other than drugs - for patients and medical providers. These include implantable, disposable or single-use medical devices.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare systems management',
    Definition1: 'Improvements in the management procedures and systems for healthcare',
    DisaggregatedIntervention: 'Routine patient-reported outcome measures',
    Definition: 'Routine administration and reporting of patient reported outcome measures to providers and/or patients',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Audit of service providers',
    Definition: 'Independent audits of public sector services, inspecting levels of spending, effectiveness and cost efficiency. This includes independent scrutiny of public sector auditors.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Care educational games',
    Definition: 'The use of games as an educational strategy to improve standards of care.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Care educational materials',
    Definition: 'Distribution to individuals, or groups, of educational materials to support clinical care, i.e., any intervention in which knowledge is distributed. For example this may be facilitated by the internet, learning critical appraisal skills; skills for electronic retrieval of information, diagnostic formulation; question formulation',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Care educational meetings',
    Definition: 'Courses, workshops, conferences or other educational meetings',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Care educational outreach visits, or academic detailing.',
    Definition: 'Personal visits by a trained person to health workers in their own settings, to provide information with the aim of changing practice.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Communities of practice in care',
    Definition: 'Groups of people with a common interest who deepen their knowledge and expertise in this area by interacting on an ongoing basis',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Distribution of health personnel',
    Definition: 'Interventions to achieve an appropriate level and mix of staff for a given area, especially in underserved locales. This could include recruitment incentives, such as the provision of housing or compensation, or the staff rotations.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Exit interviews',
    Definition: 'A verbal exchange or written questionnaire following employees’ resignation',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Health manager training',
    Definition: 'Provision of any training to health managers related to the job. This includes training pertaining to patient care management, facility/equipment maintenance, administration, etc.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Health worker protective equipment',
    Definition: 'Provision and training in the use of personal protective equipment for health workers.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Health worker qualification',
    Definition: 'Qualification or certification of health worker skills, knowledge, or ability.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Health worker training',
    Definition: 'Provision of any training to health workers related to the job. This includes training pertaining to patient care, facility/equipment maintenance, administration, etc.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Health worker transition',
    Definition: 'Strategies for managing the transition of health workers between public and private organizations.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Incentives for career choice in health',
    Definition: 'Financial or material rewards for career choices; e.g. choice of profession or primary care',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Inter-professional education for health professionals',
    Definition: 'Continuing education for health professionals that involves more than one profession in joint, interactive learning',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Managerial supervision of health workers',
    Definition: 'Routine supervision visits by health staff.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Monitoring the performance of the delivery of healthcare',
    Definition: 'Monitoring of health services by individuals or healthcare organisations, for example by comparing with an external standard.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Nutrition personnel hiring',
    Definition: 'Interventions to increase the number or quality of nutrition health professionals.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Organisational culture development in healthcare',
    Definition: 'Strategies to change organisational culture',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Patient-mediated interventions',
    Definition: 'Any intervention aimed at changing the performance of healthcare professionals through interactions with patients, or information provided by or to patients.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Pay for performance',
    Definition: 'Contract schemes for private and public sector employees, especially teachers and healthcare professionals, that provide incentives based on performance metrics.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Payment methods for health workers',
    Definition: 'Fee for services, capitation, salary',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Recruitment and retention',
    Definition: 'Interventions in the recruitment and retention practices for healthcare professionals.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'Healthcare workforce management',
    Definition1: 'Interventions to alter the management, distribution, recruitment/retention, and responsibilities of healthcare service personnel.',
    DisaggregatedIntervention: 'Role expansion and task shifting',
    Definition: 'Expanding tasks undertaken by a cadre of health workers, to include tasks not previously part of their scope of practice or shifting tasks from one cadre of healthcare service providers to another.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'ICT in healthcare',
    Definition1: 'Information and communications technologies used by healthcare organizations to manage the delivery of healthcare, and to deliver healthcare.',
    DisaggregatedIntervention: 'Electronic assistive technologies',
    Definition: 'Provision or training in electronic assistive technologies, such as mobility and communications aids.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'ICT in healthcare',
    Definition1: 'Information and communications technologies used by healthcare organizations to manage the delivery of healthcare, and to deliver healthcare.',
    DisaggregatedIntervention: 'Health information systems',
    Definition: 'Health record and health management systems to store and manage patient health information, for example electronic patient records, or systems for recalling patients for follow-up or prevention e.g., immunization.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'ICT in healthcare',
    Definition1: 'Information and communications technologies used by healthcare organizations to manage the delivery of healthcare, and to deliver healthcare.',
    DisaggregatedIntervention: 'Health information transfer',
    Definition: 'Technology based methods to transfer healthcare information and support the delivery of care.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'ICT in healthcare',
    Definition1: 'Information and communications technologies used by healthcare organizations to manage the delivery of healthcare, and to deliver healthcare.',
    DisaggregatedIntervention: 'mHealth',
    Definition: 'Practice of medicine and public health supported by mobile devices. This includes all forms of telemedicine.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'ICT in healthcare',
    Definition1: 'Information and communications technologies used by healthcare organizations to manage the delivery of healthcare, and to deliver healthcare.',
    DisaggregatedIntervention: 'Reminder systems for healthcare',
    Definition: 'Use of technologies to remind caretakers and patients to schedule appointments, take medication, etc.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'ICT in healthcare',
    Definition1: 'Information and communications technologies used by healthcare organizations to manage the delivery of healthcare, and to deliver healthcare.',
    DisaggregatedIntervention: 'Remote health monitoring equipment',
    Definition: 'Provision or training in monitoring equipment, such as thermometers or oximeters, to be used at home/off-site.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Delivery arrangements',
    InterventionGroup: 'ICT in healthcare',
    Definition1: 'Information and communications technologies used by healthcare organizations to manage the delivery of healthcare, and to deliver healthcare.',
    DisaggregatedIntervention: 'Technology-aided therapy',
    Definition: 'Use of information and communications technologies to aid in therapy, such as application to guide in mental or physical exercises.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Diagnostics',
    Definition1: 'Interventions pertaining to the diagnosis of disease and underlying symptoms, for accurate and effective treatment.',
    DisaggregatedIntervention: 'Access to testing',
    Definition: 'Interventions to increase the public\'s access to testing, through mechanisms such as subsidisation, or otherwise manage the prioritisation of testing, such as referral requirements. This is not related to the supply of testing available, those would fall under the classifications ',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Diagnostics',
    Definition1: 'Interventions pertaining to the diagnosis of disease and underlying symptoms, for accurate and effective treatment.',
    DisaggregatedIntervention: 'Accurate tests',
    Definition: 'Interventions to measure the effectiveness and accuracy of testing.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Diagnostics',
    Definition1: 'Interventions pertaining to the diagnosis of disease and underlying symptoms, for accurate and effective treatment.',
    DisaggregatedIntervention: 'Diagnostic integration with treatment',
    Definition: 'Integration of diagnostics in the patient\'s treatment regiment.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Diagnostics',
    Definition1: 'Interventions pertaining to the diagnosis of disease and underlying symptoms, for accurate and effective treatment.',
    DisaggregatedIntervention: 'Laboratory testing',
    Definition: 'Use of laboratories to test, including interventions to increase testing capacity and laboratory quality.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Diagnostics',
    Definition1: 'Interventions pertaining to the diagnosis of disease and underlying symptoms, for accurate and effective treatment.',
    DisaggregatedIntervention: 'Rapid testing',
    Definition: 'Use of rapid result field tests, including interventions to increase testing capacity and test quality/accuracy.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Diagnostics',
    Definition1: 'Interventions pertaining to the diagnosis of disease and underlying symptoms, for accurate and effective treatment.',
    DisaggregatedIntervention: 'Results interpretation support',
    Definition: 'Interventions that seek to increase the quality and understanding of results interpretation for medical professionals and results communication to patients. This can be in the form of training, guidance, or other materials to support interpretations.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Disease surveillance',
    Definition1: 'Population-level disease surveillance and prevention interventions.',
    DisaggregatedIntervention: 'Contact tracing for disease exposure',
    Definition: 'Use of technologies and techniques to assess levels of exposure to disease.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Disease surveillance',
    Definition1: 'Population-level disease surveillance and prevention interventions.',
    DisaggregatedIntervention: 'Epidemiology capacity',
    Definition: 'Expansion of epidemiological capacity, through training health professionals and provision of tools.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Disease surveillance',
    Definition1: 'Population-level disease surveillance and prevention interventions.',
    DisaggregatedIntervention: 'Mass health communications',
    Definition: 'Health communications or advertising used to convey information to a wide audience for health promotion and safety.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Disease surveillance',
    Definition1: 'Population-level disease surveillance and prevention interventions.',
    DisaggregatedIntervention: 'Population-based screening for disease',
    Definition: 'Testing for disease on an population level.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Disease surveillance',
    Definition1: 'Population-level disease surveillance and prevention interventions.',
    DisaggregatedIntervention: 'Quarantine and isolation',
    Definition: 'Establishing quarantines or isolation to prevent an outbreak of infectious disease.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Drugs for disease prevention & mitigation',
    Definition1: 'Use of drugs in the prevention or mitigation of infection and transmission.',
    DisaggregatedIntervention: 'Antibiotic prophylaxis',
    Definition: 'Use of antibiotics to prevent infection.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Drugs for disease prevention & mitigation',
    Definition1: 'Use of drugs in the prevention or mitigation of infection and transmission.',
    DisaggregatedIntervention: 'Antiviral prophylaxis',
    Definition: 'Use of antiviral drugs to prevent infection, including for HIV.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Drugs for disease prevention & mitigation',
    Definition1: 'Use of drugs in the prevention or mitigation of infection and transmission.',
    DisaggregatedIntervention: 'General treatment adherence support',
    Definition: 'Support workers interacting with patients in clinical, community and home settings where they provide education, treatment support and adherence counselling',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Drugs for disease prevention & mitigation',
    Definition1: 'Use of drugs in the prevention or mitigation of infection and transmission.',
    DisaggregatedIntervention: 'Immunisation campaigns',
    Definition: 'Campaigns to proliferate immunisation using available vaccines.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Drugs for disease prevention & mitigation',
    Definition1: 'Use of drugs in the prevention or mitigation of infection and transmission.',
    DisaggregatedIntervention: 'Immunisation information campaigns',
    Definition: 'Providing information on vaccines and targeted diseases to raise awareness.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Drugs for disease prevention & mitigation',
    Definition1: 'Use of drugs in the prevention or mitigation of infection and transmission.',
    DisaggregatedIntervention: 'Malaria prophylaxis',
    Definition: 'Use of drugs to prevent malaria infection.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Drugs for disease prevention & mitigation',
    Definition1: 'Use of drugs in the prevention or mitigation of infection and transmission.',
    DisaggregatedIntervention: 'Parasite prevention',
    Definition: 'Use of drugs for deworming or other parasite infection to prevent the incidence of parasites, such as anti-helminthic treatment to schoolchildren. Excluding the prevention of malaria (use other code).',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Drugs for disease prevention & mitigation',
    Definition1: 'Use of drugs in the prevention or mitigation of infection and transmission.',
    DisaggregatedIntervention: 'Treatment of preclinical infection',
    Definition: 'Use of drugs to prevent or mitigate against anticipated symptoms and transmissibility.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Drugs for disease prevention & mitigation',
    Definition1: 'Use of drugs in the prevention or mitigation of infection and transmission.',
    DisaggregatedIntervention: 'Vaccination',
    Definition: 'Administration of vaccines to help the immune system develop protection from diseases including: diphtheria, Haemophilus influenza serotype b infection, hepatitis B, measles, meningitis, mumps, pertussis, poliomyelitis, rubella, tetanus, tuberculosis, and yellow fever.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Drugs for disease prevention & mitigation',
    Definition1: 'Use of drugs in the prevention or mitigation of infection and transmission.',
    DisaggregatedIntervention: 'Vaccine development & testing',
    Definition: 'Development and testing of novel vaccines.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'General health promotion',
    Definition1: 'General health interventions to promote health and disease prevention.',
    DisaggregatedIntervention: 'Basic first aid',
    Definition: 'Instruction or provision of materials for use in first aid scenarios.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'General health promotion',
    Definition1: 'General health interventions to promote health and disease prevention.',
    DisaggregatedIntervention: 'Cognitive Behavioural Therapy (CBT)',
    Definition: 'Provision of goal-oriented psychological support. Cognitive Behavioural Therapy can target individuals or groups in sessions to teach skills of self-control, anger and emotions management. CBT can also be used to reduce impulsivity or addictions.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'General health promotion',
    Definition1: 'General health interventions to promote health and disease prevention.',
    DisaggregatedIntervention: 'Dental health & hygiene',
    Definition: 'Dental hygiene education and health services. This includes the use of fluoride in the water supply to prevent carries.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'General health promotion',
    Definition1: 'General health interventions to promote health and disease prevention.',
    DisaggregatedIntervention: 'General health counselling',
    Definition: 'Group or individual counselling to promote best practices and transmit knowledge.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'General health promotion',
    Definition1: 'General health interventions to promote health and disease prevention.',
    DisaggregatedIntervention: 'General health education and behaviour change',
    Definition: 'Interventions targeting unhealthy behaviours and encouraging change, not including nutrition and dieting. This would include ',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'General health promotion',
    Definition1: 'General health interventions to promote health and disease prevention.',
    DisaggregatedIntervention: 'Illicit drugs and substances elimination & control',
    Definition: 'Interventions that seeks to control/eliminate use of Illicit drugs and substances',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'General health promotion',
    Definition1: 'General health interventions to promote health and disease prevention.',
    DisaggregatedIntervention: 'Individual-based screening',
    Definition: 'Screening performed on an individual basis for specific conditions, such as eyesight or hearing.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'General health promotion',
    Definition1: 'General health interventions to promote health and disease prevention.',
    DisaggregatedIntervention: 'Injury prevention promotion',
    Definition: 'Interventions seeking to mitigate injury potential, such as wearing a bike helmet.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'General health promotion',
    Definition1: 'General health interventions to promote health and disease prevention.',
    DisaggregatedIntervention: 'Mental health and psychosocial support (MHPSS)',
    Definition: 'Provision of mental health and psychosocial support (MHPS) in emergencies or situation of chronic hardship. MHPS can be specialized if implemented by specialist such as psychiatrist or psychologist, or non-specialized if implemented by primary health care doctors and community health workers. It can also be a community support group or a network strengthening intervention to allow people to seek psychosocial support directly within their community.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'General health promotion',
    Definition1: 'General health interventions to promote health and disease prevention.',
    DisaggregatedIntervention: 'Personal protective equipment use',
    Definition: 'Provision and training in the use of personal protective equipment, especially masks/face coverings.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'General health promotion',
    Definition1: 'General health interventions to promote health and disease prevention.',
    DisaggregatedIntervention: 'Regular check-ups',
    Definition: 'Interventions that seek to increase the incidence of regular check-ups, i.e. not symptom dependent.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'General health promotion',
    Definition1: 'General health interventions to promote health and disease prevention.',
    DisaggregatedIntervention: 'Reproductive health education and behaviour change',
    Definition: 'Reproductive health education to promote the use of condoms and contraceptives and family planning',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'General health promotion',
    Definition1: 'General health interventions to promote health and disease prevention.',
    DisaggregatedIntervention: 'Shoes to prevent worms',
    Definition: 'Use of shoes to prevent soil-transmitted worms. Intervention could provide awareness or the shoes themselves.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'General health promotion',
    Definition1: 'General health interventions to promote health and disease prevention.',
    DisaggregatedIntervention: 'Solid floors to prevent worms',
    Definition: 'Use of solid flooring to prevent soil-transmitted worms. Intervention could provide awareness or the floors themselves.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'General health promotion',
    Definition1: 'General health interventions to promote health and disease prevention.',
    DisaggregatedIntervention: 'Toxic exposure reduction',
    Definition: 'Interventions that reduce the exposure to potential toxins, such as indoor air pollution, mercury, etc.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Maternal & neo-natal health',
    Definition1: 'Health interventions that address mother’s health and well-being during pregnancy and around the time of delivery, including access to appropriate care',
    DisaggregatedIntervention: 'Antenatal care',
    Definition: 'Specialised care provided to the mother and child in vitro to prevent pregnancy and/or health complications for either the mother or child.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Maternal & neo-natal health',
    Definition1: 'Health interventions that address mother’s health and well-being during pregnancy and around the time of delivery, including access to appropriate care',
    DisaggregatedIntervention: 'Delivery practices',
    Definition: 'Interventions that improve delivery practices, such as use of anti-septic wipes.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Maternal & neo-natal health',
    Definition1: 'Health interventions that address mother’s health and well-being during pregnancy and around the time of delivery, including access to appropriate care',
    DisaggregatedIntervention: 'Exclusive breastfeeding',
    Definition: 'Interventions that promote, support and protect exclusive breastfeeding.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Maternal & neo-natal health',
    Definition1: 'Health interventions that address mother’s health and well-being during pregnancy and around the time of delivery, including access to appropriate care',
    DisaggregatedIntervention: 'Kangaroo care',
    Definition: 'Promotion of Kangaroo care, sometimes called skin-to-skin contact, especially for low-birth weight infants.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Maternal & neo-natal health',
    Definition1: 'Health interventions that address mother’s health and well-being during pregnancy and around the time of delivery, including access to appropriate care',
    DisaggregatedIntervention: 'Labour and delivery support',
    Definition: 'Inclusion of family member or staff to provide emotional support to women in labour.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Maternal & neo-natal health',
    Definition1: 'Health interventions that address mother’s health and well-being during pregnancy and around the time of delivery, including access to appropriate care',
    DisaggregatedIntervention: 'Midwives',
    Definition: 'Provision of skilled and trained midwives in delivery of infants.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Maternal & neo-natal health',
    Definition1: 'Health interventions that address mother’s health and well-being during pregnancy and around the time of delivery, including access to appropriate care',
    DisaggregatedIntervention: 'Postnatal care',
    Definition: 'Specialised care provided to the child after birth to prevent death/disease or otherwise promote healthy growth.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Maternal & neo-natal health',
    Definition1: 'Health interventions that address mother’s health and well-being during pregnancy and around the time of delivery, including access to appropriate care',
    DisaggregatedIntervention: 'Post-partum care',
    Definition: 'Specialised care provided to the mother after delivery.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Maternal & neo-natal health',
    Definition1: 'Health interventions that address mother’s health and well-being during pregnancy and around the time of delivery, including access to appropriate care',
    DisaggregatedIntervention: 'Prevention of mother-to-child transmission of HIV (PMTCT)',
    Definition: 'Prevention of mother-to-child transmission (PMTCT) programmes that offer a range of services for women of reproductive age living with or at risk of HIV to maintain their health and stop their infants from acquiring HIV',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Maternal & neo-natal health',
    Definition1: 'Health interventions that address mother’s health and well-being during pregnancy and around the time of delivery, including access to appropriate care',
    DisaggregatedIntervention: 'Promotion of contraceptive use',
    Definition: 'Interventions to promote the use of contraception in family planning and STI prevention.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Maternal & neo-natal health',
    Definition1: 'Health interventions that address mother’s health and well-being during pregnancy and around the time of delivery, including access to appropriate care',
    DisaggregatedIntervention: 'Provision of contraception',
    Definition: 'Interventions that provide information about and distribute contraception.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Maternal & neo-natal health',
    Definition1: 'Health interventions that address mother’s health and well-being during pregnancy and around the time of delivery, including access to appropriate care',
    DisaggregatedIntervention: 'Umbilical cord clamping',
    Definition: 'Optimal timing of cord clamping for the prevention of iron deficiency anaemia in infants.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Menstrual hygiene management (MHM)',
    Definition1: 'These interventions encourage the practice of proper menstrual hygiene management at the household or community level. This can be either educational (i.e. hygiene education; teaching best practices) or behavioural (i.e. using social pressure or triggers of disgust and shame).',
    DisaggregatedIntervention: 'Behavioural MHM promotion',
    Definition: 'These are interventions that encourage the practice of MHM at the household or community level through behavioural promotion, such as using social pressure, environmental cues, or nudges',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Menstrual hygiene management (MHM)',
    Definition1: 'These interventions encourage the practice of proper menstrual hygiene management at the household or community level. This can be either educational (i.e. hygiene education; teaching best practices) or behavioural (i.e. using social pressure or triggers of disgust and shame).',
    DisaggregatedIntervention: 'Educational MHM promotion',
    Definition: 'These are interventions that encourage the practice of MHM at the household or community level through educational promotion, such as hygiene education, teaching best practices, and information on menstrual hygiene.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Menstrual hygiene management (MHM)',
    Definition1: 'These interventions encourage the practice of proper menstrual hygiene management at the household or community level. This can be either educational (i.e. hygiene education; teaching best practices) or behavioural (i.e. using social pressure or triggers of disgust and shame).',
    DisaggregatedIntervention: 'Information campaigns - Menstrual hygiene management (MHM)',
    Definition: 'Interventions that provide information regarding menstrual hygiene, practices, and promote behavioural change.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Menstrual hygiene management (MHM)',
    Definition1: 'These interventions encourage the practice of proper menstrual hygiene management at the household or community level. This can be either educational (i.e. hygiene education; teaching best practices) or behavioural (i.e. using social pressure or triggers of disgust and shame).',
    DisaggregatedIntervention: 'Peer support groups for menstrual hygiene and sensitisation',
    Definition: 'Interventions that encourage the formation of peer support groups for menstrual hygiene and sensitisation.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Menstrual hygiene management (MHM)',
    Definition1: 'These interventions encourage the practice of proper menstrual hygiene management at the household or community level. This can be either educational (i.e. hygiene education; teaching best practices) or behavioural (i.e. using social pressure or triggers of disgust and shame).',
    DisaggregatedIntervention: 'Provision of menstrual care products with promotion',
    Definition: 'These interventions include the provision of materials to help manage menstrual hygiene at the household or community level; this can include sanitary napkins, tampons, disposal bins, or incinerators.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Nutrition',
    Definition1: 'Interventions targeting nutrition and diet',
    DisaggregatedIntervention: 'Growth monitoring and assessment of children',
    Definition: 'The assessment and monitoring of height and weight to detect both underweight and overweight throughout the life-course',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Nutrition',
    Definition1: 'Interventions targeting nutrition and diet',
    DisaggregatedIntervention: 'Maternal and infant nutrition care',
    Definition: 'Nutritional counselling on healthy diet to reduce low birth weight, appropriate macro and micro nutrient supplementation for women during pregnancy and postpartum, counsel women to improve breastfeeding practices and manage breastfeeding difficulties, optimal feeding of low-birth weight infants, Optimize new-born feeding practices and address additional care needs of infants',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Nutrition',
    Definition1: 'Interventions targeting nutrition and diet',
    DisaggregatedIntervention: 'Micronutrient supplementation in emergencies',
    Definition: 'Infant and young child feeding programmes in emergencies, prevention and control of micronutrient deficiencies in emergencies.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Nutrition',
    Definition1: 'Interventions targeting nutrition and diet',
    DisaggregatedIntervention: 'Nutrient fortification in foods',
    Definition: 'Interventions to deliver nutrient fortified foods, such as iron, vitamin A or iodine fortification.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Nutrition',
    Definition1: 'Interventions targeting nutrition and diet',
    DisaggregatedIntervention: 'Nutrient supplementation',
    Definition: 'Use of nutrition supplements and specialized food products to prevent and treat nutrition deficiencies (including micro and macro nutrients) .',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Nutrition',
    Definition1: 'Interventions targeting nutrition and diet',
    DisaggregatedIntervention: 'Nutrition care throughout the life-course',
    Definition: 'Nutrition counselling and support for appropriate complementary feeding and achieving healthy diets throughout the life-course as well as specialised nutritional care for specific medical conditions in line with the WHO Essential Nutrition Actions.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Nutrition',
    Definition1: 'Interventions targeting nutrition and diet',
    DisaggregatedIntervention: 'Nutrition education and behaviour change',
    Definition: 'Interventions targeting unhealthy nutrition and dieting behaviour and encouraging change. This includes overnutrion, as well as malnutrition.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'STI & HIV prevention',
    Definition1: 'Interventions targeting the prevention of STIs and HIV.',
    DisaggregatedIntervention: 'Antiretroviral therapy (ART)',
    Definition: 'Treatment regime often used in the treatment of HIV/AIDS patients.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'STI & HIV prevention',
    Definition1: 'Interventions targeting the prevention of STIs and HIV.',
    DisaggregatedIntervention: 'Antiviral prophylaxis',
    Definition: 'Use of antiviral drugs to prevent infection, including for HIV.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'STI & HIV prevention',
    Definition1: 'Interventions targeting the prevention of STIs and HIV.',
    DisaggregatedIntervention: 'Awareness campaigns about STI/HIV transmission, prevention, and treatment.',
    Definition: 'Information campaigns targeted to raise awareness about STI/HIV transmission, prevention, and treatment.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'STI & HIV prevention',
    Definition1: 'Interventions targeting the prevention of STIs and HIV.',
    DisaggregatedIntervention: 'HIV counselling',
    Definition: 'Group or individual counselling to promote best practices and transmit knowledge for HIV/AIDS.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'STI & HIV prevention',
    Definition1: 'Interventions targeting the prevention of STIs and HIV.',
    DisaggregatedIntervention: 'Medical male circumcision',
    Definition: 'Interventions that promote or encourage circumcision for HIV/STI prevention.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'STI & HIV prevention',
    Definition1: 'Interventions targeting the prevention of STIs and HIV.',
    DisaggregatedIntervention: 'Provision of contraception',
    Definition: 'Interventions that provide information about and distribute contraception.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'STI & HIV prevention',
    Definition1: 'Interventions targeting the prevention of STIs and HIV.',
    DisaggregatedIntervention: 'STI/HIV testing',
    Definition: 'Screening for Sexually Transmitted Infections and HIV.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Treatment regimes',
    Definition1: 'Treatment regiments for disease.',
    DisaggregatedIntervention: 'Alternative use of medicines',
    Definition: 'Use of medicines for unconventional/off-label treatment for a condition.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Treatment regimes',
    Definition1: 'Treatment regiments for disease.',
    DisaggregatedIntervention: 'Antibiotic medication',
    Definition: 'Introduction of antibiotic medication to address symptoms and underlying disease, including parasitic infection.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Treatment regimes',
    Definition1: 'Treatment regiments for disease.',
    DisaggregatedIntervention: 'Antiretroviral therapy (ART)',
    Definition: 'Treatment regime often used in the treatment of HIV/AIDS patients.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Treatment regimes',
    Definition1: 'Treatment regiments for disease.',
    DisaggregatedIntervention: 'Oral rehydration therapy',
    Definition: 'Oral rehydration therapy is a type of fluid replacement used to prevent and treat dehydration, especially that due to diarrheal. It involves drinking water with modest amounts of sugar and salts, specifically sodium and potassium. Oral rehydration therapy can also be given by a nasogastric tube.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Treatment regimes',
    Definition1: 'Treatment regiments for disease.',
    DisaggregatedIntervention: 'Other medicated treatments',
    Definition: 'Interventions using any medicated treatment other than those listed within this intervention group.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Treatment regimes',
    Definition1: 'Treatment regiments for disease.',
    DisaggregatedIntervention: 'Pain management drugs',
    Definition: 'Use of drugs for pain management.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Treatment regimes',
    Definition1: 'Treatment regiments for disease.',
    DisaggregatedIntervention: 'Physical therapy regimes',
    Definition: 'Interventions to use physical therapy regimes to improve health and recovery outcomes.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Treatment regimes',
    Definition1: 'Treatment regiments for disease.',
    DisaggregatedIntervention: 'Traditional medicine',
    Definition: 'Use of traditional treatment methods and medicine in disease treatment.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Vector-borne disease',
    Definition1: 'Prevention and control of disease that results from an infection transmitted to humans and other animals by blood-feeding anthropoids, such as mosquitoes, ticks, and fleas. Examples of vector-borne diseases include Dengue fever, West Nile Virus, Lyme disease, and malaria.',
    DisaggregatedIntervention: 'Insect repellents',
    Definition: 'Use of repellents on the skin to prevent human-vector contact.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Vector-borne disease',
    Definition1: 'Prevention and control of disease that results from an infection transmitted to humans and other animals by blood-feeding anthropoids, such as mosquitoes, ticks, and fleas. Examples of vector-borne diseases include Dengue fever, West Nile Virus, Lyme disease, and malaria.',
    DisaggregatedIntervention: 'Insecticide Treated Nets (ITNs)',
    Definition: 'Use of ITNs to reduce human–vector contact, often used against malaria.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Vector-borne disease',
    Definition1: 'Prevention and control of disease that results from an infection transmitted to humans and other animals by blood-feeding anthropoids, such as mosquitoes, ticks, and fleas. Examples of vector-borne diseases include Dengue fever, West Nile Virus, Lyme disease, and malaria.',
    DisaggregatedIntervention: 'Residual insecticides',
    Definition: 'Application of insecticides on surfaces to deter/kill vectors, such as insecticide applied on walls. This includes most community-based spraying interventions.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Vector-borne disease',
    Definition1: 'Prevention and control of disease that results from an infection transmitted to humans and other animals by blood-feeding anthropoids, such as mosquitoes, ticks, and fleas. Examples of vector-borne diseases include Dengue fever, West Nile Virus, Lyme disease, and malaria.',
    DisaggregatedIntervention: 'Vector population control',
    Definition: 'Population control interventions targeting disease vectors.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Disease control & prevention',
    InterventionGroup: 'Vector-borne disease',
    Definition1: 'Prevention and control of disease that results from an infection transmitted to humans and other animals by blood-feeding anthropoids, such as mosquitoes, ticks, and fleas. Examples of vector-borne diseases include Dengue fever, West Nile Virus, Lyme disease, and malaria.',
    DisaggregatedIntervention: 'Vector-borne disease information',
    Definition: 'Awareness and information campaigns focusing on vector-borne disease.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Financial arrangements',
    InterventionGroup: 'Collection of funds',
    Definition1: 'Mechanisms by which financial resources to pay for health care are obtained.',
    DisaggregatedIntervention: 'External funding for healthcare',
    Definition: 'Financial contributions such as donations, loans, etc. from public or private entities from outside the national or local health financing system',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Financial arrangements',
    InterventionGroup: 'Collection of funds',
    Definition1: 'Mechanisms by which financial resources to pay for health care are obtained.',
    DisaggregatedIntervention: 'Health community loan funds',
    Definition: 'Funds generated from contributions of community members that families can borrow to pay for emergency transportation and related healthcare costs.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Financial arrangements',
    InterventionGroup: 'Collection of funds',
    Definition1: 'Mechanisms by which financial resources to pay for health care are obtained.',
    DisaggregatedIntervention: 'Health savings accounts',
    Definition: 'Prepayment schemes for individuals or families without risk pooling, to cover healthcare-related costs.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Financial arrangements',
    InterventionGroup: 'Collection of funds',
    Definition1: 'Mechanisms by which financial resources to pay for health care are obtained.',
    DisaggregatedIntervention: 'Healthcare funding reform',
    Definition: 'Reform in collection of funds through general tax revenues versus earmarked tax revenues versus employer payments versus direct payments',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Financial arrangements',
    InterventionGroup: 'Collection of funds',
    Definition1: 'Mechanisms by which financial resources to pay for health care are obtained.',
    DisaggregatedIntervention: 'Out-of-pocket payment for drugs',
    Definition: 'Direct patient payments for part of the cost of drugs, often referred to as co-payments or user fees.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Financial arrangements',
    InterventionGroup: 'Collection of funds',
    Definition1: 'Mechanisms by which financial resources to pay for health care are obtained.',
    DisaggregatedIntervention: 'Out-of-pocket payment for health services',
    Definition: 'Direct patient payments for part of the cost of health services, often referred to as co-payments or user fees.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Financial arrangements',
    InterventionGroup: 'Insurance schemes',
    Definition1: 'Risk pooling to cover all or part of the costs of health care services.',
    DisaggregatedIntervention: 'Community-based health insurance',
    Definition: 'A scheme managed and operated by an organization, other than a government or private for-profit company, that provides risk pooling to cover all or part of the costs of health care services',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Financial arrangements',
    InterventionGroup: 'Insurance schemes',
    Definition1: 'Risk pooling to cover all or part of the costs of health care services.',
    DisaggregatedIntervention: 'Decision-making about health insurance coverage',
    Definition: 'Processes for deciding what is reimbursed and who is covered by health insurance including policies that regulate what drugs are reimbursed, policies that regulate what services are reimbursed, restrictions on reimbursement for health insurance, and strategies for expanding health insurance coverage.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Financial arrangements',
    InterventionGroup: 'Insurance schemes',
    Definition1: 'Risk pooling to cover all or part of the costs of health care services.',
    DisaggregatedIntervention: 'Health insurance regulations',
    Definition: 'Policies that regulate the provision of insurance, for example insurance coverage of essential drugs. This includes insurances for health service providers.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Financial arrangements',
    InterventionGroup: 'Insurance schemes',
    Definition1: 'Risk pooling to cover all or part of the costs of health care services.',
    DisaggregatedIntervention: 'Private health insurance',
    Definition: 'Private for-profit health insurance',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Financial arrangements',
    InterventionGroup: 'Insurance schemes',
    Definition1: 'Risk pooling to cover all or part of the costs of health care services.',
    DisaggregatedIntervention: 'Social health insurance',
    Definition: 'Government operated and funded insurance schemes to provide health coverage.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Financial arrangements',
    InterventionGroup: 'Payment mechanisms',
    Definition1: 'Mechanisms for the payment of health services.',
    DisaggregatedIntervention: 'Conditional Cash Transfers (CCTs)',
    Definition: 'Cash transfer to families or individuals provided on a specific condition, such as school enrolment or child vaccination. The transfer is made when and only if the condition is met, which is verified by a more or less intense monitoring.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Financial arrangements',
    InterventionGroup: 'Payment mechanisms',
    Definition1: 'Mechanisms for the payment of health services.',
    DisaggregatedIntervention: 'Pricing and purchasing policies for health goods and services',
    Definition: 'Policies that determine the price that is paid or how commercial products are purchased, for example health technologies, drugs.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Financial arrangements',
    InterventionGroup: 'Payment mechanisms',
    Definition1: 'Mechanisms for the payment of health services.',
    DisaggregatedIntervention: 'Unconditional Cash Transfers (UCTs)',
    Definition: 'Cash transfer to families or individuals without any condition from the beneficiaries. This can be a regular cash transfer or a one-time cash transfer.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Financial arrangements',
    InterventionGroup: 'Payment mechanisms',
    Definition1: 'Mechanisms for the payment of health services.',
    DisaggregatedIntervention: 'Voucher schemes for health',
    Definition: 'Provision of vouchers that can be redeemed for health',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Healthcare policies',
    Definition1: 'Authority and accountability for health policies.',
    DisaggregatedIntervention: 'Abortion policy',
    Definition: 'Reform of abortion policies, including regulation and prohibition of sex- or disability-selective abortions.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Healthcare policies',
    Definition1: 'Authority and accountability for health policies.',
    DisaggregatedIntervention: 'Population control policies',
    Definition: 'Government policies to govern reproductive decisions by families, such as China\'s one-child policy.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Healthcare policies',
    Definition1: 'Authority and accountability for health policies.',
    DisaggregatedIntervention: 'Community mobilization in health',
    Definition: 'Processes that enable people to organize themselves, including community-based behaviour change.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Healthcare policies',
    Definition1: 'Authority and accountability for health policies.',
    DisaggregatedIntervention: 'Decentralisation',
    Definition: 'Decentralisation interventions shift existing power over spending and decision-making to sub-national levels.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Healthcare policies',
    Definition1: 'Authority and accountability for health policies.',
    DisaggregatedIntervention: 'Patients’ rights',
    Definition: 'Policies that regulate patients’ rights, including access to care and information (includes regulation of information provided to patients)',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Healthcare policies',
    Definition1: 'Authority and accountability for health policies.',
    DisaggregatedIntervention: 'Policies to manage absenteeism of health workers',
    Definition: 'Regulations for managing absenteeism',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Healthcare policies',
    Definition1: 'Authority and accountability for health policies.',
    DisaggregatedIntervention: 'Policies to reduce corruption in health',
    Definition: 'Regulations that are intended to reduce corruption in the health sector',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Healthcare policies',
    Definition1: 'Authority and accountability for health policies.',
    DisaggregatedIntervention: 'Public release of health provider performance data',
    Definition: 'Informing the public about healthcare providers by the release of performance data in written or electronic form.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Healthcare policies',
    Definition1: 'Authority and accountability for health policies.',
    DisaggregatedIntervention: 'Stakeholder involvement in policy decisions about healthcare',
    Definition: 'Policies and procedures for involving stakeholders in decision-making',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Healthcare policies',
    Definition1: 'Authority and accountability for health policies.',
    DisaggregatedIntervention: 'Stewardship of private health services',
    Definition: 'Policies that regulate health services provided by the private sector',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Healthcare policies',
    Definition1: 'Authority and accountability for health policies.',
    DisaggregatedIntervention: 'Stewardship of public health services',
    Definition: 'Policies that regulate health services provided by the public sector',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of commercial products',
    Definition1: 'Authority and accountability for commercial products.',
    DisaggregatedIntervention: 'Health marketing regulations',
    Definition: 'Policies that regulate marketing of commercial products, for example medical devices, drugs, the private provision of healthcare.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of commercial products',
    Definition1: 'Authority and accountability for commercial products.',
    DisaggregatedIntervention: 'Liability for commercial health products',
    Definition: 'Policies that regulate liability for commercial products.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of commercial products',
    Definition1: 'Authority and accountability for commercial products.',
    DisaggregatedIntervention: 'Patents and profits regulations in health',
    Definition: 'Policies that regulate patents and profits, for example medical devices, drugs.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of commercial products',
    Definition1: 'Authority and accountability for commercial products.',
    DisaggregatedIntervention: 'Registration regulations in commercial health products',
    Definition: 'Procedures for registering or licensing commercial products, for example medical devices, drugs.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of commercial products',
    Definition1: 'Authority and accountability for commercial products.',
    DisaggregatedIntervention: 'Regulating sales and dispensing of health products',
    Definition: 'Policies that regulate sales and dispensing of commercial products, for example over the counter and prescription drugs.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of health professionals',
    Definition1: 'Authority and accountability for health professionals.',
    DisaggregatedIntervention: 'Accreditation of healthcare providers',
    Definition: 'Processes for accrediting healthcare providers',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of health professionals',
    Definition1: 'Authority and accountability for health professionals.',
    DisaggregatedIntervention: 'Authority and accountability for the quality of practice of health professionals',
    Definition: 'Policies that regulate authority and accountability for the quality of care or safety, for example implementation of clinical guidelines.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of health professionals',
    Definition1: 'Authority and accountability for health professionals.',
    DisaggregatedIntervention: 'Dual practice regulation',
    Definition: 'Policies that regulate dual practice, e.g., working in public and privately owned healthcare settings',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of health professionals',
    Definition1: 'Authority and accountability for health professionals.',
    DisaggregatedIntervention: 'Emigration and immigration policies of health professionals',
    Definition: 'Policies that regulate emigration and immigration of health professionals',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of health professionals',
    Definition1: 'Authority and accountability for health professionals.',
    DisaggregatedIntervention: 'Health worker support groups',
    Definition: 'Groups that provide support and counselling for healthcare workers.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of health professionals',
    Definition1: 'Authority and accountability for health professionals.',
    DisaggregatedIntervention: 'Prescribing medication regulation',
    Definition: 'Policies related to who can prescribe drugs, what drugs, for what purpose, for patient care.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of health professionals',
    Definition1: 'Authority and accountability for health professionals.',
    DisaggregatedIntervention: 'Professional competence of health workers',
    Definition: 'Policies or procedures for assuring professional competence',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of health professionals',
    Definition1: 'Authority and accountability for health professionals.',
    DisaggregatedIntervention: 'Professional liability of health workers',
    Definition: 'Policies that regulate liability for health professionals',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of health professionals',
    Definition1: 'Authority and accountability for health professionals.',
    DisaggregatedIntervention: 'Scope of practice of health workers',
    Definition: 'Policies that regulate what health professionals can do',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of health professionals',
    Definition1: 'Authority and accountability for health professionals.',
    DisaggregatedIntervention: 'Training and licensing for health professionals',
    Definition: 'Policies that regulate training, specialty certification and licensure requirements for health professionals',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of informal medicine',
    Definition1: 'Rules and restrictions placed on the exercise of traditional medicine.',
    DisaggregatedIntervention: 'Procedures permitted by informal health workers',
    Definition: 'Regulation of procedures allowed to be conducted by informal health workers.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of informal medicine',
    Definition1: 'Rules and restrictions placed on the exercise of traditional medicine.',
    DisaggregatedIntervention: 'Traditional medical provider regulation',
    Definition: 'Regulation of informal/traditional medical providers.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of informal medicine',
    Definition1: 'Rules and restrictions placed on the exercise of traditional medicine.',
    DisaggregatedIntervention: 'Traditional medicine regulation',
    Definition: 'Regulation of the use of alternative/traditional medicine in treatment.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of organisations',
    Definition1: 'Authority and accountability for organisations.',
    DisaggregatedIntervention: 'Accreditation of healthcare providers',
    Definition: 'Processes for accrediting healthcare providers',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of organisations',
    Definition1: 'Authority and accountability for organisations.',
    DisaggregatedIntervention: 'Liability of healthcare organisations',
    Definition: 'Policies that limit liability of healthcare organisations.',
  },
  {
    InterventionSector: 'Health',
    BroadGroup: 'Governance arrangements',
    InterventionGroup: 'Regulation of organisations',
    Definition1: 'Authority and accountability for organisations.',
    DisaggregatedIntervention: 'Regulation of health service organisation ownership',
    Definition: 'Policies that regulate who can own health service organisations, for example for-profit vs not-for-profit; public vs private; religious affiliations.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Business management',
    Definition1: 'Interventions that target business management or administrative practices.',
    DisaggregatedIntervention: 'Business networks',
    Definition: 'Establishment or strengthening of networking organisations between business to share knowledge and connect with other business. An example would be a chamber of commerce.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Business management',
    Definition1: 'Interventions that target business management or administrative practices.',
    DisaggregatedIntervention: 'Financial audit',
    Definition: 'Implementation of independent audits in the private and/or public sector in order to verify their compliance with financial laws and regulations, and identify issues of corruption. This also includes interventions to augment auditing capacity.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Business management',
    Definition1: 'Interventions that target business management or administrative practices.',
    DisaggregatedIntervention: 'Financial management training',
    Definition: 'Financial management education and training programmes.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Business management',
    Definition1: 'Interventions that target business management or administrative practices.',
    DisaggregatedIntervention: 'Financial projections',
    Definition: 'Programmes to provide financial projections or skills in forecasting, to estimate the future financial performance of a business.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Business management',
    Definition1: 'Interventions that target business management or administrative practices.',
    DisaggregatedIntervention: 'Financing opportunities',
    Definition: 'Programmes that increase awareness or access to financing, including performance-based financing.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Business management',
    Definition1: 'Interventions that target business management or administrative practices.',
    DisaggregatedIntervention: 'Firm restructuring',
    Definition: 'Programmes or independent restructuring efforts to increase organisational/firm efficiency. This also includes efforts to downsize or shift resources, such as outsourcing.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Business management',
    Definition1: 'Interventions that target business management or administrative practices.',
    DisaggregatedIntervention: 'Organisational culture development',
    Definition: 'Programmes that aim to develop the organisational culture.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Business Risk Management (BRM)',
    Definition1: 'Interventions to identify, prioritize and address the risk to minimize penalties from unexpected incidents, in business operations, systems and process.',
    DisaggregatedIntervention: 'Bailouts and stimulus to prevent financial risk',
    Definition: 'Public sector bailouts of private industry or other stimulus efforts to jump-start an economy or sector or for preventing it from failure. These interventions may include capital injections or other measures to avoid firms\' bankruptcy.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Business Risk Management (BRM)',
    Definition1: 'Interventions to identify, prioritize and address the risk to minimize penalties from unexpected incidents, in business operations, systems and process.',
    DisaggregatedIntervention: 'Currency risk management',
    Definition: 'Interventions that target currency value. These include fixed/floating currency regimes, debasements/appraisements, foreign exchange reserves or the introduction of a new currency.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Business Risk Management (BRM)',
    Definition1: 'Interventions to identify, prioritize and address the risk to minimize penalties from unexpected incidents, in business operations, systems and process.',
    DisaggregatedIntervention: 'Procurement policies',
    Definition: 'Demand and supply side interventions that aim to spur innovation and growth within sectors. This can include the provision of guidelines for efficient procurement.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Human resources',
    Definition1: 'Interventions related to human resource management policy or practices.',
    DisaggregatedIntervention: 'Dismissal of employees',
    Definition: 'Reform of policies and regulation around the dismissal of employees, including anti-discrimination protections.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Human resources',
    Definition1: 'Interventions related to human resource management policy or practices.',
    DisaggregatedIntervention: 'Employee compensation strategies',
    Definition: 'Employee compensation strategy interventions. These include commission based compensation schemes.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Human resources',
    Definition1: 'Interventions related to human resource management policy or practices.',
    DisaggregatedIntervention: 'Performance-based financial incentives',
    Definition: 'Providing incentives to businesses and/or employees related to job performance.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Human resources',
    Definition1: 'Interventions related to human resource management policy or practices.',
    DisaggregatedIntervention: 'Professional incentives provided to employees',
    Definition: 'Incentives provided to employees regarding professional competencies and development, such as advancement, recognition, or increased autonomy/independence.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Human resources',
    Definition1: 'Interventions related to human resource management policy or practices.',
    DisaggregatedIntervention: 'Recruiting of employee strategies',
    Definition: 'Recruiting strategy interventions to help businesses to locate qualified candidates for employment, such as university engagement, job postings, or talent agencies.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Sales & marketing',
    Definition1: 'Interventions that promote and support sales.',
    DisaggregatedIntervention: 'Access to markets',
    Definition: 'Reduction of financial barriers to market, for example through reducing requirements of license to operate or taxes, and physical barriers to market, for example through transport subsidies or road construction.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Sales & marketing',
    Definition1: 'Interventions that promote and support sales.',
    DisaggregatedIntervention: 'Business marketing practices',
    Definition: 'Programmes that strengthen or promote the marketing/advertising practices of a business.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Sales & marketing',
    Definition1: 'Interventions that promote and support sales.',
    DisaggregatedIntervention: 'Employee sales training',
    Definition: 'Training provided to employees to improve sales skills.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Sales & marketing',
    Definition1: 'Interventions that promote and support sales.',
    DisaggregatedIntervention: 'New market expansion',
    Definition: 'Programmes to grow markets beyond those that currently exist.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Sales & marketing',
    Definition1: 'Interventions that promote and support sales.',
    DisaggregatedIntervention: 'Opening of new stores',
    Definition: 'Business expansion through the opening of new stores.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Supply chains & manufacturing',
    Definition1: 'Interventions that support supply chain and manufacturing.',
    DisaggregatedIntervention: 'Agglomeration policies',
    Definition: 'Policies that encourage/discourage firms and people to locate near one another for economic gains.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Supply chains & manufacturing',
    Definition1: 'Interventions that support supply chain and manufacturing.',
    DisaggregatedIntervention: 'Logistic frameworks for supply chains',
    Definition: 'Programmes to expand or maintain logistical networks.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Supply chains & manufacturing',
    Definition1: 'Interventions that support supply chain and manufacturing.',
    DisaggregatedIntervention: 'Manufacturing processes',
    Definition: 'Alterations to the manufacturing processes in order to improve productivity.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Business operations & management',
    InterventionGroup: 'Supply chains & manufacturing',
    Definition1: 'Interventions that support supply chain and manufacturing.',
    DisaggregatedIntervention: 'Supply chain integration',
    Definition: 'Supply chain integration programmes that aim to integrate various parties involved in a product or service.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Innovation finance',
    Definition1: 'Interventions to make financing available for research and development.',
    DisaggregatedIntervention: 'Credit schemes for R&D',
    Definition: 'Provision of credit opportunities to finance R&D projects.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Innovation finance',
    Definition1: 'Interventions to make financing available for research and development.',
    DisaggregatedIntervention: 'Grants and subsidisation of R&D',
    Definition: 'Provision of grants and subsidies for industrial research and development, this does not include seed capital for SMEs.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Innovation finance',
    Definition1: 'Interventions to make financing available for research and development.',
    DisaggregatedIntervention: 'R&D portfolio management',
    Definition: 'Interventions to affect the management of R&D portfolios, including implementing strategies for diversification and hedging.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Innovation finance',
    Definition1: 'Interventions to make financing available for research and development.',
    DisaggregatedIntervention: 'Seed capital grants',
    Definition: 'Grants to provide the initial investment for the creation of a business, including grants for small and medium-sized enterprises to compete with established businesses and increase competition.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Knowledge sharing & collaboration',
    Definition1: 'Interventions to provide opportunities or instil a custom of knowledge sharing and collaboration between organisations or individuals.',
    DisaggregatedIntervention: 'Networks for innovation',
    Definition: 'Facilitating the development of networks, partnerships and relationships between individuals or organisations for the purposes of information sharing, technology diffusion, network development or creating credible and recognisable associations.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity or the standards of research.',
    DisaggregatedIntervention: 'Informed consent for research',
    Definition: 'Facilitation of informed consent procedures to approve research projects',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity and/or the standards of research.',
    DisaggregatedIntervention: 'Institutional Review Boards (IRBs)',
    Definition: 'Institution of boards with power to modify and approve research projects, with an aim to improve the technical or ethical components of the research.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity or the standards of research.',
    DisaggregatedIntervention: 'Material support for research',
    Definition: 'Provision of material support for research programmes, this can come in the form of samples, tools and equipment, or the facilities themselves.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity or the standards of research.',
    DisaggregatedIntervention: 'Research exchanges and collaborations',
    Definition: 'Collaboration between researchers, educational institutions or other research-based entities for the purposes of scientific research or capacity building.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity or the standards of research.',
    DisaggregatedIntervention: 'Research fellowships',
    Definition: 'Fellowship opportunities provided to students to be able to investigate a field or topic.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity or the standards of research.',
    DisaggregatedIntervention: 'Research funding',
    Definition: 'Funding provided to allow for a greater volume of research or higher quality of research.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity or the standards of research.',
    DisaggregatedIntervention: 'Research labs and facilities',
    Definition: 'Building or refurbishment of science labs and facilities to facilitate research.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity or the standards of research.',
    DisaggregatedIntervention: 'Research policy for industry',
    Definition: 'Changes in government policy and regulation around industry, trade, or service research.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity or the standards of research.',
    DisaggregatedIntervention: 'STEM education programmes',
    Definition: 'Educational programmes, scholarships, training and in-kind donations at all non-tertiary educational levels intended to promote the science, technology, engineering and mathematics (STEM) fields.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Environment for innovation',
    InterventionGroup: 'Research',
    Definition1: 'Interventions to augment the research capacity or the standards of research.',
    DisaggregatedIntervention: 'Technical assistance for research',
    Definition: 'Assistance or training for researchers and innovation, often provided by an international nongovernmental organisation or university from a high-income country.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Employability & skills training',
    Definition1: 'Skills development programmes with an eye to employment.',
    DisaggregatedIntervention: 'Apprenticeship programmes',
    Definition: 'Work experience programmes, often formal, to train a worker to become skilled in a particular trade.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Employability & skills training',
    Definition1: 'Skills development programmes with an eye to employment.',
    DisaggregatedIntervention: 'Counselling for job seekers',
    Definition: 'Counselling session with unemployed or youth job seekers to assist them in searching jobs, provide placement assistance, and advise them on skill development relevant to the job market. These include job fairs.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Employability & skills training',
    Definition1: 'Skills development programmes with an eye to employment.',
    DisaggregatedIntervention: 'Entrepreneurship training',
    Definition: 'Provision of entrepreneurship training focused on how to start up a business from scratch, which can include for example developing a business plan, day-to-day management of small enterprise, including bookkeeping, financial planning, etc.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Employability & skills training',
    Definition1: 'Skills development programmes with an eye to employment.',
    DisaggregatedIntervention: 'Internship programmes',
    Definition: 'Work experience programmes, often informal, to learn about a company, industry, or trade.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Employability & skills training',
    Definition1: 'Skills development programmes with an eye to employment.',
    DisaggregatedIntervention: 'Job placement schemes',
    Definition: 'Work placement programmes that seek to put job-seekers into positions within business or trade. These include internship or apprenticeship programmes.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Employability & skills training',
    Definition1: 'Skills development programmes with an eye to employment.',
    DisaggregatedIntervention: 'Leadership programmes',
    Definition: 'Programmes directed at students to develop leadership skills and ability.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Employability & skills training',
    Definition1: 'Skills development programmes with an eye to employment.',
    DisaggregatedIntervention: 'Other life skills',
    Definition: 'Programmes that train and enhance other life skills.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Employability & skills training',
    Definition1: 'Skills development programmes with an eye to employment.',
    DisaggregatedIntervention: 'Technical and Vocational Education and Training (TVET)',
    Definition: 'Education and training which provides knowledge and skills for employment. TVET uses formal, non-formal, and informal learning.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Employability & skills training',
    Definition1: 'Skills development programmes with an eye to employment.',
    DisaggregatedIntervention: 'Transferable skills training',
    Definition: 'Programmes that train in portable skills that are universal in trade work/employment.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Financial support for human capital',
    Definition1: 'Provision of funding/financing/mechanisms to support human capital growth activities.',
    DisaggregatedIntervention: 'Alternative education funding',
    Definition: 'Interventions to promote any alternative education funding schemes, especially innovative funding mechanisms.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Financial support for human capital',
    Definition1: 'Provision of funding/financing/mechanisms to support human capital growth activities.',
    DisaggregatedIntervention: 'Education institution-level grants',
    Definition: 'Grants to fund development plans in schools, vocational institutions or universities.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Financial support for human capital',
    Definition1: 'Provision of funding/financing/mechanisms to support human capital growth activities.',
    DisaggregatedIntervention: 'Education lending',
    Definition: 'Provision or promotion of educational loans, including programmes to provide subsidized or reduced interest loans to students.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Financial support for human capital',
    Definition1: 'Provision of funding/financing/mechanisms to support human capital growth activities.',
    DisaggregatedIntervention: 'Education savings accounts',
    Definition: 'Prepayment schemes for individuals or families to cover education-related costs.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Financial support for human capital',
    Definition1: 'Provision of funding/financing/mechanisms to support human capital growth activities.',
    DisaggregatedIntervention: 'Endowment management',
    Definition: 'Changes to, or establishing, an endowment for an educational institution with the hope of promoting sustainable financing. This could be for the overall institution, or to support specific staff positions or programmes.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Financial support for human capital',
    Definition1: 'Provision of funding/financing/mechanisms to support human capital growth activities.',
    DisaggregatedIntervention: 'Fee for service in education',
    Definition: 'Cost-recovery through tuition and/or other fees.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Financial support for human capital',
    Definition1: 'Provision of funding/financing/mechanisms to support human capital growth activities.',
    DisaggregatedIntervention: 'Financial aid information for education',
    Definition: 'Provision of information pertaining to financial aid for education, including funding options and programmes available.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Financial support for human capital',
    Definition1: 'Provision of funding/financing/mechanisms to support human capital growth activities.',
    DisaggregatedIntervention: 'Merit-based scholarships',
    Definition: 'Scholarships to attend school contingent on high academic performance: achievement or ability.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Financial support for human capital',
    Definition1: 'Provision of funding/financing/mechanisms to support human capital growth activities.',
    DisaggregatedIntervention: 'Reduce school fees',
    Definition: 'Interventions designed to reduce school fees or other economic costs such as uniforms or materials as a barrier to attendance.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Financial support for human capital',
    Definition1: 'Provision of funding/financing/mechanisms to support human capital growth activities.',
    DisaggregatedIntervention: 'Tuition reimbursement',
    Definition: 'Reimbursement schemes that reimburse students for the cost of education as part of a job-related compensation package.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Professional & student associations',
    Definition1: 'Associations to strengthen skills and network for students and professionals.',
    DisaggregatedIntervention: 'Business networks',
    Definition: 'Establishment or strengthening of networking organisations between business to share knowledge and connect with other business. An example would be a chamber of commerce.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Professional & student associations',
    Definition1: 'Associations to strengthen skills and network for students and professionals.',
    DisaggregatedIntervention: 'Job placement schemes',
    Definition: 'Work placement programmes that seek to put job-seekers into positions within business or trade. These include internship or apprenticeship programmes.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Professional & student associations',
    Definition1: 'Associations to strengthen skills and network for students and professionals.',
    DisaggregatedIntervention: 'Young professionals networks',
    Definition: 'Establishment or strengthening of networking organisations that offer information and opportunities to young professionals.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Human capital development',
    InterventionGroup: 'Professional & student associations',
    Definition1: 'Associations to strengthen skills and network for students and professionals.',
    DisaggregatedIntervention: 'Youth extracurricular programmes',
    Definition: 'Programmes that offer youth with extracurricular activities, such as special interest clubs.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Government policies',
    Definition1: 'Policies enacted by the government affecting industry, trade, and services, excluding labour rights frameworks.',
    DisaggregatedIntervention: 'Active labour market policies for foreigners',
    Definition: 'This policies or programmes aim at incentivising immigration of professionals to activate the high-qualified labour force. This can be done by offering incentives, improving job readiness or by help in finding suitable employment.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Government policies',
    Definition1: 'Policies enacted by the government affecting industry, trade, and services, excluding labour rights frameworks.',
    DisaggregatedIntervention: 'Anti-trust policies',
    Definition: 'Antitrust policies attempt to make companies act in a competitive manner. These include breaking up companies that are monopolies, prohibiting mergers that would increase market power, and finding and fining companies that collude to establish higher prices.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Government policies',
    Definition1: 'Policies enacted by the government affecting industry, trade, and services, excluding labour rights frameworks.',
    DisaggregatedIntervention: 'Business formalisation',
    Definition: 'Formalisation of informal businesses or firms or campaigns to foment the formalisation of informal enterprises.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Government policies',
    Definition1: 'Policies enacted by the government affecting industry, trade, and services, excluding labour rights frameworks.',
    DisaggregatedIntervention: 'Capital controls',
    Definition: 'Policies to restrict or loosen capital controls on inflows and outflows.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Government policies',
    Definition1: 'Policies enacted by the government affecting industry, trade, and services, excluding labour rights frameworks.',
    DisaggregatedIntervention: 'Compliance enforcement in business',
    Definition: 'Use of compliance officers or other mechanisms to ensure that business owners are complying to laws and regulations',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Government policies',
    Definition1: 'Policies enacted by the government affecting industry, trade, and services, excluding labour rights frameworks.',
    DisaggregatedIntervention: 'Environmental regulation',
    Definition: 'Any regulation of pollution or environmental footprint. These include environmental auditing institutions.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Government policies',
    Definition1: 'Policies enacted by the government affecting industry, trade, and services, excluding labour rights frameworks.',
    DisaggregatedIntervention: 'Environmentally-friendly business',
    Definition: 'Incentives for the creation of environmentally-friendly businesses',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Government policies',
    Definition1: 'Policies enacted by the government affecting industry, trade, and services, excluding labour rights frameworks.',
    DisaggregatedIntervention: 'Furlough and employment protection schemes',
    Definition: 'Government schemes to provide employment protections, such as layoff regulation, the provide support for furloughed employees and any other measures to promote job retention of employees on leave.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Government policies',
    Definition1: 'Policies enacted by the government affecting industry, trade, and services, excluding labour rights frameworks.',
    DisaggregatedIntervention: 'Incentives for business creation',
    Definition: 'Interventions to encourage entrepreneurial activities such as supplier development, support for environmental audit, provision of working premises, and others that will facilitate the creation of businesses for new entrepreneurs. This includes interventions that provide a lease of assets to the poor to access income-generating assets.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Government policies',
    Definition1: 'Policies enacted by the government affecting industry, trade, and services, excluding labour rights frameworks.',
    DisaggregatedIntervention: 'Liberalisation policies',
    Definition: 'Lessening of government regulations and restrictions in an economy in exchange for greater participation by private entities, including privatisation.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Government policies',
    Definition1: 'Policies enacted by the government affecting industry, trade, and services, excluding labour rights frameworks.',
    DisaggregatedIntervention: 'Licensing and permitting',
    Definition: 'Policies pertaining to licensing and permitting of businesses, including anti-corruption measures.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Government policies',
    Definition1: 'Policies enacted by the government affecting industry, trade, and services, excluding labour rights frameworks.',
    DisaggregatedIntervention: 'Migrants entrepreneurship',
    Definition: 'Policies that promote the inclusion of migrants and refugees in in local economies, by sharing their knowledge and entrepreneurial spirit, and creating new market opportunities and cross-border networks.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Government policies',
    Definition1: 'Policies enacted by the government affecting industry, trade, and services, excluding labour rights frameworks.',
    DisaggregatedIntervention: 'Privatisation of government owned assets',
    Definition: 'Transferring/selling government owned assets to private enterprise, including government owned shares',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Government policies',
    Definition1: 'Policies enacted by the government affecting industry, trade, and services, excluding labour rights frameworks.',
    DisaggregatedIntervention: 'Production quotas',
    Definition: 'A goal for the production of a good. It is typically set by a government or an organization, and can be applied to an individual worker, firm, industry or country. Quotas can be set high to encourage production, or can be used to restrict production to support a certain price level.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Government policies',
    Definition1: 'Policies enacted by the government affecting industry, trade, and services, excluding labour rights frameworks.',
    DisaggregatedIntervention: 'Research policy for industry',
    Definition: 'Changes in government policy and regulation around industry, trade, or service research.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Government policies',
    Definition1: 'Policies enacted by the government affecting industry, trade, and services, excluding labour rights frameworks.',
    DisaggregatedIntervention: 'Tax compliance and formalisation',
    Definition: 'Increasing tax compliance and encourage formalisation of businesses including tax code simplification.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Labour rights',
    Definition1: 'Legal frameworks, extensions, and advocacy for labour rights issues.',
    DisaggregatedIntervention: 'Collective bargaining',
    Definition: 'Advocacy or extension of the right to unionise, or otherwise form collective bargaining units.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Labour rights',
    Definition1: 'Legal frameworks, extensions, and advocacy for labour rights issues.',
    DisaggregatedIntervention: 'Employment discrimination',
    Definition: 'Prevention of discrimination in hiring or firing decisions based on the employees membership in a protected group.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Labour rights',
    Definition1: 'Legal frameworks, extensions, and advocacy for labour rights issues.',
    DisaggregatedIntervention: 'Forced and child labour',
    Definition: 'Protection against the use of forced labour and child labour.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Labour rights',
    Definition1: 'Legal frameworks, extensions, and advocacy for labour rights issues.',
    DisaggregatedIntervention: 'Freedom of association',
    Definition: 'Advocacy or extension of the right to freely associate/leave any group at any time.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Labour rights',
    Definition1: 'Legal frameworks, extensions, and advocacy for labour rights issues.',
    DisaggregatedIntervention: 'Layoff protection',
    Definition: 'Increase of barriers to prevent layoff or firing.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Labour rights',
    Definition1: 'Legal frameworks, extensions, and advocacy for labour rights issues.',
    DisaggregatedIntervention: 'Wage controls',
    Definition: 'Institution or alteration of wage restrictions, i.e. minimum wage.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Labour rights',
    Definition1: 'Legal frameworks, extensions, and advocacy for labour rights issues.',
    DisaggregatedIntervention: 'Working conditions',
    Definition: 'Conditions, including safety, of labour.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Labour rights',
    Definition1: 'Legal frameworks, extensions, and advocacy for labour rights issues.',
    DisaggregatedIntervention: 'Workweek regulation',
    Definition: 'Regulation that governs the amount of time workers may work, including the number and frequency of holidays and enforced breaks.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Safety regulations',
    Definition1: 'Regulations for consumer products and industrial processes to ensure safety.',
    DisaggregatedIntervention: 'Food safety certification',
    Definition: 'Consumer certification of food safety.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Safety regulations',
    Definition1: 'Regulations for consumer products and industrial processes to ensure safety.',
    DisaggregatedIntervention: 'Product safety regulation',
    Definition: 'Regulation for product safety, including warnings.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Safety regulations',
    Definition1: 'Regulations for consumer products and industrial processes to ensure safety.',
    DisaggregatedIntervention: 'Workplace health and safety regulations',
    Definition: 'Introduction of new requirements to respect health and safety rules to minimize the number of accidents and to provide safe working spaces.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Taxes & tariffs',
    Definition1: 'Tax reform programmes to affect trade and investment.',
    DisaggregatedIntervention: 'Excise tax',
    Definition: 'Any duty on manufactured goods that is levied at the moment of manufacture rather than at sale.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Taxes & tariffs',
    Definition1: 'Tax reform programmes to affect trade and investment.',
    DisaggregatedIntervention: 'Export levies',
    Definition: 'Tax that a country imposes on its exports, which makes them more expensive. Export levies may encourage domestic consumption of domestically produced goods.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Taxes & tariffs',
    Definition1: 'Tax reform programmes to affect trade and investment.',
    DisaggregatedIntervention: 'Government tax policy',
    Definition: 'Overall government tax policy such as tax rates and tax collection, including tax holidays.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Taxes & tariffs',
    Definition1: 'Tax reform programmes to affect trade and investment.',
    DisaggregatedIntervention: 'Import regulations',
    Definition: 'Standards and regulation reforms for the importation of goods or services.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Taxes & tariffs',
    Definition1: 'Tax reform programmes to affect trade and investment.',
    DisaggregatedIntervention: 'Import tariffs',
    Definition: 'Tax that a country imposes on its imports. A duty exists to make an import more expensive and to thereby encourage people to buy goods produced in their own country. Proponents of their use argue that duties discourage outsourcing of jobs to other countries and make the country more self-sufficient',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Taxes & tariffs',
    Definition1: 'Tax reform programmes to affect trade and investment.',
    DisaggregatedIntervention: 'Income tax',
    Definition: 'Tax imposed on individuals or entities that varies with respective income or profits.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Taxes & tariffs',
    Definition1: 'Tax reform programmes to affect trade and investment.',
    DisaggregatedIntervention: 'Property taxes',
    Definition: 'Regulation of tax imposed on individuals or entities based on the value of property or wealth held.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Taxes & tariffs',
    Definition1: 'Tax reform programmes to affect trade and investment.',
    DisaggregatedIntervention: 'Tax code reform',
    Definition: 'Interventions that look to simplify the tax code, often to reduce the burden of tax reporting.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Legal and regulatory environment',
    InterventionGroup: 'Taxes & tariffs',
    Definition1: 'Tax reform programmes to affect trade and investment.',
    DisaggregatedIntervention: 'Trade and environment',
    Definition: 'Regulation of taxes to incentivise green and blue economies',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Services',
    InterventionGroup: 'Professional services',
    Definition1: 'Provision of professional services for individuals and firms.',
    DisaggregatedIntervention: 'Accounting and bookkeeping',
    Definition: 'Providing accounting and/or bookkeeping services or training.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Services',
    InterventionGroup: 'Professional services',
    Definition1: 'Provision of professional services for individuals and firms.',
    DisaggregatedIntervention: 'Legal advice',
    Definition: 'Provision of legal advice in finance and business management.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Services',
    InterventionGroup: 'Professional services',
    Definition1: 'Provision of professional services for individuals and firms.',
    DisaggregatedIntervention: 'Mergers and acquisitions',
    Definition: 'Providing advisory services for transactions in which the ownership of companies, other business organizations, or their operating units are transferred or consolidated with other entities.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Services',
    InterventionGroup: 'Tourism',
    Definition1: 'Programmes to support the local tourism sector and generally promote tourism.',
    DisaggregatedIntervention: 'Advertisement campaigns for tourism',
    Definition: 'Advertisement campaigns for the promotion of tourism to a specific area/country.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Services',
    InterventionGroup: 'Tourism',
    Definition1: 'Programmes to support the local tourism sector and generally promote tourism.',
    DisaggregatedIntervention: 'Immigration control for tourism',
    Definition: 'Visa regulation for the purpose of tourism.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Services',
    InterventionGroup: 'Tourism',
    Definition1: 'Programmes to support the local tourism sector and generally promote tourism.',
    DisaggregatedIntervention: 'Public concessions in tourism',
    Definition: 'Contracts between governments and private provides of tourism services, such as those offered at national parks.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Services',
    InterventionGroup: 'Tourism',
    Definition1: 'Programmes to support the local tourism sector and generally promote tourism.',
    DisaggregatedIntervention: 'Support for tourism sector',
    Definition: 'Support for the tourism sector including investment, infrastructure improvements, environmental policy, heritage policy, sanitation, support for sector workers etc.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Trade',
    InterventionGroup: 'Foreign direct investment',
    Definition1: 'Interventions to promote foreign direct investment (FDI).',
    DisaggregatedIntervention: 'Bonded warehouses',
    Definition: 'Building or other secured area in which dutiable goods may be stored, manipulated, or undergo manufacturing operations without payment of duty. It may be managed by the state or by private enterprise. In the latter case a customs bond must be posted with the government.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Trade',
    InterventionGroup: 'Foreign direct investment',
    Definition1: 'Interventions to promote foreign direct investment (FDI).',
    DisaggregatedIntervention: 'Derogation of regulation',
    Definition: 'An exemption from or relaxation of a rule or law for a given firm, to encourage investment.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Trade',
    InterventionGroup: 'Foreign direct investment',
    Definition1: 'Interventions to promote foreign direct investment (FDI).',
    DisaggregatedIntervention: 'Infrastructure subsidies',
    Definition: 'Providing firms with subsidies to improve or utilize infrastructure in business operations and logistics.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Trade',
    InterventionGroup: 'Foreign direct investment',
    Definition1: 'Interventions to promote foreign direct investment (FDI).',
    DisaggregatedIntervention: 'Investment guarantees',
    Definition: 'Programmes to protect investments from non-commercial risk.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Trade',
    InterventionGroup: 'Foreign direct investment',
    Definition1: 'Interventions to promote foreign direct investment (FDI).',
    DisaggregatedIntervention: 'Land subsidies',
    Definition: 'Providing land grants or subsidies to investors.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Trade',
    InterventionGroup: 'Foreign direct investment',
    Definition1: 'Interventions to promote foreign direct investment (FDI).',
    DisaggregatedIntervention: 'Special economic zones',
    Definition: 'Designation of specific areas with unique regulation to promote investment.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Trade',
    InterventionGroup: 'Foreign direct investment',
    Definition1: 'Interventions to promote foreign direct investment (FDI).',
    DisaggregatedIntervention: 'Tax concessions',
    Definition: 'Tax breaks offered to firms and individuals to encourage investment. This can include lowered taxes, tax holidays, or preferential depreciation schemes.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Trade',
    InterventionGroup: 'Logistics',
    Definition1: 'Services that support the logistics of international and domestic trade.',
    DisaggregatedIntervention: 'ICT and e-commerce',
    Definition: 'Interventions that provide training on building digital capacities such as e-commerce or virtual market places to support SMEs',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Trade',
    InterventionGroup: 'Logistics',
    Definition1: 'Services that support the logistics of international and domestic trade.',
    DisaggregatedIntervention: 'Trade facilitation services',
    Definition: 'Services offered to firms in order to promote and facilitate the import or export of a commodity.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Trade',
    InterventionGroup: 'Trade controls & protection',
    Definition1: 'Government controls imposed on international and domestic trade.',
    DisaggregatedIntervention: 'Anti-dumping policies',
    Definition: 'Policies that prohibit or otherwise restrict the dumping of products to protect domestic markets.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Trade',
    InterventionGroup: 'Trade controls & protection',
    Definition1: 'Government controls imposed on international and domestic trade.',
    DisaggregatedIntervention: 'Export quotas',
    Definition: 'A restriction imposed by a government on the amount or number of goods or services that may be exported within a given period, usually with the intent of keeping prices of those goods or services low for domestic users.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Trade',
    InterventionGroup: 'Trade controls & protection',
    Definition1: 'Government controls imposed on international and domestic trade.',
    DisaggregatedIntervention: 'Export/production subsidies',
    Definition: 'An incentive provided by the government to subsidise a specific sector based on international competitiveness. These include tax breaks provided to domestic producers.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Trade',
    InterventionGroup: 'Trade partnerships',
    Definition1: 'Government partnerships in the form of trade deals and general trade policies.',
    DisaggregatedIntervention: 'Cartel membership',
    Definition: 'Membership in international commodity cartels to manage the demand and supply of commodities.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Trade',
    InterventionGroup: 'Trade partnerships',
    Definition1: 'Government partnerships in the form of trade deals and general trade policies.',
    DisaggregatedIntervention: 'Free Trade Agreements (FTAs)',
    Definition: 'Signing of a new or modified FTA with either a bilateral partner or a trade bloc.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Trade',
    InterventionGroup: 'Trade partnerships',
    Definition1: 'Government partnerships in the form of trade deals and general trade policies.',
    DisaggregatedIntervention: 'Reciprocity in trade',
    Definition: 'Policies that reciprocate favours or restrictions imposed by other countries pertaining to those countries.',
  },
  {
    InterventionSector: 'Industry, Trade, & Services',
    BroadGroup: 'Trade',
    InterventionGroup: 'Trade partnerships',
    Definition1: 'Government partnerships in the form of trade deals and general trade policies.',
    DisaggregatedIntervention: 'Trade blocs and common markets',
    Definition: 'Partnerships between countries to provide special trade conditions between the countries, this can often include unifying trade regulations and standards.',
  },
  {
    InterventionSector: 'Information & Communications Technologies',
    BroadGroup: 'Information & Communications Technologies',
    InterventionGroup: 'ICT infrastructure',
    Definition1: 'This code refers to procurement or deployment of ICT infrastructure in the project, such as (a) deployment of IT connectivity networks to public institutions e.g. to schools, hospitals, rural communities or government offices, (b) deployment of national, regional and international connectivity e.g. submarine cable landing stations, middle mile and last mile technologies and (c) smart technologies e.g. for energy smart grids.',
    DisaggregatedIntervention: 'Energy smart grids',
    Definition: 'Grids that manage the energy generation of intermittent sources of electricity using modern technologies.',
  },
  {
    InterventionSector: 'Information & Communications Technologies',
    BroadGroup: 'Information & Communications Technologies',
    InterventionGroup: 'ICT infrastructure',
    Definition1: 'This code refers to procurement or deployment of ICT infrastructure in the project, such as (a) deployment of IT connectivity networks to public institutions e.g. to schools, hospitals, rural communities or government offices, (b) deployment of national, regional and international connectivity e.g. submarine cable landing stations, middle mile and last mile technologies and (c) smart technologies e.g. for energy smart grids.',
    DisaggregatedIntervention: 'ICT infrastructure financing',
    Definition: 'Financing of ICT infrastructure projects.',
  },
  {
    InterventionSector: 'Information & Communications Technologies',
    BroadGroup: 'Information & Communications Technologies',
    InterventionGroup: 'ICT infrastructure',
    Definition1: 'This code refers to procurement or deployment of ICT infrastructure in the project, such as (a) deployment of IT connectivity networks to public institutions e.g. to schools, hospitals, rural communities or government offices, (b) deployment of national, regional and international connectivity e.g. submarine cable landing stations, middle mile and last mile technologies and (c) smart technologies e.g. for energy smart grids.',
    DisaggregatedIntervention: 'LAN/WAN networks',
    Definition: 'LAN/WAN networks among government agencies and public institutions (e.g. schools, hospitals and rural communities).',
  },
  {
    InterventionSector: 'Information & Communications Technologies',
    BroadGroup: 'Information & Communications Technologies',
    InterventionGroup: 'ICT infrastructure',
    Definition1: 'This code refers to procurement or deployment of ICT infrastructure in the project, such as (a) deployment of IT connectivity networks to public institutions e.g. to schools, hospitals, rural communities or government offices, (b) deployment of national, regional and international connectivity e.g. submarine cable landing stations, middle mile and last mile technologies and (c) smart technologies e.g. for energy smart grids.',
    DisaggregatedIntervention: 'Network deployment',
    Definition: 'Deployment of broadband networks through regional and national backbone networks, submarine cables, shared infrastructure and alternative networks.',
  },
  {
    InterventionSector: 'Information & Communications Technologies',
    BroadGroup: 'Information & Communications Technologies',
    InterventionGroup: 'ICT infrastructure',
    Definition1: 'This code refers to procurement or deployment of ICT infrastructure in the project, such as (a) deployment of IT connectivity networks to public institutions e.g. to schools, hospitals, rural communities or government offices, (b) deployment of national, regional and international connectivity e.g. submarine cable landing stations, middle mile and last mile technologies and (c) smart technologies e.g. for energy smart grids.',
    DisaggregatedIntervention: 'Smart transport',
    Definition: 'Integrated application of modern technologies and management strategies in transportation systems.',
  },
  {
    InterventionSector: 'Information & Communications Technologies',
    BroadGroup: 'Information & Communications Technologies',
    InterventionGroup: 'ICT infrastructure',
    Definition1: 'This code refers to procurement or deployment of ICT infrastructure in the project, such as (a) deployment of IT connectivity networks to public institutions e.g. to schools, hospitals, rural communities or government offices, (b) deployment of national, regional and international connectivity e.g. submarine cable landing stations, middle mile and last mile technologies and (c) smart technologies e.g. for energy smart grids.',
    DisaggregatedIntervention: 'Technical assistance - infrastructure',
    Definition: 'Technical assistance on telecom and ICT policy/regulation/legislation/law, sector reform, Public Private Partnership, creating enabling environment, policy and regulatory capacity building, National Research and Education Networks (NREN).',
  },
  {
    InterventionSector: 'Information & Communications Technologies',
    BroadGroup: 'Information & Communications Technologies',
    InterventionGroup: 'ICT services',
    Definition1: 'This code refers to procurement or deployment of ICT Services and Applications provided to government agencies or to end beneficiaries such as businesses, citizens, government employees, students and health care workers. These ICT-enabled services and applications aim to improve the efficiency, quality, accountability and transparency of government delivery of services.',
    DisaggregatedIntervention: 'ICT capacity building',
    Definition: 'ICT capacity building such as Computer literacy training and ICT skill training, including in mobile technology.',
  },
  {
    InterventionSector: 'Information & Communications Technologies',
    BroadGroup: 'Information & Communications Technologies',
    InterventionGroup: 'ICT services',
    Definition1: 'This code refers to procurement or deployment of ICT Services and Applications provided to government agencies or to end beneficiaries such as businesses, citizens, government employees, students and health care workers. These ICT-enabled services and applications aim to improve the efficiency, quality, accountability and transparency of government delivery of services.',
    DisaggregatedIntervention: 'ICT services financing',
    Definition: 'Financing of ICT Services and applications provided to government agencies or to end beneficiaries such as businesses, citizens, government employees, students and health care workers.',
  },
  {
    InterventionSector: 'Information & Communications Technologies',
    BroadGroup: 'Information & Communications Technologies',
    InterventionGroup: 'ICT services',
    Definition1: 'This code refers to procurement or deployment of ICT Services and Applications provided to government agencies or to end beneficiaries such as businesses, citizens, government employees, students and health care workers. These ICT-enabled services and applications aim to improve the efficiency, quality, accountability and transparency of government delivery of services.',
    DisaggregatedIntervention: 'ICT systems improvement',
    Definition: 'System automation and modernization, system integration, information management systems (IMS), digital platforms, cloud computing, data centres, cyber security, e-government applications, mobile applications, portals, e-service delivery, digital content development, digitization, content management systems, ITS, GIS, sector specific applications, digital ID, Open Data, etc. For mHealth interventions see the health taxonomy, for technology in education see education taxonomy, for mobile banking see finance taxonomy.',
  },
  {
    InterventionSector: 'Information & Communications Technologies',
    BroadGroup: 'Information & Communications Technologies',
    InterventionGroup: 'ICT services',
    Definition1: 'This code refers to procurement or deployment of ICT Services and Applications provided to government agencies or to end beneficiaries such as businesses, citizens, government employees, students and health care workers. These ICT-enabled services and applications aim to improve the efficiency, quality, accountability and transparency of government delivery of services.',
    DisaggregatedIntervention: 'Technical assistance - services',
    Definition: 'Technical assistance on ICT services including feasibility studies and strategies.',
  },
  {
    InterventionSector: 'Information & Communications Technologies',
    BroadGroup: 'Information & Communications Technologies',
    InterventionGroup: 'Public administration',
    Definition1: 'This code refers to supporting the public administration of the ICT sector, primarily through assistance to the central agency in charge of ICT programs and policies.',
    DisaggregatedIntervention: 'ICT analysis',
    Definition: 'General analytical work on ICT (e.g. ICT taxation, Global ICT sector assessment/trend monitoring).',
  },
  {
    InterventionSector: 'Information & Communications Technologies',
    BroadGroup: 'Information & Communications Technologies',
    InterventionGroup: 'Public administration',
    Definition1: 'This code refers to supporting the public administration of the ICT sector, primarily through assistance to the central agency in charge of ICT programs and policies.',
    DisaggregatedIntervention: 'Institutional Capacity building for ICT',
    Definition: 'Institutional capacity building, skill development related activities specifically targeted to the Ministry and government agencies in charge of ICT.',
  },
  {
    InterventionSector: 'Information & Communications Technologies',
    BroadGroup: 'Information & Communications Technologies',
    InterventionGroup: 'Public administration',
    Definition1: 'This code refers to supporting the public administration of the ICT sector, primarily through assistance to the central agency in charge of ICT programs and policies.',
    DisaggregatedIntervention: 'Regulation of ICT',
    Definition: 'Reform of ICT sector strategies and policies.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Administrative reform',
    Definition1: 'Reforms to administrative practices of public sector institutions.',
    DisaggregatedIntervention: 'Decentralisation',
    Definition: 'Decentralisation interventions shift existing power over spending and decision-making to sub-national levels.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Administrative reform',
    Definition1: 'Reforms to administrative practices of public sector institutions.',
    DisaggregatedIntervention: 'e-governance',
    Definition: 'Application of ICT for delivering government services, exchange of information, communication transactions, integration of various stand-alone systems between government to citizen (G2C), government-to-business (G2B), government-to-government (G2G), government-to-employees (G2E) as well as back-office processes and interactions within the entire government framework.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Administrative reform',
    Definition1: 'Reforms to administrative practices of public sector institutions.',
    DisaggregatedIntervention: 'Government budget policy',
    Definition: 'Government budget policies such as matched expenditures, surplus management and reallocation.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Administrative reform',
    Definition1: 'Reforms to administrative practices of public sector institutions.',
    DisaggregatedIntervention: 'Government debt policy',
    Definition: 'Government debt policies, including debt structures.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Administrative reform',
    Definition1: 'Reforms to administrative practices of public sector institutions.',
    DisaggregatedIntervention: 'Government expenditure',
    Definition: 'Reform of government spending or expenditure policies: all government consumption, investment, and transfer payments.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Administrative reform',
    Definition1: 'Reforms to administrative practices of public sector institutions.',
    DisaggregatedIntervention: 'Inflation control',
    Definition: 'Policies to control inflation rates such as, inflation targeting and wage and price controls.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Administrative reform',
    Definition1: 'Reforms to administrative practices of public sector institutions.',
    DisaggregatedIntervention: 'Interest rates',
    Definition: 'Government interventions in capital markets to affect interest rates. These are often done by central banks.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Administrative reform',
    Definition1: 'Reforms to administrative practices of public sector institutions.',
    DisaggregatedIntervention: 'Internal monitoring and oversight',
    Definition: 'Introduction or strengthening of internal monitoring and oversight in public institutions. This also includes monitoring & evaluation systems.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Community-based natural resource management for conflict resolution',
    Definition: 'These interventions aim to strengthen community-level management of natural resources, including water, rangelands and forests. This may include participatory management of irrigation systems, water user associations or rangeland/forestry user associations. These interventions aim to decentralise control over resource management and benefits to a local level. Interventions to support community-based natural resource management groups often comprise elements of conflict resolution training.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Community-driven Development & Reconstruction (CDD & CDR)',
    Definition: 'Development initiative that provides control of the development process, resources and decision making authority directly to groups in the community. This includes both community-driven development (CDD) and community driven reconstruction (CDR).',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Education in emergencies and academic catch-up',
    Definition: 'These programmes aim to address the school years lost by children due to conflict and displacement (Amal for Education, 2019). They are particularly important when attempting to reintegrate refugee and child soldier populations. Typically they will cover all academic areas to ensure the children have broad capacity. This also includes \'education in emergency\' interventions that aim to create interim opportunities to ensure children have access to education, such as through setting up temporary education in camps for displaced populations.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Housing reconstruction',
    Definition: 'This should be separated out from the infrastructure intervention group, which is more about the establishment and management of public infrastructure. This relates specifically to the reconstruction of (private) housing following conflict or natural disasters.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Infrastructure development and reconstruction',
    Definition: 'This covers all interventions that build, or re-build, infrastructure outside of CDD&R processes, with the aim of stimulating the economy through both short-term employment and ensuring the presence of infrastructure necessary for economic development.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Labour-intensive public works',
    Definition: 'This should also be separated out from the infrastructure interventions as a specific type of intervention, as it is often used as a way of both ensuring critical infrastructure is rebuilt or maintained, and as a way of providing access to income for vulnerable households.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Life skills and employment training for conflict affected population',
    Definition: 'This category provides training programmes primarily for jobs and livelihoods for conflict affected populations, but can also include programmes such as music instruction for youth, which may have less obvious monetary reward but aims to provide skills and an alternative to violence. This category does not include the development of peace skills (such as conflict management).',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Market development to support economic development',
    Definition: 'This includes interventions that aim to develop the wider economic market and provide increased opportunities at the macro-level of the economy. This includes work on trade, foreign and national investment, and business, cooperative or association formation and oversight.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'National natural resource benefits sharing to reduce conflict',
    Definition: 'This includes interventions to shape the sharing of natural resource benefits, particularly in the extractives sector, such as efforts to increase the provision of benefits to local communities or reduce risks of violent conflicts over control of resources',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Social funds',
    Definition: 'These interventions encourage participatory planning and the inclusion of local constituents in identifying and addressing local needs. This can include social funds based on community decision-making or collaborative projects with the local government. It does not include the establishment of community committees to help oversee the development or reconstruction efforts, which are captured under CDD. This also does not cover social safety nets or the provision of funds for community projects where expenditure decisions are not controlled by communities.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Transboundary water sharing',
    Definition: 'This includes support for (re)negotiation of transboundary water agreements (e.g. treaties) and management to improve the equity and sustainability of water use.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Economic foundations & livelihoods',
    Definition1: 'This category of interventions aims to address some of the underlying economic drivers of instability and conflict. They usually aim to generate employment, improve access to and resilience of livelihoods, including in the context of climate change, and promote the engagement of all citizens (especially minority groups) in the economy.',
    DisaggregatedIntervention: 'Urban design for prevention of violence',
    Definition: 'Interventions that improve the safety of public services such as transport.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Human resources & capital development',
    Definition1: 'Interventions related to human resource management policy or practices in the public sector.',
    DisaggregatedIntervention: 'Counselling for job seekers',
    Definition: 'Counselling session with unemployed or youth job seekers to assist them in searching jobs, provide placement assistance, and advise them on skill development relevant to the job market. These include job fairs.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Human resources & capital development',
    Definition1: 'Interventions related to human resource management policy or practices in the public sector.',
    DisaggregatedIntervention: 'Dismissal of employees',
    Definition: 'Reform of policies and regulation around the dismissal of employees, including anti-discrimination protections.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Human resources & capital development',
    Definition1: 'Interventions related to human resource management policy or practices in the public sector.',
    DisaggregatedIntervention: 'Employee compensation strategies',
    Definition: 'Employee compensation strategy interventions. These include commission based compensation schemes.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Human resources & capital development',
    Definition1: 'Interventions related to human resource management policy or practices in the public sector.',
    DisaggregatedIntervention: 'Job placement schemes',
    Definition: 'Work placement programmes that seek to put job-seekers into positions within business or trade. These include internship or apprenticeship programmes.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Human resources & capital development',
    Definition1: 'Interventions related to human resource management policy or practices in the public sector.',
    DisaggregatedIntervention: 'Leadership programmes',
    Definition: 'Programmes directed at students to develop leadership skills and ability.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Human resources & capital development',
    Definition1: 'Interventions related to human resource management policy or practices in the public sector.',
    DisaggregatedIntervention: 'Other life skills',
    Definition: 'Programmes that train and enhance other life skills.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Human resources & capital development',
    Definition1: 'Interventions related to human resource management policy or practices in the public sector.',
    DisaggregatedIntervention: 'Pay for performance',
    Definition: 'Contract schemes for private and public sector employees, especially teachers and healthcare professionals, that provide incentives based on performance metrics.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Human resources & capital development',
    Definition1: 'Interventions related to human resource management policy or practices in the public sector.',
    DisaggregatedIntervention: 'Professional incentives provided to employees',
    Definition: 'Incentives provided to employees regarding professional competencies and development, such as advancement, recognition, or increased autonomy/independence.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Human resources & capital development',
    Definition1: 'Interventions related to human resource management policy or practices in the public sector.',
    DisaggregatedIntervention: 'Public service adaptability',
    Definition: 'Public service officers training on how to improve interactions and responses to the constituency.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Human resources & capital development',
    Definition1: 'Interventions related to human resource management policy or practices in the public sector.',
    DisaggregatedIntervention: 'Recruiting of employee strategies',
    Definition: 'Recruiting strategy interventions to help businesses to locate qualified candidates for employment, such as university engagement, job postings, or talent agencies.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Human resources & capital development',
    Definition1: 'Interventions related to human resource management policy or practices in the public sector.',
    DisaggregatedIntervention: 'Technical and Vocational Education and Training (TVET)',
    Definition: 'Education and training which provides knowledge and skills for employment. TVET uses formal, non-formal, and informal learning.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Human resources & capital development',
    Definition1: 'Interventions related to human resource management policy or practices in the public sector.',
    DisaggregatedIntervention: 'Transferable skills training',
    Definition: 'Programmes that train in portable skills that are universal in trade work/employment.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Procurement',
    Definition1: 'Interventions relating to public procurement practices.',
    DisaggregatedIntervention: 'Contract bidding processes',
    Definition: 'Bidding processes for government contracts.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Procurement',
    Definition1: 'Interventions relating to public procurement practices.',
    DisaggregatedIntervention: 'E-procurement',
    Definition: 'Purchase and sale of supplies, work, and services through the internet.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Procurement',
    Definition1: 'Interventions relating to public procurement practices.',
    DisaggregatedIntervention: 'Service contracts for procurement',
    Definition: 'Contracts between the government and service providers. This may include pre-approved rosters, undefined contract terms, or requisitioning.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Public service delivery',
    Definition1: 'General public service delivery strategies.',
    DisaggregatedIntervention: 'Simplified service delivery',
    Definition: 'These are \'one stop shops\' where governments provide access to a number of different administrative services, such as identity cards, marriage registration, licenses, etc. They enable better access to services by decreasing the time required to access a variety of basic services. Also called \'service centres\' or \'one stop centres\'.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Public service delivery',
    Definition1: 'General public service delivery strategies.',
    DisaggregatedIntervention: 'Standardised service delivery',
    Definition: 'These are interventions to standardise the quality or menu of services available across communities. This should be cross-listed under the accountability group as well.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Public-private partnerships',
    Definition1: 'Partnerships between the public and private sectors to provide services or economic capacity.',
    DisaggregatedIntervention: 'Civil works and service contracts',
    Definition: 'Public contracts for services/works to be completed by private enterprise; e.g. infrastructure construction/maintenance, etc.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Public-private partnerships',
    Definition1: 'Partnerships between the public and private sectors to provide services or economic capacity.',
    DisaggregatedIntervention: 'Concessions',
    Definition: 'Allowing private enterprise to operate on public land or with publically owned assets.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Public-private partnerships',
    Definition1: 'Partnerships between the public and private sectors to provide services or economic capacity.',
    DisaggregatedIntervention: 'Privatisation of government owned assets',
    Definition: 'Transferring/selling government owned assets to private enterprise, including government owned shares',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Institutions & service reform',
    InterventionGroup: 'Public-private partnerships',
    Definition1: 'Partnerships between the public and private sectors to provide services or economic capacity.',
    DisaggregatedIntervention: 'Public-private joint ventures',
    Definition: 'A commercial enterprise undertaken jointly by two or more parties which otherwise retain their distinct identities, where one of these entities is public.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Anti-corruption',
    Definition1: 'Intervention undertaken to combat or undermine corruption.',
    DisaggregatedIntervention: 'Anti-corruption in bidding processes for government contracts',
    Definition: 'Measures taken to ensure fair bidding processes for government contracts such as blind bidding.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Anti-corruption',
    Definition1: 'Intervention undertaken to combat or undermine corruption.',
    DisaggregatedIntervention: 'Audit of service providers',
    Definition: 'Independent audits of public sector services, inspecting levels of spending, effectiveness and cost efficiency. This includes independent scrutiny of public sector auditors.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Anti-corruption',
    Definition1: 'Intervention undertaken to combat or undermine corruption.',
    DisaggregatedIntervention: 'Community observers',
    Definition: 'Members of the community charged with observing legal processes and conditions.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Anti-corruption',
    Definition1: 'Intervention undertaken to combat or undermine corruption.',
    DisaggregatedIntervention: 'Community scorecards',
    Definition: 'Community Score Card (CSC) is a two-way and ongoing participatory tool for assessment, planning, monitoring and evaluation of services. It is easy to use and can be adapted into any sector where there is a service delivery scenario. The Community Score Card brings together the demand side (“service user”) and the supply side (“service provider”) of a particular service or program to jointly analyse issues underlying service delivery problems and find a common and shared way of addressing those issues.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Anti-corruption',
    Definition1: 'Intervention undertaken to combat or undermine corruption.',
    DisaggregatedIntervention: 'Creation of anti-corruption agency',
    Definition: 'Establishment of an anti-corruption official or office to independently investigate incidences of corruption or misuse of public resources.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Anti-corruption',
    Definition1: 'Intervention undertaken to combat or undermine corruption.',
    DisaggregatedIntervention: 'Decentralisation',
    Definition: 'Decentralisation interventions shift existing power over spending and decision-making to sub-national levels.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Anti-corruption',
    Definition1: 'Intervention undertaken to combat or undermine corruption.',
    DisaggregatedIntervention: 'Financial incentives to reduce corruption',
    Definition: 'Providing financial incentives to not engage in corrupt behaviour or to report corruption when observed, including salary raises or other benefits.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Anti-corruption',
    Definition1: 'Intervention undertaken to combat or undermine corruption.',
    DisaggregatedIntervention: 'Increasing enforcement of anti-corruption policies',
    Definition: 'Crackdowns on incidences of corruption, including imposition of fines or penalties.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Anti-corruption',
    Definition1: 'Intervention undertaken to combat or undermine corruption.',
    DisaggregatedIntervention: 'Information/education campaign to reduce corruption',
    Definition: 'Providing information on the impacts of corruption, identifying incidences of corruption, etc.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Anti-corruption',
    Definition1: 'Intervention undertaken to combat or undermine corruption.',
    DisaggregatedIntervention: 'Internal monitoring and oversight',
    Definition: 'Introduction or strengthening of internal monitoring and oversight in public institutions. This also includes monitoring & evaluation systems.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Anti-corruption',
    Definition1: 'Intervention undertaken to combat or undermine corruption.',
    DisaggregatedIntervention: 'Monitoring committees',
    Definition: 'Committees established and/or strengthened to properly monitor legal processes and conditions, effectively watch-dog groups.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Anti-corruption',
    Definition1: 'Intervention undertaken to combat or undermine corruption.',
    DisaggregatedIntervention: 'Strengthening of ombudsmen capacity',
    Definition: 'Provision of additional resources or human capacity support to strengthen ombudsmen',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Anti-corruption',
    Definition1: 'Intervention undertaken to combat or undermine corruption.',
    DisaggregatedIntervention: 'Whistleblowing protections',
    Definition: 'Legal protections in place to protect potential whistle-blowers from retribution.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Democratic political processes',
    Definition1: 'Interventions to increase inclusivity and transparency of political processes.',
    DisaggregatedIntervention: 'Civic engagement initiatives',
    Definition: 'These are interventions that aim to empower individuals, often marginalised groups or youth, by providing them with formal and/or informal opportunities to make their voices heard through engaging in politics, civilian participation, and oversight of public institutions and other civil society activities.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Democratic political processes',
    Definition1: 'Interventions to increase inclusivity and transparency of political processes.',
    DisaggregatedIntervention: 'Community-driven Development & Reconstruction (CDD & CDR)',
    Definition: 'Development initiative that provides control of the development process, resources and decision making authority directly to groups in the community.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Democratic political processes',
    Definition1: 'Interventions to increase inclusivity and transparency of political processes.',
    DisaggregatedIntervention: 'Elections support to ensure fair elections',
    Definition: 'Trusted elections help to establish the legitimacy of the government, and election support interventions typically work with both international actors (who often monitor elections) and local civil society organisations (and NGOs) to ensure that electoral law is followed, eligible citizens are able to vote freely, and election results are more trustworthy. Elections support also comprises interventions to increase access to information about election processes and candidates.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Democratic political processes',
    Definition1: 'Interventions to increase inclusivity and transparency of political processes.',
    DisaggregatedIntervention: 'Electoral systems reform',
    Definition: 'Introducing new terms and restrictions, changes to primaries, changes to vote counting, etc.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Democratic political processes',
    Definition1: 'Interventions to increase inclusivity and transparency of political processes.',
    DisaggregatedIntervention: 'e-voting',
    Definition: 'Introduction of new voting technologies designed to increase turnout and reduce malfeasance.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Democratic political processes',
    Definition1: 'Interventions to increase inclusivity and transparency of political processes.',
    DisaggregatedIntervention: 'Information dissemination - political processes',
    Definition: 'Interventions increasing access to media in general or providing information related to elections and political processes. Typically information on voting rights, candidate qualifications, voting records or incumbent performance, or that contain anti-corruption, anti-violence, anti-vote buying or public policy messages.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Democratic political processes',
    Definition1: 'Interventions to increase inclusivity and transparency of political processes.',
    DisaggregatedIntervention: 'New levels of political administration',
    Definition: 'Creation of a new administrative level - usually a more local one. This is distinct from decentralisation, which pushes resource and decision-making control further down an existing chain.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Democratic political processes',
    Definition1: 'Interventions to increase inclusivity and transparency of political processes.',
    DisaggregatedIntervention: 'Performance measurement of elected officials',
    Definition: 'Evaluations of the performance of elected officials, across all levels of government.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Democratic political processes',
    Definition1: 'Interventions to increase inclusivity and transparency of political processes.',
    DisaggregatedIntervention: 'Representation of women & minorities',
    Definition: 'Introduction of quotas, reserved seats or similar within political institutions - such as parliament, state legislatures, and village councils - for women, ethnic minorities, and castes.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Democratic political processes',
    Definition1: 'Interventions to increase inclusivity and transparency of political processes.',
    DisaggregatedIntervention: 'Training for politicians & leaders',
    Definition: 'Interventions providing training and capacity building for politicians and leaders.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'International relations',
    Definition1: 'Interventions that involve international relations.',
    DisaggregatedIntervention: 'Free Trade Agreements (FTAs)',
    Definition: 'Signing of a new or modified FTA with either a bilateral partner or a trade bloc.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'International relations',
    Definition1: 'Interventions that involve international relations.',
    DisaggregatedIntervention: 'Humanitarian assistance',
    Definition: 'Receipt or extension of humanitarian relief.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'International relations',
    Definition1: 'Interventions that involve international relations.',
    DisaggregatedIntervention: 'International financial institution membership',
    Definition: 'Membership in an international financial institutions, such as World Bank or African Development Bank (AfDB).',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'International relations',
    Definition1: 'Interventions that involve international relations.',
    DisaggregatedIntervention: 'Multi-lateral agencies cooperation',
    Definition: 'Cooperation with multi-lateral agencies, such as the United Nations and it\'s subordinate organisations.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'International relations',
    Definition1: 'Interventions that involve international relations.',
    DisaggregatedIntervention: 'Official Development Assistance (ODA)',
    Definition: 'Receipt or extension of ODA.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'International relations',
    Definition1: 'Interventions that involve international relations.',
    DisaggregatedIntervention: 'Regional associations cooperation',
    Definition: 'Cooperation with regional associations, such as Organisation of American States (OAS).',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'International relations',
    Definition1: 'Interventions that involve international relations.',
    DisaggregatedIntervention: 'Supranational organisation membership',
    Definition: 'Membership in an international group or union in which the power of member states transcends national boundaries or interests to share in decision making and vote on issues concerning the collective body. Examples of these are the European Union and the World Trade Organization (WTO).',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Responsiveness and accountability',
    Definition1: 'Intervention that seek to augment responsiveness and accountability in institutions.',
    DisaggregatedIntervention: 'Audit of service providers',
    Definition: 'Independent audits of public sector services, inspecting levels of spending, effectiveness and cost efficiency. This includes independent scrutiny of public sector auditors.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Responsiveness and accountability',
    Definition1: 'Intervention that seek to augment responsiveness and accountability in institutions.',
    DisaggregatedIntervention: 'Citizen feedback mechanisms',
    Definition: 'Interventions facilitating meetings between service users and providers, setting up grievance redress mechanisms and scorecards allowing users to rate providers.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Responsiveness and accountability',
    Definition1: 'Intervention that seek to augment responsiveness and accountability in institutions.',
    DisaggregatedIntervention: 'Decentralisation',
    Definition: 'Decentralisation interventions shift existing power over spending and decision-making to sub-national levels.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Responsiveness and accountability',
    Definition1: 'Intervention that seek to augment responsiveness and accountability in institutions.',
    DisaggregatedIntervention: 'Government performance incentives',
    Definition: 'Providing wage or other career incentives for public servants and politicians or performance-based grants for public sector providers.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Responsiveness and accountability',
    Definition1: 'Intervention that seek to augment responsiveness and accountability in institutions.',
    DisaggregatedIntervention: 'Information dissemination - services',
    Definition: 'Information for citizens on their rights as users and providers\' responsibilities or publishing allocated funds or budget plans for public institutions.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Responsiveness and accountability',
    Definition1: 'Intervention that seek to augment responsiveness and accountability in institutions.',
    DisaggregatedIntervention: 'Internal monitoring and oversight',
    Definition: 'Introduction or strengthening of internal monitoring and oversight in public institutions. This also includes monitoring & evaluation systems.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Responsiveness and accountability',
    Definition1: 'Intervention that seek to augment responsiveness and accountability in institutions.',
    DisaggregatedIntervention: 'Knowledge management',
    Definition: 'Interventions to increase internal knowledge and data management systems to improve learning and efficiency.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Responsiveness and accountability',
    Definition1: 'Intervention that seek to augment responsiveness and accountability in institutions.',
    DisaggregatedIntervention: 'Land titling and certification',
    Definition: 'Conferring freehold or leasehold rights over land.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Responsiveness and accountability',
    Definition1: 'Intervention that seek to augment responsiveness and accountability in institutions.',
    DisaggregatedIntervention: 'Non-tax revenue',
    Definition: 'Government policy related to sources of income other than taxes. These include municipal services, fines and penalties, sovereign funds, administrative services, etc.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Responsiveness and accountability',
    Definition1: 'Intervention that seek to augment responsiveness and accountability in institutions.',
    DisaggregatedIntervention: 'Participatory priority setting',
    Definition: 'Facilitating public participation in institutions\' decision-making processes or budget allocation decisions.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Responsiveness and accountability',
    Definition1: 'Intervention that seek to augment responsiveness and accountability in institutions.',
    DisaggregatedIntervention: 'Performance evaluations of government employees',
    Definition: 'Evaluations of the performance of government employees.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Responsiveness and accountability',
    Definition1: 'Intervention that seek to augment responsiveness and accountability in institutions.',
    DisaggregatedIntervention: 'Social audits',
    Definition: 'A public or social audit is where public officials stand before constituents and present their budget, activities and plans for constituents to inspect, ask questions about, etc. It enables constituents to hold public servants accountable, while it gives public servants an opportunity to explain themselves and the constraints under which they\'re working, to better manage expectations.',
  },
  {
    InterventionSector: 'Public Administration',
    BroadGroup: 'Public institutions, transparency & accountability',
    InterventionGroup: 'Responsiveness and accountability',
    Definition1: 'Intervention that seek to augment responsiveness and accountability in institutions.',
    DisaggregatedIntervention: 'Tax compliance and formalisation',
    Definition: 'Increasing tax compliance and encourage formalisation of businesses including tax code simplification.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Community monitoring',
    Definition1: 'Interventions to support social accountability initiatives to monitor, assess and hold actors within the justice institutions and services to account. For example, through the use of community scorecards, community observers, social audits, participatory budgeting and other feedback loops etc.',
    DisaggregatedIntervention: 'Community observers',
    Definition: 'Members of the community charged with observing legal processes and conditions.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Community monitoring',
    Definition1: 'Interventions to support social accountability initiatives to monitor, assess and hold actors within the justice institutions and services to account. For example, through the use of community scorecards, community observers, social audits, participatory budgeting and other feedback loops etc.',
    DisaggregatedIntervention: 'Community scorecards',
    Definition: 'Interventions that apply Community Score Card (CSC) methods. CSC is a two-way participatory tool for assessment, planning, monitoring and evaluation of services. It is easy to use and can be adapted into any sector. It brings together the demand side (“service user”) and the supply side (“service provider”) of a particular service or program.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Community monitoring',
    Definition1: 'Interventions to support social accountability initiatives to monitor, assess and hold actors within the justice institutions and services to account. For example, through the use of community scorecards, community observers, social audits, participatory budgeting and other feedback loops etc.',
    DisaggregatedIntervention: 'Monitoring committees',
    Definition: 'Committees established and/or strengthened to properly monitor legal processes and conditions, effectively watch-dog groups.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Community monitoring',
    Definition1: 'Interventions to support social accountability initiatives to monitor, assess and hold actors within the justice institutions and services to account. For example, through the use of community scorecards, community observers, social audits, participatory budgeting and other feedback loops etc.',
    DisaggregatedIntervention: 'Participatory priority setting',
    Definition: 'Facilitating public participation in institutions\' decision-making processes or budget allocation decisions.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Community monitoring',
    Definition1: 'Interventions to support social accountability initiatives to monitor, assess and hold actors within the justice institutions and services to account. For example, through the use of community scorecards, community observers, social audits, participatory budgeting and other feedback loops etc.',
    DisaggregatedIntervention: 'Social audits',
    Definition: 'A public or social audit is where public officials stand before constituents and present their budget, activities and plans for constituents to inspect, ask questions about, etc. It enables constituents to hold public servants accountable, while it gives public servants an opportunity to explain themselves and the constraints under which they\'re working, to better manage expectations.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Crime prevention',
    Definition1: 'Initiatives to prevent crime through pro-active actions to address vulnerable populations.',
    DisaggregatedIntervention: 'Border control',
    Definition: 'Interventions that address mobility and immigration at borders, such as the creation of checkpoints for borders control.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Crime prevention',
    Definition1: 'Initiatives to prevent crime through pro-active actions to address vulnerable populations.',
    DisaggregatedIntervention: 'Community-based natural resource management for conflict resolution',
    Definition: 'These interventions aim to strengthen community-level management of natural resources, including water, rangelands and forests. This may include participatory management of irrigation systems, water user associations or rangeland/forestry user associations. These interventions aim to decentralise control over resource management and benefits to a local level. Interventions to support community-based natural resource management groups often comprise elements of conflict resolution training.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Crime prevention',
    Definition1: 'Initiatives to prevent crime through pro-active actions to address vulnerable populations.',
    DisaggregatedIntervention: 'Community-driven Development & Reconstruction (CDD & CDR)',
    Definition: 'Development initiative that provides control of the development process, resources and decision making authority directly to groups in the community. This includes both community-driven development (CDD) and community driven reconstruction (CDR).',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Crime prevention',
    Definition1: 'Initiatives to prevent crime through pro-active actions to address vulnerable populations.',
    DisaggregatedIntervention: 'Education in emergencies and academic catch-up',
    Definition: 'These programmes aim to address the school years lost by children due to conflict and displacement (Amal for Education, 2019). They are particularly important when attempting to reintegrate refugee and child soldier populations. Typically they will cover all academic areas to ensure the children have broad capacity. This also includes \'education in emergency\' interventions that aim to create interim opportunities to ensure children have access to education, such as through setting up temporary education in camps for displaced populations.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Crime prevention',
    Definition1: 'Initiatives to prevent crime through pro-active actions to address vulnerable populations.',
    DisaggregatedIntervention: 'Housing reconstruction',
    Definition: 'Interventions that relate specifically to the reconstruction of (private) housing following conflict or natural disasters. This is separate from the infrastructure intervention group, which is more about the establishment and management of public infrastructure. ',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Crime prevention',
    Definition1: 'Initiatives to prevent crime through pro-active actions to address vulnerable populations.',
    DisaggregatedIntervention: 'Infrastructure development and reconstruction',
    Definition: 'This covers all interventions that build, or re-build, infrastructure outside of CDD&R processes, with the aim of stimulating the economy through both short-term employment and ensuring the presence of infrastructure necessary for economic development.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Crime prevention',
    Definition1: 'Initiatives to prevent crime through pro-active actions to address vulnerable populations.',
    DisaggregatedIntervention: 'Labour-intensive public works',
    Definition: 'These interventions are often used as a way of both ensuring critical infrastructure is rebuilt or maintained, and as a way of providing access to income for vulnerable households. This is separate from the infrastructure interventions as a specific type of intervention.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Crime prevention',
    Definition1: 'Initiatives to prevent crime through pro-active actions to address vulnerable populations.',
    DisaggregatedIntervention: 'Life skills and employment training for conflict affected population',
    Definition: 'This category provides training programmes primarily for jobs and livelihoods for conflict affected populations, but can also include programmes such as music instruction for youth, which may have less obvious monetary reward but aims to provide skills and an alternative to violence. This category does not include the development of peace skills (such as conflict management).',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Crime prevention',
    Definition1: 'Initiatives to prevent crime through pro-active actions to address vulnerable populations.',
    DisaggregatedIntervention: 'Market development to support economic development',
    Definition: 'This includes interventions that aim to develop the wider economic market and provide increased opportunities at the macro-level of the economy. This includes work on trade, foreign and national investment, and business, cooperative or association formation and oversight.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Crime prevention',
    Definition1: 'Initiatives to prevent crime through pro-active actions to address vulnerable populations.',
    DisaggregatedIntervention: 'Mental health and psychosocial support (MHPSS)',
    Definition: 'Provision of mental health and psychosocial support (MHPS) in emergencies or situation of chronic hardship. MHPS can be specialized if implemented by specialist such as psychiatrist or psychologist, or non-specialized if implemented by primary health care doctors and community health workers. It can also be a community support group or a network strengthening intervention to allow people to seek psychosocial support directly within their community.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Crime prevention',
    Definition1: 'Initiatives to prevent crime through pro-active actions to address vulnerable populations.',
    DisaggregatedIntervention: 'National natural resource benefits sharing to reduce conflict',
    Definition: 'This includes interventions to shape the sharing of natural resource benefits, particularly in the extractives sector, such as efforts to increase the provision of benefits to local communities or reduce risks of violent conflicts over control of resources',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Crime prevention',
    Definition1: 'Initiatives to prevent crime through pro-active actions to address vulnerable populations.',
    DisaggregatedIntervention: 'Social funds',
    Definition: 'These interventions encourage participatory planning and the inclusion of local constituents in identifying and addressing local needs. This can include social funds based on community decision-making or collaborative projects with the local government. It does not include the establishment of community committees to help oversee the development or reconstruction efforts, which are captured under CDD. This also does not cover social safety nets or the provision of funds for community projects where expenditure decisions are not controlled by communities.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Crime prevention',
    Definition1: 'Initiatives to prevent crime through pro-active actions to address vulnerable populations.',
    DisaggregatedIntervention: 'Transboundary water sharing',
    Definition: 'This includes support for (re)negotiation of transboundary water agreements (e.g. treaties) and management to improve the equity and sustainability of water use.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Crime prevention',
    Definition1: 'Initiatives to prevent crime through pro-active actions to address vulnerable populations.',
    DisaggregatedIntervention: 'Urban design for prevention of violence',
    Definition: 'Interventions that improve the safety of public services such as transport.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Deterrence mechanisms',
    Definition1: 'Mechanisms to deter crime and violence through pro-active initiatives.',
    DisaggregatedIntervention: 'Municipal clean ups/civil remedies',
    Definition: 'Interventions other than policing that seek to improve areas that are badly looked after in order to deter crime. For example, by removing graffiti and signs of damage.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Deterrence mechanisms',
    Definition1: 'Mechanisms to deter crime and violence through pro-active initiatives.',
    DisaggregatedIntervention: 'Technology surveillance',
    Definition: 'Increased presence of video surveillance in crime prone areas.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Diversion',
    Definition1: 'Policies and mechanisms to divert suspects and criminals to appropriate services, such as rehabilitation, counselling, or probation.',
    DisaggregatedIntervention: 'Diversion to probation or appropriate services.',
    Definition: 'Arrest and pre-trial diversion programs that share the objective of diverting these populations out of the criminal justice system and into behavioural healthcare and other more appropriate services.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Policing strategies & tactics',
    Definition1: 'Changes in the strategy/doctrine of combating crime, across levels.',
    DisaggregatedIntervention: 'Community-oriented policing',
    Definition: 'Police officers seek to make contact with communities (whether the general population or specific actors such as business owners) and create linkages. This may include meetings or written communications where police and residents make plans and share information. It may also include informal and opportunistic contact to find out citizen concerns.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Policing strategies & tactics',
    Definition1: 'Changes in the strategy/doctrine of combating crime, across levels.',
    DisaggregatedIntervention: 'Concentrated/hotspot policing',
    Definition: 'Directing police resources to areas where crime is known to occur frequently.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Policing strategies & tactics',
    Definition1: 'Changes in the strategy/doctrine of combating crime, across levels.',
    DisaggregatedIntervention: 'Data-driven policing',
    Definition: 'Data driven policing interventions promote or implement the use of a wide variety of digitised data sources to inform decision making, improve processes, and increase actionable intelligence for all personnel within a police service, whether they be operating at the front-line or in positions of strategic leadership. This includes the use of digital technology to collect and examine data related to violence or crime.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Policing strategies & tactics',
    Definition1: 'Changes in the strategy/doctrine of combating crime, across levels.',
    DisaggregatedIntervention: 'Gender-based interventions in policing',
    Definition: 'Intervention in policing with any gender component, including increasing representation of women in the police force or prevention of discrimination. This also includes policies that govern interactions between police and police and suspects, typically to improve safety for each. Some examples would be same-sex screening/frisking of suspects or policies that require two or more female officers to be assigned together.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Policing strategies & tactics',
    Definition1: 'Changes in the strategy/doctrine of combating crime, across levels.',
    DisaggregatedIntervention: 'High visibility policing',
    Definition: 'High visibility patrol tactics contribute to increasing citizen\'s awareness of police presence and help prevent crime and reduce fear. High visibility patrol tactics include, but are not limited to: establishing foot beat, bicycle, and scooter patrols.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Policing strategies & tactics',
    Definition1: 'Changes in the strategy/doctrine of combating crime, across levels.',
    DisaggregatedIntervention: 'Police management',
    Definition: 'Interventions related to the administration and management of the police such as, weekly duty rotation, police staff management, etc.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Policing strategies & tactics',
    Definition1: 'Changes in the strategy/doctrine of combating crime, across levels.',
    DisaggregatedIntervention: 'Problem-oriented policing',
    Definition: 'The process of problem-oriented policing focuses on analysing data to determine what problems are, there they occur and how to determine a most effective response.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Policing strategies & tactics',
    Definition1: 'Changes in the strategy/doctrine of combating crime, across levels.',
    DisaggregatedIntervention: 'Strengthening the ability of law enforcement personnel to tackle specialised issues',
    Definition: 'Interventions that support/strengthen law enforcement\'s ability to address specialised issues, for example supporting them to tackle issues of anti-corruption, organized crime and gender-based violence.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Protection',
    Definition1: 'Provisions for the physical security and human rights of particular at-risk individuals for involvement in the justice system.',
    DisaggregatedIntervention: 'Protection for at risk legal actors',
    Definition: 'Interventions that seek to protect either justice actors or justice seekers from harm that may be posed by their attempts to seek justice for themselves or others',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Protection',
    Definition1: 'Provisions for the physical security and human rights of particular at-risk individuals for involvement in the justice system.',
    DisaggregatedIntervention: 'Protection for political prisoners',
    Definition: 'Interventions to support the fair trial and safe treatment of political prisoners',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Protection',
    Definition1: 'Provisions for the physical security and human rights of particular at-risk individuals for involvement in the justice system.',
    DisaggregatedIntervention: 'Witness protection services',
    Definition: 'Interventions to ensure that witnesses do not come to harm for their willingness to provide evidence',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Rehabilitation & reintegration',
    Definition1: 'Interventions to support the rehabilitation and re-integration of criminals for eventual or immediate release back into society.',
    DisaggregatedIntervention: 'In-facility rehabilitation',
    Definition: 'Interventions to that are implemented while in a correctional facility, to support prisoners to integrate effectively into society. These may include vocational training, life skills provision or psycho-social support. This code can and should be used in conjunction with the pertaining service component (e.g. MHPSS, vocational training, etc.).',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Rehabilitation & reintegration',
    Definition1: 'Interventions to support the rehabilitation and re-integration of criminals for eventual or immediate release back into society.',
    DisaggregatedIntervention: 'Out-of-facility reintegration',
    Definition: 'Interventions to support ex-offenders to integrate effectively into society after they have left a correctional institution. These may include vocational training, life skills provision or psycho-social support. This code can and should be used in conjunction with the pertaining service component (e.g. MHPSS, vocational training, etc.).',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Society-led crime prevention & reporting',
    Definition1: 'Anti-crime and violence programmes that are implemented and effectuated through community members, as opposed to law enforcement agents.',
    DisaggregatedIntervention: 'Crime observatories',
    Definition: 'Systems for monitoring incidences of crimes.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Society-led crime prevention & reporting',
    Definition1: 'Anti-crime and violence programmes that are implemented and effectuated through community members, as opposed to law enforcement agents.',
    DisaggregatedIntervention: 'Neighbourhood watch schemes',
    Definition: 'Systems-led or created by citizens to support reporting and prevention of crime in their locality',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Society-led crime prevention & reporting',
    Definition1: 'Anti-crime and violence programmes that are implemented and effectuated through community members, as opposed to law enforcement agents.',
    DisaggregatedIntervention: 'Reporting and referral by non-legal service providers',
    Definition: 'Strengthening the ability of actors in no-legal services who come into contact with victims of crime and abuse to notice and report issues. For example, teacher trained to recognise child abuse in pupils.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Society-led crime prevention & reporting',
    Definition1: 'Anti-crime and violence programmes that are implemented and effectuated through community members, as opposed to law enforcement agents.',
    DisaggregatedIntervention: 'School or community anti-crime or violence campaigns',
    Definition: 'Locally-led campaigns that are either school and/or community based to promote anti-violence and anti-crime values.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Crime prevention & security',
    InterventionGroup: 'Transitional justice processes',
    Definition1: 'Legal proceedings designed to take into account previous human rights abuses. These can be particularly important processes after civil wars or following authoritarian regime change.',
    DisaggregatedIntervention: 'Transitional justice processes',
    Definition: 'Legal proceedings designed to take into account previous human rights abuses. These can be particularly important processes after civil wars or following authoritarian regime change.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Access to justice',
    Definition1: 'Introduction of new mechanisms to increase access to justice.',
    DisaggregatedIntervention: 'Adapting services to meet the needs of people with physical disability',
    Definition: 'Provision and adaptation of services to help people with physical disabilities to access services such as though wheel-chair friendly buildings, information in sign language etc.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Access to justice',
    Definition1: 'Introduction of new mechanisms to increase access to justice.',
    DisaggregatedIntervention: 'Court fee payment or waivers',
    Definition: 'Interventions that provide funds or waive fees related to court cases.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Access to justice',
    Definition1: 'Introduction of new mechanisms to increase access to justice.',
    DisaggregatedIntervention: 'Improving accessibility of legislation',
    Definition: 'Creating databases or other centralised locations for laws, providing updated versions and amendments to increase understanding of legal obligations and rights.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Access to justice',
    Definition1: 'Introduction of new mechanisms to increase access to justice.',
    DisaggregatedIntervention: 'In-person one stop centres for justice',
    Definition: 'The creation of legal service centres that combine a range of different legal services in one place. These can facilitate access in remote areas. For example, they may house judges, prosecutors, public defenders, police officers and the court in one place.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Access to justice',
    Definition1: 'Introduction of new mechanisms to increase access to justice.',
    DisaggregatedIntervention: 'Legal aid and public defenders',
    Definition: 'Interventions that provide free legal aid in terms of formal legal advice or representation to those who cannot otherwise afford to use the formal legal system.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Access to justice',
    Definition1: 'Introduction of new mechanisms to increase access to justice.',
    DisaggregatedIntervention: 'Phone and online applications for legal advice or documentation',
    Definition: 'The provision of legal advice, often as a low cost, using digital approaches instead of traditional face-to-face advice. In addition to advice of rights and procedure, these may provide online templates for contracting. They can be based on specific legal issues or maybe provide advice on multiple areas of law.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Access to justice',
    Definition1: 'Introduction of new mechanisms to increase access to justice.',
    DisaggregatedIntervention: 'Support to navigate plural legal systems',
    Definition: 'Provision of information, advice or streamlining of processes, whether in person or virtually, on how to navigate plural legal systems.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Access to justice',
    Definition1: 'Introduction of new mechanisms to increase access to justice.',
    DisaggregatedIntervention: 'Victims’ and citizens’ rights clinics',
    Definition: 'The creation and resourcing of physical clinics to provide advice to populations, including those who believe they may be victims of crime or rights abuses.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Appearance notification systems',
    Definition1: 'Notification systems to remind people to attend court appearances and other proceedings in the legal process. These could be physical or digital.',
    DisaggregatedIntervention: 'Letter or in-person reminders',
    Definition: 'Notification provided through writing or in-person communication to provide an attendance reminder for legal proceedings.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Appearance notification systems',
    Definition1: 'Notification systems to remind people to attend court appearances and other proceedings in the legal process. These could be physical or digital.',
    DisaggregatedIntervention: 'Technology-based attendance reminders',
    Definition: 'Notification provided through technology-based communication, such as SMS or calling, to provide an attendance reminder for legal proceedings.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Case management systems',
    Definition1: 'Improvements in the systems for managing legal cases, within the justice system.',
    DisaggregatedIntervention: 'Creation of improved infrastructure for case management',
    Definition: 'Interventions to create and reform administrative systems to support more efficient case management and processing within the formal or informal legal institutions. This can include the creation of systematic processes or the development of infrastructure including digital software.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Case management systems',
    Definition1: 'Improvements in the systems for managing legal cases, within the justice system.',
    DisaggregatedIntervention: 'Problem-centred approached for case management',
    Definition: 'Approaches for engaging justice seekers in the processing of their case',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Court devolution & specialisation initiatives',
    Definition1: 'Creating and strengthening devolved and specialised courts and chambers.',
    DisaggregatedIntervention: 'Devolved courts',
    Definition: 'The creation of formal courts that function at lower administrative levels. For example, the creation of local courts that deal with issues formally but at a community level.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Court devolution & specialisation initiatives',
    Definition1: 'Creating and strengthening devolved and specialised courts and chambers.',
    DisaggregatedIntervention: 'Mobile courts',
    Definition: 'Supporting mobile courts that can bring formal justice courts to people in remote areas that are underserved by legal infrastructure.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Court devolution & specialisation initiatives',
    Definition1: 'Creating and strengthening devolved and specialised courts and chambers.',
    DisaggregatedIntervention: 'Specialised courts and chambers',
    Definition: 'The creation of formal courts that deal with specific issues. For example, the creation of small claims courts or that focus on specialised areas of law including: anti-corruption, organised crime, drugs, youth, gender-based violence, family law, or commercial law.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Selection & certification processes',
    Definition1: 'Interventions to improve the selection process for justice actors to support transparent and rigorous vetting for those who can practice and be appointed.',
    DisaggregatedIntervention: 'Strengthening of judicial services commissions',
    Definition: 'Creating or strengthening of judicial services commissions, for the appointment recommendation and tracking of judge performance.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Selection & certification processes',
    Definition1: 'Interventions to improve the selection process for justice actors to support transparent and rigorous vetting for those who can practice and be appointed.',
    DisaggregatedIntervention: 'Transparent criteria and/or exams for entering law school or the bar',
    Definition: 'Creating or improving transparency criteria for the exams and requirements for entering law school or joining the bar.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Judiciary services & institutions',
    InterventionGroup: 'Selection & certification processes',
    Definition1: 'Interventions to improve the selection process for justice actors to support transparent and rigorous vetting for those who can practice and be appointed.',
    DisaggregatedIntervention: 'Vetting and formal criteria for the selection of judges, police and other justice actors',
    Definition: 'Creating or improving the criteria used in the selection and hiring of justice system office holders, including law enforcement and the judiciary.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Access to legal careers',
    Definition1: 'Provision of support to women and to minority groups to access careers in the legal sector. For example, interventions that support women, specific ethnic or religious groups, and people with disabilities. These might be through mentorship schemes, training sessions or quota systems that provide places in legal education institutions to specific groups.',
    DisaggregatedIntervention: 'Access and participation',
    Definition: 'Provision of support and services to increase access to services and participation (for example in education, employment, public life). This can be done for example through quotas in universities, public services and political institutions (affirmative positive actions) or through educational campaign and targeted encouragement (positive action) or other social inclusion interventions. For communication or behavioural change interventions relating to gender issues, please use the gender equality code.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Access to legal careers',
    Definition1: 'Provision of support to women and to minority groups to access careers in the legal sector. For example, interventions that support women, specific ethnic or religious groups, and people with disabilities. These might be through mentorship schemes, training sessions or quota systems that provide places in legal education institutions to specific groups.',
    DisaggregatedIntervention: 'Group-specific training for law',
    Definition: 'Programmes that provide legal professionals from underrepresented groups with training tailored to those groups to increase access and knowledge of legal professions.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Access to legal careers',
    Definition1: 'Provision of support to women and to minority groups to access careers in the legal sector. For example, interventions that support women, specific ethnic or religious groups, and people with disabilities. These might be through mentorship schemes, training sessions or quota systems that provide places in legal education institutions to specific groups.',
    DisaggregatedIntervention: 'Mentorships in law',
    Definition: 'Programmes to link aspiring legal professionals from underrepresented groups with mentors who provide support and advice for the aspirant\'s legal career.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Human capacity development (formal)',
    Definition1: 'Training and education (including curricula development and university courses) to strengthen the skills of formal justice actors to be able to perform their roles effectively and to qualify within the profession. Targets of training may include judges, lawyers, police and other actors that support the formal justice system. In addition to developing legal skills, programmes may seek to development a stronger culture of respect and appreciation for rule of law and human rights among justice actors.',
    DisaggregatedIntervention: 'In-service training and continuing education for formal institutional actors',
    Definition: 'Training and education (including curricula development and university courses) to strengthen the skills of formal justice actors, currently in-service, to be able to perform their roles effectively and to qualify within the profession. Targets of training may include judges, lawyers, police and other actors that support the formal justice system. In addition to developing legal skills, programmes may seek to development a stronger culture of respect and appreciation for rule of law and human rights among justice actors.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Human capacity development (formal)',
    Definition1: 'Training and education (including curricula development and university courses) to strengthen the skills of formal justice actors to be able to perform their roles effectively and to qualify within the profession. Targets of training may include judges, lawyers, police and other actors that support the formal justice system. In addition to developing legal skills, programmes may seek to development a stronger culture of respect and appreciation for rule of law and human rights among justice actors.',
    DisaggregatedIntervention: 'Pre-service training, education and curricula improvement for formal institutional actors',
    Definition: 'Training and education (including curricula development and university courses) to strengthen the skills of formal justice actors, before beginning service, to be able to perform their roles effectively and to qualify within the profession. Targets of training may include judges, lawyers, police and other actors that support the formal justice system. In addition to developing legal skills, programmes may seek to development a stronger culture of respect and appreciation for rule of law and human rights among justice actors.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Human capacity development (informal)',
    Definition1: 'This includes training and education for actors who are working within the informal justice system to be able to perform their roles more effectively. Targets may include paralegals, traditional chiefs, religious leaders and others who support varied forms of informal justice. Training and education may focus on understanding the law and human rights but also developing personal skills such as building confidence and conflict resolution.',
    DisaggregatedIntervention: 'Mentoring and oversight programmes for paralegals',
    Definition: 'Specific support for paralegals from legal professionals to enable them to perform their roles effectively.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Human capacity development (informal)',
    Definition1: 'This includes training and education for actors who are working within the informal justice system to be able to perform their roles more effectively. Targets may include paralegals, traditional chiefs, religious leaders and others who support varied forms of informal justice. Training and education may focus on understanding the law and human rights but also developing personal skills such as building confidence and conflict resolution.',
    DisaggregatedIntervention: 'Training and education for informal justice system actors for informal institutional actors',
    Definition: 'This includes training and education for actors who are working within the informal justice system to be able to perform their roles more effectively. Targets may include paralegals, traditional chiefs, religious leaders and others who support varied forms of informal justice. Training and education may focus on understanding the law and human rights but also developing personal skills such as building confidence and conflict resolution.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Informal non-state justice mechanisms',
    Definition1: 'Mechanisms used to achieve justice through informal/non-state process and actors.',
    DisaggregatedIntervention: 'Alternative dispute resolution mechanisms',
    Definition: 'Informal mechanisms for resolving disputes and sharing information related to those disputes. These methods may include arbitration and mediation, using in person or online mechanism.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Informal non-state justice mechanisms',
    Definition1: 'Mechanisms used to achieve justice through informal/non-state process and actors.',
    DisaggregatedIntervention: 'Restorative justice mechanisms',
    Definition: 'Provision or support of non-formal restorative justice mechanism that often include dialogue, relationship building, and communication of moral values.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Legal empowerment',
    Definition1: 'Legal empowerment approaches to accessing quality services or address human rights abuses.',
    DisaggregatedIntervention: 'Approaches using right to information requests',
    Definition: 'Training justice seekers to file right to information (RTI) requests and advocate using that information to achieve justice in access to quality services',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Legal empowerment',
    Definition1: 'Legal empowerment approaches to accessing quality services or address human rights abuses.',
    DisaggregatedIntervention: 'Citizen-led approaches using laws and legal frameworks',
    Definition: 'The use of laws and legal frameworks by citizens (often with the support of paralegals) to redress problems encountered with accessing quality services, especially for vulnerable and marginalised groups. These typically focus on redressing individual grievances.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Legal empowerment',
    Definition1: 'Legal empowerment approaches to accessing quality services or address human rights abuses.',
    DisaggregatedIntervention: 'Strengthening public interest litigation',
    Definition: 'Use of judicial review to address issues of human rights and equality, particularly for minority or disadvantaged groups or individuals',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Legal registration',
    Definition1: 'Facilitation and support for government registrations.',
    DisaggregatedIntervention: 'Legal registration campaigns for citizens',
    Definition: 'Birth or citizen registration for individuals to support their ability to access services.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Legal registration',
    Definition1: 'Facilitation and support for government registrations.',
    DisaggregatedIntervention: 'Legal registration for business',
    Definition: 'Interventions to support legal registration of businesses and other entities.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Legal registration',
    Definition1: 'Facilitation and support for government registrations.',
    DisaggregatedIntervention: 'Legal registration for land and assets',
    Definition: 'Interventions to support the registration of land and other assets, such as vehicles, with the government. This is not the same as land titling or certification, which confers land rights.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Legal registration',
    Definition1: 'Facilitation and support for government registrations.',
    DisaggregatedIntervention: 'Legal registration for non-citizens',
    Definition: 'Interventions that support providing a form of legal identity to noncitizens, such as settled status for refugees.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Linking formal & informal institutions',
    Definition1: 'Creating or supporting linkages between formal and informal justice institutions.',
    DisaggregatedIntervention: 'Creation of advanced level curricula, training and education on traditional and customary law',
    Definition: 'Education and training on traditional and customary law for legal actors. This can help to support them to operate within and between both systems',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Linking formal & informal institutions',
    Definition1: 'Creating or supporting linkages between formal and informal justice institutions.',
    DisaggregatedIntervention: 'Legal harmonization',
    Definition: 'Amending state laws to redress conflicts between the statutory and customary law. Also referred to as establishing a hybrid system.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Linking formal & informal institutions',
    Definition1: 'Creating or supporting linkages between formal and informal justice institutions.',
    DisaggregatedIntervention: 'Strengthening of relationships and referral mechanisms between actors across the formal and non-formal systems, including through MOUs',
    Definition: 'Interventions designed to create linkages between informal and formal justice institutions and actors by building relationships and referral mechanisms between informal community-based dispute resolution arbitrators and formal justice sector actors. Examples include, memoranda of understanding or other written agreements.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Participatory constitutional development',
    Definition1: 'Creating of transparent and participatory processes for all citizens to feed into the creation and amendment of constitutions, particularly following regime changes.',
    DisaggregatedIntervention: 'Participatory constitutional development',
    Definition: 'Creating of transparent and participatory processes for all citizens to feed into the creation and amendment of constitutions, particularly following regime changes.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Performance incentives',
    Definition1: 'Incentives for actors in the justice sector to operate effectively and efficiently. For example, these may be based on increased pay related to performance or to career development.',
    DisaggregatedIntervention: 'Career advancement opportunities',
    Definition: 'Promotion programmes for professionals within the legal professions to advance based on past performance.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Performance incentives',
    Definition1: 'Incentives for actors in the justice sector to operate effectively and efficiently. For example, these may be based on increased pay related to performance or to career development.',
    DisaggregatedIntervention: 'Pay for performance',
    Definition: 'Contract schemes for private and public sector employees, especially teachers and healthcare professionals, that provide incentives based on performance metrics.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Professional association capacity building',
    Definition1: 'Provision of additional resources to support professional associations within the legal sector, such as Judges’ Associations, Bar Association, Public Defenders’ Associations. For example, through strengthening of membership services or human capacity development or strengthening internal management infrastructure.',
    DisaggregatedIntervention: 'Human and infrastructural capacity development',
    Definition: 'Capacity building for professional associations within the legal field, such as skills or resources.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Professional association capacity building',
    Definition1: 'Provision of additional resources to support professional associations within the legal sector, such as Judges’ Associations, Bar Association, Public Defenders’ Associations. For example, through strengthening of membership services or human capacity development or strengthening internal management infrastructure.',
    DisaggregatedIntervention: 'Strengthening of membership services',
    Definition: 'Provision of additional or improved membership services from professional associations within the legal field.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Public awareness & outreach on legal literacy',
    Definition1: 'Programmes to raise the public awareness and understanding of relevant legal issues, or generalised legal literacy.',
    DisaggregatedIntervention: 'Creation of simplified guides to support understanding of specific areas of law',
    Definition: 'Creation of simplified guides to help lay people understand aspects of the law for the specific problems that they face. For example, the creation of guides on child custody arrangements.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Public awareness & outreach on legal literacy',
    Definition1: 'Programmes to raise the public awareness and understanding of relevant legal issues, or generalised legal literacy.',
    DisaggregatedIntervention: 'Public legal education campaigns',
    Definition: 'Using different forms of media to share information and increase awareness about laws, rights and services to the general population.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Public awareness & outreach on legal literacy',
    Definition1: 'Programmes to raise the public awareness and understanding of relevant legal issues, or generalised legal literacy.',
    DisaggregatedIntervention: 'Support to community justice advocates',
    Definition: 'Training and resources provided to community justice advocates to share information and raise issues.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Public awareness & outreach on legal literacy',
    Definition1: 'Programmes to raise the public awareness and understanding of relevant legal issues, or generalised legal literacy.',
    DisaggregatedIntervention: 'Targeted legal education campaigns',
    Definition: 'Using different forms of media to meet specific populations with local information of direct relevance to their group.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Public awareness & outreach on legal literacy',
    Definition1: 'Programmes to raise the public awareness and understanding of relevant legal issues, or generalised legal literacy.',
    DisaggregatedIntervention: 'Translation of legal material for marginalised groups',
    Definition: 'Translating information about laws, rights and services to people who may find traditional forms of relaying information difficult to understand. For example, this may relate to provision of information into different languages or providing illustrations or videos for adults or children who cannot read.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Social services for victims of crime & violence',
    Definition1: 'Provision or strengthening of social services provided to victims of crime and violence.',
    DisaggregatedIntervention: 'Problem solving approaches to case intake',
    Definition: 'The use and strengthening of approaches that engage the person involved in working on addressing the problems, specifically in relation to social care.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Social services for victims of crime & violence',
    Definition1: 'Provision or strengthening of social services provided to victims of crime and violence.',
    DisaggregatedIntervention: 'Support for victims referred by an actor or organisation that is part of the justice system or services',
    Definition: 'The creation and resourcing of services and interventions that can provide crisis intervention, emergency treatment, and referrals for services (physical or mental support) to adult and child victims that have been referred by a relevant justice actor or institution. This could include court-ordered placement of children into social services or mental health support for crime victims referred by a Victims’ Advice Bureau.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Support to civil society & the media',
    Definition1: 'Programmes to support an environment of justice for all within civil society and the media.',
    DisaggregatedIntervention: 'Civil society strengthening to support justice for all',
    Definition: 'Supporting civil society organisations to monitor, report and advocate for effective and fair rule of law through trainings, networks and resources.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Legal empowerment & advocacy',
    InterventionGroup: 'Support to civil society & the media',
    Definition1: 'Programmes to support an environment of justice for all within civil society and the media.',
    DisaggregatedIntervention: 'Media strengthening to support justice for all',
    Definition: 'Supporting media to monitor, report and advocate for effective and fair rule of law through trainings, networks and resources.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Transparency & accountability',
    InterventionGroup: 'Promoting participation & human rights standards',
    Definition1: 'Initiatives to improvement the justice system, so as to provide for the protection and promotion of participation and human rights in judicial processes.',
    DisaggregatedIntervention: 'Participatory law reform',
    Definition: 'Engaging affected communities in the creation or reform of laws that affect them',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Transparency & accountability',
    InterventionGroup: 'Promoting participation & human rights standards',
    Definition1: 'Initiatives to improvement the justice system, so as to provide for the protection and promotion of participation and human rights in judicial processes.',
    DisaggregatedIntervention: 'Review and reform of laws by justice system actors that protect or support access to justice for particular marginalised groups',
    Definition: 'Changing laws to specifically support the protection or access to social justice for marginalised groups. For example, changing laws that would allow juvenile migrants to access education in their host country or to protect girls from defilement',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Transparency & accountability',
    InterventionGroup: 'Promoting participation & human rights standards',
    Definition1: 'Initiatives to improvement the justice system, so as to provide for the protection and promotion of participation and human rights in judicial processes.',
    DisaggregatedIntervention: 'Review and reform of laws by justice system actors to meet international human rights standards',
    Definition: 'Changing laws so that they meet the standards in human rights treaties. For example, interventions that seek to implement the Convention of the Rights of the Child in national law.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Transparency & accountability',
    InterventionGroup: 'Promoting participation & human rights standards',
    Definition1: 'Initiatives to improvement the justice system, so as to provide for the protection and promotion of participation and human rights in judicial processes.',
    DisaggregatedIntervention: 'Transition to oral/accusatorial systems',
    Definition: 'Interventions to shift from inquisitorial systems (judges attempt to determine facts while representing the interests of the state) to oral or accusatorial systems (the two sides are presented to an independent judge)',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Transparency & accountability',
    InterventionGroup: 'Transparency, monitoring, & accountability initiatives',
    Definition1: 'Initiatives to promote transparency in the judicial system through monitoring and accountability/oversight mechanisms.',
    DisaggregatedIntervention: 'Audit of service providers',
    Definition: 'Independent audits of public sector services, inspecting levels of spending, effectiveness and cost efficiency. This includes independent scrutiny of public sector auditors.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Transparency & accountability',
    InterventionGroup: 'Transparency, monitoring, & accountability initiatives',
    Definition1: 'Initiatives to promote transparency in the judicial system through monitoring and accountability/oversight mechanisms.',
    DisaggregatedIntervention: 'Citizen feedback mechanisms',
    Definition: 'Interventions facilitating meetings between service users and providers, setting up grievance redress mechanisms and scorecards allowing users to rate providers.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Transparency & accountability',
    InterventionGroup: 'Transparency, monitoring, & accountability initiatives',
    Definition1: 'Initiatives to promote transparency in the judicial system through monitoring and accountability/oversight mechanisms.',
    DisaggregatedIntervention: 'Compliance enforcement in business',
    Definition: 'Use of compliance officers or other mechanisms to ensure that business owners are complying to laws and regulations',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Transparency & accountability',
    InterventionGroup: 'Transparency, monitoring, & accountability initiatives',
    Definition1: 'Initiatives to promote transparency in the judicial system through monitoring and accountability/oversight mechanisms.',
    DisaggregatedIntervention: 'Information dissemination - services',
    Definition: 'Information for citizens on their rights as users and providers\' responsibilities or publishing allocated funds or budget plans for public institutions.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Transparency & accountability',
    InterventionGroup: 'Transparency, monitoring, & accountability initiatives',
    Definition1: 'Initiatives to promote transparency in the judicial system through monitoring and accountability/oversight mechanisms.',
    DisaggregatedIntervention: 'Internal monitoring and oversight',
    Definition: 'Introduction or strengthening of internal monitoring and oversight in public institutions. This also includes monitoring & evaluation systems.',
  },
  {
    InterventionSector: 'Rule of Law',
    BroadGroup: 'Transparency & accountability',
    InterventionGroup: 'Transparency, monitoring, & accountability initiatives',
    Definition1: 'Initiatives to promote transparency in the judicial system through monitoring and accountability/oversight mechanisms.',
    DisaggregatedIntervention: 'Strengthening of ombudsmen capacity',
    Definition: 'Provision of additional resources or human capacity support to strengthen ombudsmen',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Civic Engagement',
    InterventionGroup: 'Civil society strengthening',
    Definition1: 'Support to civil society and groups',
    DisaggregatedIntervention: 'Civil society capacity building',
    Definition: 'This encompasses a fairly broad range of interventions that work with civil society organisations (CSOs) to develop their capacity as a force of change (i.e. capacity building of CSOs to advocate for and engage with citizens and the government). This can include the establishment of community interest groups, such as women and youth committees.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Civic Engagement',
    InterventionGroup: 'Civil society strengthening',
    Definition1: 'Support to civil society and groups',
    DisaggregatedIntervention: 'Civil society subsidisation',
    Definition: 'Subsidisation of civil society group/organisations, this can be in the form of tax benefits.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Civic Engagement',
    InterventionGroup: 'Public participation',
    Definition1: 'Promotion of citizens\' participation and accountability in public life',
    DisaggregatedIntervention: 'Compulsory community service',
    Definition: 'Introduction of compulsory community services to promote civic participation.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Civic Engagement',
    InterventionGroup: 'Public participation',
    Definition1: 'Promotion of citizens\' participation and accountability in public life',
    DisaggregatedIntervention: 'Transparency and accountability initiatives',
    Definition: 'Promotion of transparency and accountability through citizens\' participation and monitoring initiatives.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Labour Market Interventions',
    InterventionGroup: 'Active labour market interventions for the poor',
    Definition1: 'Interventions that help poor people acquire skills and connect them to labour markets. The main objective of these interventions is to reduce  household vulnerability in the event of the loss of employment (interventions mostly aimed to increase capacity, skills and productivity of labour force are therefore excluded).',
    DisaggregatedIntervention: 'Employment incentives and wage subsidies',
    Definition: 'Provision of employment incentives and wage subsidies for private sector work with the aim to create jobs.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Labour Market Interventions',
    InterventionGroup: 'Active labour market interventions for the poor',
    Definition1: 'Interventions that help poor people acquire skills and connect them to labour markets. The main objective of these interventions is to reduce  household vulnerability in the event of the loss of employment (interventions mostly aimed to increase capacity, skills and productivity of labour force are therefore excluded).',
    DisaggregatedIntervention: 'Labour market services and intermediation',
    Definition: 'Provision and promotion of employment services to jobseekers, such as job-matching, support, and counselling and advice.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Labour Market Interventions',
    InterventionGroup: 'Passive labour market interventions for the poor',
    Definition1: 'Policies that help protect people against loss of income from unemployment.',
    DisaggregatedIntervention: 'Early retirement incentives',
    Definition: 'Policies that regulate early retirement incentives.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Labour Market Interventions',
    InterventionGroup: 'Passive labour market interventions for the poor',
    Definition1: 'Policies that help protect people against loss of income from unemployment.',
    DisaggregatedIntervention: 'Injury compensation',
    Definition: 'Policies that regulate injury compensation.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Labour Market Interventions',
    InterventionGroup: 'Passive labour market interventions for the poor',
    Definition1: 'Policies that help protect people against loss of income from unemployment.',
    DisaggregatedIntervention: 'Parental leave',
    Definition: 'Policies that regulate parental leave.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Labour Market Interventions',
    InterventionGroup: 'Passive labour market interventions for the poor',
    Definition1: 'Policies that help protect people against loss of income from unemployment.',
    DisaggregatedIntervention: 'Sickness benefits',
    Definition: 'Policies that regulate sickness benefits.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Labour Market Interventions',
    InterventionGroup: 'Passive labour market interventions for the poor',
    Definition1: 'Policies that help protect people against loss of income from unemployment.',
    DisaggregatedIntervention: 'Wage controls',
    Definition: 'Institution or alteration of wage restrictions, e.g. minimum wage.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Labour Market Interventions',
    InterventionGroup: 'Passive labour market interventions for the poor',
    Definition1: 'Policies that help protect people against loss of income from unemployment.',
    DisaggregatedIntervention: 'Workplace health and safety regulations',
    Definition: 'Introduction of new requirements to respect health and safety rules to minimize the number of accidents and to provide safe working spaces.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Labour Market Interventions',
    InterventionGroup: 'Passive labour market interventions for the poor',
    Definition1: 'Policies that help protect people against loss of income from unemployment.',
    DisaggregatedIntervention: 'Workweek regulation',
    Definition: 'Regulation that governs the amount of time workers may work, including the number and frequency of holidays and enforced breaks.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Fee waivers',
    Definition1: 'Fee waivers are exemptions from payment (partial or total) for a good or service for a specific category of people. They aim to subsidise certain goods and services for the poor (World Bank, 2018b)',
    DisaggregatedIntervention: 'Education fee waivers',
    Definition: 'Provision of waiver from payment for education fee.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Fee waivers',
    Definition1: 'Fee waivers are exemptions from payment (partial or total) for a good or service for a specific category of people. They aim to subsidise certain goods and services for the poor (World Bank, 2018b)',
    DisaggregatedIntervention: 'Health insurance waivers',
    Definition: 'Provision of waiver from payment for health insurance.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Fee waivers',
    Definition1: 'Fee waivers are exemptions from payment (partial or total) for a good or service for a specific category of people. They aim to subsidise certain goods and services for the poor (World Bank, 2018b)',
    DisaggregatedIntervention: 'Housing allowance',
    Definition: 'Provision of waiver from payment for housing.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Fee waivers',
    Definition1: 'Fee waivers are exemptions from payment (partial or total) for a good or service for a specific category of people. They aim to subsidise certain goods and services for the poor (World Bank, 2018b)',
    DisaggregatedIntervention: 'Reduced medical fees',
    Definition: 'Provision of waiver from payment for medical fees.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Fee waivers',
    Definition1: 'Fee waivers are exemptions from payment (partial or total) for a good or service for a specific category of people. They aim to subsidise certain goods and services for the poor (World Bank, 2018b)',
    DisaggregatedIntervention: 'Transportation allowance',
    Definition: 'Provision of waiver from payment for transport (public or private).',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Fee waivers',
    Definition1: 'Fee waivers are exemptions from payment (partial or total) for a good or service for a specific category of people. They aim to subsidise certain goods and services for the poor (World Bank, 2018b)',
    DisaggregatedIntervention: 'Utility and electricity allowance',
    Definition: 'Provision of waiver from payment for utility and electricity.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Public works programmes',
    Definition1: 'Public works programmes (PWPs) are activities which entail the payment of a wage (in cash or food), often but not always by the state, in return for the provision of labour. The aim is to enhance employment and produce a physical or social asset, with the overall objective of promoting social protection. They are sometimes classified as labour market interventions depending on whether their function is primarily poverty alleviation or job creation. Sometimes referred to as public employment programmes (PEP) defined as ‘programmes creating state sponsored employment which is not market based (known as Public Works Programmes, Workfare, Welfare to Work, Cash for Work, Employment of Last Resort, Employment Guarantee programmes, etc.)’ (McCord, 2018: 10).',
    DisaggregatedIntervention: 'Cash for work',
    Definition: 'Cash or income transfers to poor families and individuals in return from the provision of labour.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Public works programmes',
    Definition1: 'Public works programmes (PWPs) are activities which entail the payment of a wage (in cash or food), often but not always by the state, in return for the provision of labour. The aim is to enhance employment and produce a physical or social asset, with the overall objective of promoting social protection. They are sometimes classified as labour market interventions depending on whether their function is primarily poverty alleviation or job creation. Sometimes referred to as public employment programmes (PEP) defined as ‘programmes creating state sponsored employment which is not market based (known as Public Works Programmes, Workfare, Welfare to Work, Cash for Work, Employment of Last Resort, Employment Guarantee programmes, etc.)’ (McCord, 2018: 10).',
    DisaggregatedIntervention: 'Food for work',
    Definition: 'Food (in-kind) transfers to poor families and individuals in return from the provision of labour.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Public works programmes',
    Definition1: 'Public works programmes (PWPs) are activities which entail the payment of a wage (in cash or food), often but not always by the state, in return for the provision of labour. The aim is to enhance employment and produce a physical or social asset, with the overall objective of promoting social protection. They are sometimes classified as labour market interventions depending on whether their function is primarily poverty alleviation or job creation. Sometimes referred to as public employment programmes (PEP) defined as ‘programmes creating state sponsored employment which is not market based (known as Public Works Programmes, Workfare, Welfare to Work, Cash for Work, Employment of Last Resort, Employment Guarantee programmes, etc.)’ (McCord, 2018: 10).',
    DisaggregatedIntervention: 'Graduation programmes',
    Definition: 'Graduation programmes that provide a sequenced package of support – including cash transfers, asset transfers, access to savings and credit, and training and coaching – for a limited period of  time with the aim of strengthening livelihoods and promoting a sustainable move out of poverty. These programmes are primarily productivity focused and target households with labour capacity.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Public works programmes',
    Definition1: 'Public works programmes (PWPs) are activities which entail the payment of a wage (in cash or food), often but not always by the state, in return for the provision of labour. The aim is to enhance employment and produce a physical or social asset, with the overall objective of promoting social protection. They are sometimes classified as labour market interventions depending on whether their function is primarily poverty alleviation or job creation. Sometimes referred to as public employment programmes (PEP) defined as ‘programmes creating state sponsored employment which is not market based (known as Public Works Programmes, Workfare, Welfare to Work, Cash for Work, Employment of Last Resort, Employment Guarantee programmes, etc.)’ (McCord, 2018: 10).',
    DisaggregatedIntervention: 'Voucher for work',
    Definition: 'Voucher transfers to poor families and individuals in return from the provision of labour.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Social Transfers',
    Definition1: 'Social transfers are direct transfers, increasingly paid through secure electronic systems, such as directly into bank accounts, mobile phone accounts, or on smart cards. They can be regular or one-off payments and recipients must meet the eligibility criteria. They have the objective of providing immediate relief and reducing poverty, as well as contributing to increased resilience of poor households by enabling them to save, invest and cope better with risks and shocks.',
    DisaggregatedIntervention: 'Asset transfers',
    Definition: 'Provision of productive assets as in-kind support (non-cash goods or services)',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Social Transfers',
    Definition1: 'Social transfers are direct transfers, increasingly paid through secure electronic systems, such as directly into bank accounts, mobile phone accounts, or on smart cards. They can be regular or one-off payments and recipients must meet the eligibility criteria. They have the objective of providing immediate relief and reducing poverty, as well as contributing to increased resilience of poor households by enabling them to save, invest and cope better with risks and shocks.',
    DisaggregatedIntervention: 'Conditional Cash Transfers (CCTs)',
    Definition: 'Cash transfer to families or individuals provided on a specific condition, such as school enrolment or child vaccination. The transfer is made when and only if the condition is met, which is verified by a more or less intense monitoring.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Social Transfers',
    Definition1: 'Social transfers are direct transfers, increasingly paid through secure electronic systems, such as directly into bank accounts, mobile phone accounts, or on smart cards. They can be regular or one-off payments and recipients must meet the eligibility criteria. They have the objective of providing immediate relief and reducing poverty, as well as contributing to increased resilience of poor households by enabling them to save, invest and cope better with risks and shocks.',
    DisaggregatedIntervention: 'Food transfers',
    Definition: 'Provision of free food as in-kind support (non cash / goods or services)',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Social Transfers',
    Definition1: 'Social transfers are direct transfers, increasingly paid through secure electronic systems, such as directly into bank accounts, mobile phone accounts, or on smart cards. They can be regular or one-off payments and recipients must meet the eligibility criteria. They have the objective of providing immediate relief and reducing poverty, as well as contributing to increased resilience of poor households by enabling them to save, invest and cope better with risks and shocks.',
    DisaggregatedIntervention: 'Labelled cash transfers',
    Definition: 'Monetary transfer to families or individuals without any formal conditionality attached to it, but labelled with the intended purpose of the transfer( soft conditionality). The aim of the transfer is emphasized when given to the beneficiaries but no monitoring of the beneficiaries\' actions following the transfer is conducted.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Social Transfers',
    Definition1: 'Social transfers are direct transfers, increasingly paid through secure electronic systems, such as directly into bank accounts, mobile phone accounts, or on smart cards. They can be regular or one-off payments and recipients must meet the eligibility criteria. They have the objective of providing immediate relief and reducing poverty, as well as contributing to increased resilience of poor households by enabling them to save, invest and cope better with risks and shocks.',
    DisaggregatedIntervention: 'Non-agricultural input grants',
    Definition: 'Provision of non-agricultural inputs as in-kind support (non-cash goods or services)',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Social Transfers',
    Definition1: 'Social transfers are direct transfers, increasingly paid through secure electronic systems, such as directly into bank accounts, mobile phone accounts, or on smart cards. They can be regular or one-off payments and recipients must meet the eligibility criteria. They have the objective of providing immediate relief and reducing poverty, as well as contributing to increased resilience of poor households by enabling them to save, invest and cope better with risks and shocks.',
    DisaggregatedIntervention: 'Other in-kind transfers',
    Definition: 'Provision of other in-kind transfer (non cash / goods or services).',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Social Transfers',
    Definition1: 'Social transfers are direct transfers, increasingly paid through secure electronic systems, such as directly into bank accounts, mobile phone accounts, or on smart cards. They can be regular or one-off payments and recipients must meet the eligibility criteria. They have the objective of providing immediate relief and reducing poverty, as well as contributing to increased resilience of poor households by enabling them to save, invest and cope better with risks and shocks.',
    DisaggregatedIntervention: 'School feeding programmes',
    Definition: 'Provision of food at school as in-kind support. Normally provision of free nutritious meal at school – usually lunch – and sometimes take-home rations for children most in need. School feeding programmes aim both to reduce hunger and improve food security, as well as increase school attendance and learner performance.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Social Transfers',
    Definition1: 'Social transfers are direct transfers, increasingly paid through secure electronic systems, such as directly into bank accounts, mobile phone accounts, or on smart cards. They can be regular or one-off payments and recipients must meet the eligibility criteria. They have the objective of providing immediate relief and reducing poverty, as well as contributing to increased resilience of poor households by enabling them to save, invest and cope better with risks and shocks.',
    DisaggregatedIntervention: 'School supplies transfers (textbooks, uniforms, etc.)',
    Definition: 'Provision of school supplies as in-kind support (such as textbooks, uniforms, etc.)',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Social Transfers',
    Definition1: 'Social transfers are direct transfers, increasingly paid through secure electronic systems, such as directly into bank accounts, mobile phone accounts, or on smart cards. They can be regular or one-off payments and recipients must meet the eligibility criteria. They have the objective of providing immediate relief and reducing poverty, as well as contributing to increased resilience of poor households by enabling them to save, invest and cope better with risks and shocks.',
    DisaggregatedIntervention: 'Unconditional Cash Transfers (UCTs)',
    Definition: 'Cash transfer to families or individuals without any condition from the beneficiaries. This can be a regular cash transfer or a one-time cash transfer.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Subsidies',
    Definition1: 'Subsidies for goods or services in the form of entitled reduced price for the population. Subsidies aim to keep prices low for certain goods and services.',
    DisaggregatedIntervention: 'Food subsidies',
    Definition: 'Food subsidies in the form of entitled reduced price.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Subsidies',
    Definition1: 'Subsidies for goods or services in the form of entitled reduced price for the population. Subsidies aim to keep prices low for certain goods and services.',
    DisaggregatedIntervention: 'Fuel subsidies',
    Definition: 'Fuel subsidies in the form of entitled reduced price (such as fuel for cooking, vehicles, etc.);',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Subsidies',
    Definition1: 'Subsidies for goods or services in the form of entitled reduced price for the population. Subsidies aim to keep prices low for certain goods and services.',
    DisaggregatedIntervention: 'Housing subsidies',
    Definition: 'Housing subsidies in the form of entitled reduced price.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Subsidies',
    Definition1: 'Subsidies for goods or services in the form of entitled reduced price for the population. Subsidies aim to keep prices low for certain goods and services.',
    DisaggregatedIntervention: 'Non-agricultural inputs subsidies',
    Definition: 'Non-agricultural inputs subsidies in the form of entitled reduced price.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Subsidies',
    Definition1: 'Subsidies for goods or services in the form of entitled reduced price for the population. Subsidies aim to keep prices low for certain goods and services.',
    DisaggregatedIntervention: 'Transportation subsidies',
    Definition: 'Transport subsidies in the form of entitled reduced price.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Assistance',
    InterventionGroup: 'Subsidies',
    Definition1: 'Subsidies for goods or services in the form of entitled reduced price for the population. Subsidies aim to keep prices low for certain goods and services.',
    DisaggregatedIntervention: 'Utility and electricity subsidies',
    Definition: 'Utility and electricity subsidies in the form of entitled reduced price.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social insurance',
    InterventionGroup: 'Health-related insurance schemes',
    Definition1: 'Insurance schemes to protect from health-related events.',
    DisaggregatedIntervention: 'Community-based health insurance',
    Definition: 'A scheme managed and operated by an organization, other than a government or private for-profit company, that provides risk pooling to cover all or part of the costs of health care services',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social insurance',
    InterventionGroup: 'Health-related insurance schemes',
    Definition1: 'Insurance schemes to protect from health-related events.',
    DisaggregatedIntervention: 'Private health insurance',
    Definition: 'Private for-profit health insurance',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social insurance',
    InterventionGroup: 'Health-related insurance schemes',
    Definition1: 'Insurance schemes to protect from health-related events.',
    DisaggregatedIntervention: 'Sickness and injury insurance',
    Definition: 'Contributory schemes where participants make regular payments to a scheme that will issue payments in the event of injury and sickness, this includes worker\'s compensation schemes.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social insurance',
    InterventionGroup: 'Health-related insurance schemes',
    Definition1: 'Insurance schemes to protect from health-related events.',
    DisaggregatedIntervention: 'Social health insurance',
    Definition: 'Government operated and funded insurance schemes to provide health coverage.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social insurance',
    InterventionGroup: 'Health-related insurance schemes',
    Definition1: 'Insurance schemes to protect from health-related events.',
    DisaggregatedIntervention: 'Survivor and disability pensions',
    Definition: 'Contributory schemes where participants make regular payments to a scheme that will issue payments in the event of disability and death.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social insurance',
    InterventionGroup: 'Pension schemes',
    Definition1: 'Insurance schemes to help during old age.',
    DisaggregatedIntervention: 'Old-age and pension schemes',
    Definition: 'Contributory schemes where participants make regular payments that will issue payments when they reach old-age.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social insurance',
    InterventionGroup: 'Work-related insurance schemes',
    Definition1: 'Insurance schemes to protect from employment-related events.',
    DisaggregatedIntervention: 'Maternity and paternity benefits',
    Definition: 'Contributory schemes where participants make regular payments to a scheme that will issue payments in the event of maternal/paternal leave.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social insurance',
    InterventionGroup: 'Work-related insurance schemes',
    Definition1: 'Insurance schemes to protect from employment-related events.',
    DisaggregatedIntervention: 'Unemployment insurance',
    Definition: 'Contributory schemes where participants make regular payments to a scheme that will issue payments in the event of unemployment.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social protection system strengthening',
    InterventionGroup: 'Financial sustainability',
    Definition1: 'Policies that promote financial sustainability of social protection programmes and systems.',
    DisaggregatedIntervention: 'Financing models for social protection',
    Definition: 'Changes in the financing model for social protection systems to promote financial sustainability. This could be through endowments, levies, debt financing, etc.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social protection system strengthening',
    InterventionGroup: 'Institutional capacity',
    Definition1: 'Provision of capacity building and reforms to strengthen social protection systems.',
    DisaggregatedIntervention: 'Institutional capacity building for social protection systems',
    Definition: 'Provision of support, including trainings to strengthen institutional capacity in social assistance, insurance and social services.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social protection system strengthening',
    InterventionGroup: 'Institutional capacity',
    Definition1: 'Provision of capacity building and reforms to strengthen social protection systems.',
    DisaggregatedIntervention: 'Social protection systems reform and coordination',
    Definition: 'Reforms of systems, processes and tools to improve coordination across social protection programs and policies. This include coordinated registries of beneficiaries, payment systems, and targeting mechanisms.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Other social services',
    Definition1: 'Provision of general social services not listed above.',
    DisaggregatedIntervention: 'Day-care services',
    Definition: 'Provision of day-care services.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Other social services',
    Definition1: 'Provision of general social services not listed above.',
    DisaggregatedIntervention: 'Family counselling',
    Definition: 'Provision of counselling services to families, including by social workers.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Other social services',
    Definition1: 'Provision of general social services not listed above.',
    DisaggregatedIntervention: 'Intergroup dialogues',
    Definition: 'These interventions aim to increase dialogue and social interaction between different groups, including different ethnic groups, displaced and host community groups, and people of different faiths. They are not part of formal peace processes, but rather processes that use engagement with key community leaders to bring different groups together. They may include purely dialogue-focused interventions or bring groups together through activities, such as arts or sports.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Services and care for children and adults in need or at risk',
    Definition1: 'Provision of services and interventions to provide care and support to children or adults in need or at risk, or with needs arising from their life situation such as illness, disability, age or poverty (e.g. victims of violence, vulnerable children/youth/old people, substance abuse, low socio-economic status, experience of homelessness etc.)',
    DisaggregatedIntervention: 'Access and participation',
    Definition: 'Provision of support and services to increase access to services and participation (for example in education, employment, public life). This can be done for example through quotas in universities, public services and political institutions (affirmative positive actions) or through educational campaign and targeted encouragement (positive action) or other social inclusion interventions. For communication or behavioural change interventions relating to gender issues, please use the gender equality code.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Services and care for children and adults in need or at risk',
    Definition1: 'Provision of services and interventions to provide care and support to children or adults in need or at risk, or with needs arising from their life situation such as illness, disability, age or poverty (e.g. victims of violence, vulnerable children/youth/old people, substance abuse, low socio-economic status, experience of homelessness etc.)',
    DisaggregatedIntervention: 'Gender equality behavioural-change communications',
    Definition: 'These interventions aim to change behaviours, attitudes and beliefs around gender equality and the role of women society.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Services and care for children and adults in need or at risk',
    Definition1: 'Provision of services and interventions to provide care and support to children or adults in need or at risk, or with needs arising from their life situation such as illness, disability, age or poverty (e.g. victims of violence, vulnerable children/youth/old people, substance abuse, low socio-economic status, experience of homelessness etc.)',
    DisaggregatedIntervention: 'Behavioural change and public awareness campaign',
    Definition: 'Provision of services to change behaviours, attitudes and beliefs, to increase awareness or prevention around a specific social issue.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Services and care for children and adults in need or at risk',
    Definition1: 'Provision of services and interventions to provide care and support to children or adults in need or at risk, or with needs arising from their life situation such as illness, disability, age or poverty (e.g. victims of violence, vulnerable children/youth/old people, substance abuse, low socio-economic status, experience of homelessness etc.)',
    DisaggregatedIntervention: 'Entrepreneurship training',
    Definition: 'Provision of entrepreneurship training focused on how to start up a business from scratch, which can include for example developing a business plan, day-to-day management of small enterprise, including bookkeeping, financial planning, etc.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Services and care for children and adults in need or at risk',
    Definition1: 'Provision of services and interventions to provide care and support to children or adults in need or at risk, or with needs arising from their life situation such as illness, disability, age or poverty (e.g. victims of violence, vulnerable children/youth/old people, substance abuse, low socio-economic status, experience of homelessness etc.)',
    DisaggregatedIntervention: 'Formal foster care for children',
    Definition: 'Placement of children in a state-certified residential child care centre for orphans, or with foster parent.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Services and care for children and adults in need or at risk',
    Definition1: 'Provision of services and interventions to provide care and support to children or adults in need or at risk, or with needs arising from their life situation such as illness, disability, age or poverty (e.g. victims of violence, vulnerable children/youth/old people, substance abuse, low socio-economic status, experience of homelessness etc.)',
    DisaggregatedIntervention: 'Interim alternative care for children',
    Definition: 'Placement of children in a temporary care centre or a peer-headed household, as an intermediary care service while the family is being located. This service is usually provided after a disaster or conflict which led the child to be separated from his/ her family.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Services and care for children and adults in need or at risk',
    Definition1: 'Provision of services and interventions to provide care and support to children or adults in need or at risk, or with needs arising from their life situation such as illness, disability, age or poverty (e.g. victims of violence, vulnerable children/youth/old people, substance abuse, low socio-economic status, experience of homelessness etc.)',
    DisaggregatedIntervention: 'Mental health and psychosocial support (MHPSS)',
    Definition: 'Provision of mental health and psychosocial support (MHPS) in emergencies or situation of chronic hardship. MHPS can be specialized if implemented by specialist such as psychiatrist or psychologist, or non-specialized if implemented by primary health care doctors and community health workers. It can also be a community support group or a network strengthening intervention to allow people to seek psychosocial support directly within their community.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Services and care for children and adults in need or at risk',
    Definition1: 'Provision of services and interventions to provide care and support to children or adults in need or at risk, or with needs arising from their life situation such as illness, disability, age or poverty (e.g. victims of violence, vulnerable children/youth/old people, substance abuse, low socio-economic status, experience of homelessness etc.)',
    DisaggregatedIntervention: 'Provision of shelter',
    Definition: 'Provision of temporary or permanent shelter for the homeless, victims of conflict or disaster who have lost their home, or street children.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Services and care for children and adults in need or at risk',
    Definition1: 'Provision of services and interventions to provide care and support to children or adults in need or at risk, or with needs arising from their life situation such as illness, disability, age or poverty (e.g. victims of violence, vulnerable children/youth/old people, substance abuse, low socio-economic status, experience of homelessness etc.)',
    DisaggregatedIntervention: 'Regulation for social services',
    Definition: 'Changes in the regulation or enforcement for social services and care providers, such as licensing and procedures.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Services and care for children and adults in need or at risk',
    Definition1: 'Provision of services and interventions to provide care and support to children or adults in need or at risk, or with needs arising from their life situation such as illness, disability, age or poverty (e.g. victims of violence, vulnerable children/youth/old people, substance abuse, low socio-economic status, experience of homelessness etc.)',
    DisaggregatedIntervention: 'Rotating/Accumulated savings and credit associations',
    Definition: 'A group of individuals acting as an informal financial institution in the form of an alternative financial vehicle. A ROSCA happens via set contributions and withdrawals to and from a common fund. An ASCRA happens via set contributions with the common fund used for extending credit within or outside of the group. These include Savings and Internal Lending Communities (SILCs) and Self-Help Groups (SHG).',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Services and care for children and adults in need or at risk',
    Definition1: 'Provision of services and interventions to provide care and support to children or adults in need or at risk, or with needs arising from their life situation such as illness, disability, age or poverty (e.g. victims of violence, vulnerable children/youth/old people, substance abuse, low socio-economic status, experience of homelessness etc.)',
    DisaggregatedIntervention: 'Youth counselling',
    Definition: 'Interventions that provide counselling for at-risk youth.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Services to protect minorities and categories at risk of social exclusion',
    Definition1: 'Provision of services and interventions to promote inclusion of groups at risk of exclusion because of their race, colour, religion, national origin, sex, sexual orientation, age, physical or mental disability.',
    DisaggregatedIntervention: 'Access and participation',
    Definition: 'Provision of support and services to increase access to services and participation (for example in education, employment, public life). This can be done for example through quotas in universities, public services and political institutions (affirmative positive actions) or through educational campaign and targeted encouragement (positive action) or other social inclusion interventions. For communication or behavioural change interventions relating to gender issues, please use the gender equality code.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Services to protect minorities and categories at risk of social exclusion',
    Definition1: 'Provision of services and interventions to promote inclusion of groups at risk of exclusion because of their race, colour, religion, national origin, sex, sexual orientation, age, physical or mental disability.',
    DisaggregatedIntervention: 'Behavioural change and public awareness campaign',
    Definition: 'Provision of services to change behaviours, attitudes and beliefs, to increase awareness or prevention around a specific social issue.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Services to protect minorities and categories at risk of social exclusion',
    Definition1: 'Provision of services and interventions to promote inclusion of groups at risk of exclusion because of their race, colour, religion, national origin, sex, sexual orientation, age, physical or mental disability.',
    DisaggregatedIntervention: 'Mental health and psychosocial support (MHPSS)',
    Definition: 'Provision of mental health and psychosocial support (MHPS) in emergencies or situation of chronic hardship. MHPS can be specialized if implemented by specialist such as psychiatrist or psychologist, or non-specialized if implemented by primary health care doctors and community health workers. It can also be a community support group or a network strengthening intervention to allow people to seek psychosocial support directly within their community.',
  },
  {
    InterventionSector: 'Social Protection & Development',
    BroadGroup: 'Social Services',
    InterventionGroup: 'Services to protect minorities and categories at risk of social exclusion',
    Definition1: 'Provision of services and interventions to promote inclusion of groups at risk of exclusion because of their race, colour, religion, national origin, sex, sexual orientation, age, physical or mental disability.',
    DisaggregatedIntervention: 'Regulation for social inclusion',
    Definition: 'Changes in the regulation or enforcement for social inclusion, such as anti-discrimination laws.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Access to transportation',
    InterventionGroup: 'Gendered access & participation',
    Definition1: 'Interventions to reduce barriers to female access and participation in transportation.',
    DisaggregatedIntervention: 'Female operator promotion',
    Definition: 'Programmes to advocate for the hiring of female operators (drivers, conductors, pilots, etc.).',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Access to transportation',
    InterventionGroup: 'Gendered access & participation',
    Definition1: 'Interventions to reduce barriers to female access and participation in transportation.',
    DisaggregatedIntervention: 'Sex-segregated spaces in transportation',
    Definition: 'Designation of specific areas/carriages for women.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Access to transportation',
    InterventionGroup: 'General access to transportation',
    Definition1: 'Any intervention to increase generalised access to transportation for the public.',
    DisaggregatedIntervention: 'Alternative transportation promotion',
    Definition: 'Interventions that promote the use of alternative forms of transportation, such as public information campaigns to encourage biking.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Access to transportation',
    InterventionGroup: 'General access to transportation',
    Definition1: 'Any intervention to increase generalised access to transportation for the public.',
    DisaggregatedIntervention: 'Demand-side transportation subsidies',
    Definition: 'Subsidised fares and transport costs provided to vulnerable groups to access transportation, such as bus passes, for private transportation services.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Access to transportation',
    InterventionGroup: 'General access to transportation',
    Definition1: 'Any intervention to increase generalised access to transportation for the public.',
    DisaggregatedIntervention: 'Public transport subsidies',
    Definition: 'These interventions provide transport subsidies to citizens to improve access to public transports. These can take the form of general price reduction or distribution of smartcards for the poor.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Access to transportation',
    InterventionGroup: 'Handicap accessibility',
    Definition1: 'Interventions to reduce barriers to handicap accessibility and participation in transportation.',
    DisaggregatedIntervention: 'Handicap accessibility awareness',
    Definition: 'Awareness campaigns for handicap accessibility.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Access to transportation',
    InterventionGroup: 'Handicap accessibility',
    Definition1: 'Interventions to reduce barriers to handicap accessibility and participation in transportation.',
    DisaggregatedIntervention: 'Infrastructure modification for accessibility',
    Definition: 'Modifications made to transportation infrastructure, private or public, to improve accessibility for handicapped persons.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Access to transportation',
    InterventionGroup: 'Handicap accessibility',
    Definition1: 'Interventions to reduce barriers to handicap accessibility and participation in transportation.',
    DisaggregatedIntervention: 'Reserved seating',
    Definition: 'Specially designated seating for those with disabilities.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Access to transportation',
    InterventionGroup: 'Handicap accessibility',
    Definition1: 'Interventions to reduce barriers to handicap accessibility and participation in transportation.',
    DisaggregatedIntervention: 'Vehicle modification for accessibility',
    Definition: 'Modifications made to vehicles, private or public, to improve accessibility for handicapped persons.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Aviation fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve aviation fleets and services.',
    DisaggregatedIntervention: 'Building or requisition of aircraft',
    Definition: 'Programmes to build or purchase aircraft.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Aviation fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve aviation fleets and services.',
    DisaggregatedIntervention: 'Capacity building for aviators and ground crew',
    Definition: 'Capacity building offered to any aviator or ground crew member',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Aviation fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve aviation fleets and services.',
    DisaggregatedIntervention: 'Cargo transport services',
    Definition: 'Provision or improvement of passenger transportation services.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Aviation fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve aviation fleets and services.',
    DisaggregatedIntervention: 'Development of aircraft',
    Definition: 'Development of new aircraft designs, including incorporating new technologies into existing aircraft.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Aviation fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve aviation fleets and services.',
    DisaggregatedIntervention: 'Flight route management',
    Definition: 'Management of flight routes and restricted areas for environment, noise, and security.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Aviation fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve aviation fleets and services.',
    DisaggregatedIntervention: 'Maintenance of air fleet',
    Definition: 'Programmes to maintain air fleets.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Aviation fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve aviation fleets and services.',
    DisaggregatedIntervention: 'Operation of aircraft',
    Definition: 'Changes in the operation procedures of aircraft.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Aviation fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve aviation fleets and services.',
    DisaggregatedIntervention: 'Passenger services',
    Definition: 'Provision or improvement of passenger transportation services.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Aviation infrastructure',
    Definition1: 'Interventions pertaining to aviation infrastructure development.',
    DisaggregatedIntervention: 'Airport construction',
    Definition: 'These interventions construct or provide knowledge on how to construct airports, including terminals, taxiways, hangars, etc.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Aviation infrastructure',
    Definition1: 'Interventions pertaining to aviation infrastructure development.',
    DisaggregatedIntervention: 'Airport ground management and operations',
    Definition: 'Changes in the operation procedures and management of airports.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Aviation infrastructure',
    Definition1: 'Interventions pertaining to aviation infrastructure development.',
    DisaggregatedIntervention: 'Airport maintenance',
    Definition: 'These interventions conduct work to or provide knowledge on how to regularly maintain existing airports, including terminals, taxiways, hangars, etc.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Aviation infrastructure',
    Definition1: 'Interventions pertaining to aviation infrastructure development.',
    DisaggregatedIntervention: 'Airport rehabilitation',
    Definition: 'These interventions conduct work to or provide knowledge on how to repair existing airports, including terminals, taxiways, hangars, etc., or parts of them, that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Aviation infrastructure',
    Definition1: 'Interventions pertaining to aviation infrastructure development.',
    DisaggregatedIntervention: 'Capacity building for transport builders',
    Definition: 'Specific capacity building for transportation infrastructure builders, including for regular maintenance.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Aviation infrastructure',
    Definition1: 'Interventions pertaining to aviation infrastructure development.',
    DisaggregatedIntervention: 'Capacity building for transport operators',
    Definition: 'Capacity building offered to those who operate/use transport infrastructure.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Administrative and governing bodies',
    Definition: 'Creation or reorganisation of administrative or governing bodies to manage transportation sectors.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Air traffic regulation',
    Definition: 'Regulation of air traffic, including the institution of air traffic control systems.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Aviation authority',
    Definition: 'Interventions to modify aviation authority organisation, management, or jurisdiction. This can be for civil or military authorities.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Concessions',
    Definition: 'Allowing private enterprise to operate on public land or with publically owned assets.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Environmental regulation',
    Definition: 'Any regulation of pollution or environmental footprint. These include environmental auditing institutions.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Institutional capacity building of aviation authority',
    Definition: 'These interventions work with the government department that oversees aviation to strengthen their capacity. This can be in terms or planning, technical skills in construction, and knowledge on management.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Legal framework for aviation',
    Definition: 'Changes in the legal framework or enforcement for aviation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Licensing and permitting',
    Definition: 'Policies pertaining to licensing and permitting of businesses, including anti-corruption measures.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Local air services development',
    Definition: 'Policies to promote the development of local air services.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Local land use planning',
    Definition: 'Determining appropriate (and inappropriate) use of properties around airports; land use policy and regulatory tools used by airports and local land use planners.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Privatisation of government owned assets',
    Definition: 'Transferring/selling government owned assets to private enterprise, including government owned shares',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public Private Partnerships (PPPs) in transportation',
    Definition: 'Cooperative arrangements between the government and private sector actors in the transportation sector. Public contracts for services/works to be completed by private enterprise; e.g. infrastructure construction/maintenance, etc.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public safety campaigns in transportation',
    Definition: 'Information campaigns to raise awareness of safety issues around transportation infrastructure and vehicles.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public safety laws in transportation',
    Definition: 'Regulatory reform and enforcement of public safety laws related to transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public-private joint ventures',
    Definition: 'A commercial enterprise undertaken jointly by two or more parties which otherwise retain their distinct identities, where one of these entities is public.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Safe operations regulation in transportation',
    Definition: 'Regulatory reform and enforcement of safe operations standards and practices in transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Security monitoring systems in transportation',
    Definition: 'Security provisions for transportation, to combat crime, including piracy or robbery, through monitoring systems, for example CCTV.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Security personnel in transportation',
    Definition: 'Security provisions for transportation, to combat crime, including piracy or robbery, through personnel: security guards or officers.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Aviation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on aviation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Supply-side subsidisation for transport',
    Definition: 'Any form of subsidisation, including tax breaks, provided to private transportation infrastructure builders or operators.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'In-land watercraft fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve in-land watercraft fleets and services.',
    DisaggregatedIntervention: 'Building or requisition of watercraft',
    Definition: 'Programmes to build or purchase watercraft vessels.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'In-land watercraft fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve in-land watercraft fleets and services.',
    DisaggregatedIntervention: 'Capacity building for vehicle operators',
    Definition: 'Capacity building offered to vehicle operators who control or direct the operations of a single motor vehicle or a combination',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'In-land watercraft fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve in-land watercraft fleets and services.',
    DisaggregatedIntervention: 'Cargo transport services',
    Definition: 'Provision or improvement of passenger transportation services.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'In-land watercraft fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve in-land watercraft fleets and services.',
    DisaggregatedIntervention: 'Development of watercraft',
    Definition: 'Development of new watercraft designs, including incorporating new technologies into existing vessels.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'In-land watercraft fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve in-land watercraft fleets and services.',
    DisaggregatedIntervention: 'Maintenance of watercraft fleet',
    Definition: 'Programmes to maintain watercraft fleets.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'In-land watercraft fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve in-land watercraft fleets and services.',
    DisaggregatedIntervention: 'Operation of watercraft',
    Definition: 'Changes in the operation procedures of watercraft.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'In-land watercraft fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve in-land watercraft fleets and services.',
    DisaggregatedIntervention: 'Passenger services',
    Definition: 'Provision or improvement of passenger transportation services.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'In-land waterway infrastructure',
    Definition1: 'Interventions pertaining to in-land waterway infrastructure development.',
    DisaggregatedIntervention: 'Capacity building for transport builders',
    Definition: 'Specific capacity building for transportation infrastructure builders, including for regular maintenance.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'In-land waterway infrastructure',
    Definition1: 'Interventions pertaining to in-land waterway infrastructure development.',
    DisaggregatedIntervention: 'Capacity building for transport operators',
    Definition: 'Capacity building offered to those who operate/use transport infrastructure.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'In-land waterway infrastructure',
    Definition1: 'Interventions pertaining to in-land waterway infrastructure development.',
    DisaggregatedIntervention: 'Private dock construction',
    Definition: 'These interventions construct, or provide knowledge on how to, private docks.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'In-land waterway infrastructure',
    Definition1: 'Interventions pertaining to in-land waterway infrastructure development.',
    DisaggregatedIntervention: 'Private dock maintenance',
    Definition: 'These interventions conduct work to or provide knowledge on how to regularly maintain docks.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'In-land waterway infrastructure',
    Definition1: 'Interventions pertaining to in-land waterway infrastructure development.',
    DisaggregatedIntervention: 'Private dock rehabilitation',
    Definition: 'These interventions conduct work to or provide knowledge on how to repair existing private docks.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'In-land waterway infrastructure',
    Definition1: 'Interventions pertaining to in-land waterway infrastructure development.',
    DisaggregatedIntervention: 'Waterway construction',
    Definition: 'These interventions construct or provide knowledge on how to construct waterways, which are rivers, canals, or other routes for inland travel by water.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'In-land waterway infrastructure',
    Definition1: 'Interventions pertaining to in-land waterway infrastructure development.',
    DisaggregatedIntervention: 'Waterway maintenance',
    Definition: 'These interventions conduct work to or provide knowledge on how to regularly maintain existing waterways.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'In-land waterway infrastructure',
    Definition1: 'Interventions pertaining to in-land waterway infrastructure development.',
    DisaggregatedIntervention: 'Waterway rehabilitation',
    Definition: 'These interventions conduct work to or provide knowledge on how to repair existing waterways that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on in-land waterway transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Administrative and governing bodies',
    Definition: 'Creation or reorganisation of administrative or governing bodies to manage transportation sectors.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on in-land waterway transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Concessions',
    Definition: 'Allowing private enterprise to operate on public land or with publically owned assets.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on in-land waterway transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Environmental regulation',
    Definition: 'Any regulation of pollution or environmental footprint. These include environmental auditing institutions.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on in-land waterway transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Legal framework for in-land waterway transportation',
    Definition: 'Changes in the legal framework or enforcement for in-land waterway transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on in-land waterway transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Licensing and permitting',
    Definition: 'Policies pertaining to licensing and permitting of businesses, including anti-corruption measures.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on in-land waterway transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Privatisation of government owned assets',
    Definition: 'Transferring/selling government owned assets to private enterprise, including government owned shares',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on in-land waterway transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public Private Partnerships (PPPs) in transportation',
    Definition: 'Cooperative arrangements between the government and private sector actors in the transportation sector. Public contracts for services/works to be completed by private enterprise; e.g. infrastructure construction/maintenance, etc.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on in-land waterway transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public safety campaigns in transportation',
    Definition: 'Information campaigns to raise awareness of safety issues around transportation infrastructure and vehicles.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on in-land waterway transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public safety laws in transportation',
    Definition: 'Regulatory reform and enforcement of public safety laws related to transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on in-land waterway transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public-private joint ventures',
    Definition: 'A commercial enterprise undertaken jointly by two or more parties which otherwise retain their distinct identities, where one of these entities is public.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on in-land waterway transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Safe operations regulation in transportation',
    Definition: 'Regulatory reform and enforcement of safe operations standards and practices in transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on in-land waterway transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Security monitoring systems in transportation',
    Definition: 'Security provisions for transportation, to combat crime, including piracy or robbery, through monitoring systems, for example CCTV.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on in-land waterway transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Security personnel in transportation',
    Definition: 'Security provisions for transportation, to combat crime, including piracy or robbery, through personnel: security guards or officers.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'In-land waterway transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on in-land waterway transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Supply-side subsidisation for transport',
    Definition: 'Any form of subsidisation, including tax breaks, provided to private transportation infrastructure builders or operators.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Intermodal transportation fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve intermodal transportation fleets (specialized vehicles and intermodal transport units – containers, contrailers, swap bodies) and services.',
    DisaggregatedIntervention: 'Building or requisition of intermodal transport units',
    Definition: 'Programmes to build or purchase intermodal transport units (e.g. containers, contrailers, swap bodies).',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Intermodal transportation fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve intermodal transportation fleets (specialized vehicles and intermodal transport units – containers, contrailers, swap bodies) and services.',
    DisaggregatedIntervention: 'Capacity building for vehicle operators',
    Definition: 'Capacity building offered to vehicle operators.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Intermodal transportation fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve intermodal transportation fleets (specialized vehicles and intermodal transport units – containers, contrailers, swap bodies) and services.',
    DisaggregatedIntervention: 'Cargo transport services',
    Definition: 'Provision or improvement of passenger transportation services.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Intermodal transportation fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve intermodal transportation fleets (specialized vehicles and intermodal transport units – containers, contrailers, swap bodies) and services.',
    DisaggregatedIntervention: 'Development of intermodal transport units',
    Definition: 'Development of new intermodal transport units (e.g. containers, contrailers, swap bodies) designs, including incorporating new technologies into existing units.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Intermodal transportation fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve intermodal transportation fleets (specialized vehicles and intermodal transport units – containers, contrailers, swap bodies) and services.',
    DisaggregatedIntervention: 'Maintenance of intermodal transport unit fleet',
    Definition: 'Programmes to maintain intermodal transport units (e.g. containers, contrailers, swap bodies) fleets.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Intermodal transportation fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve intermodal transportation fleets (specialized vehicles and intermodal transport units – containers, contrailers, swap bodies) and services.',
    DisaggregatedIntervention: 'Operation of intermodal transport units',
    Definition: 'Changes in the operation procedures of intermodal transport units (e.g. containers, contrailers, swap bodies).',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Intermodal transportation fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve intermodal transportation fleets (specialized vehicles and intermodal transport units – containers, contrailers, swap bodies) and services.',
    DisaggregatedIntervention: 'Passenger services',
    Definition: 'Provision or improvement of passenger transportation services.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Intermodal transportation infrastructure',
    Definition1: 'Interventions pertaining to intermodal transportation infrastructure (logistic centres, intermodal terminals) development.',
    DisaggregatedIntervention: 'Capacity building for transport builders',
    Definition: 'Specific capacity building for transportation infrastructure builders, including for regular maintenance.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Intermodal transportation infrastructure',
    Definition1: 'Interventions pertaining to intermodal transportation infrastructure (logistic centres, intermodal terminals) development.',
    DisaggregatedIntervention: 'Capacity building for transport operators',
    Definition: 'Capacity building offered to those who operate/use transport infrastructure.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Intermodal transportation infrastructure',
    Definition1: 'Interventions pertaining to intermodal transportation infrastructure (logistic centres, intermodal terminals) development.',
    DisaggregatedIntervention: 'Intermodal facility management and operations',
    Definition: 'Changes in the operation procedures and management of intermodal transportation facilities.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Intermodal transportation infrastructure',
    Definition1: 'Interventions pertaining to intermodal transportation infrastructure (logistic centres, intermodal terminals) development.',
    DisaggregatedIntervention: 'Intermodal transportation infrastructure construction',
    Definition: 'These interventions construct, or provide knowledge on how to, intermodal transportation infrastructure (logistic centres, intermodal terminals).',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Intermodal transportation infrastructure',
    Definition1: 'Interventions pertaining to intermodal transportation infrastructure (logistic centres, intermodal terminals) development.',
    DisaggregatedIntervention: 'Intermodal transportation infrastructure maintenance',
    Definition: 'These interventions conduct work to, or provide knowledge on how to, regularly maintain existing intermodal transportation infrastructure (logistic centres, intermodal terminals).',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Intermodal transportation infrastructure',
    Definition1: 'Interventions pertaining to intermodal transportation infrastructure (logistic centres, intermodal terminals) development.',
    DisaggregatedIntervention: 'Intermodal transportation infrastructure rehabilitation',
    Definition: 'These interventions conduct work to, or knowledge on how to, repair existing intermodal transportation infrastructure (logistic centres, intermodal terminals) that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on intermodal transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Administrative and governing bodies',
    Definition: 'Creation or reorganisation of administrative or governing bodies to manage transportation sectors.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on intermodal transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Concessions',
    Definition: 'Allowing private enterprise to operate on public land or with publically owned assets.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on intermodal transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Environmental regulation',
    Definition: 'Any regulation of pollution or environmental footprint. These include environmental auditing institutions.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on intermodal transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Legal framework for intermodal transportation',
    Definition: 'Changes in the legal framework or enforcement for intermodal transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on intermodal transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Licensing and permitting',
    Definition: 'Policies pertaining to licensing and permitting of businesses, including anti-corruption measures.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on intermodal transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Privatisation of government owned assets',
    Definition: 'Transferring/selling government owned assets to private enterprise, including government owned shares',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on intermodal transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public Private Partnerships (PPPs) in transportation',
    Definition: 'Cooperative arrangements between the government and private sector actors in the transportation sector. Public contracts for services/works to be completed by private enterprise; e.g. infrastructure construction/maintenance, etc.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on intermodal transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public safety campaigns in transportation',
    Definition: 'Information campaigns to raise awareness of safety issues around transportation infrastructure and vehicles.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on intermodal transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public safety laws in transportation',
    Definition: 'Regulatory reform and enforcement of public safety laws related to transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on intermodal transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public-private joint ventures',
    Definition: 'A commercial enterprise undertaken jointly by two or more parties which otherwise retain their distinct identities, where one of these entities is public.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on intermodal transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Safe operations regulation in transportation',
    Definition: 'Regulatory reform and enforcement of safe operations standards and practices in transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on intermodal transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Security monitoring systems in transportation',
    Definition: 'Security provisions for transportation, to combat crime, including piracy or robbery, through monitoring systems, for example CCTV.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on intermodal transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Security personnel in transportation',
    Definition: 'Security provisions for transportation, to combat crime, including piracy or robbery, through personnel: security guards or officers.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Intermodal transportation',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on intermodal transportation, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Supply-side subsidisation for transport',
    Definition: 'Any form of subsidisation, including tax breaks, provided to private transportation infrastructure builders or operators.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on maritime transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Administrative and governing bodies',
    Definition: 'Creation or reorganisation of administrative or governing bodies to manage transportation sectors.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on maritime transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Concessions',
    Definition: 'Allowing private enterprise to operate on public land or with publically owned assets.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on maritime transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Environmental regulation',
    Definition: 'Any regulation of pollution or environmental footprint. These include environmental auditing institutions.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on maritime transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Institutional capacity building of port or river authorities',
    Definition: 'These interventions work with the government department that oversees ports, rivers, and other waterways to strengthen their capacity. This can be in terms or planning, technical skills in construction, and knowledge on management.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on maritime transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Legal framework for maritime transportation',
    Definition: 'Changes in the legal framework or enforcement for maritime transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on maritime transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Licensing and permitting',
    Definition: 'Policies pertaining to licensing and permitting of businesses, including anti-corruption measures.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on maritime transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Maritime environmental protection',
    Definition: 'Interventions that regulate the use of maritime areas to protect the environment/ecosystem. These are different then environmental regulation, as these pertain to protected ecosystems.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on maritime transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Port or river authority',
    Definition: 'Interventions to modify port or river authority organisation, management, or jurisdiction. This can be for civil or military authorities.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on maritime transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Privatisation of government owned assets',
    Definition: 'Transferring/selling government owned assets to private enterprise, including government owned shares',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on maritime transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public Private Partnerships (PPPs) in transportation',
    Definition: 'Cooperative arrangements between the government and private sector actors in the transportation sector. Public contracts for services/works to be completed by private enterprise; e.g. infrastructure construction/maintenance, etc.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on maritime transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public safety campaigns in transportation',
    Definition: 'Information campaigns to raise awareness of safety issues around transportation infrastructure and vehicles.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on maritime transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public safety laws in transportation',
    Definition: 'Regulatory reform and enforcement of public safety laws related to transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on maritime transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public-private joint ventures',
    Definition: 'A commercial enterprise undertaken jointly by two or more parties which otherwise retain their distinct identities, where one of these entities is public.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on maritime transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Safe operations regulation in transportation',
    Definition: 'Regulatory reform and enforcement of safe operations standards and practices in transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on maritime transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Security monitoring systems in transportation',
    Definition: 'Security provisions for transportation, to combat crime, including piracy or robbery, through monitoring systems, for example CCTV.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on maritime transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Security personnel in transportation',
    Definition: 'Security provisions for transportation, to combat crime, including piracy or robbery, through personnel: security guards or officers.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on maritime transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Supply-side subsidisation for transport',
    Definition: 'Any form of subsidisation, including tax breaks, provided to private transportation infrastructure builders or operators.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve maritime fleets and services.',
    DisaggregatedIntervention: 'Building or requisition of maritime vessels',
    Definition: 'Programmes to build or purchase maritime vessels.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve maritime fleets and services.',
    DisaggregatedIntervention: 'Capacity building for mariners',
    Definition: 'Capacity building offered to any mariners',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve maritime fleets and services.',
    DisaggregatedIntervention: 'Cargo transport services',
    Definition: 'Provision or improvement of passenger transportation services.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve maritime fleets and services.',
    DisaggregatedIntervention: 'Development of maritime vessels',
    Definition: 'Development of new vessel designs, including incorporating new technologies into existing vessels.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve maritime fleets and services.',
    DisaggregatedIntervention: 'Maintenance of maritime vessels',
    Definition: 'Programmes to maintain maritime fleets.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve maritime fleets and services.',
    DisaggregatedIntervention: 'Operation of maritime vessels',
    Definition: 'Changes in the operation procedures of maritime vessels.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve maritime fleets and services.',
    DisaggregatedIntervention: 'Passenger services',
    Definition: 'Provision or improvement of passenger transportation services.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime infrastructure',
    Definition1: 'Interventions pertaining to maritime infrastructure development.',
    DisaggregatedIntervention: 'Capacity building for transport builders',
    Definition: 'Specific capacity building for transportation infrastructure builders, including for regular maintenance.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime infrastructure',
    Definition1: 'Interventions pertaining to maritime infrastructure development.',
    DisaggregatedIntervention: 'Capacity building for transport operators',
    Definition: 'Capacity building offered to those who operate/use transport infrastructure.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime infrastructure',
    Definition1: 'Interventions pertaining to maritime infrastructure development.',
    DisaggregatedIntervention: 'Harbour management and operation',
    Definition: 'Changes in the operation procedures and management of harbours and ports.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime infrastructure',
    Definition1: 'Interventions pertaining to maritime infrastructure development.',
    DisaggregatedIntervention: 'Port construction & rehabilitation',
    Definition: 'These interventions construct or provide knowledge on how to construct ports, which are places on the coast where ships may moor in shelter.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime infrastructure',
    Definition1: 'Interventions pertaining to maritime infrastructure development.',
    DisaggregatedIntervention: 'Port maintenance',
    Definition: 'These interventions conduct work to or provide knowledge on how to regularly maintain ports.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime infrastructure',
    Definition1: 'Interventions pertaining to maritime infrastructure development.',
    DisaggregatedIntervention: 'Port rehabilitation',
    Definition: 'These interventions conduct work to or provide knowledge on how to repair existing ports that are no longer usable, or difficult to use, to a safe standard',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime infrastructure',
    Definition1: 'Interventions pertaining to maritime infrastructure development.',
    DisaggregatedIntervention: 'Private dock construction',
    Definition: 'These interventions construct or provide knowledge on how to construct private docks.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime infrastructure',
    Definition1: 'Interventions pertaining to maritime infrastructure development.',
    DisaggregatedIntervention: 'Private dock maintenance',
    Definition: 'These interventions conduct work to or provide knowledge on how to regularly maintain docks.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime infrastructure',
    Definition1: 'Interventions pertaining to maritime infrastructure development.',
    DisaggregatedIntervention: 'Private dock rehabilitation',
    Definition: 'These interventions conduct work to or provide knowledge on how to repair existing private docks.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime infrastructure',
    Definition1: 'Interventions pertaining to maritime infrastructure development.',
    DisaggregatedIntervention: 'Shipyard construction',
    Definition: 'These interventions construct or provide knowledge on how to shipyards which are facilities used in shipbuilding.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime infrastructure',
    Definition1: 'Interventions pertaining to maritime infrastructure development.',
    DisaggregatedIntervention: 'Shipyard maintenance',
    Definition: 'These interventions conduct work to or provide knowledge on how to regularly maintain existing shipyards.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime infrastructure',
    Definition1: 'Interventions pertaining to maritime infrastructure development.',
    DisaggregatedIntervention: 'Shipyard rehabilitation',
    Definition: 'These interventions conduct work to, or provide knowledge on how to, repair existing shipyards that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime infrastructure',
    Definition1: 'Interventions pertaining to maritime infrastructure development.',
    DisaggregatedIntervention: 'Waterway construction',
    Definition: 'These interventions construct or provide knowledge on how to construct waterways, which are rivers, canals, or other routes for inland travel by water.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime infrastructure',
    Definition1: 'Interventions pertaining to maritime infrastructure development.',
    DisaggregatedIntervention: 'Waterway maintenance',
    Definition: 'These interventions conduct work to or provide knowledge on how to regularly maintain existing waterways.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Maritime transport',
    InterventionGroup: 'Maritime infrastructure',
    Definition1: 'Interventions pertaining to maritime infrastructure development.',
    DisaggregatedIntervention: 'Waterway rehabilitation',
    Definition: 'These interventions conduct work to or provide knowledge on how to repair existing waterways that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on railroad transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Administrative and governing bodies',
    Definition: 'Creation or reorganisation of administrative or governing bodies to manage transportation sectors.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on railroad transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Concessions',
    Definition: 'Allowing private enterprise to operate on public land or with publically owned assets.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on railroad transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Environmental regulation',
    Definition: 'Any regulation of pollution or environmental footprint. These include environmental auditing institutions.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on railroad transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Institutional capacity building of railway agency',
    Definition: 'These interventions work with the government department that oversees the railways to strengthen their capacity. This can be in terms of planning, technical skills in construction, and knowledge on management.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on railroad transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Legal framework for railroading',
    Definition: 'Changes in the legal framework or enforcement for railroad transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on railroad transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Licensing and permitting',
    Definition: 'Policies pertaining to licensing and permitting of businesses, including anti-corruption measures.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on railroad transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Privatisation of government owned assets',
    Definition: 'Transferring/selling government owned assets to private enterprise, including government owned shares',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on railroad transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public Private Partnerships (PPPs) in transportation',
    Definition: 'Cooperative arrangements between the government and private sector actors in the transportation sector. Public contracts for services/works to be completed by private enterprise; e.g. infrastructure construction/maintenance, etc.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on railroad transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public safety campaigns in transportation',
    Definition: 'Information campaigns to raise awareness of safety issues around transportation infrastructure and vehicles.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on railroad transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public safety laws in transportation',
    Definition: 'Regulatory reform and enforcement of public safety laws related to transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on railroad transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public-private joint ventures',
    Definition: 'A commercial enterprise undertaken jointly by two or more parties which otherwise retain their distinct identities, where one of these entities is public.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on railroad transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Railroading authority',
    Definition: 'Interventions to modify railroading authority organisation, management, or jurisdiction. This can be for civil or military authorities.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on railroad transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Railway industry institutional reforming',
    Definition: 'General reform of railway industry institutions.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on railroad transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Safe operations regulation in transportation',
    Definition: 'Regulatory reform and enforcement of safe operations standards and practices in transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on railroad transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Security monitoring systems in transportation',
    Definition: 'Security provisions for transportation, to combat crime, including piracy or robbery, through monitoring systems, for example CCTV.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on railroad transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Security personnel in transportation',
    Definition: 'Security provisions for transportation, to combat crime, including piracy or robbery, through personnel: security guards or officers.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on railroad transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Supply-side subsidisation for transport',
    Definition: 'Any form of subsidisation, including tax breaks, provided to private transportation infrastructure builders or operators.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Railroading infrastructure',
    Definition1: 'Interventions pertaining to railroading infrastructure development.',
    DisaggregatedIntervention: 'Capacity building for transport builders',
    Definition: 'Specific capacity building for transportation infrastructure builders, including for regular maintenance.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Railroading infrastructure',
    Definition1: 'Interventions pertaining to railroading infrastructure development.',
    DisaggregatedIntervention: 'Capacity building for transport operators',
    Definition: 'Capacity building offered to those who operate/use transport infrastructure.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Railroading infrastructure',
    Definition1: 'Interventions pertaining to railroading infrastructure development.',
    DisaggregatedIntervention: 'Rail station construction',
    Definition: 'These interventions construct or provide knowledge on how to construct railway stations.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Railroading infrastructure',
    Definition1: 'Interventions pertaining to railroading infrastructure development.',
    DisaggregatedIntervention: 'Rail station maintenance',
    Definition: 'These interventions conduct work to or provide knowledge on how to regularly maintain existing railway stations.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Railroading infrastructure',
    Definition1: 'Interventions pertaining to railroading infrastructure development.',
    DisaggregatedIntervention: 'Rail station rehabilitation',
    Definition: 'These interventions conduct work to or provide knowledge on how to repair existing railway stations, or parts of them, that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Railroading infrastructure',
    Definition1: 'Interventions pertaining to railroading infrastructure development.',
    DisaggregatedIntervention: 'Railway construction',
    Definition: 'These interventions construct or provide knowledge on how to construct all or parts of railway systems. These include railyards.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Railroading infrastructure',
    Definition1: 'Interventions pertaining to railroading infrastructure development.',
    DisaggregatedIntervention: 'Railway maintenance',
    Definition: 'These interventions conduct work to or provide knowledge on how to regularly maintain existing railways. These include railyards.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Railroading infrastructure',
    Definition1: 'Interventions pertaining to railroading infrastructure development.',
    DisaggregatedIntervention: 'Railway rehabilitation',
    Definition: 'These interventions conduct work to or provide knowledge on how to repair existing railways, or parts of them, that are no longer usable, or difficult to use, to a safe standard. These include railyards.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Railroading infrastructure',
    Definition1: 'Interventions pertaining to railroading infrastructure development.',
    DisaggregatedIntervention: 'Railyard management and operations',
    Definition: 'Changes in the operation procedures and management of railyards.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Railroading infrastructure',
    Definition1: 'Interventions pertaining to railroading infrastructure development.',
    DisaggregatedIntervention: 'Track management and operations',
    Definition: 'Changes in the operation procedures and management of tracks, including managing the flow of traffic.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Rolling stock fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve rolling stock fleets and services.',
    DisaggregatedIntervention: 'Building or requisition of rolling stock',
    Definition: 'Programmes to build or purchase rolling stock (locomotives, carriages, etc.).',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Rolling stock fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve rolling stock fleets and services.',
    DisaggregatedIntervention: 'Capacity building for railroaders',
    Definition: 'Capacity building offered to any railroader, regardless of job.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Rolling stock fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve rolling stock fleets and services.',
    DisaggregatedIntervention: 'Cargo transport services',
    Definition: 'Provision or improvement of passenger transportation services.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Rolling stock fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve rolling stock fleets and services.',
    DisaggregatedIntervention: 'Development of rolling stock',
    Definition: 'Development of new rolling stock (locomotives, carriages, etc.) designs, including incorporating new technologies into existing rolling stock.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Rolling stock fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve rolling stock fleets and services.',
    DisaggregatedIntervention: 'Maintenance of rolling stock fleet',
    Definition: 'Programmes to maintain rolling stock (locomotives, carriages, etc.) fleets.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Rolling stock fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve rolling stock fleets and services.',
    DisaggregatedIntervention: 'Operation of locomotives',
    Definition: 'Changes in the operation procedures of rolling stock (locomotives, carriages, etc.).',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Railroad transport',
    InterventionGroup: 'Rolling stock fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve rolling stock fleets and services.',
    DisaggregatedIntervention: 'Passenger services',
    Definition: 'Provision or improvement of passenger transportation services.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Administrative and governing bodies',
    Definition: 'Creation or reorganisation of administrative or governing bodies to manage transportation sectors.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Concessions',
    Definition: 'Allowing private enterprise to operate on public land or with publically owned assets.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Environmental regulation',
    Definition: 'Any regulation of pollution or environmental footprint. These include environmental auditing institutions.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Institutional capacity building of road agency',
    Definition: 'These interventions work with the government department that oversees roads to strengthen their capacity. This can be in terms or planning, technical skills in construction, and knowledge on management.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Legal framework for road use',
    Definition: 'Changes in the legal framework or enforcement for public road use.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Licensing and permitting',
    Definition: 'Policies pertaining to licensing and permitting of businesses, including anti-corruption measures.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Owner-operators’ regulation',
    Definition: 'Owner-operator regulation, including legal status of business.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Privatisation of government owned assets',
    Definition: 'Transferring/selling government owned assets to private enterprise, including government owned shares',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public Private Partnerships (PPPs) in transportation',
    Definition: 'Cooperative arrangements between the government and private sector actors in the transportation sector. Public contracts for services/works to be completed by private enterprise; e.g. infrastructure construction/maintenance, etc.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public safety campaigns in transportation',
    Definition: 'Information campaigns to raise awareness of safety issues around transportation infrastructure and vehicles.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public safety laws in transportation',
    Definition: 'Regulatory reform and enforcement of public safety laws related to transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public-private joint ventures',
    Definition: 'A commercial enterprise undertaken jointly by two or more parties which otherwise retain their distinct identities, where one of these entities is public.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Rural road safety monitoring',
    Definition: 'These interventions provide monitoring services on rural roads, either through speed camera or presence of police officers, to monitor drivers’ compliance with road safety rules.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Safe operations regulation in transportation',
    Definition: 'Regulatory reform and enforcement of safe operations standards and practices in transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Security monitoring systems in transportation',
    Definition: 'Security provisions for transportation, to combat crime, including piracy or robbery, through monitoring systems, for example CCTV.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Security personnel in transportation',
    Definition: 'Security provisions for transportation, to combat crime, including piracy or robbery, through personnel: security guards or officers.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Supply-side subsidisation for transport',
    Definition: 'Any form of subsidisation, including tax breaks, provided to private transportation infrastructure builders or operators.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Toll roads and road charges',
    Definition: 'These interventions establish public or private roads that charge users a fee (usually collected at toll gates or booths) for using them.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Traffic laws',
    Definition: 'Regulatory reform and enforcement of traffic laws, including speed, lane control etc.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on roads, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Vehicle safety standards',
    Definition: 'Changes in, or roll out of, a system for vehicle inspection and regulation, ensuring cars on the road meet certain safety standards.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Road infrastructure',
    Definition1: 'Interventions pertaining to road infrastructure development.',
    DisaggregatedIntervention: 'Bridge construction',
    Definition: 'These interventions construct, or provide knowledge on how to, bridges.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Road infrastructure',
    Definition1: 'Interventions pertaining to road infrastructure development.',
    DisaggregatedIntervention: 'Bridge maintenance',
    Definition: 'These interventions conduct work to, or provide knowledge on how to, regularly maintain existing bridges.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Road infrastructure',
    Definition1: 'Interventions pertaining to road infrastructure development.',
    DisaggregatedIntervention: 'Bridge rehabilitation',
    Definition: 'These interventions conduct work to, or provide knowledge on how to, repair existing bridges, or parts of them, that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Road infrastructure',
    Definition1: 'Interventions pertaining to road infrastructure development.',
    DisaggregatedIntervention: 'Capacity building for transport builders',
    Definition: 'Specific capacity building for transportation infrastructure builders, including for regular maintenance.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Road infrastructure',
    Definition1: 'Interventions pertaining to road infrastructure development.',
    DisaggregatedIntervention: 'Capacity building for transport operators',
    Definition: 'Capacity building offered to those who operate/use transport infrastructure.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Road infrastructure',
    Definition1: 'Interventions pertaining to road infrastructure development.',
    DisaggregatedIntervention: 'Renewable energy infrastructure construction',
    Definition: 'These interventions construct, or provide knowledge on how to, renewable energy infrastructure (e.g. charging stations for electric vehicles).',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Road infrastructure',
    Definition1: 'Interventions pertaining to road infrastructure development.',
    DisaggregatedIntervention: 'Renewable energy infrastructure maintenance',
    Definition: 'These interventions conduct work to, or knowledge on how to, regularly maintain existing renewable energy infrastructure (e.g. charging stations for electric vehicles).',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Road infrastructure',
    Definition1: 'Interventions pertaining to road infrastructure development.',
    DisaggregatedIntervention: 'Renewable energy infrastructure rehabilitation',
    Definition: 'These interventions conduct work to, or knowledge on how to, repair existing renewable energy infrastructure (e.g. charging stations for electric vehicles) that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Road infrastructure',
    Definition1: 'Interventions pertaining to road infrastructure development.',
    DisaggregatedIntervention: 'Road quality monitoring',
    Definition: 'These interventions provide monitoring services for road quality, including training of monitoring professionals.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Road infrastructure',
    Definition1: 'Interventions pertaining to road infrastructure development.',
    DisaggregatedIntervention: 'Roadside services infrastructure construction',
    Definition: 'These interventions construct, or provide knowledge on how to, roadside service infrastructure (e.g. lorry parks/rest stops).',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Road infrastructure',
    Definition1: 'Interventions pertaining to road infrastructure development.',
    DisaggregatedIntervention: 'Roadside services infrastructure maintenance',
    Definition: 'These interventions conduct work to, or provide knowledge on how to, regularly maintain existing roadside service infrastructure (e.g. lorry parks/rest stops).',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Road infrastructure',
    Definition1: 'Interventions pertaining to road infrastructure development.',
    DisaggregatedIntervention: 'Roadside services infrastructure rehabilitation',
    Definition: 'These interventions conduct work to, or provide knowledge on how to, repair existing roadside service infrastructure (e.g. lorry parks/rest stops) that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Road infrastructure',
    Definition1: 'Interventions pertaining to road infrastructure development.',
    DisaggregatedIntervention: 'Rural road construction',
    Definition: 'These interventions construct, or provide knowledge on how to, roads in rural areas and/or between cities.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Road infrastructure',
    Definition1: 'Interventions pertaining to road infrastructure development.',
    DisaggregatedIntervention: 'Rural road maintenance',
    Definition: 'These interventions conduct work to, or provide knowledge on how to, regularly maintain existing rural roads.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Road infrastructure',
    Definition1: 'Interventions pertaining to road infrastructure development.',
    DisaggregatedIntervention: 'Rural road rehabilitation',
    Definition: 'These interventions conduct work to, or provide knowledge on how to, repair existing rural roads that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Vehicle fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve vehicle fleets and services.',
    DisaggregatedIntervention: 'Building or requisition of vehicle',
    Definition: 'Programmes to build or purchase vehicles.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Vehicle fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve vehicle fleets and services.',
    DisaggregatedIntervention: 'Capacity building for vehicle operators',
    Definition: 'Capacity building offered to vehicle operators.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Vehicle fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve vehicle fleets and services.',
    DisaggregatedIntervention: 'Cargo transport services',
    Definition: 'Provision or improvement of passenger transportation services.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Vehicle fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve vehicle fleets and services.',
    DisaggregatedIntervention: 'Development of vehicles',
    Definition: 'Development of new vehicle designs, including incorporating new technologies into existing vehicles.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Vehicle fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve vehicle fleets and services.',
    DisaggregatedIntervention: 'Maintenance of vehicle fleet',
    Definition: 'Programmes to maintain vehicle fleets.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Vehicle fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve vehicle fleets and services.',
    DisaggregatedIntervention: 'Operation of road vehicles',
    Definition: 'Changes in the operation procedures of road vehicles.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Vehicle fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve vehicle fleets and services.',
    DisaggregatedIntervention: 'Passenger services',
    Definition: 'Provision or improvement of passenger transportation services.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Vehicle fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve vehicle fleets and services.',
    DisaggregatedIntervention: 'Personal mobility vehicle adoption',
    Definition: 'Adoption interventions (promotion/provision/swap) for personal mobility vehicles (all types of individual muscle-electric-driven vehicles).',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Vehicle fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve vehicle fleets and services.',
    DisaggregatedIntervention: 'Renewable or efficient vehicle adoption',
    Definition: 'Adoption interventions (promotion/provision/swap) for renewable or energy efficient vehicles.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Vehicle fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve vehicle fleets and services.',
    DisaggregatedIntervention: 'Roadside assistance services',
    Definition: 'Professional services to aid drivers with mechanical or other issues whilst driving.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Road transport',
    InterventionGroup: 'Vehicle fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve vehicle fleets and services.',
    DisaggregatedIntervention: 'Roadside service stations',
    Definition: 'Provision of services to vehicle operators throughout the road network.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Administrative and governing bodies',
    Definition: 'Creation or reorganisation of administrative or governing bodies to manage transportation sectors.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Concessions',
    Definition: 'Allowing private enterprise to operate on public land or with publically owned assets.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Environmental regulation',
    Definition: 'Any regulation of pollution or environmental footprint. These include environmental auditing institutions.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Institutional capacity building of urban transport agencies',
    Definition: 'These interventions work with the government department that oversees urban transport to strengthen their capacity. This can be in terms or planning, technical skills in construction, and knowledge on management.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Legal framework for urban transport',
    Definition: 'Changes in the legal framework or enforcement for urban transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Licensing and permitting',
    Definition: 'Policies pertaining to licensing and permitting of businesses, including anti-corruption measures.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Parking policies',
    Definition: 'Policies on parking, such as public street parking hours. This includes setting and enforcing violations.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Privatisation of government owned assets',
    Definition: 'Transferring/selling government owned assets to private enterprise, including government owned shares',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public Private Partnerships (PPPs) in transportation',
    Definition: 'Cooperative arrangements between the government and private sector actors in the transportation sector. Public contracts for services/works to be completed by private enterprise; e.g. infrastructure construction/maintenance, etc.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public safety campaigns in transportation',
    Definition: 'Information campaigns to raise awareness of safety issues around transportation infrastructure and vehicles.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public safety laws in transportation',
    Definition: 'Regulatory reform and enforcement of public safety laws related to transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Public-private joint ventures',
    Definition: 'A commercial enterprise undertaken jointly by two or more parties which otherwise retain their distinct identities, where one of these entities is public.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Safe operations regulation in transportation',
    Definition: 'Regulatory reform and enforcement of safe operations standards and practices in transportation.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Security monitoring systems in transportation',
    Definition: 'Security provisions for transportation, to combat crime, including piracy or robbery, through monitoring systems, for example CCTV.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Security personnel in transportation',
    Definition: 'Security provisions for transportation, to combat crime, including piracy or robbery, through personnel: security guards or officers.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Supply-side subsidisation for transport',
    Definition: 'Any form of subsidisation, including tax breaks, provided to private transportation infrastructure builders or operators.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Traffic laws',
    Definition: 'Regulatory reform and enforcement of traffic laws, including speed, lane control etc.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Transport in city planning',
    Definition: 'Incorporation of transportation system planning in city management and planning.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Urban and metropolitan transport planning',
    Definition: 'These interventions provide trainings and information on urban and metropolitan transport planning.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Urban logistics',
    Definition: 'Policies and planning for urban logistics.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Urban road safety monitoring',
    Definition: 'These interventions provide monitoring services on urban roads, either through speed camera or presence of police officers, to monitor drivers’ compliance with road safety rules.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Law, regulation, & policy',
    Definition1: 'Governance interventions on urban transport, including legislation, regulation, and policy.',
    DisaggregatedIntervention: 'Vehicle safety standards',
    Definition: 'Changes in, or roll out of, a system for vehicle inspection and regulation, ensuring cars on the road meet certain safety standards.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve urban transport fleets and services.',
    DisaggregatedIntervention: 'Building or requisition of vehicle',
    Definition: 'Programmes to build or purchase vehicles.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve urban transport fleets and services.',
    DisaggregatedIntervention: 'Capacity building for vehicle operators',
    Definition: 'Capacity building offered to vehicle operators.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve urban transport fleets and services.',
    DisaggregatedIntervention: 'Cargo transport services',
    Definition: 'Provision or improvement of passenger transportation services.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve urban transport fleets and services.',
    DisaggregatedIntervention: 'Development of vehicles',
    Definition: 'Development of new vehicle designs, including incorporating new technologies into existing vehicles.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve urban transport fleets and services.',
    DisaggregatedIntervention: 'Maintenance of vehicle fleet',
    Definition: 'Programmes to maintain vehicle fleets.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve urban transport fleets and services.',
    DisaggregatedIntervention: 'Operation of road vehicles',
    Definition: 'Changes in the operation procedures of road vehicles.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve urban transport fleets and services.',
    DisaggregatedIntervention: 'Passenger services',
    Definition: 'Provision or improvement of passenger transportation services.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve urban transport fleets and services.',
    DisaggregatedIntervention: 'Personal mobility vehicle adoption',
    Definition: 'Adoption interventions (promotion/provision/swap) for personal mobility vehicles (all types of individual muscle-electric-driven vehicles).',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve urban transport fleets and services.',
    DisaggregatedIntervention: 'Renewable or efficient vehicle adoption',
    Definition: 'Adoption interventions (promotion/provision/swap) for renewable or energy efficient vehicles.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport fleet & services',
    Definition1: 'Interventions to expand, modernise, or otherwise improve urban transport fleets and services.',
    DisaggregatedIntervention: 'Smart transport',
    Definition: 'Integrated application of modern technologies and management strategies in transportation systems.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Bicycle path construction',
    Definition: 'These interventions construct or provide knowledge on how to construct bicycle paths.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Bicycle path maintenance',
    Definition: 'These interventions conduct work to or knowledge on how to regularly maintain existing bicycle paths.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Bicycle path rehabilitation',
    Definition: 'These interventions conduct work to or knowledge on how to repair existing bicycle paths that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Capacity building for transport builders',
    Definition: 'Specific capacity building for transportation infrastructure builders, including for regular maintenance.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Capacity building for transport operators',
    Definition: 'Capacity building offered to those who operate/use transport infrastructure.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Parking facility construction',
    Definition: 'These interventions construct or provide knowledge on how to construct parking facilities.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Parking facility maintenance',
    Definition: 'These interventions conduct work to or knowledge on how to regularly maintain existing parking facilities.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Parking facility rehabilitation',
    Definition: 'These interventions conduct work to or knowledge on how to repair existing parking facilities that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Pedestrian sidewalk and footpath construction',
    Definition: 'These interventions construct or provide knowledge on how to construct pedestrian sidewalks and footpaths.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Pedestrian sidewalk and footpath maintenance',
    Definition: 'These interventions conduct work to or knowledge on how to regularly maintain existing pedestrian sidewalks and footpaths.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Pedestrian sidewalk and footpath rehabilitation',
    Definition: 'These interventions conduct work to or knowledge on how to repair existing pedestrian sidewalks and footpaths that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Public transport construction',
    Definition: 'These interventions construct or provide knowledge on how to construct public transport in an urban setting. This can include metro and bus systems.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Public transport maintenance',
    Definition: 'These interventions conduct work to or knowledge on how to regularly maintain existing public transport systems.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Public transport rehabilitation',
    Definition: 'These interventions conduct work to or knowledge on how to repair existing public transport systems that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Renewable energy infrastructure construction',
    Definition: 'These interventions construct, or provide knowledge on how to, renewable energy infrastructure (e.g. charging stations for electric vehicles).',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Renewable energy infrastructure maintenance',
    Definition: 'These interventions conduct work to, or knowledge on how to, regularly maintain existing renewable energy infrastructure (e.g. charging stations for electric vehicles).',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Renewable energy infrastructure rehabilitation',
    Definition: 'These interventions conduct work to, or knowledge on how to, repair existing renewable energy infrastructure (e.g. charging stations for electric vehicles) that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Street lighting',
    Definition: 'Promotion of streetlight construction and maintenance in urban settings, often to reduce crime.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Traffic control systems (e.g. traffic lights)',
    Definition: 'These interventions construct or provide the knowledge to construct traffic control systems, such as traffic lights and roundabouts.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Urban freight infrastructure construction',
    Definition: 'These interventions construct or provide knowledge on how to construct urban freight infrastructure (city terminals, loading points, etc.).',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Urban freight infrastructure maintenance',
    Definition: 'These interventions conduct work to or provide knowledge on how to regularly maintain existing urban freight infrastructure (city terminals, loading points, etc.).',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Urban freight infrastructure rehabilitation',
    Definition: 'These interventions conduct work to or knowledge on how to repair existing urban freight infrastructure (city terminals, loading points, etc.) that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Urban road construction',
    Definition: 'These interventions construct or provide knowledge on how to construct roads in urban settings.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Urban road maintenance',
    Definition: 'These interventions conduct work to or provide knowledge on how to regularly maintain existing urban roads.',
  },
  {
    InterventionSector: 'Transportation',
    BroadGroup: 'Urban transport',
    InterventionGroup: 'Urban transport infrastructure',
    Definition1: 'Interventions pertaining to urban transport infrastructure development.',
    DisaggregatedIntervention: 'Urban road rehabilitation',
    Definition: 'These interventions conduct work to or knowledge on how to repair existing urban roads that are no longer usable, or difficult to use, to a safe standard.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Domestic hygiene',
    Definition1: 'Household-based interventions that aim to protect populations by reducing disease transmission risks in the local environment.',
    DisaggregatedIntervention: 'Household disinfection kits',
    Definition: 'Household disinfection kits are distributed to help a household clean a house during an outbreak to prevent ongoing transmission (and tend to have bleach, brushes, gloves, etc.).',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Domestic hygiene',
    Definition1: 'Household-based interventions that aim to protect populations by reducing disease transmission risks in the local environment.',
    DisaggregatedIntervention: 'Hygiene kits or Non-food item kits',
    Definition: 'These interventions provide hygiene kits. They typically include point-of-use water treatment products, soap, water storage containers, etc.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Domestic hygiene',
    Definition1: 'Household-based interventions that aim to protect populations by reducing disease transmission risks in the local environment.',
    DisaggregatedIntervention: 'Jerry can/vessel cleaning',
    Definition: 'These interventions clean the vessels (typically jerry cans) used to transport and store water with a disinfectant (most often chlorine).',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Domestic hygiene',
    Definition1: 'Household-based interventions that aim to protect populations by reducing disease transmission risks in the local environment.',
    DisaggregatedIntervention: 'Surface disinfectants',
    Definition: 'These interventions provide, and promote the use of, surface disinfectants (e.g. bleach) in communal areas/facilities.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Environmental hygiene',
    Definition1: 'Community-based interventions that aim to protect populations by reducing disease transmission risks in the local environment.',
    DisaggregatedIntervention: 'Recycling & reuse promotion',
    Definition: 'Interventions to promote recycling behaviour.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Environmental hygiene',
    Definition1: 'Community-based interventions that aim to protect populations by reducing disease transmission risks in the local environment.',
    DisaggregatedIntervention: 'Rubbish clearing',
    Definition: 'These interventions promote environmental clean-up and rubbish clearing including interventions to discourage trash burning, and encourage environmentally friendly disposal methods.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Environmental hygiene',
    Definition1: 'Community-based interventions that aim to protect populations by reducing disease transmission risks in the local environment.',
    DisaggregatedIntervention: 'Surface disinfectants',
    Definition: 'These interventions provide, and promote the use of, surface disinfectants (e.g. bleach) in communal areas/facilities.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Face washing promotion',
    Definition1: 'These interventions aim to improve face washing practices; this can include the provision of suitable materials (such as a face cloth).',
    DisaggregatedIntervention: 'Face washing promotion',
    Definition: 'These interventions aim to improve face washing practices; this can include the provision of suitable materials (such as a face cloth).',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Food hygiene',
    Definition1: 'Interventions to promote hygienic food handling practices.',
    DisaggregatedIntervention: 'Behavioural food hygiene promotion',
    Definition: 'These are interventions that encourage the practice of proper food hygiene at the household or community level through behavioural promotion, such as using social pressure, environmental cues, nudges, or triggers of disgust and shame.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Food hygiene',
    Definition1: 'Interventions to promote hygienic food handling practices.',
    DisaggregatedIntervention: 'Educational food hygiene promotion',
    Definition: 'These are interventions that encourage the practice of proper food hygiene practices at the household or community level through educational promotion, such as food hygiene education, teaching best practices, and information on food hygiene.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Food hygiene',
    Definition1: 'Interventions to promote hygienic food handling practices.',
    DisaggregatedIntervention: 'Food safety regulations',
    Definition: 'Government policies that establish quality assurance regulations for agricultural food products (excludes food safety regulations at point of sale)',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Hand hygiene interventions',
    Definition1: 'This includes all interventions that aim to improve hand hygiene practices and should be used when multiple types are being pooled; this can include interventions that promote handwashing, as well as ones that provide materials (such as soap).',
    DisaggregatedIntervention: 'Behavioural hand hygiene promotion',
    Definition: 'These are interventions that encourage the practice of proper handwashing at the household or community level through behavioural promotion, such as using social pressure, environmental cues, nudges, or triggers of disgust and shame.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Hand hygiene interventions',
    Definition1: 'This includes all interventions that aim to improve hand hygiene practices and should be used when multiple types are being pooled; this can include interventions that promote handwashing, as well as ones that provide materials (such as soap).',
    DisaggregatedIntervention: 'Educational hand hygiene promotion',
    Definition: 'These are interventions that encourage the practice of proper handwashing at the household or community level through educational promotion, such as hygiene education, teaching best practices, and information on hand hygiene.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Hand hygiene interventions',
    Definition1: 'This includes all interventions that aim to improve hand hygiene practices and should be used when multiple types are being pooled; this can include interventions that promote handwashing, as well as ones that provide materials (such as soap).',
    DisaggregatedIntervention: 'Handwashing stations',
    Definition: 'Provision or improving of handwashing stations.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Hand hygiene interventions',
    Definition1: 'This includes all interventions that aim to improve hand hygiene practices and should be used when multiple types are being pooled; this can include interventions that promote handwashing, as well as ones that provide materials (such as soap).',
    DisaggregatedIntervention: 'Provision of hand sanitizer',
    Definition: 'These are interventions where hand sanitizer (both alcohol and other-chemical based) has been provided at the household or community level. This may, or may not, be accompanied by hygiene promotion.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Hand hygiene interventions',
    Definition1: 'This includes all interventions that aim to improve hand hygiene practices and should be used when multiple types are being pooled; this can include interventions that promote handwashing, as well as ones that provide materials (such as soap).',
    DisaggregatedIntervention: 'Provision of soap',
    Definition: 'These are interventions where soap (including antibacterial soap) has been provided at the household or community level. This may, or may not, be accompanied by hygiene promotion.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Menstrual hygiene management (MHM)',
    Definition1: 'These interventions encourage the practice of proper menstrual hygiene management at the household or community level. This can be either educational (i.e. hygiene education; teaching best practices) or behavioural (i.e. using social pressure or triggers of disgust and shame).',
    DisaggregatedIntervention: 'Behavioural MHM promotion',
    Definition: 'These are interventions that encourage the practice of MHM at the household or community level through behavioural promotion, such as using social pressure, environmental cues, or nudges',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Menstrual hygiene management (MHM)',
    Definition1: 'These interventions encourage the practice of proper menstrual hygiene management at the household or community level. This can be either educational (i.e. hygiene education; teaching best practices) or behavioural (i.e. using social pressure or triggers of disgust and shame).',
    DisaggregatedIntervention: 'Educational MHM promotion',
    Definition: 'These are interventions that encourage the practice of MHM at the household or community level through educational promotion, such as hygiene education, teaching best practices, and information on menstrual hygiene.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Menstrual hygiene management (MHM)',
    Definition1: 'These interventions encourage the practice of proper menstrual hygiene management at the household or community level. This can be either educational (i.e. hygiene education; teaching best practices) or behavioural (i.e. using social pressure or triggers of disgust and shame).',
    DisaggregatedIntervention: 'Gender sensitive toilets',
    Definition: 'Interventions that facilitate the construction or designation of gender specific toilets, especially in schools.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Menstrual hygiene management (MHM)',
    Definition1: 'These interventions encourage the practice of proper menstrual hygiene management at the household or community level. This can be either educational (i.e. hygiene education; teaching best practices) or behavioural (i.e. using social pressure or triggers of disgust and shame).',
    DisaggregatedIntervention: 'Information campaigns - Menstrual hygiene management (MHM)',
    Definition: 'Interventions that provide information regarding menstrual hygiene, practices, and promote behavioural change.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Menstrual hygiene management (MHM)',
    Definition1: 'These interventions encourage the practice of proper menstrual hygiene management at the household or community level. This can be either educational (i.e. hygiene education; teaching best practices) or behavioural (i.e. using social pressure or triggers of disgust and shame).',
    DisaggregatedIntervention: 'Peer support groups for menstrual hygiene and sensitisation',
    Definition: 'Interventions that encourage the formation of peer support groups for menstrual hygiene and sensitisation.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Menstrual hygiene management (MHM)',
    Definition1: 'These interventions encourage the practice of proper menstrual hygiene management at the household or community level. This can be either educational (i.e. hygiene education; teaching best practices) or behavioural (i.e. using social pressure or triggers of disgust and shame).',
    DisaggregatedIntervention: 'Provision of menstrual care products with promotion',
    Definition: 'These interventions include the provision of materials to help manage menstrual hygiene at the household or community level; this can include sanitary napkins, tampons, disposal bins, or incinerators.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Vector Control',
    Definition1: 'Use of vector control methods to prevent the spread of disease.',
    DisaggregatedIntervention: 'Environmental insecticide spraying',
    Definition: 'Application of insecticides in the environment to deter/kill vectors, such as insecticide applied on walls. This includes most community-based spraying interventions.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Vector Control',
    Definition1: 'Use of vector control methods to prevent the spread of disease.',
    DisaggregatedIntervention: 'Insecticide Treated Nets (ITNs)',
    Definition: 'Use of ITNs to reduce human–vector contact, often used against malaria.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Vector Control',
    Definition1: 'Use of vector control methods to prevent the spread of disease.',
    DisaggregatedIntervention: 'Residual insecticides',
    Definition: 'Application of insecticides on surfaces to deter/kill vectors within households, such as insecticide applied on walls.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Hygiene',
    InterventionGroup: 'Vector Control',
    Definition1: 'Use of vector control methods to prevent the spread of disease.',
    DisaggregatedIntervention: 'Untreated bednets',
    Definition: 'Untreated bednets protection against malaria and mosquito bites',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Drainage',
    Definition1: 'All interventions that aim to provide the safe drainage of excreta. This can include desludging services or connecting existing systems to a sewerage system or septic tank.',
    DisaggregatedIntervention: 'Centralized rural drainage',
    Definition: 'Centralized rural drainage systems.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Drainage',
    Definition1: 'All interventions that aim to provide the safe drainage of excreta. This can include desludging services or connecting existing systems to a sewerage system or septic tank.',
    DisaggregatedIntervention: 'Centralized urban drainage',
    Definition: 'Centralized urban drainage systems.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Drainage',
    Definition1: 'All interventions that aim to provide the safe drainage of excreta. This can include desludging services or connecting existing systems to a sewerage system or septic tank.',
    DisaggregatedIntervention: 'Construction of rural drainage',
    Definition: 'Construction or improvement of rural drainage systems.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Drainage',
    Definition1: 'All interventions that aim to provide the safe drainage of excreta. This can include desludging services or connecting existing systems to a sewerage system or septic tank.',
    DisaggregatedIntervention: 'Construction of urban drainage',
    Definition: 'Construction or improvement of urban drainage systems.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Drainage',
    Definition1: 'All interventions that aim to provide the safe drainage of excreta. This can include desludging services or connecting existing systems to a sewerage system or septic tank.',
    DisaggregatedIntervention: 'Informal rural drainage',
    Definition: 'Construction of informal rural drainage systems.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Drainage',
    Definition1: 'All interventions that aim to provide the safe drainage of excreta. This can include desludging services or connecting existing systems to a sewerage system or septic tank.',
    DisaggregatedIntervention: 'Informal urban drainage',
    Definition: 'Construction of informal urban drainage systems.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Drainage',
    Definition1: 'All interventions that aim to provide the safe drainage of excreta. This can include desludging services or connecting existing systems to a sewerage system or septic tank.',
    DisaggregatedIntervention: 'Maintenance of rural drainage',
    Definition: 'Maintenance of rural drainage systems.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Drainage',
    Definition1: 'All interventions that aim to provide the safe drainage of excreta. This can include desludging services or connecting existing systems to a sewerage system or septic tank.',
    DisaggregatedIntervention: 'Maintenance of urban drainage',
    Definition: 'Maintenance of urban drainage systems.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Latrine and toilet provision',
    Definition1: 'These interventions provide new or improved hardware for latrines, or other means of excreta disposal',
    DisaggregatedIntervention: 'Accessible latrines and toilets',
    Definition: 'Intervention that outfit or facilitate access to toilets for the elderly or handicapped.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Latrine and toilet provision',
    Definition1: 'These interventions provide new or improved hardware for latrines, or other means of excreta disposal',
    DisaggregatedIntervention: 'Community latrine and toilet provision',
    Definition: 'These interventions provide new or improved hardware for latrines, or other means of excreta disposal, for the community. A shared facility provided for a defined group of residents or an entire settlement / community. It is normally located in or near the community area and used by almost community members.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Latrine and toilet provision',
    Definition1: 'These interventions provide new or improved hardware for latrines, or other means of excreta disposal',
    DisaggregatedIntervention: 'Gender sensitive toilets',
    Definition: 'Interventions that facilitate the construction or designation of gender specific toilets, especially in schools.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Latrine and toilet provision',
    Definition1: 'These interventions provide new or improved hardware for latrines, or other means of excreta disposal',
    DisaggregatedIntervention: 'Health facility latrine and toilet provision',
    Definition: 'These interventions provide new or improved hardware for latrines, or other means of excreta disposal, at a health facility.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Latrine and toilet provision',
    Definition1: 'These interventions provide new or improved hardware for latrines, or other means of excreta disposal',
    DisaggregatedIntervention: 'Household latrine and toilet provision',
    Definition: 'These interventions provide new or improved hardware for latrines, or other means of excreta disposal, at the household level (i.e. private toilets).',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Latrine and toilet provision',
    Definition1: 'These interventions provide new or improved hardware for latrines, or other means of excreta disposal',
    DisaggregatedIntervention: 'Public latrine and toilet provision',
    Definition: 'These interventions provide new or improved hardware for latrines, or other means of excreta disposal, at a public place. These are provided for the floating population/general public in places such as markets, train stations or other public areas and used by mostly undefined users.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Latrine and toilet provision',
    Definition1: 'These interventions provide new or improved hardware for latrines, or other means of excreta disposal',
    DisaggregatedIntervention: 'Sanitation hardware for child care',
    Definition: 'These interventions provide new or improved hardware for latrines, or other means of excreta disposal, specifically for children, such as children\'s potties and changing stations.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Latrine and toilet provision',
    Definition1: 'These interventions provide new or improved hardware for latrines, or other means of excreta disposal',
    DisaggregatedIntervention: 'School latrine and toilet provision',
    Definition: 'These interventions provide new or improved hardware for latrines, or other means of excreta disposal, at a school.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Onsite waste management',
    Definition1: 'A sanitation system in which excreta and wastewater are collected and stored or treated on the plot where they are generated',
    DisaggregatedIntervention: 'Composting',
    Definition: 'Interventions to promote safe composting practices in waste management.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Onsite waste management',
    Definition1: 'A sanitation system in which excreta and wastewater are collected and stored or treated on the plot where they are generated',
    DisaggregatedIntervention: 'Manual scavenging',
    Definition: 'Manual scavenging refers to the unsafe and manual removal of raw (fresh and untreated) human excreta from buckets or other containers that are used as toilets or from the pits of simple pit latrines.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Onsite waste management',
    Definition1: 'A sanitation system in which excreta and wastewater are collected and stored or treated on the plot where they are generated',
    DisaggregatedIntervention: 'Pit latrines',
    Definition: 'These interventions provide, or encourage the uptake of, pit latrines.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Onsite waste management',
    Definition1: 'A sanitation system in which excreta and wastewater are collected and stored or treated on the plot where they are generated',
    DisaggregatedIntervention: 'Septic tank',
    Definition: 'These interventions provide, or encourage the uptake of, septic tanks.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Sanitation marketing (for providers)',
    Definition1: 'These interventions use business principles to work with sanitation service providers at meeting the local demand for sanitation.',
    DisaggregatedIntervention: 'Sanitation marketing for providers',
    Definition: 'These interventions use business principles to work with sanitation service providers at meeting the local demand for sanitation.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Sanitation promotion (to users)',
    Definition1: 'Encouraging the use of latrines, and other improved sanitation practices, at the household or community level.',
    DisaggregatedIntervention: 'Behavioural sanitation promotion',
    Definition: 'These are interventions that encourage the practice of proper sanitation practices at the household or community level through behavioural promotion, such as using social pressure, environmental cues, nudges, or triggers of disgust and shame.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Sanitation promotion (to users)',
    Definition1: 'Encouraging the use of latrines, and other improved sanitation practices, at the household or community level.',
    DisaggregatedIntervention: 'Community-led total sanitation (CLTS)',
    Definition: 'CLTS is a participatory mechanism that mobilises communities by facilitating their own appraisal of, and action plan to address, open defecation. It often includes ‘walks of shame’, and the use of social pressures to elicit behaviour change.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Sanitation promotion (to users)',
    Definition1: 'Encouraging the use of latrines, and other improved sanitation practices, at the household or community level.',
    DisaggregatedIntervention: 'Educational sanitation promotion',
    Definition: 'These are interventions that encourage the practice of proper sanitation practices at the household or community level through educational promotion, such as sanitation management education, teaching best practices, and information on sanitation and waste management.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Sanitation promotion (to users)',
    Definition1: 'Encouraging the use of latrines, and other improved sanitation practices, at the household or community level.',
    DisaggregatedIntervention: 'Subsidy for latrine',
    Definition: 'These are interventions where a subsidy has been provided on cost of the materials to construct a latrine.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Sanitation promotion (to users)',
    Definition1: 'Encouraging the use of latrines, and other improved sanitation practices, at the household or community level.',
    DisaggregatedIntervention: 'Subsidy with sanitation promotion',
    Definition: 'These are interventions that have provided a subsidy and some form of sanitation promotion (except for CLTS).',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Sanitation promotion (to users)',
    Definition1: 'Encouraging the use of latrines, and other improved sanitation practices, at the household or community level.',
    DisaggregatedIntervention: 'Total sanitation campaign',
    Definition: 'This is a specific intervention designed by the Indian government. It involves an education component to drive-up the demand for sanitation services, and then in some areas also a subsidy for those below the poverty line.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Sewerage',
    Definition1: 'All interventions that aim to provide the safe drainage of excreta. This can include desludging services or connecting existing systems to a sewerage system or septic tank.',
    DisaggregatedIntervention: 'Faecal sludge management',
    Definition: 'Improvements in the management of the collection, transport, and treatment of faecal sludge from pit latrines, septic tanks or other onsite sanitation systems.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Sewerage',
    Definition1: 'All interventions that aim to provide the safe drainage of excreta. This can include desludging services or connecting existing systems to a sewerage system or septic tank.',
    DisaggregatedIntervention: 'Septic tank',
    Definition: 'These interventions provide, or encourage the uptake of, septic tanks.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Sewerage',
    Definition1: 'All interventions that aim to provide the safe drainage of excreta. This can include desludging services or connecting existing systems to a sewerage system or septic tank.',
    DisaggregatedIntervention: 'Sewer connection',
    Definition: 'These interventions construct sewer systems, or provide, or encourage the uptake of, connections to the existing sewer system.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Sewerage',
    Definition1: 'All interventions that aim to provide the safe drainage of excreta. This can include desludging services or connecting existing systems to a sewerage system or septic tank.',
    DisaggregatedIntervention: 'Sewer maintenance',
    Definition: 'Sewer maintenance to ensure that the system continues to operate and to renew the aging infrastructure',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Sanitation',
    InterventionGroup: 'Sewerage',
    Definition1: 'All interventions that aim to provide the safe drainage of excreta. This can include desludging services or connecting existing systems to a sewerage system or septic tank.',
    DisaggregatedIntervention: 'Wastewater treatment plants',
    Definition: 'Building or improvement of wastewater treatment plants.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH administration and policies',
    InterventionGroup: 'Communication strategies',
    Definition1: 'Communications methods targeting topics relevant to WaSH.',
    DisaggregatedIntervention: 'Digital materials for WaSH',
    Definition: 'Production and/or dissemination of digital materials to support WaSH campaigns.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH administration and policies',
    InterventionGroup: 'Communication strategies',
    Definition1: 'Communications methods targeting topics relevant to WaSH.',
    DisaggregatedIntervention: 'SMS messaging for WaSH',
    Definition: 'Use of SMS messaging to communicate about topics in WaSH, or provide other WaSH-related public service announcements.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH administration and policies',
    InterventionGroup: 'Communication strategies',
    Definition1: 'Communications methods targeting topics relevant to WaSH.',
    DisaggregatedIntervention: 'Social marketing',
    Definition: 'Use of traditional marketing methods to promote WaSH initiatives and awareness.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH administration and policies',
    InterventionGroup: 'Decentralisation of WaSH',
    Definition1: 'Activities to decentralise design, ownership, or management of WaSH infrastructure and services.',
    DisaggregatedIntervention: 'Community-driven Development & Reconstruction (CDD & CDR)',
    Definition: 'Development initiative that provides control of the development process, resources and decision making authority directly to groups in the community. This includes both community-driven development (CDD) and community driven reconstruction (CDR).',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH administration and policies',
    InterventionGroup: 'Decentralisation of WaSH',
    Definition1: 'Activities to decentralise design, ownership, or management of WaSH infrastructure and services.',
    DisaggregatedIntervention: 'Water users associations',
    Definition: 'A grouping of water users, often for agricultural purposes, who pool their financial, technical, material, and human resources for the operation and maintenance of a water system.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH administration and policies',
    InterventionGroup: 'Improving operator performance',
    Definition1: 'These interventions improve access to WASH facilities and services by improving the functioning of the current service provider. This includes improving accountability, increasing oversight/regulation, and changing the financing structure.',
    DisaggregatedIntervention: 'Circuit rider programmes',
    Definition: 'Programmes in which technical experts are sent to visit communities and water/sanitation treatment plants to provide training and assistance. This is typically set in a rural context.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH administration and policies',
    InterventionGroup: 'Improving operator performance',
    Definition1: 'These interventions improve access to WASH facilities and services by improving the functioning of the current service provider. This includes improving accountability, increasing oversight/regulation, and changing the financing structure.',
    DisaggregatedIntervention: 'General maintenance of latrines',
    Definition: 'Maintaining cleanliness and ensuring good level of hygiene for facilities',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH administration and policies',
    InterventionGroup: 'Improving operator performance',
    Definition1: 'These interventions improve access to WASH facilities and services by improving the functioning of the current service provider. This includes improving accountability, increasing oversight/regulation, and changing the financing structure.',
    DisaggregatedIntervention: 'General maintenance of sanitation facilities',
    Definition: 'Maintaining cleanliness and ensuring good level of hygiene for facilities',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH administration and policies',
    InterventionGroup: 'Improving operator performance',
    Definition1: 'These interventions improve access to WASH facilities and services by improving the functioning of the current service provider. This includes improving accountability, increasing oversight/regulation, and changing the financing structure.',
    DisaggregatedIntervention: 'Improving operator performance',
    Definition: 'These interventions improve access to WASH facilities and services by improving the functioning of the current service provider. This includes improving accountability, increasing oversight/regulation, and changing the financing structure.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH administration and policies',
    InterventionGroup: 'Improving operator performance',
    Definition1: 'These interventions improve access to WASH facilities and services by improving the functioning of the current service provider. This includes improving accountability, increasing oversight/regulation, and changing the financing structure.',
    DisaggregatedIntervention: 'Operator training and certification',
    Definition: 'Training and certification schemes for water suppliers and sanitation workers.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH administration and policies',
    InterventionGroup: 'Legal environment & enforcement',
    Definition1: 'Policies that govern the use of facilities and regulate behaviour.',
    DisaggregatedIntervention: 'Open defaecation policy',
    Definition: 'Change or enforcement of laws/regulation concerning open defaecation.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH administration and policies',
    InterventionGroup: 'Legal environment & enforcement',
    Definition1: 'Policies that govern the use of facilities and regulate behaviour.',
    DisaggregatedIntervention: 'Segregated facility policy',
    Definition: 'Change or enforcement of laws/regulation concerning segregation of WaSH facilities (e.g. by gender, age, etc.).',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH administration and policies',
    InterventionGroup: 'Legal environment & enforcement',
    Definition1: 'Policies that govern the use of facilities and regulate behaviour.',
    DisaggregatedIntervention: 'Wastewater management policy',
    Definition: 'Change or enforcement of laws/regulation concerning wastewater management, including discharging.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH administration and policies',
    InterventionGroup: 'Private sector involvement',
    Definition1: 'These interventions encourage the private sector, including not for profits, to become the providers of WASH facilities and services on a commercial basis.',
    DisaggregatedIntervention: 'Private sector involvement in WaSH',
    Definition: 'These interventions encourage the private sector, including not for profits, to become the providers of WASH facilities and services on a commercial basis.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH administration and policies',
    InterventionGroup: 'Private sector involvement',
    Definition1: 'These interventions encourage the private sector, including not for profits, to become the providers of WASH facilities and services on a commercial basis.',
    DisaggregatedIntervention: 'Small scale independent providers',
    Definition: 'Use of Small-Scale Independent Providers (SSIPs) to provide sanitation and water supply equipment and expertise by local businesses, tailored to the local context.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH administration and policies',
    InterventionGroup: 'WaSH finance',
    Definition1: 'Finance models for WaSH expansion, maintenance, and use.',
    DisaggregatedIntervention: 'User fees and tariffs',
    Definition: 'Introduction or changes in user fees/tariffs for access to water or sanitation facilities.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'WaSH administration and policies',
    InterventionGroup: 'WaSH finance',
    Definition1: 'Finance models for WaSH expansion, maintenance, and use.',
    DisaggregatedIntervention: 'WaSH infrastructure financing',
    Definition: 'Interventions to provide financial support to promote construction and use of water supplies and sanitation facilities, including subsidies, grants, or loans.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'At-school water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved water supply at a schools. This can include standpipes, rain water tanks, or dug wells.',
    DisaggregatedIntervention: 'At-school piped water connection',
    Definition: 'These intervention provide a piped water connection to schools, connected to the main supply system.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'At-school water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved water supply at a schools. This can include standpipes, rain water tanks, or dug wells.',
    DisaggregatedIntervention: 'At-school protected standpipe',
    Definition: 'These interventions provide, or promote, the construction of a protected standpipe at schools, which is a vertical pipe extending from a water supply.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'At-school water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved water supply at a schools. This can include standpipes, rain water tanks, or dug wells.',
    DisaggregatedIntervention: 'Borehole with hand pump',
    Definition: 'These interventions provide, or promote, the construction of a communal borehole, which is a deep, narrow hole in the ground down to water. Specifically, this code is used when boreholes are paired with manual hand pumps to draw water.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'At-school water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved water supply at a schools. This can include standpipes, rain water tanks, or dug wells.',
    DisaggregatedIntervention: 'Borehole with standpipe',
    Definition: 'These interventions provide, or promote, the construction of a communal borehole, which is a deep, narrow hole in the ground down to water, including both mechanised or solar pumps. Specifically, this code is used when boreholes are paired with standpipes to draw water.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'At-school water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved water supply at a schools. This can include standpipes, rain water tanks, or dug wells.',
    DisaggregatedIntervention: 'Protected dug well',
    Definition: 'These interventions provide, or promote, the construction of a protected dug well, which is a well made by excavating with hand tools or power machinery (instead of drilling).',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'At-school water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved water supply at a schools. This can include standpipes, rain water tanks, or dug wells.',
    DisaggregatedIntervention: 'Protected spring',
    Definition: 'These interventions provide, or promote, the construction of a protected spring.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'At-school water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved water supply at a schools. This can include standpipes, rain water tanks, or dug wells.',
    DisaggregatedIntervention: 'Rain water harvesting at schools',
    Definition: 'These interventions provide, or promote, the collection and storage of rain, rather than allowing it to run off at schools.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'At-school water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved water supply at a schools. This can include standpipes, rain water tanks, or dug wells.',
    DisaggregatedIntervention: 'Rehabilitation of water sources',
    Definition: 'Interventions relating to the maintenance of water sources.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Communal water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved communal water supply or distribution method. This includes shared boreholes or standpipes.',
    DisaggregatedIntervention: 'Borehole with hand pump',
    Definition: 'These interventions provide, or promote, the construction of a communal borehole, which is a deep, narrow hole in the ground down to water. Specifically, this code is used when boreholes are paired with manual hand pumps to draw water.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Communal water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved communal water supply or distribution method. This includes shared boreholes or standpipes.',
    DisaggregatedIntervention: 'Communal protected standpipe',
    Definition: 'These interventions provide, or promote, the construction of a communal protected standpipe, which is a vertical pipe extending from a water supply.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Communal water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved communal water supply or distribution method. This includes shared boreholes or standpipes.',
    DisaggregatedIntervention: 'Communal rain water harvesting',
    Definition: 'These interventions provide, or promote, the collection and storage of rain, rather than allowing it to run off in communal areas.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Communal water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved communal water supply or distribution method. This includes shared boreholes or standpipes.',
    DisaggregatedIntervention: 'Protected dug well',
    Definition: 'These interventions provide, or promote, the construction of a protected dug well, which is a well made by excavating with hand tools or power machinery (instead of drilling).',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Communal water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved communal water supply or distribution method. This includes shared boreholes or standpipes.',
    DisaggregatedIntervention: 'Protected spring',
    Definition: 'These interventions provide, or promote, the construction of a protected spring.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Communal water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved water supply at a schools. This can include standpipes, rain water tanks, or dug wells.',
    DisaggregatedIntervention: 'Public standpipe',
    Definition: 'These interventions provide, or promote, the construction of a public standpipe, which is a vertical pipe extending from a water supply, including boreholes with standpipe (deep, narrow hole in the ground down to water)',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Communal water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved communal water supply or distribution method. This includes shared boreholes or standpipes.',
    DisaggregatedIntervention: 'Rehabilitation of water sources',
    Definition: 'Interventions relating to the maintenance of water sources.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Communal water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved communal water supply or distribution method. This includes shared boreholes or standpipes.',
    DisaggregatedIntervention: 'Water supply management',
    Definition: 'These interventions create or strengthen mechanisms for community management of water supplies.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Household water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved household water supply. This can include private standpipes, individual rain water tanks, or dug wells.',
    DisaggregatedIntervention: 'Borehole with hand pump',
    Definition: 'These interventions provide, or promote, the construction of a communal borehole, which is a deep, narrow hole in the ground down to water. Specifically, this code is used when boreholes are paired with manual hand pumps to draw water.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Household water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved household water supply. This can include private standpipes, individual rain water tanks, or dug wells.',
    DisaggregatedIntervention: 'Borehole with standpipe',
    Definition: 'These interventions provide, or promote, the construction of a communal borehole, which is a deep, narrow hole in the ground down to water, including both mechanised or solar pumps. Specifically, this code is used when boreholes are paired with standpipes to draw water.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Household water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved household water supply. This can include private standpipes, individual rain water tanks, or dug wells.',
    DisaggregatedIntervention: 'Household piped water connection',
    Definition: 'These intervention provide a piped water connection to households, connected to the main supply system.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Household water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved household water supply. This can include private standpipes, individual rain water tanks, or dug wells.',
    DisaggregatedIntervention: 'Household protected standpipe',
    Definition: 'These interventions provide, or promote, the construction of a household protected standpipe, which is a vertical pipe extending from a water supply.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Household water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved household water supply. This can include private standpipes, individual rain water tanks, or dug wells.',
    DisaggregatedIntervention: 'Household rain water harvesting',
    Definition: 'These interventions provide, or promote, the collection and storage of rain, rather than allowing it to run off at home.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Household water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved household water supply. This can include private standpipes, individual rain water tanks, or dug wells.',
    DisaggregatedIntervention: 'Protected dug well',
    Definition: 'These interventions provide, or promote, the construction of a protected dug well, which is a well made by excavating with hand tools or power machinery (instead of drilling).',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Household water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved household water supply. This can include private standpipes, individual rain water tanks, or dug wells.',
    DisaggregatedIntervention: 'Protected spring',
    Definition: 'These interventions provide, or promote, the construction of a protected spring.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Household water source',
    Definition1: 'Any intervention that provides, or promotes, a new or improved household water supply. This can include private standpipes, individual rain water tanks, or dug wells.',
    DisaggregatedIntervention: 'Rehabilitation of water sources',
    Definition: 'Interventions relating to the maintenance of water sources.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Imported water source',
    Definition1: 'These interventions create a water supply by trucking in water from a source outside of the community, or provide water in drums, bottles, or sachets',
    DisaggregatedIntervention: 'Individual water containers',
    Definition: 'Provision of water bottles or sachets for individual consumption',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Imported water source',
    Definition1: 'These interventions create a water supply by trucking in water from a source outside of the community, or provide water in drums, bottles, or sachets',
    DisaggregatedIntervention: 'Water processing station or bulk water treatment units',
    Definition: 'Water processing stations that are used to either fill tanks or pipe water to nearby communities.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Imported water source',
    Definition1: 'These interventions create a water supply by trucking in water from a source outside of the community, or provide water in drums, bottles, or sachets',
    DisaggregatedIntervention: 'Water tanker',
    Definition: 'A truck that transports water to the household/community',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Resilience & capacity',
    Definition1: 'Interventions to increase capacity to respond to water supply shocks.',
    DisaggregatedIntervention: 'Reservoirs',
    Definition: 'Construction and management of water reservoirs.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Resilience & capacity',
    Definition1: 'Interventions to increase capacity to respond to water supply shocks.',
    DisaggregatedIntervention: 'Response plans',
    Definition: 'Interventions that establish plans and logistic networks in case of water shortage.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Resilience & capacity',
    Definition1: 'Interventions to increase capacity to respond to water supply shocks.',
    DisaggregatedIntervention: 'Secondary water treatments',
    Definition: 'Interventions that provide, or promote the uptake of, secondary forms of water treatment or supply.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Resilience & capacity',
    Definition1: 'Interventions to increase capacity to respond to water supply shocks.',
    DisaggregatedIntervention: 'Water conservation',
    Definition: 'Interventions that regulate water consumption through consumption caps, excise taxing, quotas, fines etc., and promote water conservation such as high efficiency plumbing.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water supply',
    InterventionGroup: 'Resilience & capacity',
    Definition1: 'Interventions to increase capacity to respond to water supply shocks.',
    DisaggregatedIntervention: 'Water market regulation',
    Definition: 'Regulations pertaining to the collection of water for commercial purposes.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Point-of-use water treatments',
    Definition1: 'These interventions provide supplies for, or information on, water treatments that you use at the point-of-use (i.e. in the home or other facility) to remove microbial contaminants or safe water storage practices. This code should be used when a diverse set of point-of-use treatments have been combined in one analysis or the authors have not specified the type of treatment.',
    DisaggregatedIntervention: 'Biosand filters',
    Definition: 'These interventions provide, or promote the uptake of, Biosand filters for use at home. This filter is a household version of the slow sand filter where potentially pathogenic microorganisms are removed by a biofilm layer (known as the Schmutzdeke) which forms in the top few cm of the filter.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Point-of-use water treatments',
    Definition1: 'These interventions provide supplies for, or information on, water treatments that you use at the point-of-use (i.e. in the home or other facility) to remove microbial contaminants or safe water storage practices. This code should be used when a diverse set of point-of-use treatments have been combined in one analysis or the authors have not specified the type of treatment.',
    DisaggregatedIntervention: 'Boiling',
    Definition: 'These interventions promote the uptake of boiling water for consumption, using traditional (biofuel) or emerging technology (e.g. solar) stoves.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Point-of-use water treatments',
    Definition1: 'These interventions provide supplies for, or information on, water treatments that you use at the point-of-use (i.e. in the home or other facility) to remove microbial contaminants or safe water storage practices. This code should be used when a diverse set of point-of-use treatments have been combined in one analysis or the authors have not specified the type of treatment.',
    DisaggregatedIntervention: 'Ceramic filters',
    Definition: 'These interventions provide, or promote the uptake of, ceramic filters for use at home. This filter is made of a porous ceramic (fired clay) which removes microbes from drinking water by size exclusion.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Point-of-use water treatments',
    Definition1: 'These interventions provide supplies for, or information on, water treatments that you use at the point-of-use (i.e. in the home or other facility) to remove microbial contaminants or safe water storage practices. This code should be used when a diverse set of point-of-use treatments have been combined in one analysis or the authors have not specified the type of treatment.',
    DisaggregatedIntervention: 'Chlorination',
    Definition: 'These interventions provide, or promote the uptake of, chlorine-based products to remove pathogens from drinking water at home. This can include tablet or liquid forms of compounds that release hypochlorous acid (HOCl, strong disinfectant) or hypochlorite ions (ClO-, weak disinfectant) when added to water.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Point-of-use water treatments',
    Definition1: 'These interventions provide supplies for, or information on, water treatments that you use at the point-of-use (i.e. in the home or other facility) to remove microbial contaminants or safe water storage practices. This code should be used when a diverse set of point-of-use treatments have been combined in one analysis or the authors have not specified the type of treatment.',
    DisaggregatedIntervention: 'Flocculation/disinfection',
    Definition: 'These interventions provide, or promote the uptake of, flocculation/disinfection for use at home. These combined Coagulant-Chlorine disinfection systems are commercial kits that combine dry coagulant/flocculent and chlorine as tablets or sachets.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Point-of-use water treatments',
    Definition1: 'These interventions provide supplies for, or information on, water treatments that you use at the point-of-use (i.e. in the home or other facility) to remove microbial contaminants or safe water storage practices. This code should be used when a diverse set of point-of-use treatments have been combined in one analysis or the authors have not specified the type of treatment.',
    DisaggregatedIntervention: 'Microfilters',
    Definition: 'These interventions provide, or promote the uptake of, microfilters, to remove pathogens from drinking water at home.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Point-of-use water treatments',
    Definition1: 'These interventions provide supplies for, or information on, water treatments that you use at the point-of-use (i.e. in the home or other facility) to remove microbial contaminants or safe water storage practices. This code should be used when a diverse set of point-of-use treatments have been combined in one analysis or the authors have not specified the type of treatment.',
    DisaggregatedIntervention: 'Other filtration systems',
    Definition: 'Interventions that provide, or promote the uptake of, any other filtration techniques to remove pathogens from drinking water at home.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Point-of-use water treatments',
    Definition1: 'These interventions provide supplies for, or information on, water treatments that you use at the point-of-use (i.e. in the home or other facility) to remove microbial contaminants or safe water storage practices. This code should be used when a diverse set of point-of-use treatments have been combined in one analysis or the authors have not specified the type of treatment.',
    DisaggregatedIntervention: 'Reverse osmosis system',
    Definition: 'These interventions provide, or promote the uptake of, reverse osmosis to remove pathogens from drinking water at home.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Point-of-use water treatments',
    Definition1: 'These interventions provide supplies for, or information on, water treatments that you use at the point-of-use (i.e. in the home or other facility) to remove microbial contaminants or safe water storage practices. This code should be used when a diverse set of point-of-use treatments have been combined in one analysis or the authors have not specified the type of treatment.',
    DisaggregatedIntervention: 'Sediment filters',
    Definition: 'These interventions provide, or promote the uptake of, aggregate/sediment filters for use at home. A sediment filter traps and removes suspended solids from your water supply.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Point-of-use water treatments',
    Definition1: 'These interventions provide supplies for, or information on, water treatments that you use at the point-of-use (i.e. in the home or other facility) to remove microbial contaminants or safe water storage practices. This code should be used when a diverse set of point-of-use treatments have been combined in one analysis or the authors have not specified the type of treatment.',
    DisaggregatedIntervention: 'Solar disinfection (SODIS)',
    Definition: 'These interventions provide, or promote the uptake of, solar disinfection (SODIS). This method uses transparent polyethylene terephthalate (PET or PETE) bottles filled with aerated source water and leaves them in the sun to disinfect the water by solar UV and increased temperature.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Point-of-use water treatments',
    Definition1: 'These interventions provide supplies for, or information on, water treatments that you use at the point-of-use (i.e. in the home or other facility) to remove microbial contaminants or safe water storage practices. This code should be used when a diverse set of point-of-use treatments have been combined in one analysis or the authors have not specified the type of treatment.',
    DisaggregatedIntervention: 'UV disinfection',
    Definition: 'These interventions provide, or promote the uptake of, water treatment units that use a UV light to inactivate pathogens in the water',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Point-of-use water treatments',
    Definition1: 'These interventions provide supplies for, or information on, water treatments that you use at the point-of-use (i.e. in the home or other facility) to remove microbial contaminants or safe water storage practices. This code should be used when a diverse set of point-of-use treatments have been combined in one analysis or the authors have not specified the type of treatment.',
    DisaggregatedIntervention: 'Water softening',
    Definition: 'Removal of calcium, magnesium, and certain other metal cations in hard water.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Safe water storage',
    Definition1: 'These interventions provide supplies for, or information on, the proper storage of water after it has been treated so that it is not re-contaminated. This often includes providing a covered storage container with a tap to let water out.',
    DisaggregatedIntervention: 'Behavioural safe water storage promotion',
    Definition: 'These are interventions that encourage the practice of proper safe water storage practices at the household or community level through behavioural promotion, such as using social pressure, environmental cues, nudges, or triggers of disgust and shame.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Safe water storage',
    Definition1: 'These interventions provide supplies for, or information on, the proper storage of water after it has been treated so that it is not re-contaminated. This often includes providing a covered storage container with a tap to let water out.',
    DisaggregatedIntervention: 'Educational safe water storage promotion',
    Definition: 'These are interventions that encourage the practice of proper safe water storage practices at the household or community level through educational promotion, such as safe water storage education, teaching best practices, and information on water storage.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Safe water storage',
    Definition1: 'These interventions provide supplies for, or information on, the proper storage of water after it has been treated so that it is not re-contaminated. This often includes providing a covered storage container with a tap to let water out.',
    DisaggregatedIntervention: 'Safe water storage',
    Definition: 'These interventions provide supplies for the proper storage of water after it has been treated so that it is not re-contaminated. This often includes providing a covered storage container with a tap to let water out.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Source water treatments',
    Definition1: 'These interventions provide supplies for, or information on, water treatments that you use at the water source (i.e. at the communal water point) to remove microbial contaminants. This code should be used when a diverse set of at source treatments have been combined in one analysis or the authors have not specified the type of treatment.',
    DisaggregatedIntervention: 'At source chlorination',
    Definition: 'These interventions provide, or promote the uptake of, chlorine-based products to remove pathogens from drinking water at the source. This can include tablet or liquid forms of compounds that release chlorine ions when added to water.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Source water treatments',
    Definition1: 'These interventions provide supplies for, or information on, water treatments that you use at the water source (i.e. at the communal water point) to remove microbial contaminants. This code should be used when a diverse set of at source treatments have been combined in one analysis or the authors have not specified the type of treatment.',
    DisaggregatedIntervention: 'Centralized chlorination',
    Definition: 'Centralised chlorination involves the use of chlorine for disinfection within a centralised drinking water supply system',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Source water treatments',
    Definition1: 'These interventions provide supplies for, or information on, water treatments that you use at the water source (i.e. at the communal water point) to remove microbial contaminants. This code should be used when a diverse set of at source treatments have been combined in one analysis or the authors have not specified the type of treatment.',
    DisaggregatedIntervention: 'Desalination',
    Definition: 'Process that takes away mineral components from saline water.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Source water treatments',
    Definition1: 'These interventions provide supplies for, or information on, water treatments that you use at the water source (i.e. at the communal water point) to remove microbial contaminants. This code should be used when a diverse set of at source treatments have been combined in one analysis or the authors have not specified the type of treatment.',
    DisaggregatedIntervention: 'Saltwater intrusion well cleaning',
    Definition: 'These interventions first pump the well to remove physical debris (such as silt, sand, and debris) and then follow well disinfection. This technique is used specifically after ocean water has contaminated the well.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Source water treatments',
    Definition1: 'These interventions provide supplies for, or information on, water treatments that you use at the water source (i.e. at the communal water point) to remove microbial contaminants. This code should be used when a diverse set of at source treatments have been combined in one analysis or the authors have not specified the type of treatment.',
    DisaggregatedIntervention: 'Well disinfection',
    Definition: 'All interventions that involve a once off treatment of a well that has become contaminated. This can include shock chlorination, as well as traditional, floating, and pressed tablet pot chlorination.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Water testing',
    Definition1: 'These interventions provide individuals or communities with the results of water quality tests done at any local site (e.g. private well, community tap, etc.).',
    DisaggregatedIntervention: 'Microbiological indicator testing',
    Definition: 'Tests for presence of E. Coli, faecal coliforms, or other microbiological indicators in the water supply.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Water testing',
    Definition1: 'These interventions provide individuals or communities with the results of water quality tests done at any local site (e.g. private well, community tap, etc.).',
    DisaggregatedIntervention: 'Multi-test of water supply',
    Definition: 'Tests for presence of multiple forms of contamination in the water supply.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Water testing',
    Definition1: 'These interventions provide individuals or communities with the results of water quality tests done at any local site (e.g. private well, community tap, etc.).',
    DisaggregatedIntervention: 'Toxic metal testing',
    Definition: 'Tests for arsenic, lead, or other toxic metals present in the water supply.',
  },
  {
    InterventionSector: 'Water, Sanitation, & Hygiene',
    BroadGroup: 'Water treatment & storage',
    InterventionGroup: 'Water testing',
    Definition1: 'These interventions provide individuals or communities with the results of water quality tests done at any local site (e.g. private well, community tap, etc.).',
    DisaggregatedIntervention: 'Water quality information',
    Definition: 'These interventions provide individuals or communities with the results of water quality tests done at any local site (e.g. private well, community tap, etc.).',
  },
];

export default interventionData;


// Total data - 1583
